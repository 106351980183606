import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResumeService } from '../service/resume.service';

@Component({
  selector: 'app-resume-download',
  templateUrl: './resume-download.component.html',
  styleUrls: ['./resume-download.component.css']
})
export class ResumeDownloadComponent implements OnInit, OnDestroy {

  @Input() userUUid!: string;
  resumeDownloadRequested: string = '';
  resumeLinkShareRequested: string = '';
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private resumeService: ResumeService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  downloadResume(language: string) {
    this.resumeDownloadRequested = language;
    this.resumeService.downloadResume(language, this.userUUid).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeDownloadRequested = '';
        window.open(res.download_link, "_blank");
      }, (err: any) => {
        this.resumeDownloadRequested = '';
        this.toastr.error('Failed to download resume.', 'Error');
      });
  }

  shareResume(language: string) {
    let shareResume: any = window.navigator;
    let filesArray: any;
    if(shareResume.canShare() && shareResume.canShare({files: filesArray})) {
      shareResume.share({
          files: filesArray,
          title: 'Sharing Resume',
          text: 'Please find the attachment'
      })
      .then(() => console.log('Share was successful.'))
      .catch((error: any) => console.log('Sharing failed', error));
    } else {
      // console.log('Sharing not supported');
    }
    this.resumeLinkShareRequested = language;
    this.resumeService.downloadResume(language, this.userUUid).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeLinkShareRequested = '';
        window.open(res.download_link, "_blank");
      }, (err: any) => {
        this.resumeLinkShareRequested = '';
        this.toastr.error('Failed to share resume.', 'Error');
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
