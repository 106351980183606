import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '../login/services/authentication.service';
import { VerifyEmailStatusRoutingModule } from './verify-email-status-routing.module';
import { VerifyEmailStatusComponent } from './verify-email-status.component';

@NgModule({
    imports: [
        CommonModule,
        VerifyEmailStatusRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        VerifyEmailStatusComponent
    ],
    providers: [
        AuthenticationService
    ]
})

export class VerifyEmailStatusModule {
}
