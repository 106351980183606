import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

abstract class HttpCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | null;
  abstract put(req: HttpRequest<any>, res: HttpResponse<any>): void;
}

interface CacheEntry {
  url: string;
  response: HttpResponse<any>;
  entryTime: number;
  cacheGroup: string;
}

const MAX_CACHE_AGE = 24 * 60 * 60 * 1000; // in milliseconds

@Injectable({
  providedIn: 'root'
})
export class CacheService implements HttpCache {

  /**
   * variables to hold the caches
   */
  cache = new Map<string, CacheEntry | any>();

  /**
   * function for adding new cache enrty
   * @param req any
   * @param res any
   */
  put(req: HttpRequest<any>, res: HttpResponse<any>): void {
    /**
     * check if the cache exists or not
     * if cache exists delete that cache first
     * and then add new cache
     */
    const cachedResponse = this.cache.get(req.url);
    if (cachedResponse !== null ) {
      this.cache.delete(req.url);
    }
    const newCachedResponse = { url: req.urlWithParams, response: res, entryTime: Date.now(), cacheGroup: req.headers.get('cache_group') };
    this.cache.set(req.urlWithParams, newCachedResponse);
  }

  /**
   * function for retrieving caches stored in cache
   * @param req any
   */
  get(req: HttpRequest<any>): HttpResponse<any> | null {
    const cachedResponse = this.cache.get(req.url);
    if (!cachedResponse) {
      return null;
    }
    const isExpired = (Date.now() - cachedResponse.entryTime) > MAX_CACHE_AGE;
    if (isExpired) {
      this.cache.delete(req.url);
      return null;
    }
    return cachedResponse.response;
  }

  /**
   * function for deleting caches on logout
   */
  // deleteCacheOnLogOut(cacheStartingChar: string) {
  //   this.cache.forEach(entry => {
  //     if (entry.cacheGroup.startsWith(cacheStartingChar)) {
  //       this.cache.delete(entry.url);
  //     }
  //   });
  // }

  /**
   * function for deleting cache by group
   * @param group string from header (cache_group value)
   */
  deleteCacheByGroup( group: string | null) {
    this.cache.forEach((cache) => {
      if (cache.cacheGroup === group) {
        this.cache.delete(cache.url);
      }
    });
  }

  /**
   * function for deleting all the cached data
   */
  deleteAllCache() {
    this.cache.clear();
  }
}
