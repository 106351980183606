<ng-container *ngIf="hasResponse; else fetchingAboutUsDetails">
    <!-- Page header -->
    <section class="page-header bg-header-next">
        <div class="container">
            <div class="row">
                <div class="col-md-12 page-title text-center">
                    <h2>Contact Us</h2>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Page header -->

    <!-- Main contents -->
    <section class="content content-section">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="title-header">
                        <p>Contact</p>
                        <h1>Get in Touch</h1>
                    </div>
                    <div>
                        <p [innerHTML]="aboutUsDescription"></p>
                    </div>
                </div>
                <div class="col-md-5 offset-md-1">	
                    <div class="contactInfo d-flex">
                        <div class="rounded">
                            <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div class="c-detail">
                            <strong>Visit Us</strong>
                            <p>NAX Building 9F, Shinkawa 1-3-2, Chuo-ku , Tokyo 104-0033 </p>
                        </div>
                    </div>
                    <div class="contactInfo d-flex">
                        <div class="rounded">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="c-detail">
                            <strong>Mail Us</strong>
                            <p><a href="mailto:info@bnpj.jp">info@bnpj.jp</a></p>
                        </div>
                    </div>				
                    <div class="contactInfo d-flex">
                        <div class="rounded">
                            <i class="fas fa-phone-alt"></i>
                        </div>
                        <div class="c-detail">
                            <strong>Call Us</strong>
                            <p><a href="tel:03-6222-8365">03-6222-8365</a></p>
                            <p><i class="far fa-clock"></i> Reception time 9:30am～5:30pm</p>
                            <p><i>※</i> Closed on Saturdays, Sundays, and holiday</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->

    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form class="frm-panel" [formGroup]="contactUsForm" (ngSubmit)="submitContactUsMessage()">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Name</label>
                                <input class="form-control" type="text" formControlName="name" placeholder="">
                                <ng-container *ngIf="contactUsForm.controls.name.errors && (contactUsForm.controls.name.dirty || contactUsForm.controls.name.touched || contactUsFormSubmitted)">
                                    <ng-container *ngIf="contactUsForm.controls.name.errors?.required">
                                        <div class="help-block text-danger mt-1">Please enter your name</div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Email address</label>
                                <input class="form-control" type="" formControlName="email" placeholder="Email address">
                                <ng-container *ngIf="contactUsForm.controls.email.errors && (contactUsForm.controls.email.dirty || contactUsForm.controls.email.touched || contactUsFormSubmitted)">
                                    <ng-container *ngIf="contactUsForm.controls.email.errors?.required">
                                        <div class="help-block text-danger mt-1">Please enter your email address</div>
                                    </ng-container>
                                    <ng-container *ngIf="contactUsForm.controls.email.errors?.email">
                                        <div class="help-block text-danger mt-1">Please enter a valid email</div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="form-group col-md-12">
                                <label>Message</label>
                                <textarea class="form-control" rows="5" formControlName="message"></textarea>
                                <ng-container *ngIf="contactUsForm.controls.message.errors && (contactUsForm.controls.message.dirty || contactUsForm.controls.message.touched || contactUsFormSubmitted)">
                                    <ng-container *ngIf="contactUsForm.controls.message.errors?.required">
                                        <div class="help-block text-danger mt-1">Please enter a message</div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="form-group col-md-12">
                                <re-captcha (resolved)="resolved($event)" data-theme="dark" ></re-captcha>
                                <div *ngIf="contactUsFormSubmitted && !captchaVerification" class="error">
                                    <span style="color : red">Please verify you are not a bot.</span>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-orange mt-2"><i class='fa fa-spinner fa-spin' *ngIf="showLoader"></i> Submit</button>
                            <!-- <input class="btn btn-orange mt-2" type="submit" Value="Submit"> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

    <!-- Google map -->
    <section class="g-map">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12" style="padding: 0px;">
                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56516.276891975016!2d85.29111309519689!3d27.709031933725658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600!5e0!3m2!1sen!2snp!4v1620569262854!5m2!1sen!2snp" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d19849.326138744807!2d139.7875812588656!3d35.67723315623284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sNAX%20Building%209F%2C%20Shinkawa%201-3-2%2C%20Chuo-ku%20%2C%20Tokyo!5e0!3m2!1sne!2snp!4v1625636355291!5m2!1sne!2snp" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Google map -->
</ng-container>
<ng-template #fetchingAboutUsDetails>
    <!-- Page header -->
	<section>
		<div class="container-fluid loading-skeleton">
			<div class="row">
				<div class="col-md-12 no-padding">
					<div class="s-banner inner">
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Page header -->

	<div class="container loading-skeleton">
		<div class="row">
			<div class="col-6 mt-5">
				<div class="s-banner inner rounded">
				</div>
			</div>
			<div class="col-6 mt-5">
				<div class="card-body">
					<p class="d-inline-block">About About About</p><br>
					<h1 class="d-inline-block">BNPJ-Buy Now Pay</h1>
				</div>
				<div class="card-body text-center">
					<p>BNPJ (Buy Now Pay in Japan) is an Integrated Wallet and Community Engaging Mobile App Managed and Operated by 3PLATZ株式会社 (サンプラッツ株式会社)</p>
					<p>Integrated Community Service Features are completely free for all the users who download this App.</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>
