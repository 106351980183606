<!-- Page header -->
<!-- <section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>{{partnerServiceDetails?.service_product_name}}</h2>
            </div>
        </div>
    </div>
</section> -->
<!-- End of Page header -->
<ng-container *ngIf="hasResponse; else fetchingDataFromApi">
    <!-- Main contents -->
    <section class="bg-breadcrumb">
		<div class="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a [routerLink]="['/partner-services']">Partner Services</a></li>
					<li class="breadcrumb-item breadcrumb-link" (click)="redirectUsersToListingPage(partnerServiceDetails?.category)"><a class="link">{{partnerServiceDetails?.category}}</a></li>
					<li class="breadcrumb-item active" aria-current="page">{{partnerServiceDetails?.service_product_name}}</li>
				</ol>
			</nav>
		</div>
	</section>
    <section class="content detail-section content-with-breadcrumb-section">
    <!-- <section class="content content-section detail-section no-breadcrump-header"> -->
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <img [src]="assetBaseUrl + partnerServiceDetails?.company_banner_image || 'assets/img/default_banners/default-services.svg'" onerror="this.src='assets/img/default_banners/default-services.svg'" (click)="openPartnerServiceUrl(partnerServiceDetails?.service_url)" class="img-fluid details-img-section">
                    <h3 class="link" (click)="openPartnerServiceUrl(partnerServiceDetails?.service_url)">{{partnerServiceDetails?.service_product_name}}</h3>
                    <p [innerHTML]="partnerServiceDetails?.description"></p>
                </div>
                <div class="col-lg-3 right-card card-next">
                    <ng-container *ngIf="partnerServiceDetails?.service_packages.length > 0">
                        <div class="mb-5">
                            <h4 class="d-block">Our Packages</h4>
                            <ng-container *ngFor="let package of partnerServiceDetails?.service_packages; trackBy:trackById;">
                                <div class="package-list">
                                    <img class="img-fluid" src="assets/img/icon-package.svg">
                                    <div class="p-items">
                                        <p class="mb-1">{{package.package_name}}</p>
                                        <strong class="amt d-block">¥ {{package.package_price}}</strong>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                    </ng-container>
                    
                    <div class="recents-item servicesList">
                        <h4 class="d-block mb-1">{{partner_service_category_name}}</h4>
                        <!-- <ng-container *ngIf="hasCategorywiseData; else fetchingCategorywiseData"> -->
                            <ng-container *ngIf="categoryPartnerServiceList.length > 0; else fetchingCategorywiseData">
                                <ng-container *ngFor="let service of categoryPartnerServiceList; let i=index; trackBy:trackById;">
                                    <ng-container *ngIf="i < 3">
                                        <ng-container *ngIf="service == -1; else hasCategorywisePartnerServices">
                                            <h6 class="col-lg-12 mt-3">
                                                Data unavailable at the moment.
                                            </h6>
                                        </ng-container>
                                        <ng-template #hasCategorywisePartnerServices>
                                            <div class="post-list">
                                                <div class="service-items mt-2">
                                                    <img class="img-fluid" [imagepreloader]="assetBaseUrl + service.company_logo" [targetSection]="'sliderslogo'" >
                                                    <a (click)="partnerServiceDetail(service)" [routerLink]="['/partner-services/' + service.category + '/' + service.id]">{{service.service_product_name}}</a>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <!-- <ng-template #emptyCategorywiseData>
                                <h5>Data unavailable.</h5>
                            </ng-template> -->
                        <!-- </ng-container> -->
                        <ng-template #fetchingCategorywiseData>
                            <section class="content content-section detail-section loading-skeleton avoid-skeleton-padding">
                                <div class="container">
                                    <!-- <div class="row"> -->
                                        <!-- <div class="col-md-3 right-card card-next"> -->
                                            <!-- <div class="recents-item servicesList"> -->
                                                <!-- <h4 class="d-block mb-1">Featured</h4> -->
                                                <div class="post-list">								
                                                    <div class="service-items mt-2">
                                                        <div class="service-items mt-3 d-flex">
                                                            <div class="rounded-img"></div>	
                                                            <p>Nepal Telecom Test</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="post-list">									
                                                    <div class="service-items mt-2">
                                                        <div class="service-items mt-3 d-flex">
                                                            <div class="rounded-img"></div>	
                                                            <p>Nepal Telecom Test</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            <!-- </div> -->
                                        <!-- </div> -->
                                    <!-- </div> -->
                                </div>
                            </section>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->
</ng-container>
<ng-template #fetchingDataFromApi>
    <!-- Main contents -->
    <section class="content content-section detail-section loading-skeleton no-breadcrump-header">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <div class="inner s-content mb-4" style="height: 400px;">
                    </div>
                    <h2 class="d-block mb-3">Lorem ipsum dolor sit amet, consectetu</h2>
                    <span>Praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</span>
                </div>
                <div class="col-md-3 right-card card-next">
                    <div class="mb-5">
                        <h4 class="d-block">Our Packages</h4>
                        <div class="package-list d-flex">
                            <div class="s-default-img s-round mr-3" style="height: 70px; width: 80px"></div>
                            <div class="p-items">
                                <p class="mb-1">Package 1</p>
                                <strong class="amt d-block">¥ 1200</strong>
                            </div>
                        </div>
                        <div class="package-list d-flex">
                            <div class="s-default-img s-round mr-3" style="height: 70px; width: 80px"></div>
                            <div class="p-items">
                                <p class="mb-1">Package 1</p>
                                <strong class="amt d-block">¥ 1200</strong>
                            </div>
                        </div>
                    </div>
                    <div class="recents-item servicesList">
                        <h4 class="d-block mb-1">Featured</h4>
                        <div class="post-list">								
                            <div class="service-items mt-2">
                                <div class="service-items mt-3 d-flex">
                                    <div class="rounded-img"></div>	
                                    <p>Nepal Telecom Test</p>
                                </div>
                            </div>
                        </div>
                        <div class="post-list">									
                            <div class="service-items mt-2">
                                <div class="service-items mt-3 d-flex">
                                    <div class="rounded-img"></div>	
                                    <p>Nepal Telecom Test</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->
</ng-template>
