import { Directive, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[imagepreloader]',
  host: {
    '[attr.src]': 'finalImage'    // the attribute of the host element we want to update. in this case, <img 'src' />
  }
})
export class ImagepreloaderDirective implements OnInit{

  @Input('imagepreloader') targetSource!: string;
  @Input('targetSection') targetSection!: string;

  downloadingImage: any; // In class holder of remote image
  finalImage: any; // property bound to our host attribute.
  defaultImage= '';

  constructor() { }

  // ngOnInit is needed to access the @inputs() variables. these aren't available on constructor()
  ngOnInit() {
      if(this.targetSection == 'sliders') {
        this.defaultImage = 'assets/img/default_banners/default-slider-1.jpg';
      } else if(this.targetSection == 'sliderslogo') {
        this.defaultImage = 'assets/img/default_banners/default-companyLogo.svg';
      } else if(this.targetSection == 'services') {
        this.defaultImage = 'assets/img/default_banners/default-services.svg';
      } else if(this.targetSection == 'jpmanners') {
        this.defaultImage = 'assets/img/default_banners/default-jp-manner.svg';
      } else if(this.targetSection == 'newslogo'){
        this.defaultImage = 'assets/img/default_banners/default-news-logo.svg';
      } else if(this.targetSection == 'aboutus'){
        this.defaultImage = 'assets/img/default_banners/default_placeholder.png';
      } else {
        this.defaultImage = 'assets/img/default_banners/default-news.svg';
      }
    // First set the final image to some default image while we prepare our preloader:
    this.finalImage = this.defaultImage;

    this.downloadingImage = new Image();  // create image object
    this.downloadingImage.onload = () => { // Once image is completed, console.log confirmation and switch our host attribute
      // console.log('image downloaded');
      this.finalImage = this.targetSource;  // do the switch 😀
    }
    this.downloadingImage.onerror = () => { this.finalImage = this.defaultImage; }
    // Assign the src to that of some_remote_image_url. Since its an Image Object the
    // on assignment from this.targetSource download would start immediately in the background
    // and trigger the onload()
    this.downloadingImage.src = this.targetSource;
  }

}
