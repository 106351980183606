import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ServicesComponent } from './services.component';

const SERVICES: Routes = [
    { path: '', component : ServicesComponent},
    { path: ':categoryName/:serviceId', component : ServiceDetailsComponent}
];
@NgModule({
    imports: [RouterModule.forChild(SERVICES)],
    exports: [RouterModule]
})
export class ServicesRoutingModule {
}
