import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { JapaneseMannerDetailsComponent } from './japanese-manner-details/japanese-manner-details.component';
import { JapaneseMannerComponent } from './japanese-manner.component';

const JAPANESE_MANNER: Routes = [
    { path: '', component : JapaneseMannerComponent},
    { path: ':categoryname/:jpcategoryid/manner/:jpmannerid', component : JapaneseMannerDetailsComponent}
];
@NgModule({
    imports: [RouterModule.forChild(JAPANESE_MANNER)],
    exports: [RouterModule]
})
export class JapaneseMannerRoutingModule {
}
