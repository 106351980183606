import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { RefundPolicyComponent } from './refund-policy.component';

const REFUND_POLICY: Routes = [
    { path: '', component : RefundPolicyComponent}
];
@NgModule({
    imports: [RouterModule.forChild(REFUND_POLICY)],
    exports: [RouterModule]
})
export class RefundPolicyRoutingModule {
}
