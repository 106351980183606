import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private baseUrl = environment.apiUrl;
  /**
   * configuring required headers
   */
   private authHeader = new HttpHeaders({
    auth: 'true',
    'Content-Type': 'application/json',
    'cache': 'true',
    'cache_group': 'transaction'
   });

  constructor(private http: HttpClient) { }

  getTransactionStatement(page = 1, transaction_type = '', from_date = '', to_date = '') {
    let apiUrl = this.baseUrl + '/statements/?page=' + page;
    if(transaction_type && transaction_type != 'all') {
      apiUrl = apiUrl + '&transaction_type=' + transaction_type;
    }
    if(from_date && to_date) {
      apiUrl = apiUrl + '&from_date=' + from_date + '&to_date=' + to_date;
    }
    return this.http.get(apiUrl, {headers: this.authHeader});
  }
}
