import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LoadBalanceService } from '../load-balance/service/load-balance.service';
import { TransactionService } from './service/transaction.service';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  hasBalanceResponse: boolean = false;
  fetchingMoreData: boolean = false;
  /**
   * variables declared for filtering transactions by date
   */
  // currentDate = new Date();
  // today = this.currentDate.toISOString().split('T')[0];
  // priorDate = this.currentDate.setDate(this.currentDate.getDate() - 30 );
  // priorDateBefore30 = this.priorDate.toDateString().split('T')[0];
  dateFilterForm!: FormGroup;
  // filterByDate: boolean = false;
  showDateFilter: boolean = false;
  /**
   * variables declared for filtering the existing transactions
   */
  filterForm!: FormGroup;
  /**
   * variables declared for transaction listing
   */
  // hasTransactionResponse: boolean = false;
  transactionList: any[] = [];
  filteredTransactionList: any[] = [];
  availableTabs = [
    {name: 'all', current_page: 0, has_more_data: false},
    {name: 'topup', current_page: 0, has_more_data: false},
    {name: 'purchased', current_page: 0, has_more_data: false},
    {name: 'refund', current_page: 0, has_more_data: false}
  ];
  showLoadMoreButton: boolean = false;
  // availableTabs = ['all', 'topup', 'purchased', 'refund'];
  activeTab = 'all';
  /**
   * variables declared for fetching transactions page wise
   */
  allCurrentPageNumber = 0;
  topupCurrentPage = 0;
  purchaseCurrentPage = 0;
  refundCurrentPage = 0;
  /**
   * variables declared for user balance
   */
  userBalance = 0;
  userRewardPoints = 0;

  constructor(private transactionService: TransactionService, private loadBalanceService: LoadBalanceService,
    private formBuilder: FormBuilder) {
      // console.log("Today ", this.today);
      // console.log("30 days before ", this.priorDate);
    }

  ngOnInit(): void {
    /**
     * initialize filter form
     */
     this.filterForm = this.formBuilder.group({
      searchText: ['']
    });
    /**
     * initialize date wise filter form
     */
    this.dateFilterForm = this.formBuilder.group({
      from_date : [null],
      to_date : [null]
    });
    this.getUserBalance();
    this.availableTabs.forEach(obj => {
      this.getTransactionStatement(obj.name);
    });
    /**
     * subscribe to the change in value of a search text field
     */
     this.filterForm.controls.searchText.valueChanges
     .pipe(debounceTime(1000))
     .pipe(distinctUntilChanged())
     .pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(value => {
       if(value) {
         let lowercaseValue = value.toLocaleLowerCase();
         let existingFilteredData = this.transactionList.filter(obj => obj.transaction_name.toLocaleLowerCase().includes(lowercaseValue) || obj.remarks.toLocaleLowerCase().includes(lowercaseValue) || obj.reference_id.toLocaleLowerCase().includes(lowercaseValue) || obj.topup_by.toLocaleLowerCase().includes(lowercaseValue) || obj.user.toLocaleLowerCase().includes(lowercaseValue));
         if(existingFilteredData.length > 0) {
           this.filteredTransactionList = existingFilteredData;
         } else {
           this.filteredTransactionList = ['-2'];
           this.showLoadMoreButton = false;
         }
       } else {
         if(this.activeTab == 'all') { 
           this.filteredTransactionList = this.transactionList;
           if(this.filteredTransactionList.length <= 0) {
             this.filteredTransactionList = ['-1'];
             this.showLoadMoreButton = false;
           }
         } else {
           /**
            * filter data from active tab
            */
          this.filteredTransactionList = this.transactionList.filter((obj: any) => obj.transaction_type === this.activeTab);
          if(this.filteredTransactionList.length <= 0) {
            this.filteredTransactionList = ['-1'];
            this.showLoadMoreButton = false;
          }
         }
       }
     });
  }

  /**
   * function to fetch transaction statement
   * @param page page number
   * @param type transaction type
   */
  getTransactionStatement(type: string) {
    let indexOfTransactionType = this.availableTabs.findIndex(obj => obj.name === type);
    let page = this.availableTabs[indexOfTransactionType].current_page + 1;
    let fromDate = '';
    let toDate = '';
    if(this.showDateFilter) {
      fromDate = this.dateFilterForm.controls.from_date.value;
      toDate = this.dateFilterForm.controls.to_date.value;
    }
    this.transactionService.getTransactionStatement(page, type, fromDate, toDate).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchingMoreData = false;
        const transactions = res.statements;
        /**
         * check if the transaction type has more data or not
         */
        
        if(transactions.length >= 10) {
          this.availableTabs[indexOfTransactionType].has_more_data = true;
        } else {
          this.availableTabs[indexOfTransactionType].has_more_data = false;
        }
        
        /**
         * check if the transaction statement from response exists or not
         * if do not exists push into the list
         */
        transactions.forEach((newdata: any) => {
          let index = this.transactionList.findIndex(obj => obj.id === newdata.id);
          if(index < 0) {
            this.transactionList.push(newdata);
          }
        });
        /**
         * sort list in descending order by date
         */
        this.transactionList.sort((a,b) => +new Date(b.created_at) - +new Date(a.created_at));
        /**
         * filter data according to active tab panel
         */
        // if(this.transactionList.length > 0) {
          if(this.activeTab === 'all') {
            this.filteredTransactionList = this.transactionList;
            let indexOfAllTransactionType = this.availableTabs.findIndex(obj => obj.name === 'all');
            this.showLoadMoreButton = this.availableTabs[indexOfAllTransactionType].has_more_data;
          } else {
            this.filteredTransactionList = this.transactionList.filter((obj: any) => obj.transaction_type === this.activeTab);
            let indexOfActiveTransactionTab = this.availableTabs.findIndex(obj => obj.name === this.activeTab);
            this.showLoadMoreButton = this.availableTabs[indexOfActiveTransactionTab].has_more_data;
          }
          if(this.filteredTransactionList.length <= 0) {
            this.filteredTransactionList = ['-1'];
            this.showLoadMoreButton = false;
          }
        // } else {
        //   this.filteredTransactionList = ['-1'];
        // }
        /**
         * update page numbers
         */
        this.availableTabs[indexOfTransactionType].current_page = this.availableTabs[indexOfTransactionType].current_page + 1;
        // this.hasTransactionResponse = true;
      }, (err: any) => {
        this.fetchingMoreData = false;
      });
  }

  /**
   * 
   * @param type 
   */
  fetchMoreData(type: string) {
    this.fetchingMoreData = true;
    this.getTransactionStatement(type);
  }

  /**
   * function to filter transaction statement by transaction type
   * @param type transaction type
   */
  filterTransactionsByType(type: string, changeActiveTab = false) {
    if(changeActiveTab) {
      this.activeTab = type;
    }
    if(type == 'all') {
      this.filteredTransactionList = this.transactionList;
    } else {
      this.filteredTransactionList = this.transactionList.filter((obj: any) => obj.transaction_type === type);
    }
    let indexOfClickedTransactionTab = this.availableTabs.findIndex(obj => obj.name === type);
    this.showLoadMoreButton = this.availableTabs[indexOfClickedTransactionTab].has_more_data;
    if(this.filteredTransactionList.length <= 0) {
      this.filteredTransactionList = ['-1'];
      this.showLoadMoreButton = false;
    }
  }

  /**
   * function to fetch user balance information
   */
  getUserBalance() {
    this.loadBalanceService.fetchUserBalance().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.userBalance = res.balance;
        this.userRewardPoints = res.point; 
        this.hasBalanceResponse = true;
      });
  }

  /**
   * filter transaction statements by date
   */
  filterTransactionsByDate() {
    // this.filterByDate = true;
    this.allCurrentPageNumber = 0;
    this.topupCurrentPage = 0;
    this.purchaseCurrentPage = 0;
    this.refundCurrentPage = 0;
    this.transactionList = [];
    this.filteredTransactionList = [];
    this.availableTabs.forEach(obj => {
      this.getTransactionStatement(obj.name);
    });
  }

  /**
   * show or hide date filter on click
   */
  toogleDateFilterView() {
    this.showDateFilter = !this.showDateFilter;
  }

  trackById(index: number, item: any): any {
    if(item != -1 && item != '-1') {
      return item.id;
    }
    return item;
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
