import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { VerifyEmailStatusComponent } from './verify-email-status.component';

const VERIFY_EMAIL_STATUS_ROUTES: Routes = [
    { path: '', component : VerifyEmailStatusComponent}
];
@NgModule({
    imports: [RouterModule.forChild(VERIFY_EMAIL_STATUS_ROUTES)],
    exports: [RouterModule]
})
export class VerifyEmailStatusRoutingModule {
}
