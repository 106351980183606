import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RefundPolicyRoutingModule } from './refund-policy-routing.module';
import { RefundPolicyComponent } from './refund-policy.component';

@NgModule({
    imports: [
        CommonModule,
        RefundPolicyRoutingModule
    ],
    declarations: [
        RefundPolicyComponent
    ],
    providers: [
        
    ]
})

export class RefundPolicyModule {
}
