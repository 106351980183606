import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { LoadBalanceComponent } from './load-balance.component';

const LOAD_BALANCE: Routes = [
    { path: '', component : LoadBalanceComponent}
];
@NgModule({
    imports: [RouterModule.forChild(LOAD_BALANCE)],
    exports: [RouterModule]
})
export class LoadBalanceRoutingModule {
}
