import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../login/services/authentication.service';
import { CheckPasswordMatch, CheckPasswordPattern } from '../utils/custom.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  passwordResetForm!: FormGroup;
  passwordResetRequested: boolean = false;
  showLoader: boolean = false;
  resendCodeLoader: boolean = false;
  private email: String = '';
  activation_code = '';
  hideCodeInputField: boolean = false;
  /**
   * variables declared for show/hide password
   */
   newPasswordInputType: string = 'password';
   confirmPasswordInputType: string = 'password';

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private router: Router,
    private authenticationService: AuthenticationService, private activatedRoute: ActivatedRoute) {
      this.email = atob(this.activatedRoute.snapshot.paramMap.get('base64EncodedEmail') + '');
      this.activation_code = this.activatedRoute.snapshot.queryParamMap.get('reference') || '';
      if(this.activation_code) {
        this.hideCodeInputField = true;
      }
    }

  ngOnInit(): void {
    this.passwordResetForm = this.formBuilder.group({
      email: [this.email],
      // code: [{value: this.activation_code ? this.activation_code : '', disabled: this.activation_code ? true : false}, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      code: [this.activation_code ? this.activation_code : '', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      password: ['', [Validators.required, Validators.minLength(8), CheckPasswordPattern]],
      password_confirmation: ['', Validators.required]
    }, {validator: CheckPasswordMatch('password', 'password_confirmation')});
  }

  resetPassword() {
    this.passwordResetRequested = true;
    if (this.passwordResetForm.invalid) {
      return;
    }
    this.showLoader = true;
    this.authenticationService.requestPasswordReset(this.passwordResetForm.value)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.showLoader = false;
        this.passwordResetRequested = false;
        this.toastr.success(res.success);
        this.passwordResetForm.reset();
        this.router.navigate(['/login']);
      }, (err: any) => {
        this.showLoader = false;
        this.passwordResetRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error(err.error.error[Object.keys(err.error.error)[0]][0], 'Error !!');
      });
  }

  requestPasswordResetCode() {
    this.resendCodeLoader = true;
    const data = {
      email: this.email
    }
    this.authenticationService.requestPasswordResetCode(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.hideCodeInputField = false;
        this.resendCodeLoader = false;
        this.toastr.success(res.success);
      }, (err: any) => {
        this.resendCodeLoader = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error(err.error.error.email, 'Error');
      });
  }

  showNewPassword() {
    this.newPasswordInputType = 'text';
  }

  hideNewPassword() {
    this.newPasswordInputType = 'password';
  }

  showConfirmPassword() {
    this.confirmPasswordInputType = 'text';
  }

  hideConfirmPassword() {
    this.confirmPasswordInputType = 'password';
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
