import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadBalanceService {

  private baseUrl = environment.apiUrl;

  private authLoadBalanceHeader = new HttpHeaders({
    auth: 'true',
    'cache': 'true',
    'cache_group': 'loadbalance'
  });

  constructor(private http: HttpClient) { }

  fetchPaymentTypes() {
    return this.http.get(this.baseUrl + '/topup/payment_methods', {headers: this.authLoadBalanceHeader});
  }

  verifyEsewaPayment(data: any) {
    return this.http.post(this.baseUrl + '/topup/esewa/web/verify', data, {headers: this.authLoadBalanceHeader});
  }

  verifyKhaltiPayment(data: any) {
    return this.http.post(this.baseUrl + '/topup/khalti/verify', data, {headers: this.authLoadBalanceHeader});
  }

  cardPaymentDetails(cardDetails: any) {
    return this.http.post(this.baseUrl + '/topup/stripe', cardDetails, {headers: this.authLoadBalanceHeader});
  }

  initiateIMEPay(imeDetails: any) {
    return this.http.post(this.baseUrl + '/topup/imepay/initiate', imeDetails, {headers: this.authLoadBalanceHeader});
  }

  initiatePrabhuPay(prabhupayDetails: any) {
    return this.http.post(this.baseUrl + '/topup/prabhupay/initiate', prabhupayDetails, {headers: this.authLoadBalanceHeader});
  }

  removeSavedCard(card_number : number) {
    return this.http.delete(this.baseUrl + '/topup/delete/credit_card/'+ card_number, {headers: this.authLoadBalanceHeader});
  }

  fetchUserBalance() {
    const header = new HttpHeaders({
      auth: 'true',
      'cache': 'false'
    });
    return this.http.get(this.baseUrl + '/profile/get/balance', {headers: header});
  }
}
