import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPartnerServicesPipe } from './filter-partner-services.pipe';



@NgModule({
  declarations: [
    FilterPartnerServicesPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterPartnerServicesPipe
  ]
})
export class FilterPartnerServicesModule { }
