import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy.component';

const PRIVACY_POLICY: Routes = [
    { path: '', component : PrivacyPolicyComponent}
];
@NgModule({
    imports: [RouterModule.forChild(PRIVACY_POLICY)],
    exports: [RouterModule]
})
export class PrivacyPolicyRoutingModule {
}
