import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPartnerServices'
})
export class FilterPartnerServicesPipe implements PipeTransform {

  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();
    let filteredData =  items.filter(service => {
      return service.service_product_name.toLocaleLowerCase().includes(searchText);
    });
    if(filteredData.length <= 0) {
      return [-1];
    }
    return filteredData;
  }

}
