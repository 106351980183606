import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { JapaneseMannerRoutingModule } from './japanese-manner-routing.module';
import { JapaneseMannerComponent } from './japanese-manner.component';
import { JapaneseMannerDetailsComponent } from './japanese-manner-details/japanese-manner-details.component';
import { ImagepreloadermoduleModule } from '../utils/directives/imagepreloader.module';
import { FilterJapaneseMannersModule } from '../utils/pipes/filter-japanese-manners.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeResourceUrlModule } from '../utils/pipes/safe-resource-url.module';

@NgModule({
    imports: [
        CommonModule,
        JapaneseMannerRoutingModule,
        ImagepreloadermoduleModule,
        FilterJapaneseMannersModule,
        FormsModule,
        ReactiveFormsModule,
        SafeResourceUrlModule
    ],
    declarations: [
        JapaneseMannerComponent,
        JapaneseMannerDetailsComponent
    ],
    providers: [
        
    ]
})

export class JapaneseMannerModule {
}
