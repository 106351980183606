import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { TermsConditionsComponent } from './terms-conditions.component';

const TERMS_CONDITIONS: Routes = [
    { path: '', component : TermsConditionsComponent}
];
@NgModule({
    imports: [RouterModule.forChild(TERMS_CONDITIONS)],
    exports: [RouterModule]
})
export class TermsConditionsRoutingModule {
}
