import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from './service/profile.service';

// import * as countries from '../../assets/data/countries.json';
import { ResumeService } from '../resume/service/resume.service';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  hasResponse: boolean = false;
  isKYCVerified: boolean = false;
  assetBaseUrl = environment.assetBaseUrl;
  // postalCodeInputMask = createMask({
  //   mask: '999-9999',
  // });
  /**
   * variables declared for  form
   */
  personalInfoForm!: FormGroup;
  originAddressForm!: FormGroup;
  residenceAddressForm!: FormGroup;
  /**
   * variables defined to check if edit of any section is requested or not
   */
  personalInfoEdit: boolean = false;
  personalInfoUpdateRequested: boolean = false;
  showPersonalInfoLoader: boolean = false;
  originAddressEdit: boolean = false;
  originAddressUpdateRequested: boolean = false;
  // showOriginAddressLoader: boolean = false;
  residenceAddressEdit: boolean = false;
  residenceAddressUpdateRequested: boolean = false;
  // showResidenceAddressLoader: boolean = false;
  /**
   * variables declared to hold values for form fields
   */
  nationalityList: any[] = [];
  professionList: any[] = [];
  // countryList = (countries as any).default;
  genderList = ['Male', 'Female'];
  marriedStatusList = ['Married', 'Unmarried', 'Single', 'Divorced', 'Widow'];
  minimumDate = {year: (new Date().getFullYear() - 120) , month: 1, day: 1};
  originHasProvince: boolean = false;
  originHasPrefecture: boolean = false;
  originHasCity: boolean = false;
  originCityList: any[] = [];
  originPostalCodeSearch: boolean = false;
  originAddressSearchLoader: boolean = false;
  provinceList: any[] = [];
  prefectureList: any[] = [];
  residenceHasProvince: boolean = false;
  residenceHasPrefecture: boolean = false;
  residenceHasCity: boolean = false;
  residencePostalCodeSearch: boolean = false;
  residenceAddressSearchLoader: boolean = false;
  residenceCityList: any[] = [];
  /**
   * variables declared for origin document section
   */
  documentTypes = ['Citizenship', 'Passport'];
  jpDocumentTypes = ['Japan Residence Card', 'Japan Driving License'];
  originDocumentForm!: FormGroup;
  originDocEdit: boolean= false; // whether to allow a form to edit or not
  originDocSelected: boolean = false; // whether a file is selected or not
  originKYCDocFrontUrl: any = ''; // file value to display in image src
  selectedOriginKYCDocFront: any = ''; // file value to send as a payload to api
  originKYCDocBackUrl: any = ''; // file value to display in image src
  selectedOriginKYCDocBack: any = ''; // file value to send as a payload to api
  originDocUpdateRequested: boolean = false; // to determine whether a form is submitted or not
  selectedDocumentTypeForOriginCountry = 'Document Number';
  selectedDocumentTypeForJapan = 'Document Number';
  /**
   * variables declared for residence document section
   */
  residenceDocumentForm!: FormGroup;
  residenceDocEdit: boolean = false; // whether to allow a form to edit or not
  residenceDocSelected: boolean = false; // whether a file is selected or not
  residenceKYCDocFrontUrl: any = ''; // file value to display in image src
  selectedResidenceKYCDocFront: any = ''; // file value to send as a payload to api
  residenceKYCDocBackUrl: any = ''; // file value to display in image src
  selectedResidenceKYCDocBack: any = ''; // file value to send as a payload to api
  residenceDocUpdateRequested: boolean = false; // to determine whether a form is submitted or not
  /**
   * variables declared for profile image
   */
   profileImageSelected: boolean = false;
   selectedProfileImage: any = '';
   selectedProfileImageUrl: any = '';
   profileImageUpdateRequested: boolean = false;
  /**
   * variables declared to hold profile details/values
   */
  userProfile!: any;
  /**
   * variables declared for cropping image
   */
   @ViewChild('profileImageSelectionSection') profileImageSelectionSection!: ElementRef;
  imageChangedEvent: any = '';
  croppedProfileImage: any = '';

  constructor(private service: ProfileService, private toastr: ToastrService, private formBuilder: FormBuilder,
    private resumeService: ResumeService, private el: ElementRef) { }

  ngOnInit(): void {
    this.personalInfoForm = this.formBuilder.group({
      first_name : [{value: '', disabled: true}, Validators.required],
      last_name : [{value: '', disabled: true}, Validators.required],
      furigana : [{value: '', disabled: true}],
      father_name : [{value: '', disabled: true}],
      mother_name : [{value: '', disabled: true}],
      grandfather_name : [{value: '', disabled: true}],
      company : [{value: '', disabled: true}],
      nationality : [{value: null, disabled: true}],
      profession : [{value: null, disabled: true}],
      gender : [{value: null, disabled: true}],
      marital_status : [{value: null, disabled: true}],
      dob : [{value: null, disabled: true}],
      mobile_phone : [{value: '', disabled: true}],
      other_phone : [{value: '', disabled: true}],
      email : [{value: '', disabled: true},[ Validators.required, Validators.email]]
    });
    // detect change in dob values
    this.personalInfoForm.get('dob')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if(!data || (typeof data === 'string' && data.length == 0)) {
          this.personalInfoForm.patchValue({ dob: null }, {emitEvent: false});
        }
      });
    this.originAddressForm = this.formBuilder.group({
      country_of_origin : [{value: null, disabled: true}],
      origin_postal_code : [{value: '', disabled: true}],
      origin_province : [{value: null, disabled: true}],
      origin_city_district : [{value: null, disabled: true}],
      origin_street_address : [{value: '', disabled: true}]
    });
    this.residenceAddressForm = this.formBuilder.group({
      country_of_residence : [{value: null, disabled: true}],
      postal_code : [{value: '', disabled: true}],
      province : [{value: null, disabled: true}],
      city : [{value: null, disabled: true}],
      street_address : [{value: '', disabled: true}],
      building_name : [{value: '', disabled: true}]
    });
    // detect change in origin country values
    this.originAddressForm.get('country_of_origin')?.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(country => {
      if(this.originAddressEdit) {
        this.originAddressForm.controls.origin_postal_code.reset();
        this.originAddressForm.controls.origin_province.reset();
        this.originAddressForm.controls.origin_city_district.reset();
        this.originAddressForm.controls.origin_street_address.reset();
      }
      this.checkOriginCountryValue();
      // if(country === 'Japan' && this.originAddressEdit) {
      //   this.originHasPrefecture = true;
      //   this.originHasCity = true;
      //   this.originPostalCodeSearch = true;
      //   this.originHasProvince = false;
      //   this.originAddressForm.controls.origin_city_district.disable();
      // } else if(country === 'Nepal' && this.originAddressEdit) {
      //   this.originHasPrefecture = false;
      //   this.originHasCity = true;
      //   this.originHasProvince = true;
      //   this.originPostalCodeSearch = false;
      //   this.originAddressForm.controls.origin_city_district.disable();
      // } else {
      //   this.originHasPrefecture = false;
      //   this.originHasCity = false;
      //   this.originHasProvince = false;
      //   this.originPostalCodeSearch = false;
      //   if(country != '') {
      //     if(this.originAddressEdit) {
      //       this.originAddressForm.controls.origin_city_district.enable();
      //     }
      //   }
      // }
    });
    // detect change in origin prefecture or province values
    this.originAddressForm.get('origin_province')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(prefecture => {
        const selectedCountry = this.originAddressForm.controls.country_of_origin.value;
        if(prefecture != null && (selectedCountry == 'Japan' || selectedCountry == 'Nepal')) {
          this.originAddressForm.controls.origin_city_district.disable();
          this.resumeService.getCityOrDistrict(selectedCountry, prefecture, 'en').pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              if(selectedCountry == 'Nepal') {
                this.originCityList = [...new Set(res.map((data: any) => data.district))];
                if(this.originAddressEdit) {
                  this.originAddressForm.controls.origin_city_district.enable();
                }
              }
              if(selectedCountry == 'Japan') {
                this.originCityList = [...new Set(res.map((data: any) => data.city))];
                if(this.originAddressEdit) {
                  this.originAddressForm.controls.origin_city_district.enable();
                }
              }
            });
        } else {
          this.originCityList = [];
          if(prefecture != '') {
            if(this.originAddressEdit) {
              this.originAddressForm.controls.origin_city_district.enable();
            }
          }
        }
      });
    
    // detect change in residence country values
    this.residenceAddressForm.get('country_of_residence')?.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(country => {
      if(this.residenceAddressEdit) {
        this.residenceAddressForm.controls.postal_code.reset();
        this.residenceAddressForm.controls.province.reset();
        this.residenceAddressForm.controls.city.reset();
        this.residenceAddressForm.controls.street_address.reset();
      }
      this.checkResidenceCountryValue();
      // if(country === 'Japan' && this.residenceAddressEdit) {
      //   this.residenceHasPrefecture = true;
      //   this.residenceHasCity = true;
      //   this.residencePostalCodeSearch = true;
      //   this.residenceHasProvince = false;
      //   this.residenceAddressForm.controls.city.disable();
      // } else if(country === 'Nepal' && this.residenceAddressEdit) {
      //   this.residenceHasPrefecture = false;
      //   this.residenceHasCity = true;
      //   this.residenceHasProvince = true;
      //   this.residencePostalCodeSearch = false;
      //   this.residenceAddressForm.controls.city.disable();
      // } else {
      //   this.residenceHasPrefecture = false;
      //   this.residenceHasCity = false;
      //   this.residenceHasProvince = false;
      //   this.residencePostalCodeSearch = false;
      //   if(country != '') {
      //     if(this.residenceAddressEdit) {
      //       this.residenceAddressForm.controls.city.enable();
      //     }
      //   }
      // }
    });
    // detect change in residence prefecture or province values
    this.residenceAddressForm.get('province')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(prefecture => {
        const selectedCountry = this.residenceAddressForm.controls.country_of_residence.value;
        if(prefecture != null && (selectedCountry == 'Japan' || selectedCountry == 'Nepal')) {
          this.residenceAddressForm.controls.city.disable();
          this.resumeService.getCityOrDistrict(selectedCountry, prefecture, 'en').pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              if(selectedCountry == 'Nepal') {
                this.residenceCityList = [...new Set(res.map((data: any) => data.district))];
                if(this.residenceAddressEdit) {
                  this.residenceAddressForm.controls.city.enable();
                }
              }
              if(selectedCountry == 'Japan') {
                this.residenceCityList = [...new Set(res.map((data: any) => data.city))];
                if(this.residenceAddressEdit) {
                  this.residenceAddressForm.controls.city.enable();
                }
              }
            });
        } else {
          this.residenceCityList = [];
          if(prefecture != '') {
            if(this.residenceAddressEdit) {
              this.residenceAddressForm.controls.city.enable();
            }
          }
        }
      });

    
    this.originDocumentForm = this.formBuilder.group({
      origin_kyc_doc_type: [{value: null, disabled: true}],
      origin_kyc_doc_no: [{value: '', disabled: true}]
    });

    this.originDocumentForm.get('origin_kyc_doc_type')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if(data) {
          this.selectedDocumentTypeForOriginCountry = data + ' Number';
        } else {
          this.selectedDocumentTypeForOriginCountry = 'Document Number';
        }
      });

    this.residenceDocumentForm = this.formBuilder.group({
      kyc_doc_type: [{value: null, disabled: true}],
      kyc_doc_no: [{value: '', disabled: true}]
    });

    this.residenceDocumentForm.get('kyc_doc_type')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if(data) {
          this.selectedDocumentTypeForJapan = data + ' Number';
        } else {
          this.selectedDocumentTypeForJapan = 'Document Number';
        }
      });

    this.getUserProfile();
  }

  tooglePersonalInfoEdit() {
    this.personalInfoEdit = !this.personalInfoEdit;
    if(!this.personalInfoEdit) {
      this.personalInfoForm.markAsPristine();
      this.personalInfoForm.markAsUntouched();
      this.personalInfoForm.disable();
    } else {
      this.personalInfoForm.enable();
      this.personalInfoForm.controls.email.disable();
    }
  }

  toogleOriginAddressEdit() {
    this.originAddressEdit = !this.originAddressEdit;
    if(!this.originAddressEdit) {
      this.originAddressForm.markAsPristine();
      this.originAddressForm.markAsUntouched();
      this.originAddressForm.disable({ emitEvent: false });
    } else {
      this.originAddressForm.enable({ emitEvent: false });
      
    }
    this.checkOriginCountryValue();
  }

  checkOriginCountryValue() {
    let originCountry = this.originAddressForm.get('country_of_origin')?.value;
    if(originCountry === 'Japan' && this.originAddressEdit) {
      this.originHasPrefecture = true;
      this.originHasCity = true;
      this.originPostalCodeSearch = true;
      this.originHasProvince = false;
      this.originAddressForm.controls.origin_city_district.disable();
    } else if(originCountry === 'Nepal' && this.originAddressEdit) {
      this.originHasPrefecture = false;
      this.originHasCity = true;
      this.originHasProvince = true;
      this.originPostalCodeSearch = false;
      this.originAddressForm.controls.origin_city_district.disable();
    } else {
      this.originHasPrefecture = false;
      this.originHasCity = false;
      this.originHasProvince = false;
      this.originPostalCodeSearch = false;
      if(originCountry && this.originAddressEdit) {
        this.originAddressForm.controls.origin_city_district.enable();
      }
    }
  }

  toogleResidenceAddressEdit() {
    this.residenceAddressEdit = !this.residenceAddressEdit;
    if(!this.residenceAddressEdit) {
      this.residenceAddressForm.markAsPristine();
      this.residenceAddressForm.markAsUntouched();
      this.residenceAddressForm.disable({ emitEvent: false });
    } else {
      this.residenceAddressForm.enable({ emitEvent: false });
    }
    this.checkResidenceCountryValue();
  }

  checkResidenceCountryValue() {
    let country = this.residenceAddressForm.get('country_of_residence')?.value;
    if(country === 'Japan' && this.residenceAddressEdit) {
      this.residenceHasPrefecture = true;
      this.residenceHasCity = true;
      this.residencePostalCodeSearch = true;
      this.residenceHasProvince = false;
      this.residenceAddressForm.controls.city.disable();
    } else if(country === 'Nepal' && this.residenceAddressEdit) {
      this.residenceHasPrefecture = false;
      this.residenceHasCity = true;
      this.residenceHasProvince = true;
      this.residencePostalCodeSearch = false;
      this.residenceAddressForm.controls.city.disable();
    } else {
      this.residenceHasPrefecture = false;
      this.residenceHasCity = false;
      this.residenceHasProvince = false;
      this.residencePostalCodeSearch = false;
      if(country != '') {
        if(this.residenceAddressEdit) {
          this.residenceAddressForm.controls.city.enable();
        }
      }
    }
  }

  sameAsOriginAddress(event: any) {
    if(event.target.checked) {
      this.residenceAddressForm.controls.country_of_residence.setValue(this.originAddressForm.controls.country_of_origin.value);
      this.residenceAddressForm.controls.country_of_residence.updateValueAndValidity();
      this.residenceAddressForm.controls.postal_code.setValue(this.originAddressForm.controls.origin_postal_code.value);
      this.residenceAddressForm.controls.postal_code.updateValueAndValidity();
      this.residenceAddressForm.controls.province.setValue(this.originAddressForm.controls.origin_province.value);
      this.residenceAddressForm.controls.province.updateValueAndValidity();
      this.residenceAddressForm.controls.city.setValue(this.originAddressForm.controls.origin_city_district.value);
      this.residenceAddressForm.controls.city.updateValueAndValidity();
      this.residenceAddressForm.controls.street_address.setValue(this.originAddressForm.controls.origin_street_address.value);
      this.residenceAddressForm.controls.street_address.updateValueAndValidity();
    } else {
      this.residenceAddressForm.patchValue(this.userProfile);
    }
  }

  getUserProfile() {
    this.service.fetchUserProfileDetails().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.userProfile = res.user_profile;
        this.isKYCVerified = this.userProfile.is_kyc_verified;
        if(this.userProfile.avatar) {
          this.selectedProfileImageUrl = this.userProfile.avatar;
          if(!this.selectedProfileImageUrl.startsWith('http')) {
            this.selectedProfileImageUrl = this.assetBaseUrl + this.selectedProfileImageUrl;
          }
        }
        if(this.userProfile.origin_kyc_doc_front != '') {
          this.originKYCDocFrontUrl = this.assetBaseUrl + this.userProfile.origin_kyc_doc_front;
        }
        if(this.userProfile.origin_kyc_doc_back != '') {
          this.originKYCDocBackUrl = this.assetBaseUrl + this.userProfile.origin_kyc_doc_back;
        }
        if(this.userProfile.kyc_doc_front != '') {
          this.residenceKYCDocFrontUrl = this.assetBaseUrl + this.userProfile.kyc_doc_front;
        }
        if(this.userProfile.kyc_doc_back != '') {
          this.residenceKYCDocBackUrl = this.assetBaseUrl + this.userProfile.kyc_doc_back;
        }
        const options = res.options;
        this.nationalityList = options.nationalities;
        this.professionList = options.professions;
        this.provinceList = options.provinces;
        this.prefectureList = options.prefectures;
        Object.keys(this.userProfile).forEach(key => {
            if (this.userProfile[key] === null || this.userProfile[key] === "null" || this.userProfile[key] === '') {
              delete this.userProfile[key];
            }
          });
        // set values to form fields
        
        setTimeout(()=>{
          this.personalInfoForm.patchValue(this.userProfile);
          // this.originAddressForm.patchValue({
          //   country_of_origin : this.userProfile.country_of_origin,
          //   origin_postal_code :  this.userProfile.origin_postal_code,
          //   origin_province :  this.userProfile.origin_province,
          //   origin_city_district :  this.userProfile.origin_city_district,
          //   origin_street_address :  this.userProfile.origin_street_address
          // });
          this.originAddressForm.patchValue(this.userProfile);
          this.residenceAddressForm.patchValue(this.userProfile);
          this.originDocumentForm.patchValue(this.userProfile);
          this.residenceDocumentForm.patchValue(this.userProfile);
        }, 500);
        this.hasResponse = true;
        this.updateUserBalance();
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Unable to fetch profile', 'Error');
      });
  }

  savePersonalInfo() {
    
    this.personalInfoUpdateRequested = true;
    if(this.personalInfoForm.invalid){
      // const controls = this.personalInfoForm.controls;
      //   for (const name in controls) {
      //       if (controls[name].invalid) {
      //       }
      //   }
      return;
    }
    this.showPersonalInfoLoader = true;
    let data = this.personalInfoForm.value;
    Object.keys(data).forEach(key => {
      if (data[key] === null || data[key] === "null" || data[key] === '') {
        delete data[key];
      }
    });
    data['is_kyc'] = true;
    this.service.updateUserProfileDetails(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.personalInfoUpdateRequested = false;
        this.showPersonalInfoLoader = false;
        this.tooglePersonalInfoEdit();
        this.toastr.success('Profile updated successfully', 'SUCCESS');
      }, (err: any) => {
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.personalInfoUpdateRequested = false;
        this.showPersonalInfoLoader = false;
        // this.toastr.error('Failed to update profile', 'ERROR');
      });
  }

  searchOriginAddressByPostalCode() {
    let postal_code = this.originAddressForm.controls.origin_postal_code.value;
    if(postal_code == '' || postal_code == null) {
      this.toastr.info("Please provide postal code first");
      const postalCodeControl = this.el.nativeElement.querySelector('[formControlName="origin_postal_code"]');
      postalCodeControl.focus();
      return;
    }
    this.originAddressSearchLoader = true;
    this.resumeService.getAddressByPostalCode(postal_code.replace(/-/g, ""))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res:any) => {
        this.originAddressSearchLoader = false;
        const addressDetails = res.postal_code[0];
        this.originAddressForm.controls.origin_province.setValue(addressDetails.prefecture);
        this.originAddressForm.controls.origin_province.updateValueAndValidity();
        this.originAddressForm.controls.origin_city_district.setValue(addressDetails.city);
        this.originAddressForm.controls.origin_city_district.updateValueAndValidity();
        this.originAddressForm.controls.origin_street_address.setValue(addressDetails.street);
        this.originAddressForm.controls.origin_street_address.updateValueAndValidity();
      }, (err: any) => {
        this.toastr.error(err.error.error, 'Error');
        this.originAddressSearchLoader = false;
      });
  }

  saveOriginAddressInfo() {
    if(this.originAddressForm.invalid){
      return;
    }
    this.originAddressUpdateRequested = true;
    let data = this.originAddressForm.value;
    Object.keys(data).forEach(key => {
      if (data[key] === null || data[key] === "null" || data[key] === '') {
        delete data[key];
      }
    });
    data['is_kyc'] = true;
    this.service.updateUserProfileDetails(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.toogleOriginAddressEdit();
        this.originAddressUpdateRequested = false;
        this.toastr.success('Profile updated successfully', 'SUCCESS');
      }, (err: any) => {
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.originAddressUpdateRequested = false;
        // this.toastr.error('Failed to update profile', 'ERROR');
      });
  }

  searchResidenceAddressByPostalCode() {
    let postal_code = this.residenceAddressForm.controls.postal_code.value;
    if(postal_code == '' || postal_code == null) {
      this.toastr.info("Please provide postal code first");
      const postalCodeControl = this.el.nativeElement.querySelector('[formControlName="postal_code"]');
      postalCodeControl.focus();
      return;
    }
    this.residenceAddressSearchLoader = true;
    this.resumeService.getAddressByPostalCode(postal_code.replace(/-/g, ""))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res:any) => {
        const addressDetails = res.postal_code[0];
        this.residenceAddressForm.controls.province.setValue(addressDetails.prefecture);
        this.residenceAddressForm.controls.province.updateValueAndValidity();
        this.residenceAddressForm.controls.city.setValue(addressDetails.city);
        this.residenceAddressForm.controls.city.updateValueAndValidity();
        this.residenceAddressForm.controls.street_address.setValue(addressDetails.street);
        this.residenceAddressForm.controls.street_address.updateValueAndValidity();
        this.residenceAddressSearchLoader = false;
      }, (err: any) => {
        this.toastr.error(err.error.error, 'Error');
        this.residenceAddressSearchLoader = false;
      });
  }

  saveResidenceAddressInfo() {
    if(this.residenceAddressForm.invalid){
      return;
    }
    this.residenceAddressUpdateRequested = true;
    let data = this.residenceAddressForm.value;
    Object.keys(data).forEach(key => {
      if (data[key] === null || data[key] === "null" || data[key] === '') {
        delete data[key];
      }
    });
    data['is_kyc'] = true;
    this.service.updateUserProfileDetails(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.residenceAddressUpdateRequested = false;
        this.toogleResidenceAddressEdit();
        this.toastr.success('Profile updated successfully', 'SUCCESS');
      }, (err: any) => {
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.residenceAddressUpdateRequested = false;
        // this.toastr.error('Failed to update profile', 'ERROR');
      });
  }

  /**
   * function called when file is selected
   * @param files File
   */
   onProfileImageSelect(event: any) {
    this.imageChangedEvent = event;
    setTimeout(() => {
      $('#cropImageModal').modal('show');
    },500);
    // const file = files[0];
    // if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' ) {
    //   this.profileImageSelected = true;
    //   var reader = new FileReader();
    //   reader.onload = (event: any) => {
    //       this.selectedProfileImageUrl = reader.result;
    //       this.selectedProfileImage = reader.result;
    //   }
    //   reader.readAsDataURL(file);
    // } else {
    //   this.selectedProfileImage = '';
    //   this.profileImageSelected = false;
    //   this.toastr.error('Please select image only', 'Error');
    // }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedProfileImage = event.base64;
  }
  imageLoaded() {
    //
  }
  cropperReady() {
      // cropper ready
      // setTimeout(() => {
      //   $('#cropImageModal').modal('show');
      // },200);
  }
  loadImageFailed() {
    this.profileImageSelected = false;
    this.toastr.info("Failed to load image. Please try again.");
  }

  readyToUploadCroppedImage() {
    this.selectedProfileImageUrl = this.croppedProfileImage;
    this.selectedProfileImage = this.croppedProfileImage;
    this.profileImageSelected = true;
    $('#cropImageModal').modal('hide');
  }

  cancelCroppedImageUpload() {
    this.profileImageSelectionSection.nativeElement.value='';
    this.croppedProfileImage = '';
    this.profileImageSelected = false;
    $('#cropImageModal').modal('hide');
  }

  saveProfileImage() {
    if(this.profileImageSelected && this.selectedProfileImage !== '') {
      this.profileImageUpdateRequested = true;
      let profileImageDetails = {
        avatar: this.selectedProfileImage,
        is_kyc: true
      }
      this.service.updateUserProfileDetails(profileImageDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.profileImageUpdateRequested = false;
        this.selectedProfileImage = '';
        this.profileImageSelected = false;
        this.toastr.success('Profile updated successfully', 'SUCCESS');
        this.profileImageSelectionSection.nativeElement.value='';
      }, (err: any) => {
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.profileImageUpdateRequested = false;
        this.profileImageSelectionSection.nativeElement.value='';
        // this.toastr.error('Failed to update profile', 'ERROR');
      });
    }
  }

  /**
   * function called when file is selected
   * @param files File
   */
   onOriginKYCFrontSelect(files: any) {
    const file = files[0];
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' ) {
        this.originDocSelected = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.originKYCDocFrontUrl = reader.result;
            this.selectedOriginKYCDocFront = reader.result;
        }
        reader.readAsDataURL(file);
      } else {
        this.selectedOriginKYCDocFront = '';
        this.originDocSelected = false;
        this.toastr.error('Please select image only', 'Error');
      }
  }

  /**
   * function called when file is selected
   * @param files File
   */
   onOriginKYCBackSelect(files: any) {
    const file = files[0];
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' ) {
        this.originDocSelected = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.originKYCDocBackUrl = reader.result;
            this.selectedOriginKYCDocBack = reader.result;
        }
        reader.readAsDataURL(file);
      } else {
        this.selectedOriginKYCDocBack = '';
        this.originDocSelected = false;
        this.toastr.error('Please select image only', 'Error');
      }
  }

  toogleOriginDocEdit() {
    this.originDocEdit = !this.originDocEdit;
    if(!this.originDocEdit) {
      this.originDocumentForm.markAsPristine();
      this.originDocumentForm.markAsUntouched();
      this.originDocumentForm.disable();
    } else {
      this.originDocumentForm.enable();
    }
  }


  saveOriginDocuments() {
    if(this.originDocumentForm.invalid) {
      return;
    }
    this.originDocUpdateRequested = true;
    const originDocData = {
      origin_kyc_doc_type : this.originDocumentForm.controls.origin_kyc_doc_type.value,
      origin_kyc_doc_no : this.originDocumentForm.controls.origin_kyc_doc_no.value,
      origin_kyc_doc_front: this.selectedOriginKYCDocFront != undefined && this.selectedOriginKYCDocFront != null ? this.selectedOriginKYCDocFront : '',
      origin_kyc_doc_back: this.selectedOriginKYCDocBack != undefined && this.selectedOriginKYCDocBack != null ? this.selectedOriginKYCDocBack : '',
      is_kyc: true
    }
    this.service.updateUserProfileDetails(originDocData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.originDocUpdateRequested = false;
        this.toogleOriginDocEdit();
        this.toastr.success('Profile updated successfully', 'SUCCESS');
      }, (err: any) => {
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.originDocUpdateRequested = false;
        // this.toastr.error('Failed to update profile', 'ERROR');
      });
  }

  /**
   * function called when file is selected
   * @param files File
   */
   onResidenceKYCFrontSelect(files: any) {
    const file = files[0];
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' ) {
        this.residenceDocSelected = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.residenceKYCDocFrontUrl = reader.result;
            this.selectedResidenceKYCDocFront = reader.result;
        }
        reader.readAsDataURL(file);
      } else {
        this.selectedResidenceKYCDocFront = '';
        this.residenceDocSelected = false;
        this.toastr.error('Please select image only', 'Error');
      }
  }

  /**
   * function called when file is selected
   * @param files File
   */
   onResidenceKYCBackSelect(files: any) {
    const file = files[0];
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' ) {
        this.residenceDocSelected = true;
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.residenceKYCDocBackUrl = reader.result;
            this.selectedResidenceKYCDocBack = reader.result;
        }
        reader.readAsDataURL(file);
      } else {
        this.selectedResidenceKYCDocBack = '';
        this.residenceDocSelected = false;
        this.toastr.error('Please select image only', 'Error');
      }
  }

  toogleResidenceDocEdit() {
    this.residenceDocEdit = !this.residenceDocEdit;
    if(!this.residenceDocEdit) {
      this.residenceDocumentForm.markAsPristine();
      this.residenceDocumentForm.markAsUntouched();
      this.residenceDocumentForm.disable();
    } else {
      this.residenceDocumentForm.enable();
    }
  }


  saveResidenceDocuments() {
    if(this.residenceDocumentForm.invalid) {
      return;
    }
    this.residenceDocUpdateRequested = true;
    const residenceDocData = {
      kyc_doc_type : this.residenceDocumentForm.controls.kyc_doc_type.value,
      kyc_doc_no : this.residenceDocumentForm.controls.kyc_doc_no.value,
      kyc_doc_front: this.selectedResidenceKYCDocFront != undefined && this.selectedResidenceKYCDocFront != null ? this.selectedResidenceKYCDocFront : '',
      kyc_doc_back: this.selectedResidenceKYCDocBack != undefined && this.selectedResidenceKYCDocBack != null ? this.selectedResidenceKYCDocBack : '',
      is_kyc: true
    }
    this.service.updateUserProfileDetails(residenceDocData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.residenceDocUpdateRequested = false;
        this.toogleResidenceDocEdit();
        this.toastr.success('Profile updated successfully', 'SUCCESS');
      }, (err: any) => {
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.residenceDocUpdateRequested = false;
        // this.toastr.error('Failed to update profile', 'ERROR');
      });
  }

  updateUserBalance() {
    this.service.fetchUserBalance().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.userProfile.balance = res.balance;
      }, (err: any) => {
        // this.toastr.error("Unable to fetch balance", "ERROR");
      });
    }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
