import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsComponent } from './news.component';

const   NEWS_ROUTES: Routes = [
    { path: '', component : NewsComponent},
    { path: 'details', component : NewsDetailComponent}
];
@NgModule({
    imports: [RouterModule.forChild(NEWS_ROUTES)],
    exports: [RouterModule]
})
export class NewsRoutingModule {
}
