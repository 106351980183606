import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactUsRoutingModule } from './contact-us-routing.module';
import { ContactUsComponent } from './contact-us.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

// 6Lc53zMUAAAAAEsDwpFioN-IeQrr4JWXhcB-w_zP
// 6LenFGQbAAAAABAgsqSH224XSBKfuT9heloCm2oy
const globalSettings: RecaptchaSettings = { siteKey: '6LflImQbAAAAAFSfGgJMP1w0WmndwlO_ZySpmJwY' };
@NgModule({
    imports: [
        CommonModule,
        ContactUsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule
    ],
    declarations: [
        ContactUsComponent
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: globalSettings,
          }
    ]
})

export class ContactUsModule {
}
