import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { ImagepreloadermoduleModule } from '../utils/directives/imagepreloader.module';
@NgModule({
    imports: [
        CommonModule,
        AboutRoutingModule,
        ImagepreloadermoduleModule
    ],
    declarations: [
        AboutComponent
    ]
})

export class AboutModule {
}
