import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResumeRoutingModule } from './resume-routing.module';
import { ResumeComponent } from './resume.component';
import { ResumeService } from './service/resume.service';
import { ResumeDownloadComponent } from './resume-download/resume-download.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { JpResumeComponent } from './jp-resume/jp-resume.component';
import { NgxMaskModule } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        ResumeRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgSelectModule,
        NgxMaskModule,
        ImageCropperModule
    ],
    declarations: [
        ResumeComponent,
        ResumeDownloadComponent,
        JpResumeComponent
    ],
    providers: [
        ResumeService
    ]
})

export class ResumeModule {
}
