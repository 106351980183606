import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { IndexComponent } from './index/index.component';
import { firebaseConfig } from './../environments/firebase.config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginModule } from './login/login.module';
import { NewsModule } from './news/news.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ToastrModule } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { VerifyEmailModule } from './verify-email/verify-email.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { TermsConditionsModule } from './terms-conditions/terms-conditions.module';
import { CommonModule } from '@angular/common';
import { ForgetPasswordModule } from './forget-password/forget-password.module';
import { AuthGuardService } from './utils/guards/auth-guard.service';
import { ResumeModule } from './resume/resume.module';
import { DateAgoModule } from './utils/pipes/date-ago.module';
import { ImagepreloadermoduleModule } from './utils/directives/imagepreloader.module';
import { AuthInterceptors } from './utils/interceptors/authInterceptor';
import { CanDeactivateGuard } from './utils/guards/can-deactivate-guard.service';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter, CustomDateParserFormatter } from './utils/ngbDate/datepicker-adapter';
import { CacheInterceptor } from './utils/interceptors/cacheInterceptor';
import { CacheService } from './utils/services/cache.service';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AlertModule } from './alert/alert.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ContactUsModule } from './contact-us/contact-us.module';
import { JapaneseMannerModule } from './japanese-manner/japanese-manner.module';
import { ServicesModule } from './services/services.module';
import { SafeResourceUrlModule } from './utils/pipes/safe-resource-url.module';
import { ProfileModule } from './profile/profile.module';
import { LoadBalanceModule } from './load-balance/load-balance.module';
import { RefundPolicyModule } from './refund-policy/refund-policy.module';
import { SliderImagepreloadermoduleModule } from './utils/directives/sliderimagepreloader.module';
import { VerifyEmailStatusModule } from './verify-email-status/verify-email-status.module';
import { AboutModule } from './about/about.module';
import { TransactionLawModule } from './transaction-law/transaction-law.module';
import { TransactionModule } from './transaction/transaction.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'

const fbLoginOptions = {
  scope: "public_profile,email",
  fields: "id,name,email",
  version    : "v10.0"
};

const googleLoginOptions = {
  scope: 'profile email phone'
};

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      closeButton: true
    }),
    HeaderModule,
    FooterModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoginModule,
    NewsModule,
    ResetPasswordModule,
    SocialLoginModule,
    VerifyEmailModule,
    PrivacyPolicyModule,
    TermsConditionsModule,
    RefundPolicyModule,
    ForgetPasswordModule,
    ResumeModule,
    DateAgoModule,
    ImagepreloadermoduleModule,
    AlertModule,
    ContactUsModule,
    JapaneseMannerModule,
    ServicesModule,
    SafeResourceUrlModule,
    ProfileModule,
    LoadBalanceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
      registrationStrategy: "registerImmediately"
    }),
    SliderImagepreloadermoduleModule,
    VerifyEmailStatusModule,
    AboutModule,
    TransactionLawModule,
    TransactionModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '660540445156-0kpg73vfhp1bgfscfij3pk7vatpjgmu0.apps.googleusercontent.com', googleLoginOptions
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '160947809212135', fbLoginOptions
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    AuthGuardService,
    CacheService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptors, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    CanDeactivateGuard,
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
