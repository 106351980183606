import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { TransactionLawRoutingModule } from './transaction-law-routing.module';
import { TransactionLawComponent } from './transaction-law.component';

@NgModule({
    imports: [
        CommonModule,
        TransactionLawRoutingModule
    ],
    declarations: [
        TransactionLawComponent
    ],
    providers: [
        
    ]
})

export class TransactionLawModule {
}
