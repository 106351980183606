import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CanComponentDeactivate } from '../utils/guards/can-deactivate-guard.service';
import { ResumeService } from './service/resume.service';


// import * as countries from '../../assets/data/countries.json';
import { DataShareService } from '../utils/data-share.service';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var $: any;
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  testShareFile: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  hasResponse: boolean = false;
  activeTab: string = 'en';
  userDetailsVoidResume: any;
  assetBaseUrl = environment.assetBaseUrl;
  /**
   * 
   */
  uuid: string = '';
  resumeSectionUpdateRequest: string = '';
  resumeUpdateRequested: boolean = false;
  // resumeForm!: FormGroup;
  resumeProfileImageForm!: FormGroup;
  resumeBasicInfoForm!: FormGroup;
  resumeCurrentAddressForm!: FormGroup;
  resumePermanentAddressForm!: FormGroup;
  resumeAcademicForm!: FormGroup;
  resumeExperienceForm!: FormGroup;
  resumeQualificationForm!: FormGroup;
  resumeOthersForm!: FormGroup;
  genderList = ['Male', 'Female'];
  jpLanguageLevelList = ['N1', 'N2', 'N3', 'N4', 'N5', 'N/A'];
  booleanValuesList = ['Yes', 'No'];
  hoursList: string[] = Array.from(Array(9), (v, i) => '' + i);
  // minutesList: string[] = Array.from(Array(60), (v, i) => i < 10 ? '0' + i : '' + i);
  minutesList: string[] = ['00', '30'];
  yearsList: any[] = [];
  endYearList: any[] = [];
  monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  minimumDate = {year: (new Date().getFullYear() - 120) , month: 1, day: 1};
  /**
   * variabled declared for change in profile image
   */
  profileImageSelected: boolean = false;
  selectedFile: any = '';
  oldSelectedImageUrl = '';
  selectedImageUrl: any = '';
  /**
   * variables defined to check if edit of any section is requested or not
   */
  basicInfoEdit: boolean = false;
  currAddressEdit: boolean = false;
  contAddressEdit: boolean = false;
  academicEdit: boolean = false;
  workEdit: boolean = false;
  qualificationEdit: boolean = false;
  othersEdit: boolean = false;
  /**
   * variables for en options
   */
  enCompanyTypes: any[] = [];
  enHobbies: any[] = [];
  enKnownLanguages: any[] = [];
  enMajorSubjects: any[] = [];
  enNationalities: any[] = [];
  enPreferences: any[] = [];
  enSkills: any[] = [];
  // enJpCities: any[] = [];
  enJpPrefectures: any[] = [];
  enProfessions: [] = [];
  enNpProvinces: any[] = [];
  
  /**
   * variabled declared for searching address by postal code
   */
   currAddressLoader: boolean= false;
   constAddressLoader: boolean= false;
  /**
   * variables declared for completition rates
   */
  en_academics_completition_rate = 0;
  //  en_address_completition_rate = 0;
  en_personal_info_completition_rate = 0;
  en_qualification_info_completition_rate = 0;
  en_work_completition_rate = 0;
  //  en_other_completition_rate = 0;
  // jp_academics_completition_rate = 0;
  // //  jp_address_completition_rate = 0;
  // jp_personal_info_completition_rate = 0;
  // jp_qualification_info_completition_rate = 0;
  // jp_work_completition_rate = 0;
  //  jp_other_completition_rate = 0;
  /**
    * variables declared for
    */
  // countryList = (countries as any).default;
  // nepalDistrictList = (nepalDistricts as any).default;
  currVisibleCityList: any[] = [];
  contVisibleCityList: any[] = [];
   hasProvince: boolean = false;
   hasPostalCodeSearch: boolean = false;
   hasPrefecture: boolean= false;
   hasCity: boolean = false;
   hasConstProvince: boolean = false;
   hasConstPostalCodeSearch: boolean = false;
   hasConstPrefecture: boolean= false;
   hasConstCity: boolean = false;
   hasSpouse: boolean = false;
   showWorkingMinutes: boolean = true;
   /**
    * variabled declared to hold english resume details
    */
  enUSerDetails : any = {
    first_name : '',
    last_name : '',
    furigana : '',
    profession : '',
    dob : '',
    age : '',
    gender : '',
    nationality: '',
    email: '',
    contact_number: ''
  }
  enCurrentAddress: any = {
    curr_country: '',
    curr_postal_code : '',
    curr_prefecture : '',
    curr_city: '',
    curr_address: '',
    curr_phone: ''
  }
  enPermanentAddress: any = {
    cont_country: '',
    cont_postal_code : '',
    cont_prefecture : '',
    cont_city: '',
    cont_address: '',
    cont_phone: ''
  }
  enAcademicList: any[] = [];
  enExperienceList: any[] = [];
  enQualificationList: any[] = [];
  enOthers: any = {
    known_languages : '',
    hobbies: '',
    motivation: '',
    jlpt: '',
    self_pr: '',
    skills: '',
    extra_point: '',
    available_working_hours: '',
    available_working_minutes: '',
    dependents_except_spouse: '',
    spouse: '',
    spouse_support_obligation: '',
    language_school_prefecture_id: '',
    language_school_city_id: '',
    language_school_postal_code: '',
    language_school_address: '',
    language_school_phone: '',
    university: '',
    university_postal_code: '',
    university_prefecture_id: '',
    university_city_id: '',
    special_conditions: ''
  }
  /**
   * variables declared for passing data to jp resume
   */
  jpOptions: any;
  jpDatas: any;
  /**
   * variables declares to pass data to japanese resume component
   */
  jpJpPrefecturesList: any[] = [];
  jpNepalProvincesList: any[] = [];
  jpResumeData: any = [];
  /**
   * variables declared for removing particular data from academic/work-experience/qualification
   */
  data_type_to_remove = '';
  data_index_to_be_removed: any;
  /**
   * variables declared for cropping image
   */
  @ViewChild('profileImageSelectionSection') profileImageSelectionSection!: ElementRef;
  imageChangedEvent: any = '';
  croppedProfileImage: any = '';
  /**
   * dependency injection
   */
  constructor(private resumeService: ResumeService, private toastr: ToastrService, private router: Router,
    private formBuilder:FormBuilder, private dataShareService: DataShareService, private el: ElementRef) {
      this.hoursList.splice(0, 0, 'Fulltime');
    }

  ngOnInit(): void {
    this.catchJpResumeSaveEvent();
    const currentYear = new Date().getFullYear();
    // const currentMonth = new Date().getMonth();
    for (let i = -50; i <= 0; i++ ) {
      this.yearsList.push(currentYear + i);
      this.endYearList.push(currentYear+ i);
    }
    this.yearsList.sort((a, b) => b - a);
    this.endYearList.sort((a, b) => b - a);
    this.endYearList.unshift('Running');
    this.resumeProfileImageForm = this.formBuilder.group({
      image: [''],
    });
    this.resumeBasicInfoForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z0-9]*')]],
      last_name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9]*')]],
      furigana: [''],
      profession: [null],
      dob: [null],
      age: [{value:'', disabled: true}],
      gender: [null],
      nationality: [null],
      email: ['', [Validators.required, Validators.email]],
      contact_number: ['']
    });
    /**
     * detect change in date value and calculate age
     */
    this.resumeBasicInfoForm.controls.dob.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(date => {
        if(date) {
          let age = this.calculateAge(new Date(date));
          this.resumeBasicInfoForm.controls.age.setValue(age);
        } else {
          this.resumeBasicInfoForm.controls.age.setValue('');
        }
      });
    this.resumeCurrentAddressForm = this.formBuilder.group({
      curr_country: [null],
      curr_postal_code: [''],
      curr_prefecture: [null],
      curr_city: [null],
      curr_address: [''],
      curr_phone: ['']
    });
    this.resumePermanentAddressForm = this.formBuilder.group({
      cont_country: [null],
      cont_postal_code: [''],
      cont_prefecture: [null],
      cont_city: [null],
      cont_address: [''],
      cont_phone: ['']
    });
    this.resumeAcademicForm = this.formBuilder.group({
      education: this.formBuilder.array([])
    });
    this.resumeExperienceForm = this.formBuilder.group({
      experience: this.formBuilder.array([])
    });
    this.resumeQualificationForm = this.formBuilder.group({
      qualification: this.formBuilder.array([]),
    });
    this.resumeOthersForm = this.formBuilder.group({
      known_languages: [null],
      hobbies: [null],
      motivation: [''],
      jlpt: [null],
      self_pr: [''],
      skills: [''],
      extra_point: [''],
      available_working_hours: [null],
      available_working_minutes: [null],
      dependents_except_spouse: [''],
      spouse: [null],
      spouse_support_obligation: [null],
      language_school_prefecture_id: [''],
      language_school_city_id: [''],
      language_school_postal_code: [''],
      language_school_address: [''],
      language_school_phone: [''],
      university: [''],
      university_postal_code: [''],
      university_prefecture_id: [''],
      university_city_id: [''],
      special_conditions: ['Follow as per company rules and regulations.']
    });
    this.getResumeDetails();
    this.resumeOthersForm.get('spouse')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if(value === 'Yes') {
          this.hasSpouse = true;
        } else {
          this.hasSpouse = false;
        }
      });
    
    this.resumeOthersForm.get('available_working_hours')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if(value === 'Fulltime') {
          this.showWorkingMinutes = false;
        } else {
          this.showWorkingMinutes = true;
        }
      });

    /**
     * 
     */
    this.resumeCurrentAddressForm.get('curr_country')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(country => {
        if(this.currAddressEdit) {
          this.resumeCurrentAddressForm.controls.curr_postal_code.reset();
          this.resumeCurrentAddressForm.controls.curr_prefecture.reset();
          this.resumeCurrentAddressForm.controls.curr_city.reset();
          this.resumeCurrentAddressForm.controls.curr_address.reset();
        }
        if(country === 'Japan' && this.currAddressEdit) {
          this.hasPrefecture = true;
          this.hasCity = true;
          this.hasProvince = false;
          this.hasPostalCodeSearch = true;
          this.resumeCurrentAddressForm.controls.curr_city.disable();
        } else if(country === 'Nepal' && this.currAddressEdit) {
          this.hasProvince = true;
          this.hasCity = true;
          this.hasPrefecture = false;
          this.hasPostalCodeSearch = false;
          this.resumeCurrentAddressForm.controls.curr_city.disable();
        } else {
          this.hasCity = false;
          this.hasProvince = false;
          this.hasPrefecture = false;
          this.hasPostalCodeSearch = false;
          if(country && this.currAddressEdit) {
            this.resumeCurrentAddressForm.controls.curr_city.enable();
          }
        }
        // let filteredCountry = this.countryList.find((obj: any) => obj.countryName == country);
        // this.resumeCurrentAddressForm.controls.curr_phone.setValue(filteredCountry?.phoneCode);
      });

    this.resumeCurrentAddressForm.get('curr_prefecture')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        const selectedCountry = this.resumeCurrentAddressForm.controls.curr_country.value;
        if(value != null && (selectedCountry == 'Japan' || selectedCountry == 'Nepal')) {
          this.resumeCurrentAddressForm.controls.curr_city.disable();
          this.resumeService.getCityOrDistrict(selectedCountry, value, 'en').pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              if(selectedCountry == 'Nepal') {
                this.currVisibleCityList = [...new Set(res.map((data: any) => data.district))];
                this.resumeCurrentAddressForm.controls.curr_city.enable();
              }
              if(selectedCountry == 'Japan') {
                this.currVisibleCityList = [...new Set(res.map((data: any) => data.city))];
                this.resumeCurrentAddressForm.controls.curr_city.enable();
              }
            });
        } else {
          this.currVisibleCityList = [];
          this.resumeCurrentAddressForm.controls.curr_city.enable();
        }
      });
    

    /**
     * 
     */
     this.resumePermanentAddressForm.get('cont_country')?.valueChanges
     .pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(country => {
       if(this.contAddressEdit) {
        this.resumePermanentAddressForm.controls.cont_postal_code.reset();
        this.resumePermanentAddressForm.controls.cont_prefecture.reset();
        this.resumePermanentAddressForm.controls.cont_city.reset();
        this.resumePermanentAddressForm.controls.cont_address.reset();
       }
       if(country === 'Japan' && this.contAddressEdit) {
        this.hasConstPrefecture = true;
        this.hasConstCity = true;
        this.hasConstProvince = false;
        this.hasConstPostalCodeSearch = true;
        this.resumePermanentAddressForm.controls.cont_city.disable();
       } else if(country === 'Nepal' && this.contAddressEdit) {
        this.hasConstProvince = true;
        this.hasConstCity = true;
        this.hasConstPrefecture = false;
        this.hasConstPostalCodeSearch = false;
        this.resumePermanentAddressForm.controls.cont_city.disable();
       } else {
        this.hasConstCity = false;
        this.hasConstProvince = false;
        this.hasConstPrefecture = false;
        this.hasConstPostalCodeSearch = false;
        if(country && this.contAddressEdit) {
          this.resumePermanentAddressForm.controls.cont_city.enable();
        }
       }
     });

   this.resumePermanentAddressForm.get('cont_prefecture')?.valueChanges
     .pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(value => {
      const selectedCountry = this.resumePermanentAddressForm.controls.cont_country.value;
      if(value != null && (selectedCountry == 'Japan' || selectedCountry == 'Nepal')) {
        this.resumePermanentAddressForm.controls.cont_city.disable();
        this.resumeService.getCityOrDistrict(selectedCountry, value, 'en').pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            if(selectedCountry == 'Nepal') {
              this.contVisibleCityList = [...new Set(res.map((data: any) => data.district))];
              this.resumePermanentAddressForm.controls.cont_city.enable();
            }
            if(selectedCountry == 'Japan') {
              this.contVisibleCityList = [...new Set(res.map((data: any) => data.city))];
              this.resumePermanentAddressForm.controls.cont_city.enable();
            }
          });
      } else {
        this.contVisibleCityList = [];
        this.resumePermanentAddressForm.controls.cont_city.enable();
      }
     });
  }

  /**
   * function to catch japanese resume save event and fetch resume details after save
   */
  catchJpResumeSaveEvent() {
    this.dataShareService.currentFetchResumeStatus.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(fetchResume => {
          if(fetchResume) {
            this.getResumeDetails();
          }
        });
  }

  private calculateAge(dob: any): any {
    var timeDiff = Math.abs(Date.now() - dob);
    return Math.floor((timeDiff / (1000 * 3600 * 24))/365);
    }

  /**
   * form array initialization funtion for education
   */
   arrayOfEducation(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      institute: [''],
      start_year: [null],
      start_month: [null],
      completion_year: [null],
      completion_month: [null],
      major_subject: [null]
    });
  }

  /**
   * returning the form control of education as FormArray
   */
  get educationGroupArray() {
    return this.resumeAcademicForm.get('education') as FormArray;
  }

  /**
   * function for adding form fields into education FormArray
   */
  addEducationRepeatition() {
    this.educationGroupArray.push(this.arrayOfEducation());
    if(this.educationGroupArray.length > 1) {
      setTimeout(() => {
        const elem = document.getElementById("educationSection" + (this.educationGroupArray.length -1));
        if(elem) {
          elem.scrollIntoView({behavior: 'smooth', block: "center"});
        }
      }, 500);
    }
  }

  /**
   * function for removing form fields into education FormArray
   */
   removeEducationRepeatition(index: number) {
    // if(confirm('Are you sure you want to remove?')){
      this.educationGroupArray.removeAt(index);
      // if(this.resumeAcademicForm.touched || this.resumeAcademicForm.dirty) {
      //   this.saveAcademicsEn();
      // }
    // }
    // if(this.educationGroupArray.length > 0) {
    //   setTimeout(() => {
    //     const elem = document.getElementById("educationSection" + (index -1));
    //     if(elem) {
    //       elem.scrollIntoView({behavior: 'smooth', block: "center"});
    //     }
    //   }, 500);
    // }
  }

  /**
   * form array initialization funtion for experience
   */
   arrayOfExperience(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      company_name: [''],
      company_type: [null],
      start_year: [null],
      start_month: [null],
      end_year: [null],
      end_month: [null],
      purpose_of_resign: ['']
    });
  }

  /**
   * returning the form control of experience as FormArray
   */
  get experienceGroupArray() {
    return this.resumeExperienceForm.get('experience') as FormArray;
  }

  /**
   * function for adding form fields into experience FormArray
   */
  addExperienceRepeatition() {
    this.experienceGroupArray.push(this.arrayOfExperience());
    if(this.experienceGroupArray.length > 1) {
      setTimeout(() => {
        const elem = document.getElementById("experienceSection" + (this.experienceGroupArray.length -1));
        if(elem) {
          elem.scrollIntoView({behavior: 'smooth', block: "center"});
        }
      }, 500);
    }
  }

  /**
   * function for removing form fields into education FormArray
   */
   removeExperienceRepeatition(index: number) {
    // if(confirm('Are you sure you want to remove?')){
      this.experienceGroupArray.removeAt(index);
      // if(this.resumeExperienceForm.touched || this.resumeExperienceForm.dirty) {
      //   this.saveWorkExperienceEn();
      // }
    // }
    // if(this.experienceGroupArray.length > 0) {
    //   setTimeout(() => {
    //     const elem = document.getElementById("experienceSection" + (this.experienceGroupArray.length -1));
    //     if(elem) {
    //       elem.scrollIntoView({behavior: 'smooth', block: "center"});
    //     }
    //   }, 500);
    // }
  }


  /**
   * form array initialization funtion for qualification
   */
   arrayOfQualification(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      qualification_name: [''],
      certified_year: [null],
      certified_month: [null]
    });
  }

  /**
   * returning the form control of qualification as FormArray
   */
  get qualificationGroupArray() {
    return this.resumeQualificationForm.get('qualification') as FormArray;
  }

  /**
   * function for adding form fields into qualification FormArray
   */
  addQualificationRepeatition() {
    this.qualificationGroupArray.push(this.arrayOfQualification());
    if(this.qualificationGroupArray.length > 1) {
      setTimeout(() => {
        const elem = document.getElementById("qualificationSection" + (this.qualificationGroupArray.length -1));
        if(elem) {
          elem.scrollIntoView({behavior: 'smooth', block: "center"});
        }
      }, 500);
    }
  }

  /**
   * function for removing form fields into education FormArray
   */
   removeQualificationRepeatition(index: number) {
    // if(confirm('Are you sure you want to remove?')){
      this.qualificationGroupArray.removeAt(index);
      // if(this.resumeQualificationForm.touched || this.resumeQualificationForm.dirty) {
      //   this.saveQualificationEn();
      // }
    // }
    // if(this.qualificationGroupArray.length > 0) {
    //   setTimeout(() => {
    //     const elem = document.getElementById("qualificationSection" + (this.qualificationGroupArray.length -1));
    //     if(elem) {
    //       elem.scrollIntoView({behavior: 'smooth', block: "center"});
    //     }
    //   }, 500);
    // }
  }

  // workingHoursValue() {
  //   return this.resumeOthersForm.controls.available_working_hours.value;
  // }

  getResumeDetails() {
    this.hasResponse = false;
    this.resumeService.getResumeDetails().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.userDetailsVoidResume = res['user_detail'];
        const userDetails = res['user_detail'];
        this.uuid = userDetails.uuid;
        if(!res['resume_data']['has_resume']) { 
          this.enUSerDetails.first_name =  userDetails.first_name;
          this.enUSerDetails.last_name =  userDetails.last_name;
          // this.enUSerDetails.furigana =  userDetails.furigana;
          // this.enUSerDetails.profession =  userDetails.profession;
          // this.enUSerDetails.dob =  userDetails.dob;
          // this.enUSerDetails.age =  userDetails.age;
          // this.enUSerDetails.gender =  userDetails.gender; 
          // this.enUSerDetails.nationality =  userDetails.nationality; 
          this.enUSerDetails.email =  userDetails.email; 
          this.enUSerDetails.contact_number =  userDetails.mobile ? userDetails.mobile : ''; 
          Object.keys(this.enUSerDetails).forEach(key => {
            if (this.enUSerDetails[key] === null || this.enUSerDetails[key] === "null" || this.enUSerDetails[key] === '') {
              delete this.enUSerDetails[key];
            }
          });
          const jpOptions = res['resume_data']['data']['jp']['options'];
          this.jpResumeData = {
                                'personal_info': this.enUSerDetails,
                                'options': jpOptions
                              };
          this.resumeBasicInfoForm.patchValue(this.enUSerDetails);
        } else {
          const resumePersonalDetails = res['resume_data']['data']['en']['personal_info'];
          this.jpResumeData = res['resume_data']['data']['jp'];
          // Object.keys(resumePersonalDetails).forEach(key => {
          //   if (resumePersonalDetails[key] === null || resumePersonalDetails[key] === "null" || resumePersonalDetails[key] === '') {
          //     delete resumePersonalDetails[key];
          //   }
          // });
          this.enUSerDetails.first_name =  resumePersonalDetails.first_name || userDetails.first_name;
          this.enUSerDetails.last_name =  resumePersonalDetails.last_name || userDetails.last_name;
          this.enUSerDetails.furigana =  resumePersonalDetails.furigana;
          this.enUSerDetails.profession =  resumePersonalDetails.profession ? resumePersonalDetails.profession : '';
          this.enUSerDetails.dob =  resumePersonalDetails.dob;
          this.enUSerDetails.age =  resumePersonalDetails.age;
          this.enUSerDetails.gender =  resumePersonalDetails.gender; 
          this.enUSerDetails.nationality =  resumePersonalDetails.nationality; 
          this.enUSerDetails.email =  resumePersonalDetails.email || userDetails.email; 
          this.enUSerDetails.contact_number =  resumePersonalDetails.contact_number || userDetails.mobile; 
          Object.keys(this.enUSerDetails).forEach(key => {
            if (this.enUSerDetails[key] === null || this.enUSerDetails[key] === "null" || this.enUSerDetails[key] === '') {
              delete this.enUSerDetails[key];
            }
          });
          this.resumeBasicInfoForm .patchValue(this.enUSerDetails);
          /**
           * populate current address details
           */
          this.enCurrentAddress.curr_country = resumePersonalDetails.curr_country;
          if(this.enCurrentAddress.curr_country === 'Japan') {
            this.hasPostalCodeSearch = true;
          }
          this.enCurrentAddress.curr_postal_code = resumePersonalDetails.curr_postal_code;
          this.enCurrentAddress.curr_prefecture = resumePersonalDetails.curr_prefecture;
          this.enCurrentAddress.curr_city = resumePersonalDetails.curr_city;
          this.enCurrentAddress.curr_address = resumePersonalDetails.curr_address;
          this.enCurrentAddress.curr_phone = resumePersonalDetails.curr_phone;
          Object.keys(this.enCurrentAddress).forEach(key => {
            if (this.enCurrentAddress[key] === null || this.enCurrentAddress[key] === "null" || this.enCurrentAddress[key] === '') {
              delete this.enCurrentAddress[key];
            }
          });
          this.resumeCurrentAddressForm.patchValue(this.enCurrentAddress);
          /**
           * populate permanent address details
           */
           this.enPermanentAddress.cont_country = resumePersonalDetails.cont_country;
           if(this.enPermanentAddress.cont_country === 'Japan') {
            this.hasConstPostalCodeSearch = true;
           }
           this.enPermanentAddress.cont_postal_code = resumePersonalDetails.cont_postal_code;
           this.enPermanentAddress.cont_prefecture = resumePersonalDetails.cont_prefecture;
           this.enPermanentAddress.cont_city = resumePersonalDetails.cont_city;
           this.enPermanentAddress.cont_address = resumePersonalDetails.cont_address;
           this.enPermanentAddress.cont_phone = resumePersonalDetails.cont_phone;
           Object.keys(this.enPermanentAddress).forEach(key => {
            if (this.enPermanentAddress[key] === null || this.enPermanentAddress[key] === "null" || this.enPermanentAddress[key] === '') {
              delete this.enPermanentAddress[key];
            }
          });
           this.resumePermanentAddressForm.patchValue(this.enPermanentAddress);
           /**
            * populate academic details
            */
           this.educationGroupArray.clear();
           this.enAcademicList = res['resume_data']['data']['en']['academic_history'];
           if(this.enAcademicList.length > 0) {
            this.enAcademicList.forEach(obj =>{
              this.addEducationRepeatition();
              Object.keys(obj).forEach(key => {
                if (obj[key] === null || obj[key] === "null" || obj[key] === '') {
                  delete obj[key];
                }
              });
            });
            this.resumeAcademicForm.controls.education.patchValue(this.enAcademicList);
           }
           /**
            * populate work experience details
            */
            this.experienceGroupArray.clear();
            this.enExperienceList = res['resume_data']['data']['en']['work_history'];
            if(this.enExperienceList.length > 0) {
             this.enExperienceList.forEach(obj => {
               this.addExperienceRepeatition();
               Object.keys(obj).forEach(key => {
                if (obj[key] === null || obj[key] === "null" || obj[key] === '') {
                  delete obj[key];
                }
              });
              });
             this.resumeExperienceForm.controls.experience.patchValue(this.enExperienceList);
            }
          /**
            * populate qualification details
            */
           this.qualificationGroupArray.clear();
           this.enQualificationList = res['resume_data']['data']['en']['qualification_history'];
           if(this.enQualificationList.length > 0) {
            this.enQualificationList.forEach(obj => {
              this.addQualificationRepeatition();
              Object.keys(obj).forEach(key => {
                if (obj[key] === null || obj[key] === "null" || obj[key] === '') {
                  delete obj[key];
                }
              });
            });
            this.resumeQualificationForm.controls.qualification.patchValue(this.enQualificationList);
           }
           /**
            * populate otherd resume info
            */
            this.enOthers.known_languages = resumePersonalDetails.known_languages;
            this.enOthers.hobbies = resumePersonalDetails.hobbies;
            this.enOthers.motivation = resumePersonalDetails.motivation;
            this.enOthers.jlpt = resumePersonalDetails.jlpt;
            this.enOthers.self_pr = resumePersonalDetails.self_pr;
            this.enOthers.skills = resumePersonalDetails.skills;
            this.enOthers.extra_point = resumePersonalDetails.extra_point;
            const workingHours = resumePersonalDetails.working_hours.split(":")
            this.enOthers.available_working_hours = workingHours[0];
            this.enOthers.available_working_minutes = workingHours.length > 1 ? workingHours[1]: '';
            this.enOthers.dependents_except_spouse = resumePersonalDetails.dependents_except_spouse;
            this.enOthers.spouse = resumePersonalDetails.spouse;
            this.enOthers.spouse_support_obligation = resumePersonalDetails.spouse_support_obligation;
            this.enOthers.special_conditions = resumePersonalDetails.special_conditions || 'Follow as per company rules and regulations.';
            Object.keys(this.enOthers).forEach(key => {
              if (this.enOthers[key] === null || this.enOthers[key] === "null" || this.enOthers[key] === '') {
                delete this.enOthers[key];
              }
            });
           this.resumeOthersForm.patchValue(this.enOthers);
        } 
        if(userDetails.avatar) {
          if(userDetails.avatar.startsWith('http')) {
            this.selectedImageUrl = userDetails.avatar;
            this.oldSelectedImageUrl = userDetails.avatar;
          } else {
            this.selectedImageUrl = this.assetBaseUrl + userDetails.avatar;
            this.oldSelectedImageUrl = this.assetBaseUrl + userDetails.avatar;
          }
        }
        const enOptions = res['resume_data']['data']['en']['options'];
        this.enCompanyTypes = enOptions.company_types;
        this.enHobbies = enOptions.hobbies;
        this.enKnownLanguages = enOptions.known_languages;
        this.enMajorSubjects = enOptions.major_subjects;
        this.enNationalities = enOptions.nationalities;
        this.enPreferences = enOptions.preferences;
        this.enSkills = enOptions.skills;
        // this.enJpCities = enOptions.cities;
        // const jpAddress = res['addresses_jp']['en']['prefectures'];
        this.enJpPrefectures = res['addresses_jp']['en']['prefectures'];
        this.jpJpPrefecturesList = res['addresses_jp']['jp']['prefectures'];
        this.enNpProvinces = res['addresses_np']['en']['province'];
        this.jpNepalProvincesList = res['addresses_np']['jp']['province'];

        this.enProfessions = enOptions.professions;
        this.jpOptions = res['resume_data']['data']['jp']['options'];
        // this.jpCompanyTypes = jpOptions.company_types;
        // this.jpHobbies = jpOptions.hobbies;
        // this.jpKnownLanguages = jpOptions.known_languages;
        // this.jpMajorSubjects = jpOptions.major_subjects;
        // this.jpNationalities = jpOptions.nationalities;
        // this.jpPreferences = jpOptions.preferences;
        // this.jpSkills = jpOptions.skills;
        // this.jpJpCities = jpOptions.cities;
        // this.jpJpPrefectures = jpOptions.prefectures;
        // this.jpProfessions = jpOptions.professions;
        /**
         * assign completition rates
         */
        const enDatas = res['resume_data']['data']['en'];
        this.en_academics_completition_rate = enDatas.academics_completion_rate;
        this.en_personal_info_completition_rate = enDatas.personal_info_completion_rate;
        this.en_qualification_info_completition_rate = enDatas.qualification_completion_rate;
        this.en_work_completition_rate = enDatas.works_completion_rate;
        // const jpDatas = res['resume_data']['data']['jp'];
        // this.jp_academics_completition_rate = jpDatas.academics_completion_rate;
        // this.jp_personal_info_completition_rate = jpDatas.personal_info_completion_rate;
        // this.jp_qualification_info_completition_rate = jpDatas.qualification_completion_rate;
        // this.jp_work_completition_rate = jpDatas.works_completion_rate;
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Failed to fetch resume. Please try again.', 'ERROR');
      });
  }

  /**
   * 
   * @param tabname string value for a tab
   */
  changeActiveTab(tabname: string) {
    this.activeTab = tabname;
  }

  /**
   * function called when file is selected
   * @param files File
   */
   onSelectFile(event: any) {
    this.imageChangedEvent = event;
    setTimeout(() => {
      $('#cropImageModal').modal('show');
    },500);
    // const file = files[0];
    //   if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' ) {
    //     this.profileImageSelected = true;
    //     this.resumeProfileImageForm.markAsDirty();
    //     var reader = new FileReader();
    //     reader.onload = (event: any) => {
    //         this.selectedImageUrl = reader.result;
    //         this.selectedFile = reader.result;
    //         this.testShareFile = reader.result;
    //     }
    //     reader.readAsDataURL(file);
    //   } else {
    //     this.selectedFile = '';
    //     this.profileImageSelected = false;
    //     this.toastr.error('Please select image only', 'Error');
    //   }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedProfileImage = event.base64;
  }
  imageLoaded() {
    // setTimeout(() => {
    //   $('#cropImageModal').modal('show');
    // },500);
  }
  cropperReady() {
      // cropper ready
      // setTimeout(() => {
      //   $('#cropImageModal').modal('show');
      // },200);
  }
  loadImageFailed() {
    this.profileImageSelected = false;
    this.toastr.info("Failed to load image. Please try again.");
  }

  readyToUploadCroppedImage() {
    this.selectedImageUrl = this.croppedProfileImage;
    this.selectedFile = this.croppedProfileImage;
    this.profileImageSelected = true;
    $('#cropImageModal').modal('hide');
  }

  cancelCroppedImageUpload() {
    this.profileImageSelectionSection.nativeElement.value='';
    this.croppedProfileImage = '';
    this.profileImageSelected = false;
    $('#cropImageModal').modal('hide');
  }
  
  /**
   * function to copy current address to contact address
   * @param event 
   */
  sameAsCurrentAddress(event: any) {
    if(event.target.checked) {
      this.resumePermanentAddressForm.controls.cont_country.setValue(this.resumeCurrentAddressForm.controls.curr_country.value);
      this.resumePermanentAddressForm.controls.cont_country.updateValueAndValidity();
      this.resumePermanentAddressForm.controls.cont_postal_code.setValue(this.resumeCurrentAddressForm.controls.curr_postal_code.value);
      this.resumePermanentAddressForm.controls.cont_postal_code.updateValueAndValidity();
      this.resumePermanentAddressForm.controls.cont_prefecture.setValue(this.resumeCurrentAddressForm.controls.curr_prefecture.value);
      this.resumePermanentAddressForm.controls.cont_prefecture.updateValueAndValidity();
      this.resumePermanentAddressForm.controls.cont_city.setValue(this.resumeCurrentAddressForm.controls.curr_city.value);
      this.resumePermanentAddressForm.controls.cont_city.updateValueAndValidity();
      this.resumePermanentAddressForm.controls.cont_address.setValue(this.resumeCurrentAddressForm.controls.curr_address.value);
      this.resumePermanentAddressForm.controls.cont_address.updateValueAndValidity();
      this.resumePermanentAddressForm.controls.cont_phone.setValue(this.resumeCurrentAddressForm.controls.curr_phone.value);
      this.resumePermanentAddressForm.controls.cont_phone.updateValueAndValidity();
    } else {
      this.resumePermanentAddressForm.patchValue(this.enPermanentAddress);
    }
  }

  shareResume() {
    // let fileListToShare: FileList ;
    // var blob = this.dataURLToBlob(this.testShareFile);
    // var fileToShare = new File([blob], 'resume.png', {lastModified: new Date().getTime(), type: 'image/png'});
    // /**
    //  * update FileList
    //  */
    // let list = new DataTransfer();
    // list.items.add(fileToShare);
    // fileListToShare = list.files;
    // /**
    //  * share files
    //  */
    // let shareResume: any = window.navigator;
    // if(shareResume.canShare ) {
    //   shareResume.share({
    //       files: fileListToShare,
    //       title: 'Sharing Resume',
    //       text: 'Please find the attached resume.',
    //       // url: 'testurl.com'
    //   })
    //   .then(() => console.log('Share was successful.'))
    //   .catch((error: any) => console.log('Sharing failed', error));
    // } else {
    //   console.log('Sharing not supported');
    // }

    
  }

  dataURLToBlob(dataURL: string) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = decodeURIComponent(parts[1]);
        return new Blob([raw], {type: contentType});
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
}

  cancelImageSelect() {
    this.selectedFile = '';
    this.profileImageSelected = false;
    this.resumeProfileImageForm.reset();
    this.selectedImageUrl = this.oldSelectedImageUrl;
    this.profileImageSelectionSection.nativeElement.value='';
  }

  getCurrAddressByPostalCode() {
    let postal_code = this.resumeCurrentAddressForm.controls.curr_postal_code.value;
    if(postal_code == '' || postal_code == null) {
      this.toastr.info("Please provide postal code first");
      const postalCodeControl = this.el.nativeElement.querySelector('[formControlName="curr_postal_code"]');
      postalCodeControl.focus();
      return;
    }
    this.currAddressLoader = true;
    this.resumeService.getAddressByPostalCode(postal_code.replace(/-/g, ""))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res:any) => {
        const addressDetails = res.postal_code[0];
        this.resumeCurrentAddressForm.controls.curr_prefecture.setValue(addressDetails.prefecture);
        this.resumeCurrentAddressForm.controls.curr_prefecture.updateValueAndValidity();
        this.resumeCurrentAddressForm.controls.curr_city.setValue(addressDetails.city);
        this.resumeCurrentAddressForm.controls.curr_city.updateValueAndValidity();
        this.resumeCurrentAddressForm.controls.curr_address.setValue(addressDetails.street);
        this.resumeCurrentAddressForm.controls.curr_address.updateValueAndValidity();
        this.currAddressLoader = false;
      }, (err: any) => {
        this.toastr.error(err.error.error, 'Error');
        this.currAddressLoader = false;
      });
  }

  getPermanentAddressByPostalCode() {
    let postal_code = this.resumePermanentAddressForm.controls.cont_postal_code.value;
    if(postal_code == '' || postal_code == null) {
      this.toastr.info("Please provide postal code first");
      const postalCodeControl = this.el.nativeElement.querySelector('[formControlName="cont_postal_code"]');
      postalCodeControl.focus();
      return;
    }
    this.constAddressLoader = true;
    this.resumeService.getAddressByPostalCode(postal_code.replace(/-/g, ""))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res:any) => {
        const addressDetails = res.postal_code[0];
        this.resumePermanentAddressForm.controls.cont_prefecture.setValue(addressDetails.prefecture);
        this.resumePermanentAddressForm.controls.cont_prefecture.updateValueAndValidity();
        this.resumePermanentAddressForm.controls.cont_city.setValue(addressDetails.city);
        this.resumePermanentAddressForm.controls.cont_city.updateValueAndValidity();
        this.resumePermanentAddressForm.controls.cont_address.setValue(addressDetails.street);
        this.resumePermanentAddressForm.controls.cont_address.updateValueAndValidity();
        this.constAddressLoader = false;
      }, (err: any) => {
        this.toastr.error(err.error.error, 'Error');
        this.constAddressLoader = false;
      });
  }

  toogleBasicInfoEdit() {
    this.basicInfoEdit = !this.basicInfoEdit;
    if(!this.basicInfoEdit) {
      this.resumeBasicInfoForm.markAsPristine();
      this.resumeBasicInfoForm.markAsUntouched();
    }
  }

  saveImage() {
    if(this.profileImageSelected && this.selectedFile !== '') {
      this.resumeSectionUpdateRequest = 'en_profile_image';
      this.resumeUpdateRequested = true;
      let resumeImageDetails = {
        img: this.selectedFile
      }
      this.resumeService.updateEnglishResume(resumeImageDetails, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.toastr.success(res.message, 'Success');
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.resumeProfileImageForm.markAsPristine();
        this.resumeProfileImageForm.markAsUntouched();
        this.profileImageSelected = false;
        this.profileImageSelectionSection.nativeElement.value='';
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        this.profileImageSelectionSection.nativeElement.value='';
        // this.toastr.error('Failed to update resume.', 'Error');
      });
    }
  }

  saveBasicInfoEn() {
    if(this.resumeBasicInfoForm.invalid) {
      return ;
    }
    this.resumeSectionUpdateRequest = 'en_basic_info';
    this.resumeUpdateRequested = true;
    const formValues = this.resumeBasicInfoForm.getRawValue();
    let basicInfoDetails = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      furigana: formValues.furigana,
      profession: formValues.profession ? formValues.profession : '',
      dob: formValues.dob ? formValues.dob : '',
      age: formValues.age,
      gender: formValues.gender ? formValues.gender : '',
      nationality: formValues.nationality ? formValues.nationality: '',
      resume_email: formValues.email,
      contact_number: formValues.contact_number
    }
    // if(formValues.dob) {
    //   Object.assign({ dob: formValues.dob }, basicInfoDetails);
    // }
    this.resumeService.updateEnglishResume(basicInfoDetails, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumeBasicInfoForm.markAsPristine();
        this.resumeBasicInfoForm.markAsUntouched();
        this.toogleBasicInfoEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });

  }

  toogleCurrentAddressEdit() {
    this.currAddressEdit = !this.currAddressEdit;
    if(!this.currAddressEdit) {
      this.resumeCurrentAddressForm.markAsPristine();
      this.resumeCurrentAddressForm.markAsUntouched();
    } else {
      this.checkCurrentCountryValue();
    }
  }

  checkCurrentCountryValue() {
    let currentCountry = this.resumeCurrentAddressForm.get('curr_country')?.value;
    if(currentCountry === 'Japan' && this.currAddressEdit) {
      this.hasPrefecture = true;
      this.hasCity = true;
      this.hasProvince = false;
      this.hasPostalCodeSearch = true;
      this.resumeCurrentAddressForm.controls.curr_city.disable();
    } else if(currentCountry === 'Nepal' && this.currAddressEdit) {
      this.hasProvince = true;
      this.hasCity = true;
      this.hasPrefecture = false;
      this.hasPostalCodeSearch = false;
      this.resumeCurrentAddressForm.controls.curr_city.disable();
    } else {
      this.hasCity = false;
      this.hasProvince = false;
      this.hasPrefecture = false;
      this.hasPostalCodeSearch = false;
      if(currentCountry && this.currAddressEdit) {
        this.resumeCurrentAddressForm.controls.curr_city.enable();
      }
    }
  }

  saveCurrentAddressEn() { 
    this.resumeSectionUpdateRequest = 'en_current_address';
    this.resumeUpdateRequested = true;
    this.resumeService.updateEnglishResume(this.resumeCurrentAddressForm.value, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumeCurrentAddressForm.markAsPristine();
        this.resumeCurrentAddressForm.markAsUntouched();
        this.toogleCurrentAddressEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  tooglePermanentAddressEdit() {
    this.contAddressEdit = !this.contAddressEdit;
    if(!this.contAddressEdit) {
      this.resumePermanentAddressForm.markAsPristine();
      this.resumePermanentAddressForm.markAsUntouched();
    } else {
      this.checkPermanentCountry();
    }
  }

  checkPermanentCountry() {
    let contCountry = this.resumePermanentAddressForm.get('cont_country')?.value;
    if(contCountry === 'Japan' && this.contAddressEdit) {
      this.hasConstPrefecture = true;
      this.hasConstCity = true;
      this.hasConstProvince = false;
      this.hasConstPostalCodeSearch = true;
      this.resumePermanentAddressForm.controls.cont_city.disable();
     } else if(contCountry === 'Nepal' && this.contAddressEdit) {
      this.hasConstProvince = true;
      this.hasConstCity = true;
      this.hasConstPrefecture = false;
      this.hasConstPostalCodeSearch = false;
      this.resumePermanentAddressForm.controls.cont_city.disable();
     } else {
      this.hasConstCity = false;
      this.hasConstProvince = false;
      this.hasConstPrefecture = false;
      this.hasConstPostalCodeSearch = false;
      if(contCountry && this.contAddressEdit) {
        this.resumePermanentAddressForm.controls.cont_city.enable();
      }
     }
  }

  savePermanentAddressEn() { 
    this.resumeSectionUpdateRequest = 'en_permanent_address';
    this.resumeUpdateRequested = true;
    this.resumeService.updateEnglishResume(this.resumePermanentAddressForm.value, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumePermanentAddressForm.markAsPristine();
        this.resumePermanentAddressForm.markAsUntouched();
        this.tooglePermanentAddressEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleAcademicEdit() {
    this.academicEdit = !this.academicEdit;
    if(!this.academicEdit) {
      this.resumeAcademicForm.markAsPristine();
      this.resumeAcademicForm.markAsUntouched();
    }
  }

  saveAcademicsEn() {
    this.resumeSectionUpdateRequest = 'en_academics';
    this.resumeUpdateRequested = true;
    const formValues = this.resumeAcademicForm.value;
    /**
     * replace null values by '' in education array
     */
     formValues.education.forEach((edu: any) => {
      Object.keys(edu).forEach(key => {
        if(edu[key] === null) {
          edu[key] = '';
        }
        if(edu['id'] === ''){
          delete edu['id'];
        }
      });
    });
    // const academicDetails = {
    //   education: formValues.education
    // }
    this.resumeService.updateEnglishResume(formValues, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumeAcademicForm.markAsPristine();
        this.resumeAcademicForm.markAsUntouched();
        this.toogleAcademicEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleWorkExperienceEdit() {
    this.workEdit = !this.workEdit;
    if(!this.workEdit) {
      this.resumeExperienceForm.markAsPristine();
      this.resumeExperienceForm.markAsUntouched();
    }
  }

  saveWorkExperienceEn() {
    this.resumeSectionUpdateRequest = 'en_work_experience';
    this.resumeUpdateRequested = true;
    const formValues = this.resumeExperienceForm.value;
    /**
     * replace null values by '' in experience array
     */
     formValues.experience.forEach((edu: any) => {
      Object.keys(edu).forEach(key => {
        if(edu[key] === null) {
          edu[key] = '';
        }
        if(edu['id'] === ''){
          delete edu['id'];
        }
      });
    });
    // const experienceDetails = {
    //   'experience': JSON.stringify(formValues.experience)
    // }
    this.resumeService.updateEnglishResume(formValues, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumeExperienceForm.markAsPristine();
        this.resumeExperienceForm.markAsUntouched();
        this.toogleWorkExperienceEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleQualificationEdit() {
    this.qualificationEdit = !this.qualificationEdit;
    if(!this.qualificationEdit) {
      this.resumeQualificationForm.markAsPristine();
      this.resumeQualificationForm.markAsUntouched();
    }
  }

  saveQualificationEn() {
    this.resumeSectionUpdateRequest = 'en_qualification';
    this.resumeUpdateRequested = true;
    const formValues = this.resumeQualificationForm.value;
    /**
     * replace null values by '' in qualification array
     */
     formValues.qualification.forEach((edu: any) => {
      Object.keys(edu).forEach(key => {
        if(edu[key] === null) {
          edu[key] = '';
        }
        if(edu['id'] === ''){
          delete edu['id'];
        }
      });
    });
    // const qualificationDetails = {
    //   "qualification": JSON.stringify(formValues.qualification)
    // }
    this.resumeService.updateEnglishResume(formValues, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumeQualificationForm.markAsPristine();
        this.resumeQualificationForm.markAsUntouched();
        this.toogleQualificationEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleOthersEdit() {
    this.othersEdit = !this.othersEdit;
    if(!this.othersEdit) {
      this.resumeOthersForm.markAsPristine();
      this.resumeOthersForm.markAsUntouched();
    }
  }

  saveOthersInfoEn() {
    
    this.resumeSectionUpdateRequest = 'en_others_info';
    this.resumeUpdateRequested = true;
    const formValues = this.resumeOthersForm.value;
    let availableWorkingTime = '';
    if(formValues.available_working_hours) {
      availableWorkingTime = formValues.available_working_hours + ':' + (formValues.available_working_minutes ? formValues.available_working_minutes : '00');
    }
    const othersInfoDetails = {
      known_languages: formValues.known_languages? formValues.known_languages : [],
      hobbies: formValues.hobbies ? formValues.hobbies : [],
      motivation: formValues.motivation,
      jlpt: formValues.jlpt? formValues.jlpt : '',
      self_pr: formValues.self_pr,
      skills: formValues.skills ? formValues.skills : [],
      extra_point: formValues.extra_point,
      working_hours: availableWorkingTime,
      dependents_except_spouse: formValues.dependents_except_spouse,
      spouse: formValues.spouse ? formValues.spouse : '',
      spouse_support_obligation :formValues.spouse_support_obligation ? formValues.spouse_support_obligation : '',
      special_conditions :formValues.special_conditions ? formValues.special_conditions : ''
    }
    this.resumeService.updateEnglishResume(othersInfoDetails, this.uuid, 'en')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getResumeDetails();
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.resumeOthersForm.markAsPristine();
        this.resumeOthersForm.markAsUntouched();
        this.toogleOthersEdit();
        // location.reload();
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  /**
   * function to remove data from education/work experience/qualification
   * @param index index of a data in an array of education/work experience/qualification data
   * @param data_id id of a data to be removed
   * @param data_type data type ie. education/work experience/qualification
   */
  removeData(index: number, data_id: number, data_type: string) {
    if(confirm('Are you sure you want to remove?')){
      this.data_type_to_remove = data_type;
      this.data_index_to_be_removed = index;
      if(data_id) {
        this.resumeService.removeResumeData(data_id, data_type).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if(this.data_type_to_remove == 'education') {
            this.toogleAcademicEdit();
          }
          if(this.data_type_to_remove == 'work_experience') {
            this.toogleWorkExperienceEdit();
          }
          if(this.data_type_to_remove == 'qualification') {
            this.toogleQualificationEdit();
          }
          this.data_type_to_remove = '';
          this.data_index_to_be_removed = '';
          this.getResumeDetails();
          this.toastr.success('Data removed from the list', 'SUCCESS');
        }, (err: any) =>{
          this.data_type_to_remove = '';
          this.data_index_to_be_removed = '';
          const errors = err.error.error;
          Object.keys(errors).forEach(key => {
            this.toastr.error(errors[key][0], key)
          });
          // this.toastr.error('Unable to remove data. Please try agaian', 'ERROR');
        });
      } else {
        if(this.data_type_to_remove == 'education') {
          this.removeEducationRepeatition(index);
        }
        if(this.data_type_to_remove == 'experience') {
          this.removeExperienceRepeatition(index);
        }
        if(this.data_type_to_remove == 'qualification') {
          this.removeQualificationRepeatition(index);
        }
        this.data_type_to_remove = '';
        this.data_index_to_be_removed = '';
      }
    }
  }

  /**
   * check if the user has made any changes to the form or not
   * if the form state is changed
   * ask user for confirmation before deactivating component
   */
   @HostListener('window:beforeunload')
   canDeactivate() {
      if (this.resumeBasicInfoForm.dirty && !this.resumeBasicInfoForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in basic details will be discarded.");
      } else if (this.resumeCurrentAddressForm.dirty && !this.resumeCurrentAddressForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in current address will be discarded.");
      } else if (this.resumePermanentAddressForm.dirty && !this.resumePermanentAddressForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in permanent address will be discarded.");
      } else if (this.resumeAcademicForm.dirty && !this.resumeAcademicForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in education will be discarded.");
      } else if (this.resumeExperienceForm.dirty && !this.resumeExperienceForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in work will be discarded.");
      } else if (this.resumeQualificationForm.dirty && !this.resumeQualificationForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in qualification will be discarded.");
      } else if (this.resumeOthersForm.dirty && !this.resumeOthersForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in others will be discarded.");
      }
     return true;
   }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
