import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root'
})
export class PushnotificationService {
  firebaseToken: any;

  constructor(private angularFireMessaging: AngularFireMessaging) { 
      this.angularFireMessaging.getToken.subscribe(res=>{
        if(res) {
            this.firebaseToken=res;
            localStorage.removeItem("fb-token");
            localStorage.setItem("fb-token", res);
        }
      });
    }

    requestPermission() {
      this.angularFireMessaging.requestToken.subscribe(
        token => {
          // console.log("Permission granted => "+ token);
          if(token) {
            localStorage.removeItem("fb-token");
            localStorage.setItem("fb-token", token);
          }
        },
        err => {
          // console.log("Permission denied =>"+err);
        }
      );
    }

    tokenRefresh(){
      this.angularFireMessaging.tokenChanges.subscribe(
        token => {
            // console.log("refreshed token =>"+token);
            if(token) {
                localStorage.removeItem("fb-token");
                localStorage.setItem("fb-token", token);
            }
        },
        err => {
          // console.log("Error while getting refreshed token =>"+err);
        }
      );
    }

    receiveMessage() {
      this.angularFireMessaging.messages.subscribe(
        (payload: any) => {
            // console.log("message received");
        //   this.dataEmissionService.notificationEmissionMethod(payload);
        },
        err => {
        }
      );
    }
}
