import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AboutUsService } from '../utils/services/aboutus.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  aboutUsDescription: string = '';
  currentYear = new Date().getFullYear();

  constructor(private aboutusService: AboutUsService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAboutUsDetails();
  }

  getAboutUsDetails() { 
    this.aboutusService.fetchAboutUsDetails().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.aboutUsDescription = res['about_us'][0]['description'];
      }, (err: any) => {
        // this.toastr.error('Failed to fetch About details', 'ERROR');
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
