import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private baseUrl = environment.apiUrl;

  private contactusHeader = new HttpHeaders({
    'cache': 'false',
    'cache_group': 'contactus'
  });

  constructor(private http: HttpClient) { }

  submitContactMessage(message: any) {
      return this.http.post(this.baseUrl + '/pages/contact_us ', message, {headers: this.contactusHeader});
  }
}
