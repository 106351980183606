import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        ProfileRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgSelectModule,
        NgxMaskModule,
        ImageCropperModule
    ],
    declarations: [
        ProfileComponent
    ],
    providers: [
        
    ]
})

export class ProfileModule {
}
