import { Component } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { PushnotificationService } from './utils/pushnotification.service';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'buy-now-pay-in-japan';
  onlineEvent!: Observable<Event>;
	offlineEvent!: Observable<Event>;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private pushNotificationService : PushnotificationService, private toastr: ToastrService, 
        private swUpdate: SwUpdate, private router: Router)
    { }

    ngOnInit()
    {
        this.pushNotificationService.requestPermission();
        this.pushNotificationService.receiveMessage();
        this.getUserLocation();
        this.checkForUpdates();
        this.checkUserAgent();
        /**
        * Get the online/offline status from browser window
        */
       this.onlineEvent = fromEvent(window, 'online');
       this.offlineEvent = fromEvent(window, 'offline');

       this.onlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.toastr.clear();
            this.toastr.success('Your internet connection is restored.', 'Welcome Back !!');
            window.location.reload();
       });

       this.offlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
            this.toastr.clear();
            this.toastr.error('Datas will be synced after your internet connection is restored.', 'Connection Lost !!');
       });
       /**
        * scroll to top on every route change
        */
        this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
              return;
          }
          window.scroll(0,0);
          // this.sectionToScroll.nativeElement.scrollTop = 30;
      });
    }

    /**
     * function to make page scroll to top on refresh/load
     * @param event 
     */
    onActivate(event: any) {
      // setTimeout(()=> {
        window.scroll(0,0);
        // this.sectionToScroll.nativeElement.scrollTop = 30;
      // }, 100);
      // document.getElementById("highleveldiv")?.scrollIntoView();
    }

    /**
     * function to check for new versions
     */
    checkForUpdates() {
      /**
       * Notify user about the latest release and ask for comfirmation to load the latest release.
       */
       if (this.swUpdate.isEnabled) {
        this.swUpdate.available.subscribe(() => {
            if (confirm('New version available. Load New Version?')) {
              window.location.reload();
            }
        });
        }
    }

    /**
   * function to check if the user is exploring website from mobile devices
   */
   checkUserAgent() {
    /**
     * detect mobile device by browser sniffing
     */
     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) || window.orientation > -1) {
      let uaDate = localStorage.getItem('ua');
      let currentDate = new Date().toLocaleDateString();
      if(!uaDate || new Date(currentDate) > new Date(uaDate)) {
        setTimeout(()=> {
          $('#mobileUserModal').modal('show');
        }, 2000);
      }
    }
  }

  /**
   * function to store the date user has agreed to continue if a user is from mobile device
   */
  userAgreement() {
    localStorage.setItem('ua', '' +new Date().toLocaleDateString());
  }

  /**
   * function to get user location
   */
    getUserLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                localStorage.removeItem("created_gps");
                localStorage.setItem("created_gps", position.coords.latitude + ":" + position.coords.longitude);
              });
          }
      }
}
