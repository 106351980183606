import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';

@NgModule({
    imports: [
        CommonModule,
        ResetPasswordRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ResetPasswordComponent
    ],
    providers: [
        
    ]
})

export class ResetPasswordModule {
}
