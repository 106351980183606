import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { TransactionLawComponent } from './transaction-law.component';

const TRANSACTION_LAW: Routes = [
    { path: '', component : TransactionLawComponent}
];
@NgModule({
    imports: [RouterModule.forChild(TRANSACTION_LAW)],
    exports: [RouterModule]
})
export class TransactionLawRoutingModule {
}
