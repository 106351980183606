<!-- Page header -->
<section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>Transactions</h2>
            </div>
        </div>
    </div>
</section>
<!-- End of Page header -->

<!-- Main contents -->
<section class="content content-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="inner-tabs">
                    <div class="head-tabs">
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link link" [ngClass]="activeTab == 'all' ? 'active':''" id="all-transaction-tab" (click)="filterTransactionsByType('all', true)" data-toggle="tab" role="tab" aria-controls="for-you" aria-selected="true">All</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link link" [ngClass]="activeTab == 'topup' ? 'active':''" id="topup-tab" (click)="filterTransactionsByType('topup', true)" data-toggle="tab" role="tab" aria-controls="news" aria-selected="false">Topup</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link link" [ngClass]="activeTab == 'purchased' ? 'active':''" id="purchase-tab" (click)="filterTransactionsByType('purchased', true)" data-toggle="tab" role="tab" aria-controls="alerts" aria-selected="false">Purchase</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link link" [ngClass]="activeTab == 'refund' ? 'active':''" id="purchase-tab" (click)="filterTransactionsByType('refund', true)" data-toggle="tab" role="tab" aria-controls="alerts" aria-selected="false">Refund</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6 col-lg-6">
                              <form class="d-flex justify-content-between" [formGroup]="filterForm">
                                <input class="form-control local-transaction-filter" formControlName="searchText" type="text" placeholder="Search" aria-label="Search">
                                <button type="button" class="btn btn-orange filter-transaction-btn" (click)="toogleDateFilterView()">
                                  <i class="fas fa-filter"></i> 
                                  <span class="filter-text">Filter</span>
                                </button>
                              </form>
                              <ng-container *ngIf="showDateFilter">
                                <div class="col-md-12 mt-3 no-padding">
                                  <form class="d-flex justify-content-between" [formGroup]="dateFilterForm" (ngSubmit)="filterTransactionsByDate()">
                                    <input class="form-control date-filter" formControlName="from_date" placeholder="From Date" ngbDatepicker #f="ngbDatepicker" (click)="f.toggle()">
                                    <input class="form-control date-filter" formControlName="to_date" placeholder="To Date"  ngbDatepicker #e="ngbDatepicker" (click)="e.toggle()">
                                    <button type="submit" class="btn btn-orange filter-transaction-btn">
                                      <span class="filter-text">Show</span>
                                    </button>
                                  </form>
                                </div>
                              </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent">

                        <div class="tab-pane fade show active" id="transaction-statements" role="tabpanel">
                            <div class="row">
                                <div class="col-md-12">
                                    <ng-container *ngIf="filteredTransactionList.length > 0; else fetchingTransactionStatements">
                                        <ng-container *ngFor="let transaction of filteredTransactionList;  trackBy:trackById;">
                                          <ng-container *ngIf="transaction == -2; else displayFilteredData">
                                            <h6 class="col-lg-12 text-center mt-3">
                                                Sorry, but nothing matched your search terms. Please try again with some different keywords.
                                            </h6>
                                          </ng-container>
                                          <ng-template #displayFilteredData>
                                            <ng-container *ngIf="transaction == -1; else hasFilteredTransactionData">
                                              <h6 class="col-lg-12 text-center mt-3">
                                                  Sorry, transactions are not available.
                                              </h6>
                                            </ng-container>
                                            <ng-template #hasFilteredTransactionData>
                                              <div class="alerts-item d-flex justify-content-between">
                                                  <div class="l-detail d-flex">
                                                      <img class="img-fluid" src="{{transaction.icon}}">
                                                      <div class="alert-wrap">
                                                          <a>{{transaction.transaction_name}}</a>
                                                          <div class="mt-1">
                                                              <item>
                                                              <span>{{transaction.transaction_type}}</span>
                                                          </item>
                                                              <item>
                                                                  <span>{{ transaction.created_at | date:'MMM d y - h:mm'}}</span>
                                                                  <!-- <span> 06:46</span> -->
                                                              </item>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="text-center">
                                                      <strong class="d-block mb-2 transaction-amount">{{transaction.currency}} {{transaction.topup_amount}}</strong>
                                                      <span>Status: <span [ngClass]="transaction.status ? 'transaction-status' : 'transaction-failed'">{{transaction.transaction_status}}</span>
                                                      </span>
                                                  </div>
                                              </div>
                                            </ng-template>
                                          </ng-template>
                                          
                                        </ng-container>
                                        <ng-container *ngIf="showLoadMoreButton">
                                            <div class="row justify-content-center mt-5">
                                                <button type="submit" class="btn btn-orange s-btn" (click)="fetchMoreData(activeTab)">
                                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchingMoreData"></i>{{fetchingMoreData ? 'Loading' : 'Load More'}}
                                                </button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #fetchingTransactionStatements>
                                        <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                            <div class="container">
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                                    <div class="head-tabs">
                                                      <div class="row">
                                                        <div class="col-md-12">
                                                          <div class="tab-content" id="myTabContent">
                                                            <div class="tab-pane fade" id="latest-alerts" role="tabpanel">
                                                              <div class="row">	
                                                                <div class="col-md-12">
                                                                  sdfsd
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="tab-pane fade show active" id="earthquake-info" role="tabpanel">
                                                              <div class="row">	
                                                                <div class="col-md-12">
                                                                  <div class="alerts-item d-flex justify-content-between">
                                                                    <div class="l-detail d-flex">
                                                                      <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                                      <div class="alert-wrap">
                                                                        <a >Northern Wakayama Pref</a>
                                                                        <div class="mt-1">
                                                                          <item>
                                                                            <span>2021/03/24</span>
                                                                            <span> 06:46</span>
                                                                          </item>
                                                                          <span>Maximum seismic intensity 4.2</span>
                                                                        </div>
                              
                                                                      </div>
                                                                    </div>
                                                                    <div class="r-info text-center">
                                                                      <strong class="d-block mb-2">4.2</strong>
                                                                      <span>10h ago</span>
                                                                    </div>
                                                                  </div>
                                                                  <div class="alerts-item d-flex justify-content-between">
                                                                    <div class="l-detail d-flex">
                                                                      <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                                      <div class="alert-wrap">
                                                                        <a >Northern Wakayama Pref</a>
                                                                        <div class="mt-1">
                                                                          <item>
                                                                            <span>2021/03/24</span>
                                                                            <span> 06:46</span>
                                                                          </item>
                                                                          <span>Maximum seismic intensity 4.2</span>
                                                                        </div>
                              
                                                                      </div>
                                                                    </div>
                                                                    <div class="r-info text-center">
                                                                      <strong class="d-block mb-2">4.2</strong>
                                                                      <span>10h ago</span>
                                                                    </div>
                                                                  </div>
                                                                  <div class="alerts-item d-flex justify-content-between">
                                                                    <div class="l-detail d-flex">
                                                                      <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                                      <div class="alert-wrap">
                                                                        <a >Northern Wakayama Pref</a>
                                                                        <div class="mt-1">
                                                                          <item>
                                                                            <span>2021/03/24</span>
                                                                            <span> 06:46</span>
                                                                          </item>
                                                                          <span>Maximum seismic intensity 4.2</span>
                                                                        </div>
                              
                                                                      </div>
                                                                    </div>
                                                                    <div class="r-info text-center">
                                                                      <strong class="d-block mb-2">4.2</strong>
                                                                      <span>10h ago</span>
                                                                    </div>
                                                                  </div>
                                                                  <div class="alerts-item d-flex justify-content-between">
                                                                    <div class="l-detail d-flex">
                                                                      <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                                      <div class="alert-wrap">
                                                                        <a >Northern Wakayama Pref</a>
                                                                        <div class="mt-1">
                                                                          <item>
                                                                            <span>2021/03/24</span>
                                                                            <span> 06:46</span>
                                                                          </item>
                                                                          <span>Maximum seismic intensity 4.2</span>
                                                                        </div>
                              
                                                                      </div>
                                                                    </div>
                                                                    <div class="r-info text-center">
                                                                      <strong class="d-block mb-2">4.2</strong>
                                                                      <span>10h ago</span>
                                                                    </div>
                                                                  </div>
                                                                  <div class="alerts-item d-flex justify-content-between">
                                                                    <div class="l-detail d-flex">
                                                                      <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                                      <div class="alert-wrap">
                                                                        <a >Northern Wakayama Pref</a>
                                                                        <div class="mt-1">
                                                                          <item>
                                                                            <span>2021/03/24</span>
                                                                            <span> 06:46</span>
                                                                          </item>
                                                                          <span>Maximum seismic intensity 4.2</span>
                                                                        </div>
                              
                                                                      </div>
                                                                    </div>
                                                                    <div class="r-info text-center">
                                                                      <strong class="d-block mb-2">4.2</strong>
                                                                      <span>10h ago</span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>							
                                                    </div>												
                                                  </div>
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </section>
                                          <!-- End of Main contents -->
                                    </ng-template>
                                    
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <ng-container *ngIf="hasBalanceResponse; else fetchingUserBalance">
                    <div class="transaction-card-box">
                        <h4 class="d-block mb-3">Status</h4>
                        <hr class="mt-0">
                        <div class="d-flex justify-content-between">
                          <div class="balance">
                            <span>Available Balance</span>
                            <h5>JPY {{userBalance ? userBalance : 0}}</h5>
                            <a [routerLink]="['/topup']" class="btn btn-orange mt-2"><i class="fas fa-plus pr-1"></i>Add Balance</a>
                          </div>
                          <div class="points">
                            <span class="d-block">Points</span>
                            <strong>{{userRewardPoints ? userRewardPoints : 0}}</strong>
                          </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #fetchingUserBalance>
                    <section class="content content-section loading-skeleton avoid-skeleton-padding">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="inner s-content" style="height: 250px">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ng-template>
                
            </div>
        </div>
    </div>
</section>
<!-- End of Main contents -->