<!-- Main conatiner -->
<section class="login-section">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-lg-4 mx-auto">
        <div class="form-inner verify">
          <ng-container *ngIf="verifiedStatus == 'pending'; else successOrFailure">
            <img src="assets/img/icon-email-verify-pending.svg" class="img-fluid mb-4">
            <h2>Verifying your email..</h2>
          </ng-container>
          <ng-template #successOrFailure>
            <ng-container *ngIf="verifiedStatus == 'success'; else failedEmailVerification">
                <img src="assets/img/icon-email-verify.svg" class="img-fluid mb-4">
                <h2>Your email is verified successfully!! You may now proceed to the BNPJ application.</h2>
                <div class="col-12 my-3">
                  <button type="button" [routerLink]="['/login']" class="btn btn-orange s-btn">Login Now</button>
                  &nbsp;&nbsp;
                </div>
              </ng-container>
              <ng-template #failedEmailVerification>
                <img src="assets/img/icon-email-unverified.svg" class="img-fluid mb-4">
                <h2>Unable to verify your email.Please try again.</h2>
                <div class="col-12 my-3">
                  <button type="button" class="btn btn-orange s-btn" (click)="resentVerificationCode()">
                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="showResendCodeLoader"></i>Resend Code
                  </button>
                </div>
              </ng-template>
          </ng-template>
          
        </div>
      </div>
      <!-- End of form -->
    </div>
  </div>
</section>
<!-- End of Main conatiner -->