import { AgmMap } from '@agm/core';
import {Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/utils/data-share.service';
import { AlertService } from '../service/alert.service';

@Component({
  selector: 'app-volcano-detail',
  templateUrl: './volcano-detail.component.html',
  styleUrls: ['./volcano-detail.component.css']
})
export class VolcanoDetailComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  /**
   * variables declared to hold path param values
   */
  hasResponse: boolean = false;
  volcanoCode: string = '';
  language: string = '';
  /**
   * variables declared to hold data for earthquakes
   */
  volcanoDetail: any;
  volcanoList: any[] = [];

  constructor(private alertService: AlertService, private toastr: ToastrService, 
    private activatedRoute: ActivatedRoute, private dataShareService: DataShareService, private router: Router) {
    this.language = this.activatedRoute.snapshot.paramMap.get('language') || '';
    this.volcanoCode = this.activatedRoute.snapshot.paramMap.get('code') || '';
  }

  ngOnInit(): void {
    this.getVolcanoDetailsByCode();
    this.fetchLatestVolcanos();
  }

  /**
   * function to fetch earthquake details
   */
   getVolcanoDetailsByCode() {
    this.alertService.getVolcanoDetailByCode(this.language, this.volcanoCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.volcanoDetail = res[0];
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Failed to fetch earthquake details', 'Error');
      });
  }

  /**
   * function to fetch latest earthquake list
   */
   fetchLatestVolcanos() {
    this.alertService.getLatestVolcanoList(3)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.volcanoList = res;
        if(this.volcanoList.length == 0) {
          this.volcanoList = [-1];
        }
      }, (err: any) => {
        // this.toastr.error('Failed to fetch latest Volcanos', 'Error');
      });
  }

  changeVolcanoDetails(volcanoDetail: any) {
    this.volcanoDetail = volcanoDetail;
  }

  /**
   * function to redirect user to alert page with the clicked tab as active tab
   */
   redirectUsersToListingPage(tab: any) {
    this.router.navigate(['/alert'])
    .then(() => {
      this.dataShareService.changeAlertActiveTab(tab);
    });
  }

  trackAlertByCode(index: number, item: any) {
    if(item != -1 && item != '-1') {
      if(item.label == 'Earthquake') {
        return item.code;
      }
      return item.volcano_code;
    }
    return item;
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
