<ng-container *ngIf="hasResponse; else waitingForResponse">
  <!-- Page header -->
  <section class="page-header bg-header-next">
    <div class="container">
      <div class="row">
        <div class="col-md-12 page-title text-center">
          <h2>About</h2>
          <p>{{ aboutUsDetails?.title }}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Page header -->
  <!-- Main contents -->
  <section class="content content-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="left-wrap">
            <img class="pic1" [imagepreloader]="assetBaseUrl + aboutUsDetails?.image" [targetSection]="'aboutus'">
            <!-- <img class="pic2" src="img/abt-img2.jpg"> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="title-header">
            <p>About</p>
            <h1>{{ aboutUsDetails?.title }}</h1>
          </div>
          <div>
            <p [innerHTML]="aboutUsDetails?.description"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Main contents -->
  <!-- <section class="cta-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <p>Labore et dolore magna aliqua <br>Tpsum dolor sit ame</p>
            </div>
            <div class="col-md-4 text-right">
                <button class="btn-white">View All</button>
            </div>
        </div>
    </div>
</section> -->
</ng-container>
<ng-template #waitingForResponse>
    <!-- <div class="loading">
		<i class="fas fa-spinner fa-spin"></i>
	</div>  -->
  <!-- Page header -->
	<section>
		<div class="container-fluid loading-skeleton">
			<div class="row">
				<div class="col-md-12 no-padding">
					<div class="s-banner inner">
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Page header -->

	<div class="container loading-skeleton">
		<div class="row">
			<div class="col-6 mt-5">
				<div class="s-banner inner rounded">
				</div>
			</div>
			<div class="col-6 mt-5">
				<div class="card-body">
					<p class="d-inline-block">About About About</p><br>
					<h1 class="d-inline-block">BNPJ-Buy Now Pay</h1>
				</div>
				<div class="card-body text-center">
					<p>BNPJ (Buy Now Pay in Japan) is an Integrated Wallet and Community Engaging Mobile App Managed and Operated by 3PLATZ株式会社 (サンプラッツ株式会社)</p>
					<p>Integrated Community Service Features are completely free for all the users who download this App.</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>