<ng-container *ngIf="hasResponse; else noResponse">
<!-- Page header -->
<section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>Load Money</h2>
            </div>
            <!-- <ul class="breadcrumb">
                <li><a [routerLink]="['/profile']">Profile</a></li>
                <li>Topup</li>
            </ul> -->
        </div>
    </div>
</section>
<!-- End of Page header -->

<!-- Main contents -->
<section class="content content-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="inner-lMoney inner-tabs">
                    <div class="head-tabs">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <ng-container *ngFor="let payment of paymentList; let i = index;">
                                <ng-container *ngIf="payment.is_active">
                                    <li class="nav-item">
                                        <img src="{{payment?.logo? payment.logo : 'assets/img/default_banners/default_payment.png'}}" onerror="this.src='assets/img/default_banners/default_payment.png'" style="height: 55px; width: 60px;object-fit: scale-down;">
                                        <a class="nav-link" [ngClass]="i== 0? 'active' : ''" id="tab-{{payment.type}}" data-toggle="tab" href="#tab{{payment.type}}" role="tab" attr.aria-controls="tab{{payment.type}}" attr.aria-selected="i == 0 > 'true': 'false'">{{payment.name}}</a>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="tab-content" id="myTabContent">
                        <ng-container *ngFor="let payment of paymentList; let i = index;">
                            <ng-container *ngIf="payment.is_active">
                                <div class="tab-pane fade" [ngClass]="i == 0 ? 'show active' : ''" id="tab{{payment.type}}" role="tabpanel">
                                    <div class="row">
                                        <div class="col-md-12">	
                                            <ng-container *ngIf="payment.type === 'stripe'">
                                                <form class="frm-content">
                                                    <div class="inner-section pt-4">
                                                        <div class="add-card text-center">
                                                                <h5 class="d-block mb-3">{{payment.name}} topup is only available in app.</h5>
                                                                <div class="row d-flex app-options">
                                                                    <a class="link" href="https://play.google.com/store/apps/details?id=nsp.platz.nitv.com" target="_blank">
                                                                        <img class="m-1" src="assets/img/android-sm.png">
                                                                    </a>
                                                                    <a class="link" href="https://apps.apple.com/jm/app/bnpj-buy-now-pay-japan/id1558970505" target="_blank">
                                                                        <img class="m-1" src="assets/img/ios-sm.png">
                                                                    </a>
                                                                </div>
                                                        </div>
                                                    </div>
                                                </form>
                                              
                                            </ng-container>
                                            <ng-container *ngIf="payment.type === 'ime_pay'">
                                                <form class="frm-content" [formGroup]="imePayForm" (ngSubmit)="imePayCheckout()">	
                                                    <div class="inner-section pt-4">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label>Amount(रु)</label>
                                                                <input class="form-control" type="text" formControlName="amount" placeholder="Amount(रु)">
                                                                <ng-container *ngIf="imePayAmount > 0 && imePayConvertedAmount > 0 && imePayForm.valid">
                                                                    <span class="conversion-rate">Rs.{{imePayAmount}} = JPY {{imePayConvertedAmount | number: '1.1-2'}}</span> 
                                                                </ng-container>
                                                                <ng-container *ngIf="imePayForm.controls.amount.errors && (imePayForm.controls.amount.dirty || imePayForm.controls.amount.touched || imePayFormSubmitted)">
                                                                    <ng-container *ngIf="imePayForm.controls.amount.errors?.required">
                                                                        <div class="help-block text-danger mt-1">Please enter your topup amount</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="imePayForm.controls.amount.errors?.min">
                                                                        <div class="help-block text-danger mt-1">The topup amount must be atleast रु{{imePayLowerBalanceLimit}} or more</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="imePayForm.controls.amount.errors?.pattern">
                                                                        <div class="help-block text-danger mt-1">Only numbers are allowed</div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <button type="submit" class="btn btn-orange mt-2"><i class='fa fa-spinner fa-spin' *ngIf="imePayLoader"></i> Proceed</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </ng-container>
                                            <ng-container *ngIf="payment.type === 'khalti'">
                                                <form class="frm-content" [formGroup]="khaltiForm" (ngSubmit)="khaltiCheckout()">	
                                                    <div class="inner-section pt-4">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label>Amount(रु)</label>
                                                                <input class="form-control" type="text" formControlName="amount" placeholder="Amount(रु)">
                                                                <ng-container *ngIf="khaltiAmount > 0 && khaltiConvertedAmount > 0 && khaltiForm.valid">
                                                                    <span class="conversion-rate">Rs.{{khaltiAmount}} = JPY {{khaltiConvertedAmount | number: '1.1-2'}}</span> 
                                                                </ng-container>
                                                                <ng-container *ngIf="khaltiForm.controls.amount.errors && (khaltiForm.controls.amount.dirty || khaltiForm.controls.amount.touched || khaltiFormSubmitted)">
                                                                    <ng-container *ngIf="khaltiForm.controls.amount.errors?.required">
                                                                        <div class="help-block text-danger mt-1">Please enter your topup amount</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="khaltiForm.controls.amount.errors?.min">
                                                                        <div class="help-block text-danger mt-1">The topup amount must be atleast रु{{khaltiLowerBalanceLimit}} or more</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="khaltiForm.controls.amount.errors?.pattern">
                                                                        <div class="help-block text-danger mt-1">Only numbers are allowed</div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <!-- <button type="submit" class="btn btn-orange mt-2">Proceed</button> -->
                                                                <input class="btn btn-orange mt-2" type="submit" Value="Proceed">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </ng-container>
                                            <ng-container *ngIf="payment.type === 'prabhupay'">
                                                <form class="frm-content" [formGroup]="prabhuPayForm" (ngSubmit)="initiatePrabhuPayCheckout()">	
                                                    <div class="inner-section pt-4">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label>Amount(रु)</label>
                                                                <input class="form-control" type="text" formControlName="amount" placeholder="Amount(रु)">
                                                                <ng-container *ngIf="prabhuPayAmount > 0 && prabhuPayConvertedAmount > 0 && prabhuPayForm.valid">
                                                                    <span class="conversion-rate">Rs.{{prabhuPayAmount}} = JPY {{prabhuPayConvertedAmount | number: '1.1-2'}}</span> 
                                                                </ng-container>
                                                                <ng-container *ngIf="prabhuPayForm.controls.amount.errors && (prabhuPayForm.controls.amount.dirty || prabhuPayForm.controls.amount.touched || prabhuPayFormSubmitted)">
                                                                    <ng-container *ngIf="prabhuPayForm.controls.amount.errors?.required">
                                                                        <div class="help-block text-danger mt-1">Please enter your topup amount</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="prabhuPayForm.controls.amount.errors?.min">
                                                                        <div class="help-block text-danger mt-1">The topup amount must be atleast रु{{prabhuPayLowerBalanceLimit}} or more</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="prabhuPayForm.controls.amount.errors?.pattern">
                                                                        <div class="help-block text-danger mt-1">Only numbers are allowed</div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <button type="submit" class="btn btn-orange mt-2"><i class='fa fa-spinner fa-spin' *ngIf="prabhuPayLoader"></i> Proceed</button>
                                                                <!-- <input class="btn btn-orange mt-2" type="submit" Value="Proceed"> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </ng-container>
                                            <ng-container *ngIf="payment.type === 'esewa'">
                                                <form class="frm-content" [formGroup]="esewaForm" (ngSubmit)="initiateEsewaPayment()">	
                                                    <div class="inner-section pt-4">
                                                        <div class="row">
                                                            <div class="form-group col-md-6">
                                                                <label>Amount(रु)</label>
                                                                <input class="form-control" type="text" formControlName="amount" placeholder="Amount(रु)">
                                                                <ng-container *ngIf="esewaAmount > 0 && esewaConvertedAmount > 0 && esewaForm.valid">
                                                                    <span class="conversion-rate">Rs.{{esewaAmount}} = JPY {{esewaConvertedAmount | number: '1.1-2'}}</span> 
                                                                </ng-container>
                                                                <ng-container *ngIf="esewaForm.controls.amount.errors && (esewaForm.controls.amount.dirty || esewaForm.controls.amount.touched || esewaFormSubmitted)">
                                                                    <ng-container *ngIf="esewaForm.controls.amount.errors?.required">
                                                                        <div class="help-block text-danger mt-1">Please enter your topup amount</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="esewaForm.controls.amount.errors?.min">
                                                                        <div class="help-block text-danger mt-1">The topup amount must be atleast रु{{esewaLowerBalanceLimit}} or more</div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="esewaForm.controls.amount.errors?.pattern">
                                                                        <div class="help-block text-danger mt-1">Only numbers are allowed</div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <input class="btn btn-orange mt-2" type="submit" value="Proceed">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form action="{{esewaBaseUrl}}epay/main" method="POST" #esewaPaymentInitiationForm>
                                                    <input value="{{esewaTopupAmount}}" name="tAmt" type="hidden">
                                                    <input value="{{esewaTopupAmount}}" name="amt" type="hidden">
                                                    <input value="0" name="txAmt" type="hidden">
                                                    <input value="0" name="psc" type="hidden">
                                                    <input value="0" name="pdc" type="hidden">
                                                    <input value="{{esewaMerchant}}" name="scd" type="hidden">
                                                    <input value="{{randomAlphaNumeric}}" name="pid" type="hidden">
                                                    <input value="{{esewaSuccessUrl}}" type="hidden" name="su">
                                                    <input value="{{esewaErrorUrl}}" type="hidden" name="fu">
                                                </form>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>				
                </div>						
            </div>
            <div class="col-md-4">
                <ng-container *ngIf="hasUserBalanceResponse; else fetchingUserBalance">
                    <div class="right-card-box mt-3 mt-md-0">
                        <h4 class="d-block mb-3">Status</h4>
                        <hr class="mt-0">
                        <div class="d-flex justify-content-between">
                            <div class="balance">
                                <span>Available Balance</span>
                                <h5>JPY {{userBalance}}</h5>
                                <!-- <h5>JPY {{userBalance | number: '1.0-0'}}</h5> -->
                            </div>
                            <div class="points">
                                <span class="d-block">Points</span>
                                <strong>{{userReedemPoints}}</strong>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #fetchingUserBalance>
                    <section class="content content-section loading-skeleton">
                        <div class="container">
                                    <div class="right-card-box mt-3 mt-md-0">
                                        <h4 class="d-block mb-3">Status</h4>
                                        <hr class="mt-0">
                                        <div class="d-flex justify-content-between">
                                            <div class="balance">
                                                <span>Available Balance</span>
                                                <h5>NPR 1200</h5>
                                            </div>
                                            <div class="points">
                                                <span class="d-block">Points</span>
                                                <strong>32</strong>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </section>
                </ng-template>
                <div class="recent right-card-box mt-2">
                    <h4 class="d-block mb-2">Latest Transactions</h4>
                        <ng-container *ngIf="hasTransactionResponse; else fetchingTransactions">
                            <ng-container *ngFor="let transaction of transactionList; let i = index">
                                <ng-container *ngIf="transaction == -1; else hasFilteredTransactionData">
                                    <h6>Sorry, transactions are not available.</h6>
                                </ng-container>
                                <ng-template #hasFilteredTransactionData>
                                    <div class="alerts-item d-flex justify-content-between">
                                        <div class="l-detail d-flex">
                                            <img class="img-fluid" src="{{transaction.icon}}">
                                            <div class="alert-wrap">
                                                <a class="link">{{transaction.transaction_name}}</a>
                                                <div class="mt-1">
                                                    <!-- <item> -->
                                                        <span>{{transaction.transaction_type}}</span><br/>
                                                        <span> {{ transaction.created_at | date:'MMM d y - h:mm'}}</span>
                                                    <!-- </item> -->
                                                </div>
                
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <strong class="d-block mb-2 transaction-amount">{{transaction.currency}} {{transaction.topup_amount}}</strong>
                                            <span>status: <span [ngClass]="transaction.status ? 'transaction-status' : 'transaction-failed'">{{transaction.transaction_status}}</span></span>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="i == (transactionList.length -1)">
                                        <div class="row">
                                            <div class="col-md-12 mt-4 text-center">
                                                <button class="btn-orange" [routerLink]="['/transactions']">View All Transactions</button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                        <ng-template #fetchingTransactions>
                            <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                        <div class="head-tabs">
                                          <!-- <div class="row"> -->
                                            <!-- <div class="col-md-12"> -->
                                              <!-- <div class="tab-content" id="myTabContent"> -->
                                                <!-- <div class="tab-pane fade show active" id="earthquake-info" role="tabpanel"> -->
                                                  <div class="row">	
                                                    <div class="col-md-12">
                                                      <div class="alerts-item d-flex justify-content-between">
                                                        <div class="l-detail d-flex">
                                                          <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                          <div class="alert-wrap">
                                                            <a>Northern Wakayama Pref</a>
                                                            <!-- <div class="mt-1">
                                                              <item>
                                                                <span>2021/03/24</span>
                                                                
                                                              </item>
                                                            </div> -->
                  
                                                          </div>
                                                        </div>
                                                        <div class="r-info text-center">
                                                          <strong class="d-block mb-2">4.2</strong>
                                                          <span>10h ago</span>
                                                        </div>
                                                      </div>
                                                      <div class="alerts-item d-flex justify-content-between">
                                                        <div class="l-detail d-flex">
                                                          <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                          <div class="alert-wrap">
                                                            <a>Northern Wakayama Pref</a>
                                                            <!-- <div class="mt-1">
                                                              <item>
                                                                <span>2021/03/24</span>
                                                                
                                                              </item>
                                                            </div> -->
                  
                                                          </div>
                                                        </div>
                                                        <div class="r-info text-center">
                                                          <strong class="d-block mb-2">4.2</strong>
                                                          <span>10h ago</span>
                                                        </div>
                                                      </div>
                                                      <div class="alerts-item d-flex justify-content-between">
                                                        <div class="l-detail d-flex">
                                                          <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                          <div class="alert-wrap">
                                                            <a>Northern Wakayama Pref</a>
                                                            <!-- <div class="mt-1">
                                                              <item>
                                                                <span>2021/03/24</span>
                                                                
                                                              </item>
                                                            </div> -->
                  
                                                          </div>
                                                        </div>
                                                        <div class="r-info text-center">
                                                          <strong class="d-block mb-2">4.2</strong>
                                                          <span>10h ago</span>
                                                        </div>
                                                      </div>
                                                      <div class="alerts-item d-flex justify-content-between">
                                                        <div class="l-detail d-flex">
                                                          <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                          <div class="alert-wrap">
                                                            <a>Northern Wakayama Pref</a>
                                                            <!-- <div class="mt-1">
                                                              <item>
                                                                <span>2021/03/24</span>
                                                                
                                                              </item>
                                                            </div> -->
                  
                                                          </div>
                                                        </div>
                                                        <div class="r-info text-center">
                                                          <strong class="d-block mb-2">4.2</strong>
                                                          <span>10h ago</span>
                                                        </div>
                                                      </div>
                                                      <div class="alerts-item d-flex justify-content-between">
                                                        <div class="l-detail d-flex">
                                                          <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                                          <div class="alert-wrap">
                                                            <a>Northern Wakayama Pref</a>
                                                            <!-- <div class="mt-1">
                                                              <item>
                                                                <span>2021/03/24</span>
                                                              </item>
                                                            </div> -->
                  
                                                          </div>
                                                        </div>
                                                        <div class="r-info text-center">
                                                          <strong class="d-block mb-2">4.2</strong>
                                                          <span>10h ago</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                <!-- </div> -->
                                              <!-- </div> -->
                                            <!-- </div> -->
                                          <!-- </div>							 -->
                                        </div>												
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                              </section>
                              <!-- End of Main contents -->
                        </ng-template>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Main contents -->
</ng-container>
<ng-template #noResponse>
    <!-- Page header -->
	<section>
		<div class="container-fluid loading-skeleton">
			<div class="row">
				<div class="col-md-12 no-padding">
					<div class="s-banner inner">
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Page header -->

	<!-- Main contents -->
	<section class="content content-section loading-skeleton">
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="inner-lMoney inner-tabs">
						<div class="head-tabs">
							<ul class="nav nav-tabs" id="myTab" role="tablist">
								<li class="nav-item">
									<p>Credit/Debit</p>
								</li>
								<li class="nav-item">
									<p>Stripe</p>
								</li>
								<li class="nav-item">
									<p>eSewa</p>
								</li>
								<li class="nav-item">
									<p>Khalti</p>
								</li>
								<li class="nav-item">
									<p>IME Pay</p>
								</li>
							</ul>
						</div>
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="tab1" role="tabpanel">
								<div class="row">
									<div class="col-md-12">	
										<form class="frm-content">	
											<div class="inner-section pt-4">
												<div class="add-card">
													<a href="#">
														<!-- <img src="img/add-card.svg"> -->
														<span class="d-block">Add Another Card</span>
													</a>
												</div>
											</div>
											<div class="inner-section pt-4">
												<div class="row">
													<div class="col-md-12 col-lg-6">
														<div class="card-item">
															<a href="#" class="d-flex">
																<!-- <img src="img/visa-card.png"> -->
																<div class="info ml-3">
																	<p class="mb-0">****-****-****-1234</p>
																	<strong>Yulia Olesich</strong>
																</div>
															</a>
														</div>
													</div>
													<div class="col-md-12 col-lg-6">
														<div class="card-item">
															<a href="#" class="d-flex">
																<!-- <img src="img/master-card.png"> -->
																<div class="info ml-3">
																	<p class="mb-0">****-****-****-1234</p>
																	<strong>Yulia Olesich</strong>
																</div>
															</a>
														</div>
													</div>
													<div class="col-md-12 mt-4">
														<hr>
													</div>
												</div>
											</div>
											<div class="inner-section pt-4">										
												<div class="row">
													<div class="form-group col-md-6">
														<label>Name</label>
														<input class="form-control" type="" placeholder="">
													</div>
													<div class="form-group col-md-6">
														<label>Credit Card Number</label>
														<input class="form-control" type="" placeholder="">
													</div>
													<div class="form-group col-md-6">
														<label>Expires</label>
														<input class="form-control" type="" placeholder="">
													</div>
													<div class="form-group col-md-6">
														<label>CVC</label>
														<input class="form-control" type="" placeholder="">
													</div>
													<div class="form-group col-md-6">
														<label>NP 1,000</label>
														<input class="form-control" type="" placeholder="">
													</div>
													<div class="form-group col-md-12">
														<i>Debit cards are accepted at some location and for some categories</i>
													</div>
													<div class="form-group col-md-12">
														<div class="form-check">
															<label class="form-check-label" for="#" style="margin-top:2px;">Save this card for your next payment</label>
														</div>
													</div>
													<div class="form-group col-md-12">
														<input class="btn btn-orange mt-2" type="Submit" Value="Proceed" name="">
													</div>

												</div>
											</div>											
										</form>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="tab2" role="tabpanel">
								<div class="row">
									<div class="col-md-12">	
										<form class="frm-content">	
											<div class="inner-section pt-4">
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>				
					</div>						
				</div>
				<div class="col-md-4">
					<div class="right-card-box mt-3 mt-md-0">
						<h4 class="d-block mb-3">Status</h4>
						<hr class="mt-0">
						<div class="d-flex justify-content-between">
							<div class="balance">
								<span>Available Balance</span>
								<h5>NPR 1200</h5>
							</div>
							<div class="points">
								<span class="d-block">Points</span>
								<strong>32</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Main contents -->
</ng-template>


<!-- Modal for displaying payment verification response-->
<div class="modal fade" id="paymentVerificationStatusModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" id="exampleModalLongTitle">{{paymentVerificationStatus}}</h5>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="paymentVerificationStatus == 'SUCCESS'; else pendingOrErrorPaymentVerificationStatus">
            <p class="success text-center">
                Topup Successful !!!
            </p>
          </ng-container>
          <ng-template #pendingOrErrorPaymentVerificationStatus>
            <ng-container *ngIf="paymentVerificationStatus == 'PENDING'; else errorPaymentVerificationStatus">
              <p class="success  text-center">
                Verifying your payment &nbsp;&nbsp;&nbsp;<i class="fa fa-spinner fa-spin"></i>
              </p>
            </ng-container>
            <ng-template #errorPaymentVerificationStatus>
              <p class="error  text-center">
                {{paymentErrorMessage}}
              </p>
            </ng-template>
          </ng-template>
          
        </div>
        <ng-container *ngIf="paymentVerificationStatus == 'SUCCESS' || paymentVerificationStatus == 'ERROR'">
          <div class="modal-footer align-content-center justify-content-center">
            <button type="button" class="btn btn-orange mt-2" data-dismiss="modal" [routerLink]="[ '/topup' ]">OK</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

<!-- Modal for card removal confirmation-->
<div class="modal fade" id="cardRemovalConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" id="exampleModalLongTitle">Are you sure you want to delete?</h5>
        </div>
        <!-- <div class="modal-body">
          
        </div> -->
          <div class="modal-footer align-content-center justify-content-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
            <button type="button" class="btn btn-danger" (click)="removeSaveCard()"> <i class='fa fa-spinner fa-spin' *ngIf="removeCardLoader"></i> Yes</button>
          </div>
      </div>
    </div>
  </div>