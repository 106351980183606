import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AboutUsService } from '../utils/services/aboutus.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  aboutUsDetails: any;
  assetBaseUrl = environment.assetBaseUrl;
  hasResponse: boolean = false;

  constructor(private service: AboutUsService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.fetchAboutUsDetails();
  }

  fetchAboutUsDetails() {
    this.service.fetchAboutUsDetails().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.aboutUsDetails = res['about_us'][0];
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Failed to fetch about details', 'ERROR');
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
