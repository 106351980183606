<!-- Main conatiner -->
<section class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-4 mx-auto">
                <div class="form-inner">
                    <h2 class="pb-3">Welcome</h2>
                    <!-- Signup form -->
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab"
                                aria-controls="login">Log In</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="signup-tab" data-toggle="tab" href="#signup" role="tab"
                                aria-controls="signup">Sign Up</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="login" role="tabpanel" aria-labelledby="login-tab">
                            <form class="login-frm form-pannel" [formGroup]="loginForm" (ngSubmit)="login()">
                                <div class="form-row">
                                    <div class="form-group col-md-12"
                                        [ngClass]="loginForm.controls.email.errors && (loginForm.controls.email.dirty || loginForm.controls.email.touched || loginRequested) ? 'has-error':''">
                                        <span class="material-icons">email</span>
                                        <input type="email" class="form-control sinput" placeholder="Email Address"
                                            formControlName="email">
                                        <ng-container
                                            *ngIf="loginForm.controls.email.errors && (loginForm.controls.email.dirty || loginForm.controls.email.touched || loginRequested)">
                                            <ng-container *ngIf="loginForm.controls.email.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your email address
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="loginForm.controls.email.errors?.email">
                                                <div class="help-block text-danger mt-1">Please enter a valid email
                                                    address</div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="form-group col-md-12"
                                        [ngClass]="loginForm.controls.password.errors && (loginForm.controls.password.dirty || loginForm.controls.password.touched || loginRequested) ? 'has-error':''">
                                        <span class="material-icons">https</span>
                                        <ng-container *ngIf="passwordInputType == 'text'; else hiddenPassword">
                                            <span class="material-icons password-view link"
                                                (click)="hidePassword()">visibility</span>
                                        </ng-container>
                                        <ng-template #hiddenPassword>
                                            <span class="material-icons password-view link"
                                                (click)="showPassword()">visibility_off</span>
                                        </ng-template>
                                        <input type="{{passwordInputType}}" placeholder="Password"
                                            class="form-control sinput" formControlName="password">
                                        <ng-container
                                            *ngIf="loginForm.controls.password.errors && (loginForm.controls.password.dirty || loginForm.controls.password.touched || loginRequested)">
                                            <ng-container *ngIf="loginForm.controls.password.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your password
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox"
                                                            formControlName="rememberMe"> Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-6 text-right">
                                                <span class="forgotPassword-label"> <a
                                                        [routerLink]="['/forget-password']">Forgot Password?</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 my-3">
                                        <button class="btn btn-orange s-btn">
                                            <i class="fa fa-spinner fa-spin mr-2" *ngIf="loginLoader"></i>Log In
                                        </button>
                                        <!-- <input type="submit" class="btn s-btn" value="Log In" id="login-btn"> -->
                                    </div>
                                </div>
                            </form>
                            <hr class="mt-5">
                            <ng-template #socialLoginIcons>
                                <div class="text-center">
                                    <span class="line-tag">Or Sign Up with</span>
                                </div>
                                <div class="social-logins text-center">
                                    <a (click)="facebookLoginInProgress ? '' : signInWithFB()"
                                        class="link social-login-icon"><i
                                            class="fa fa-spinner fa-spin ml-2 social-login-spinner"
                                            *ngIf="facebookLoginInProgress"></i><img src="assets/img/icon-fb.svg"
                                            [ngClass]="facebookLoginInProgress ? 'social-login-in-progress':''"></a>
                                    <a (click)="googleLoginInProgress ? '' : signInWithGoogle()"
                                        class="link social-login-icon"><i
                                            class="fa fa-spinner fa-spin ml-2 social-login-spinner"
                                            *ngIf="googleLoginInProgress"></i><img src="assets/img/icon-gmail.svg"
                                            [ngClass]="googleLoginInProgress ? 'social-login-in-progress':''"></a>
                                    <!-- <a href="#"><img src="assets/img/icon-apple.svg"></a> -->
                                    <!-- <a href="#"><img src="assets/img/icon-viber.svg"></a> -->
                                </div>
                            </ng-template>
                            <ng-container [ngTemplateOutlet]="socialLoginIcons"></ng-container>
                        </div>
                        <div class="tab-pane fade" id="signup" role="tabpanel" aria-labelledby="signup-tab">
                            <form class="login-frm form-pannel" [formGroup]="registerForm" (ngSubmit)="register()">
                                <div class="form-row">
                                    <div class="form-group col-md-12"
                                        [ngClass]="registerForm.controls.first_name.errors && (registerForm.controls.first_name.dirty || registerForm.controls.first_name.touched || registerRequested) ? 'has-error':''">
                                        <span class="material-icons">person</span>
                                        <input type="text" class="form-control sinput" placeholder="First Name"
                                            formControlName="first_name">
                                        <ng-container
                                            *ngIf="registerForm.controls.first_name.errors && (registerForm.controls.first_name.dirty || registerForm.controls.first_name.touched || registerRequested)">
                                            <ng-container *ngIf="registerForm.controls.first_name.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your first name
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="registerForm.controls.first_name.errors?.minlength">
                                                <div class="help-block text-danger mt-1">At least 3 characters required.
                                                </div>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="registerForm.controls.first_name.errors?.pattern">
                                                <div class="help-block text-danger mt-1">Special Characters are not allowed.</div>
                                            </ng-container> -->
                                        </ng-container>
                                    </div>
                                    <div class="form-group col-md-12"
                                        [ngClass]="registerForm.controls.last_name.errors && (registerForm.controls.last_name.dirty || registerForm.controls.last_name.touched ||registerRequested) ? 'has-error':''">
                                        <span class="material-icons">person</span>
                                        <input type="text" class="form-control sinput" placeholder="Last Name"
                                            formControlName="last_name">
                                        <ng-container
                                            *ngIf="registerForm.controls.last_name.errors && (registerForm.controls.last_name.dirty || registerForm.controls.last_name.touched || registerRequested)">
                                            <ng-container *ngIf="registerForm.controls.last_name.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your last name
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="registerForm.controls.last_name.errors?.minlength">
                                                <div class="help-block text-danger mt-1">At least 2 characters required.
                                                </div>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="registerForm.controls.last_name.errors?.pattern">
                                                <div class="help-block text-danger mt-1">Special Characters are not allowed.</div>
                                            </ng-container> -->
                                        </ng-container>
                                    </div>
                                    <div class="form-group col-md-12"
                                        [ngClass]="registerForm.controls.email.errors && (registerForm.controls.email.dirty || registerForm.controls.email.touched || registerRequested) ? 'has-error':''">
                                        <span class="material-icons">email</span>
                                        <input type="email" class="form-control sinput" placeholder="Email Address"
                                            formControlName="email">
                                        <ng-container
                                            *ngIf="registerForm.controls.email.errors && (registerForm.controls.email.dirty || registerForm.controls.email.touched || registerRequested)">
                                            <ng-container *ngIf="registerForm.controls.email.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your email address
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="registerForm.controls.email.errors?.email">
                                                <div class="help-block text-danger mt-1">Please enter a valid email
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <!-- <div class="form-group col-md-12"
                                        [ngClass]="registerForm.controls.mobile.errors && (registerForm.controls.mobile.dirty || registerForm.controls.mobile.touched || registerRequested) ? 'has-error':''">
                                        <span class="material-icons">phone</span>
                                        <input type="text" class="form-control sinput" placeholder="Mobile Number"
                                            formControlName="mobile">
                                        <ng-container
                                            *ngIf="registerForm.controls.mobile.errors && (registerForm.controls.mobile.dirty || registerForm.controls.mobile.touched || registerRequested)">
                                            <ng-container *ngIf="registerForm.controls.mobile.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your mobile number
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="registerForm.controls.mobile.errors?.minlength">
                                                <div class="help-block text-danger mt-1">At least 10 characters
                                                    required.</div>
                                            </ng-container>
                                            <ng-container *ngIf="registerForm.controls.mobile.errors?.maxlength">
                                                <div class="help-block text-danger mt-1">Only 10 characters allowed.
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div> -->
                                    <div class="form-group col-md-12"
                                        [ngClass]="registerForm.controls.password.errors && (registerForm.controls.password.dirty || registerForm.controls.password.touched || registerRequested) ? 'has-error':''">
                                        <span class="material-icons">https</span>
                                        <ng-container
                                            *ngIf="signUpPasswordInputType == 'text'; else hiddenSignupPassword">
                                            <span class="material-icons password-view link"
                                                (click)="hideSignupPassword()">visibility</span>
                                        </ng-container>
                                        <ng-template #hiddenSignupPassword>
                                            <span class="material-icons password-view link"
                                                (click)="showSignupPassword()">visibility_off</span>
                                        </ng-template>
                                        <input type="{{signUpPasswordInputType}}" placeholder="Password"
                                            class="form-control sinput" formControlName="password">
                                        <ng-container
                                            *ngIf="registerForm.controls.password.errors && (registerForm.controls.password.dirty || registerForm.controls.password.touched || registerRequested)">
                                            <ng-container *ngIf="registerForm.controls.password.errors?.required">
                                                <div class="help-block text-danger mt-1">Please enter your password
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="registerForm.controls.password.errors?.minlength">
                                                <div class="help-block text-danger mt-1">At least 8 characters required.
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="registerForm.controls.password.errors?.passwordPatternValid">
                                                <div class="help-block text-danger mt-1">Password must have atleast 1
                                                    character and 1 digit.</div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="form-group col-md-12"
                                        [ngClass]="registerForm.controls.password_confirmation.errors && (registerForm.controls.password_confirmation.dirty || registerForm.controls.password_confirmation.touched || registerRequested) ? 'has-error':''">
                                        <span class="material-icons">https</span>
                                        <ng-container
                                            *ngIf="signUpConfirmPasswordInputType == 'text'; else hiddenSignupConfirmPassword">
                                            <span class="material-icons password-view link"
                                                (click)="hideSignupConfirmPassword()">visibility</span>
                                        </ng-container>
                                        <ng-template #hiddenSignupConfirmPassword>
                                            <span class="material-icons password-view link"
                                                (click)="showSignupConfirmPassword()">visibility_off</span>
                                        </ng-template>
                                        <input type="{{signUpConfirmPasswordInputType}}" placeholder="Conform Password"
                                            class="form-control sinput" formControlName="password_confirmation">
                                        <ng-container
                                            *ngIf="registerForm.controls.password_confirmation.errors && (registerForm.controls.password_confirmation.dirty || registerForm.controls.password_confirmation.touched || registerRequested)">
                                            <ng-container
                                                *ngIf="registerForm.controls.password_confirmation.errors?.required">
                                                <div class="help-block text-danger mt-1">Please confirm your password
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="registerForm.controls.password_confirmation.errors?.passwordMatch">
                                                <div class="help-block text-danger mt-1">Password do not match</div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-12"
                                        [ngClass]="registerForm.controls.tc.errors && (registerForm.controls.tc.dirty || registerForm.controls.tc.touched || registerRequested) ? 'has-error':''">
                                        <div class="form-group form-check">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="checkbox" formControlName="tc">
                                                By signing up for Buy Now Pay in Japan (BNPJ), you agree to our <a
                                                    [routerLink]="['/terms-conditions']" target="_blank">Terms of
                                                    Service</a> and <a [routerLink]="['/privacy-policy']"
                                                    target="_blank">Privacy Policy</a>.
                                            </label>
                                            <ng-container
                                                *ngIf="registerForm.controls.tc.errors && (registerForm.controls.tc.dirty || registerForm.controls.tc.touched || registerRequested)">
                                                <div class="help-block text-danger mt-1">You must accept Terms of
                                                    Service and Privacy Policy</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-12 my-3">
                                        <button type="submit" class="btn btn-orange s-btn">
                                            <i class="fa fa-spinner fa-spin mr-2" *ngIf="registerLoader"></i>Sign Up
                                        </button>
                                        <!-- <input type="submit" class="btn s-btn" value="Sign Up" id="register-btn"> -->
                                    </div>
                                </div>
                            </form>
                            <hr class="mt-5">
                            <ng-container [ngTemplateOutlet]="socialLoginIcons"></ng-container>
                        </div>
                    </div>
                    <!-- End of Signup form -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Main conatiner -->