import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/utils/data-share.service';
import { AlertService } from '../service/alert.service';

@Component({
  selector: 'app-earthquake-detail',
  templateUrl: './earthquake-detail.component.html',
  styleUrls: ['./earthquake-detail.component.css']
})
export class EarthquakeDetailComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  /**
   * variables declared to hold path param values
   */
  hasEarthquakeResponse: boolean = false;
  earthquakeCode: string = '';
  language: string = '';
  /**
   * variables declared to hold data for earthquakes
   */
  earthquakeDetail: any;
  earthquakeList: any[] = [];

  constructor(private alertService: AlertService, private toastr: ToastrService, 
    private activatedRoute: ActivatedRoute, private router: Router, private dataShareService: DataShareService) {
    this.language = this.activatedRoute.snapshot.paramMap.get('language') || '';
    this.earthquakeCode = this.activatedRoute.snapshot.paramMap.get('code') || '';
  }

  ngOnInit(): void {
    this.getEarthquakeDetailsByCode();
    this.fetchLatestEarthquakes();
  }

  /**
   * function to fetch earthquake details
   */
  getEarthquakeDetailsByCode() {
    this.alertService.getEarthquakeDetailByCode(this.language, this.earthquakeCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.earthquakeDetail = res[0];
        if(this.earthquakeDetail.lat == null || this.earthquakeDetail.lon == null) {
          this.getEarthquakeMap();
        }
        this.hasEarthquakeResponse = true;
      }, (err: any) => {
        this.hasEarthquakeResponse = false;
        // this.toastr.error('Failed to fetch earthquake details', 'Error');
      });
  }

  /**
   * function to get earthquake lat and lon to plot on map
   */
  getEarthquakeMap() {
    this.alertService.getEarthquakeMap(this.earthquakeDetail.code).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.earthquakeDetail.lat = res.lat;
              this.earthquakeDetail.lon = res.lon;
            });
  }

  /**
   * function to fetch latest earthquake list
   */
   fetchLatestEarthquakes() {
    this.alertService.getLatestEarthquakeList(3)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.earthquakeList = res;
        if(this.earthquakeList.length === 0) {
          this.earthquakeList = [-1];
        }
      }, (err: any) => {
        // this.toastr.error('Failed to fetch latest Earthquakes', 'Error');
      });
  }

  /**
   * function to change the earthquake details with the clicked earthquake item details
   * @param earthquakeDetail clicked earthquake details
   */
  changeEarthquakeDetails(earthquakeDetail: any) {
    this.earthquakeDetail = earthquakeDetail;
    if(this.earthquakeDetail.lat == null || this.earthquakeDetail.lon == null) {
      this.getEarthquakeMap();
    }
  }

  /**
   * function to redirect user to alert page with the clicked tab as active tab
   */
   redirectUsersToListingPage(tab: any) {
    this.router.navigate(['/alert'])
    .then(() => {
      this.dataShareService.changeAlertActiveTab(tab);
    });
  }

  trackAlertByCode(index: number, item: any) {
    if(item != -1 && item != '-1') {
      if(item.label == 'Earthquake') {
        return item.code;
      }
      return item.volcano_code;
    }
    return item;
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
