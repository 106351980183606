<!-- Start header -->
<header>		
    <div class="row">
        <div class="col-md-12">
            <nav id="nav" class="navbar navbar-expand-lg fixed-top">
                <div class="container">
                    <a class="logo" [routerLink]="['/']"><img class="img-fluid" src="assets/img/logo-nsp.svg"></a>
                    <a id="showRight" class="btn d-md-block d-lg-none" (click)="toggleHamMenu()"><i class="fas fa-bars"></i></a>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/about']" [routerLinkActive]="['active']">About Us</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/partner-services']" [routerLinkActive]="['active']">Partner Services</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/news']" [routerLinkActive]="['active']" >News</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/alert']" [routerLinkActive]="['active']" >Alerts</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/japanese-manners']" [routerLinkActive]="['active']">Japanese Manner</a>
                            </li>
                            <ng-container *ngIf="isLoggedIn">
                                <li class="nav-item">
                                    <a class="nav-link" [routerLink]="['/resume']" [routerLinkActive]="['active']">My Resume</a>
                                </li>
                            </ng-container>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/contact']" [routerLinkActive]="['active']">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <ng-container *ngIf="isLoggedIn; else unAunthenticatedUSer">
                        <div class="btn-group checkout">
                            <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img [src]="userAvatar || 'assets/img/avatar-img.jpg'" onerror="this.src='assets/img/avatar-img.jpg'">
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" [routerLink]="['/profile']"><i class="fas fa-user"></i> My Profile</a>
                                <a class="dropdown-item" [routerLink]="['/transactions']"><i class="fas fa-receipt"></i> Transactions</a>
                                <a class="dropdown-item" [routerLink]="['/change-password']"><i class="fas fa-unlock-alt"></i> Change Password</a>
                                <a class="dropdown-item link" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #unAunthenticatedUSer>
                        <a [routerLink]="['/login']" class="login-btn"><img src="assets/img/icon-login.svg" class="mr-1"> Log In/Sign Up</a>
                    </ng-template>
                </div>
            </nav>
            
        </div>
    </div>
</header>
<!-- Ends header -->
<!-- Menu for mobile -->
<div class="menu menu-right menu-mobile d-md-block d-lg-none" [ngClass]="showHideHamMenu ? 'right-open' : ''">
    <div class="backBtn"><i class="fas fa-times-circle" (click)="toggleHamMenu()"></i></div>
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">Home</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/about']" [routerLinkActive]="['active']">About Us</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/partner-services']" [routerLinkActive]="['active']">Partner Services</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/news']" [routerLinkActive]="['active']">News</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/alert']" [routerLinkActive]="['active']">Alerts</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/japanese-manners']" [routerLinkActive]="['active']">Japanese Manner</a>
        </li>
        <ng-container *ngIf="isLoggedIn">
            <li class="nav-item">
                <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/resume']" [routerLinkActive]="['active']">My Resume</a>
            </li>
        </ng-container>
        <li class="nav-item">
            <a class="nav-link" (click)="toggleHamMenu()" [routerLink]="['/contact']" [routerLinkActive]="['active']">Contact</a>
        </li>
    </ul>
</div>
<!-- End of Menu for mobile -->