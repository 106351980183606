<!-- Footer -->
<footer>
	<div class="container">
		<div class="row">
			<div class="col-lg-5 col-md-4">
				<div class="abt">
					<a class="logo" href="#"><img class="img-fluid" src="assets/img/logo-white.svg"></a>
					<h4 class="mb-4">About US</h4>
					<p [innerHTML]="aboutUsDescription"></p>					
				</div>
			</div>
			<div class="col-lg-4 col-md-4">
				<div class="row">
					<div class="col-md-12">
						<h4 class="mb-4">Quick Links</h4>
						<ul class="quick-links">					
							<div>
								<li><a [routerLink]="['/about']">About Us</a></li>							
								<li><a [routerLink]="['/partner-services']">Partner Services</a></li>
								<li><a [routerLink]="['/news']">News</a></li>
								<li><a [routerLink]="['/alert']">Alerts</a></li>
								<li><a [routerLink]="['/japanese-manners']">Japanese Manner</a></li>
								<li><a [routerLink]="['/contact']">Contact Us</a></li>						
							</div>
							<div>
								<li><a [routerLink]="['/terms-conditions']" target="_blank">Terms & Conditions</a></li>							
								<li><a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a></li>							
								<li><a [routerLink]="['/refund-policy']" target="_blank">Refund Policy</a></li>
								<li><a [routerLink]="['/transactions-law']" target="_blank">Specified Commercial Transactions Law</a></li>								
							</div>	
						</ul>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-4">
				<div class="contact-info">
					<h4 class="mb-4">Contact Us</h4>
					<div>
						<i class="fas fa-phone-alt"></i>
						<span><a class="tel" href="tel:03-6222-8365">
							03-6222-8365<br>
						</a></span>
					</div>
					<div>
						<i class="far fa-clock"></i>
						<span>Reception time：<br/> 9:30am～5:30pm</span>
					</div>
					<div>
						<i>※</i> 
						<span>closed on <br/>Saturdays, Sundays, and holiday</span>
					</div>
					<div>
						<i class="fas fa-envelope"></i>
						<span><a class="mailto" href="mailto:info@bnpj.jp">info@bnpj.jp</a></span>
					</div>
					<div>
						<i class="fas fa-map-marker-alt"></i>
						<span>NAX Building 9F, Shinkawa 1-3-2, Chuo-ku , Tokyo 104-0033 </span>
					</div>	
					<a [routerLink]="['/contact']">Find us on Map</a>					
				</div>
				<div class="social-icons mt-4">
					<h4 class="mb-2">Social Connections</h4>
					<a href="https://3platz.jp/" target="_blank"><i class="fab fa-facebook-f"></i></a>
					<a href="https://3Platz.jp" target="_blank"><i class="fab fa-youtube"></i></a>
					<!-- <a href="https://3Platz.jp" target="_blank"><i class="fab fa-linkedin-in"></i></a> -->
				</div>
			</div>
		</div>
	</div>
</footer>
<!-- End of footer -->
<section class="copy-right">
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<p>©{{currentYear}} Buy Now Pay in Japan (BNPJ). All Rights Reserved</p>
			</div>
		</div>
	</div>
</section>