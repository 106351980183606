import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { ForgetPasswordRoutingModule } from './forget-password-routing.module';
import { ForgetPasswordComponent } from './forget-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ForgetPasswordRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ForgetPasswordComponent
    ],
    providers: [
        
    ]
})

export class ForgetPasswordModule {
}
