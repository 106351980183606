import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JapaneseMannerService {

  private baseUrl = environment.apiUrl;

  private httpHeaders =  new HttpHeaders({
    'cache': 'true',
    'cache_group': 'japanese_mannner'
  });

  constructor(private http: HttpClient) { }

  getAllJapaneseManners(page: number) {
    return this.http.get(this.baseUrl + '/jp_manners?page=' + page, {headers: this.httpHeaders});
  }

  getJapaneseMannersCategory() {
    return this.http.get(this.baseUrl + '/jp_manners/categories', {headers: this.httpHeaders});
  }

  getJapaneseMannersByCategory(category_id: number ,page: number) {
    if(category_id < 1) {
        return this.http.get(this.baseUrl + '/jp_manners?page=' + page, {headers: this.httpHeaders});
    }
    return this.http.get(this.baseUrl + '/jp_manners/get_manners/'+ category_id +'?page=' + page, {headers: this.httpHeaders});
  }

  getJapaneseMannerDetails(jp_manner_id: number) {
    return this.http.get(this.baseUrl + '/jp_manners/get_detail/' + jp_manner_id, {headers: this.httpHeaders});
  }
}
