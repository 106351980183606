import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { CanDeactivateGuard } from '../utils/guards/can-deactivate-guard.service';
import { ResumeComponent } from './resume.component';

const RESUME_ROUTES: Routes = [
    { path: '', component : ResumeComponent, canDeactivate: [CanDeactivateGuard]}
];
@NgModule({
    imports: [RouterModule.forChild(RESUME_ROUTES)],
    exports: [RouterModule]
})
export class ResumeRoutingModule {
}
