<!-- Page header -->
<!-- <section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>{{volcanoDetail?.volcano_name_value}}</h2>
            </div>
            <ul class="breadcrumb">
                <li><a [routerLink]="['/alert']">Alerts</a></li>
                <li>Volcano info</li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End of Page header -->

<ng-container *ngIf="hasResponse; else fetchingVolcanoDetails">
    <!-- Main contents -->
    <section class="bg-breadcrumb">
		<div class="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a [routerLink]="['/alert']">Alerts</a></li>
                    <li class="breadcrumb-item breadcrumb-link" (click)="redirectUsersToListingPage('volcano-tab')"><a class="link">Volcanos</a></li>
					<li class="breadcrumb-item active" aria-current="page">{{volcanoDetail?.volcano_name_value}}</li>
				</ol>
			</nav>
		</div>
	</section>
    <section class="content content-with-breadcrumb-section">
    <!-- <section class="content content-section no-breadcrump-header"> -->
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="alert-details">
                        <agm-map [latitude]="volcanoDetail?.volcano_latitude" [longitude]="volcanoDetail?.volcano_longtitude" [zoom]="8">
                            <agm-marker [latitude]="volcanoDetail?.volcano_latitude" [longitude]="volcanoDetail?.volcano_longtitude"></agm-marker>
                        </agm-map>
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d831438.4633291857!2d139.2093851493426!3d35.50906267231795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x605d1b87f02e57e7%3A0x2e01618b22571b89!2sTokyo%2C%20Japan!5e0!3m2!1sen!2snp!4v1618467650824!5m2!1sen!2snp" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                        <div class="row mt-4">
                            <div class="col-md-6 d-flex item-alerts align-items-start mb-4">
                                <img src="assets/img/icon-clock.svg">
                                <div class="ml-2">
                                    <strong class="d-block">Date & Time</strong>
                                    <span>{{ volcanoDetail?.occurred_value | dateAgo }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex item-alerts align-items-start mb-4">
                                <img src="assets/img/icon-intensity.svg">
                                <div class="ml-2">
                                    <strong class="d-block">warning</strong>
                                    <span class="text-danger">{{volcanoDetail?.type_value}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex item-alerts align-items-start">
                                <img src="assets/img/icon-level.svg">
                                <div class="ml-2">
                                    <strong class="d-block">Maginitude</strong>
                                    <span class="mb-3 d-block">{{volcanoDetail?.level_title}} {{volcanoDetail?.level_value}}</span>
                                    <span>{{volcanoDetail?.level_text ? (volcanoDetail?.level_text) : ''}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 right-card">
                    <div class="recent">
                        <h4 class="d-block mb-2">Recent Volcano</h4>
                        <ng-container *ngIf="volcanoList != undefined && volcanoList.length > 0; else loader">
                            <ng-container *ngFor="let volcano of volcanoList;  trackBy:trackAlertByCode;">
                                <ng-container *ngIf="volcano == -1; else hasVolcanoData">
                                    <h6 class="col-lg-12">
                                      Data Unavailable.
                                    </h6>
                                </ng-container>
                                <ng-template #hasVolcanoData>
                                    <div class="alerts-item d-flex justify-content-between">
                                        <div class="l-detail d-flex">
                                            <img src="assets/img/icon-volcano.svg">
                                            <div class="alert-wrap">
                                                <a (click)="changeVolcanoDetails(volcano)" [routerLink]="['/volcano/en/' + volcano.volcano_code]">{{volcano.volcano_name_value}}</a>
                                                <div class="mt-1">
                                                    <item>
                                                        <span>{{ volcano.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                                        <span>{{ volcano.occurred_value | date : 'HH:mm' }}</span>
                                                    </item>
                                                    <span>{{volcano.level_title}} {{volcano.level_value}} {{volcano.level_text ? (volcano.level_text) : ''}}</span>
                                                </div>
                
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                        <ng-template #loader>
                            <section class="content content-section detail-section loading-skeleton avoid-skeleton-padding">
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
                                        <div class="alert-wrap">
                                            <a href="#">Asamayama</a>
                                            <div class="mt-1">
                                                <item>
                                                    <span>2021/03/24</span>
                                                    <span> 06:46</span>
                                                </item>
                                                <span>Level 2 (Do not approach the volcano)</span>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
                                        <div class="alert-wrap">
                                            <a href="#">Asamayama</a>
                                            <div class="mt-1">
                                                <item>
                                                    <span>2021/03/24</span>
                                                    <span> 06:46</span>
                                                </item>
                                                <span>Level 2 (Do not approach the volcano)</span>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
                                        <div class="alert-wrap">
                                            <a href="#">Asamayama</a>
                                            <div class="mt-1">
                                                <item>
                                                    <span>2021/03/24</span>
                                                    <span> 06:46</span>
                                                </item>
                                                <span>Level 2 (Do not approach the volcano)</span>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->
</ng-container>
<ng-template #fetchingVolcanoDetails>
    <!-- Main contents -->
	<section class="content content-section detail-section loading-skeleton no-breadcrump-header">
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="inner s-content" style="height: 400px;"></div>
					<div class="alert-details">
						<div class="row mt-4">
							<div class="col-md-6 d-flex item-alerts align-items-start mb-4">
								<img src="assets/img/icon-clock.svg">
								<div class="ml-2">
									<strong class="d-block">Date & Time</strong>
									<span>8 months ago</span>
								</div>
							</div>
							<div class="col-md-6 d-flex item-alerts align-items-start mb-4">
								<img src="assets/img/icon-intensity.svg">
								<div class="ml-2">
									<strong class="d-block">warning</strong>
									<span class="text-danger">Near Crater Warning</span>
								</div>
							</div>
							<div class="col-md-6 d-flex item-alerts align-items-start">
								<img src="assets/img/icon-level.svg">
								<div class="ml-2">
									<strong class="d-block">Maginitude</strong>
									<span class="mb-3 d-block">Level 2 (Do not approach the volcano)</span>

									<span>Eruption or possibility of eruption that may affect areas near the crater (threat to life is possible in these areas).</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 right-card">
					<div class="recent">
						<h4 class="d-block mb-2">Recent Earthquake</h4>
						<div class="alerts-item d-flex justify-content-between">
							<div class="l-detail d-flex">
								<div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
								<div class="alert-wrap">
									<a href="#">Asamayama</a>
									<div class="mt-1">
										<item>
											<span>2021/03/24</span>
											<span> 06:46</span>
										</item>
										<span>Level 2 (Do not approach the volcano)</span>
									</div>

								</div>
							</div>
						</div>
						<div class="alerts-item d-flex justify-content-between">
							<div class="l-detail d-flex">
								<div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
								<div class="alert-wrap">
									<a href="#">Asamayama</a>
									<div class="mt-1">
										<item>
											<span>2021/03/24</span>
											<span> 06:46</span>
										</item>
										<span>Level 2 (Do not approach the volcano)</span>
									</div>

								</div>
							</div>
						</div>
						<div class="alerts-item d-flex justify-content-between">
							<div class="l-detail d-flex">
								<div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
								<div class="alert-wrap">
									<a href="#">Asamayama</a>
									<div class="mt-1">
										<item>
											<span>2021/03/24</span>
											<span> 06:46</span>
										</item>
										<span>Level 2 (Do not approach the volcano)</span>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Main contents -->
</ng-template>