import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { ResetPasswordComponent } from './reset-password.component';

const RESET_PASSWORD_ROUTES: Routes = [
    { path: '', component : ResetPasswordComponent}
];
@NgModule({
    imports: [RouterModule.forChild(RESET_PASSWORD_ROUTES)],
    exports: [RouterModule]
})
export class ResetPasswordRoutingModule {
}
