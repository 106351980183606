<!-- Main conatiner -->
<section class="login-section">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-4 mx-auto">			
                <div class="form-inner">
                    <h2>Reset Password</h2>
                    <!-- form -->
                    <form class="login-frm form-pannel" [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
                        <div class="form-row">
                            <ng-container *ngIf="!hideCodeInputField">
                                <div class="form-group col-md-12" [ngClass]="passwordResetForm.controls.code.errors && (passwordResetForm.controls.code.dirty || passwordResetForm.controls.code.touched || passwordResetRequested) ? 'has-error':''">
                                    <span class="fas fa-asterisk"></span>
                                    <input type="text" placeholder="Code" class="form-control sinput" formControlName="code" aria-autocomplete="list">
                                    <ng-container *ngIf="passwordResetForm.controls.code.errors && (passwordResetForm.controls.code.dirty || passwordResetForm.controls.code.touched || passwordResetRequested)">
                                        <ng-container *ngIf="passwordResetForm.controls.code.errors?.required">
                                            <div class="help-block text-danger mt-1">Code is required</div>
                                        </ng-container>
                                        <ng-container *ngIf="passwordResetForm.controls.code.errors?.minlength">
                                            <div class="help-block text-danger mt-1">Only 4 characters are allowed.</div>
                                        </ng-container>
                                        <ng-container *ngIf="passwordResetForm.controls.code.errors?.maxlength">
                                            <div class="help-block text-danger mt-1">Only 4 characters are allowed.</div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="form-group col-md-12" [ngClass]="passwordResetForm.controls.password.errors && (passwordResetForm.controls.password.dirty || passwordResetForm.controls.password.touched || passwordResetRequested) ? 'has-error':''">
                                <span class="material-icons">https</span>
                                <ng-container *ngIf="newPasswordInputType == 'text'; else hiddenNewPassword">
                                    <span class="material-icons password-view link" (click)="hideNewPassword()">visibility</span>
                                </ng-container>
                                <ng-template #hiddenNewPassword>
                                    <span class="material-icons password-view link" (click)="showNewPassword()">visibility_off</span>
                                </ng-template>
                                <input type="{{newPasswordInputType}}" placeholder="New Password" class="form-control sinput" formControlName="password" aria-autocomplete="list">
                                <ng-container *ngIf="passwordResetForm.controls.password.errors && (passwordResetForm.controls.password.dirty || passwordResetForm.controls.password.touched || passwordResetRequested)">
                                    <ng-container *ngIf="passwordResetForm.controls.password.errors.required">
                                        <div class="help-block text-danger mt-1">Please enter your password</div>
                                    </ng-container>
                                    <ng-container *ngIf="passwordResetForm.controls.password.errors?.minlength">
                                        <div class="help-block text-danger mt-1">At least 8 characters required.</div>
                                    </ng-container>
                                    <ng-container *ngIf="passwordResetForm.controls.password.errors?.passwordPatternValid">
                                        <div class="help-block text-danger mt-1">Password must have atleast 1 character and 1 digit.</div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="form-group col-md-12" [ngClass]="passwordResetForm.controls.password_confirmation.errors && (passwordResetForm.controls.password_confirmation.dirty || passwordResetForm.controls.password_confirmation.touched || passwordResetRequested) ? 'has-error':''">
                                <span class="material-icons">https</span>
                                <ng-container *ngIf="confirmPasswordInputType == 'text'; else hiddenConfirmPassword">
                                    <span class="material-icons password-view link" (click)="hideConfirmPassword()">visibility</span>
                                </ng-container>
                                <ng-template #hiddenConfirmPassword>
                                    <span class="material-icons password-view link" (click)="showConfirmPassword()">visibility_off</span>
                                </ng-template>
                                <input type="{{confirmPasswordInputType}}" placeholder="Confirm Password" class="form-control sinput" formControlName="password_confirmation" aria-autocomplete="list">
                                <ng-container *ngIf="passwordResetForm.controls.password_confirmation.errors && (passwordResetForm.controls.password_confirmation.dirty || passwordResetForm.controls.password_confirmation.touched || passwordResetRequested)">
                                    <ng-container *ngIf="passwordResetForm.controls.password_confirmation.errors?.required">
                                        <div class="help-block text-danger mt-1">Please confirm your password</div>
                                    </ng-container>
                                    <ng-container *ngIf="passwordResetForm.controls.password_confirmation.errors?.passwordMatch">
                                        <div class="help-block text-danger mt-1">Password do not match</div>
                                    </ng-container>
                                </ng-container>
                            </div>		
                            <div class="col-12 my-3 text-center">
                                <button type="submit" class="btn btn-orange s-btn reset-password-button">
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="showLoader"></i>Reset Password
                                </button>&nbsp;&nbsp;
                                <button type="button" class="btn btn-orange s-btn reset-password-button" (click)="requestPasswordResetCode()">
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="resendCodeLoader"></i>Resend Code
                                </button>
                            </div>                               
                        </div>
                    </form>
                </div>
            </div>
            <!-- End of form -->
        </div>
    </div>
</section>
<!-- End of Main conatiner -->