import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../utils/data-share.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  showHideHamMenu: boolean= false;
  isLoggedIn: boolean= false;
  private ngUnsubscribe: Subject<any> = new Subject();
  userAvatar = '';
  assetBaseUrl = environment.assetBaseUrl;

  constructor(private dataShareService: DataShareService, private authService: SocialAuthService,
    private toastr: ToastrService, private router: Router) {
    if (localStorage.getItem('a-token') != null || sessionStorage.getItem('a-token') != null) {
      this.isLoggedIn = true;
      this.getUserProfileImage();
    }
  }

  ngOnInit(): void {
    this.dataShareService.currentLoginStatus.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(status => {
          if (localStorage.getItem('a_token') != null || sessionStorage.getItem('a_token') != null) {
            this.isLoggedIn = true;
            this.getUserProfileImage();
          } else {
            this.isLoggedIn = status;
            if(status == true) {
              this.getUserProfileImage();
            } else {
              this.userAvatar = '';
            }
          }
        });

  }

  getUserProfileImage() {
    this.userAvatar = localStorage.getItem('avatar') || sessionStorage.getItem('avatar') || '';
    if(this.userAvatar && !this.userAvatar.startsWith('http')) {
      this.userAvatar = this.assetBaseUrl + this.userAvatar;
    }
  }

  toggleHamMenu() {
    this.showHideHamMenu = !this.showHideHamMenu;
  }

  logout() {
    // if(localStorage.getItem('social') === '1') {
    //   this.authService.signOut();
    // }
    sessionStorage.removeItem('a_token');
    sessionStorage.removeItem('r_token');
    sessionStorage.removeItem('avatar');
    localStorage.removeItem('a_token');
    localStorage.removeItem('r_token');
    localStorage.removeItem('avatar');
    localStorage.removeItem('social');
    this.userAvatar = '';
    this.isLoggedIn = false;
    this.toastr.success('Loggedout Successfully', 'Success');
    this.router.navigate(['/']);
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
