import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../utils/data-share.service';
import { SearchService } from '../utils/services/search.service';
import { PartnerServicesService } from './service/partner-services.service';

declare function alignTabs(): any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  assetBaseUrl = environment.assetBaseUrl;
  // filterTerm: string = '';
  filterForm!: FormGroup;
  activeCategory: string = 'Featured';
  /**
   * variables declared for categories
   */
  hasResponse: boolean = false;
  categoryList: any[] = [
    {id: 0,category_name: "Featured",page: 0},
    {id: 0,category_name: "All",page: 0}
  ];
  partnerServiceList: any[] = [];
  filteredServicesList: any[] = [];

  constructor(private service: PartnerServicesService, private toastr: ToastrService,
    private formBuilder: FormBuilder, private dataShareService: DataShareService,
    private searchService: SearchService) { }

  ngOnInit(): void {
    /**
     * initialize filter form
     */
    this.filterForm = this.formBuilder.group({
      searchText: ['']
    });
    /**
     * subscribe to event for partner service category click event
     */
     this.dataShareService.activePartnerServiceCategory.pipe(takeUntil(this.ngUnsubscribe))
     .subscribe((category: any) => {
       if(category) {
         this.activeCategory = category;
        //  category.page = 0;
       }
     });
     /**
      * call a function to fetch partner services categories
      */
    this.getPartnerServiceCategories();
    /**
     * subscribe to the change in value of a search text field
     */
    this.filterForm.controls.searchText.valueChanges
      .pipe(debounceTime(1000))
      .pipe(distinctUntilChanged())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if(value) {
          this.searchService.searchData(value).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.filteredServicesList = [];
              let filteredData = res.filter((obj: any) => obj.type === 'services');
              if(filteredData[0]?.data?.length > 0) {
                this.filteredServicesList = filteredData[0].data;
              } else {
                this.filteredServicesList = [-1];
              }
            }, (err: any) => {
              this.filteredServicesList = [ -1];
            });
          // let filteredData = this.partnerServiceList.filter(obj => obj.service_product_name.toLocaleLowerCase().includes(value));
          // if(filteredData.length > 0) {
          //   this.filteredServicesList = filteredData;
          // } else {
          //   this.filteredServicesList = [-1];
          // }
        } else {
          if(this.activeCategory == 'Featured') { 
            this.filteredServicesList = [];
            this.filteredServicesList = this.partnerServiceList.filter(obj => obj.is_featured == true);
          } else if(this.activeCategory == 'All') {
            this.filteredServicesList = [];
            this.filteredServicesList = this.partnerServiceList;
          } else {
            /**
             * filter data from all japanese manner list
             */
            this.filteredServicesList = [];
            this.filteredServicesList = this.partnerServiceList.filter(obj => obj.category == this.activeCategory);
          }
          /**
           * check if the length of filteredServicesList is 0 or not
           * if 0 then push -2 to the filteredServicesList 
           * so that proper information is displayed to the user
           */
          if(this.filteredServicesList.length <= 0) {
            this.filteredServicesList = [-2];
          }
        }

      });
  }

  /**
   * function to fetch partner service categories
   */
  getPartnerServiceCategories() {
    this.service.getPartnerServicesCategory().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any)=> {
        this.categoryList = this.categoryList.concat(res.categories);
        this.hasResponse = true;
        let timeout = 500;
        // this.categoryList.forEach(obj => {
        //   obj.page = 0;
        //   timeout = timeout + 500;
        //   setTimeout(()=>{
        //     this.getJapaneseServicesByCategory(obj);
        //   },timeout);
        // });
        this.categoryList.forEach((obj, key, array) => {
          let isLastItem = key === array.length - 1;
          obj.page = 0;
          timeout = timeout + 500;
          setTimeout(()=>{
            this.getJapaneseServicesByCategory(obj, false, isLastItem);
          },timeout);
        });
        setTimeout(function(){
          alignTabs();
        },100);
      }, (err: any) => {
        // this.toastr.error('Failed to fetch partner service categories', 'ERROR');
      });
  }

  /**
   * function to fetch partner services list by category name
   * @param category partner service category object
   * @param changeCategory boolean value whether to toogle activePartnerServiceCategory value
   * @returns void
   */
  getJapaneseServicesByCategory(category: any, changeCategory = false, isLastItem=false) {
    if(this.activeCategory == category.category_name && category.page > 0) {
      return;
    }
    if(changeCategory) {
      this.activeCategory = category.category_name;
    }
    if(category.page <= 0 && category.category_name != 'Featured' && category.category_name != 'All') {
      const indexOfCategory = this.categoryList.findIndex(obj => obj.id === category.id);
      this.service.getPartnerServicesByServiceCategoryName(category.category_name, category.page + 1)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if(res.partner_services.length > 0) {
            this.categoryList[indexOfCategory].page = this.categoryList[indexOfCategory].page + 1;
            // push partner services to the list of they do not exists
            res.partner_services.forEach((obj: any) => {
              let partnerServiceIndex = this.partnerServiceList.findIndex((service: any) => service.id === obj.id);
              if(partnerServiceIndex < 0) {
                this.partnerServiceList.push(obj);
              }
              if(this.activeCategory == 'Featured') { 
                this.filteredServicesList = [];
                this.filteredServicesList = this.partnerServiceList.filter(obj => obj.is_featured == true);
              } else if(this.activeCategory == 'All') {
                this.filteredServicesList = [];
                this.filteredServicesList = this.partnerServiceList;
              } else {
                /**
                 * filter data from all japanese manner list
                 */
                this.filteredServicesList = [];
                this.filteredServicesList = this.partnerServiceList.filter(obj => obj.category == this.activeCategory);
              }
            });
          }
          /**
           * check if the length of filteredServicesList is 0 or not
           * if 0 then push -2 to the filteredServicesList 
           * so that proper information is displayed to the user
           */
          if(isLastItem && this.filteredServicesList.length <= 0) {
            this.filteredServicesList = [-2];
          }
      }, (err: any) => {
        // this.toastr.error('Failed to fetch partner services by category', 'ERROR');
      });
    } else {
      if(category.category_name == 'Featured') { 
        this.filteredServicesList = [];
        this.filteredServicesList = this.partnerServiceList.filter(obj => obj.is_featured == true);
        // return;
      } else if(category.category_name == 'All') {
        this.filteredServicesList = [];
        this.filteredServicesList = this.partnerServiceList;
        // return;
      } else {
        /**
         * filter data from all japanese manner list
         */
        this.filteredServicesList = [];
        this.filteredServicesList = this.partnerServiceList.filter(obj => obj.category  == category.category_name);
      }
      /**
       * check if the length of filteredServicesList is 0 or not
       * if 0 then push -2 to the filteredServicesList 
       * so that proper information is displayed to the user
       */
      if(isLastItem && this.filteredServicesList.length <= 0) {
        this.filteredServicesList = [-2];
      }
    }
  }

  trackById(index: number, item: any): any {
    if(item != -1 && item != '-1') {
      return item.id;
    }
    return item;
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    setTimeout(() => {
      alignTabs();
    }, 100);
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
