<ng-container *ngIf="hasResponse; else noResponse">
<!-- Carousel slider -->
<div id="carousel" class="carousel slide hero-slides" data-ride="carousel">
    <ol class="carousel-indicators">
        <ng-container *ngIf="sliderList.length > 0; else defaultSliderToogler">
            <ng-container *ngFor="let slider of sliderList; let i= index; trackBy:trackById;">
                <li [ngClass]="i ==0 ? 'active':''" data-target="#carousel" [attr.data-slide-to]="i"></li>
            </ng-container>
        </ng-container>
        <ng-template #defaultSliderToogler>
            <li class="active" data-target="#carousel" data-slide-to="0"></li>
        </ng-template>
    </ol>
    <div class="carousel-inner" role="listbox">
        <ng-container *ngIf="sliderList.length > 0; else defaultSlider">
            <ng-container *ngFor="let slider of sliderList; let i = index; trackBy:trackById;">
                <!-- <div class="carousel-item slide1" [ngClass]="i ==0 ? 'active':''" [style.background-image]="'url(' + assetBaseUrl + slider.image + '), url(assets/img/banner-1.jpg)'" > -->
                <div class="carousel-item slide1" [ngClass]="i ==0 ? 'active':''" [sliderimagepreloader]="assetBaseUrl + slider.image" >
                    <div class="container h-100">
                        <div class="row align-items-center h-100">
                            <div class="col-12 col-md-9 col-lg-8 col-xl-8">
                                <div class="caption animated fadeIn">
                                    <h2 class="animated">{{slider.title}}</h2>
                                    <p class="animated" [innerHTML]="slider.description"></p>					
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #defaultSlider>
            <div class="carousel-item active slide1" [style.background-image]="'url(assets/img/default_banners/default-banner.svg)'">
                <div class="container h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-12 col-md-9 col-lg-8 col-xl-8">
                            <div class="caption animated fadeIn">
                                <h2 class="animated">BNJP-Buy Now Pay Japan</h2>
                                <!-- <p class="animated">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>					 -->
                                <!-- <a class="animated fadeInUp btn md-3 mt-md-5" href="#">Learn More</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <!-- <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a> -->
</div>
<!-- End Carousel slider -->

<!-- About us -->
<section class="abt custom-section" *ngIf="aboutUs != undefined && aboutUs != null">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-header text-center">
                    <p>About</p>
                    <h1>{{aboutUs.title}}</h1>
                </div>
                <div class="abt-content text-center">
                    <p [innerHTML]="(aboutUs.description | slice: 0:250) + '...'"></p>
                </div>
                <div class="col-md-12 mt-4 text-center">
                    <button class="btn-orange" [routerLink]="['/about']">View More</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of About us -->

<!-- Create resume -->
<section class="create-resume">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="bg-oval">
                    <div class="row">
                        <div class="col-lg-9 col-md-7">
                            <h2>Build Resume in Japanese Template</h2>
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <button class="btn btn-yellow" [routerLink]="['/resume']"><i class="fas fa-rocket mr-2"></i>Create Resume</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Create resume -->

<!-- Services -->
<section class="our-services custom-section content-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-header text-center">
                    <p>Partner</p>
                    <h1>Services</h1>
                </div>
            </div>
        </div>
        <ng-container *ngIf="parnerServiceCategoryList.length > 0; else noPartnerServicesValue">
            <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <ng-container *ngFor="let category of parnerServiceCategoryList;let i=index; trackBy:trackById;">
                                    <li class="nav-item" (click)="getJapaneseServicesByCategory(category, true)">
                                        <a class="nav-link" [ngClass]="activePartnerServiceCategory == category.category_name ? 'active':''" id="tab-1" data-toggle="tab" href="#tab1" role="tab" attr.aria-controls="tab1" attr.aria-selected="true">{{category.category_name}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        
                    </div>
                <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                <ng-container *ngIf="filteredPartnnerServicesList.length > 0; else noPartnerServicesData">
                                    <div class="row">
                                        <ng-container *ngFor="let service of filteredPartnnerServicesList; trackBy:trackById;">
                                            <div class="col-md-6 col-lg-3">
                                                <img [imagepreloader]="assetBaseUrl + service.company_banner_image" [targetSection]="'services'" [routerLink]="['/partner-services/' + service.category + '/' + service.id]" class="img-fluid">
                                                <div class="service-items mt-3 d-flex">
                                                    <img [imagepreloader]="assetBaseUrl + service.company_logo" [targetSection]="'sliderslogo'" >
                                                    <a [routerLink]="['/partner-services/' + service.category + '/' + service.id]">{{service.service_product_name}}</a>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-template #noPartnerServicesData>
                                    <section class="content content-section loading-skeleton">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                                        <div class="tab-content" id="myTabContent">
                                                            <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                                                <div class="row">	
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>	
                                                                            <p>Nepal Telecom Test</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-lg-3">
                                                                        <div class="bg-img"></div>								
                                                                        <div class="service-items mt-3 d-flex">
                                                                            <div class="rounded-img"></div>
                                                                            <p>Nepal Telecom</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>				
                                                        </div>						
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </ng-template>
                            </div>
                </div>						
            </div>
            <div class="row">
                <div class="col-md-12 mt-4 text-center">
                    <button class="btn-orange" (click)="redirectUsersToPartnerServiceListingPage()">View All</button>
                </div>
            </div>
        </ng-container>
        <ng-template #noPartnerServicesValue>
            <!-- Main contents -->
            <section class="content content-section loading-skeleton">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                <div class="head-tabs">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12">
                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item">
                                                    <p>Featured</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Nep Telecom</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Featur</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Telecom</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Featur</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Nep Telecom</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Shop</p>
                                                </li>
                                                <li class="nav-item">
                                                    <p>Shop</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- <div class="col-md-4 col-lg-3">
                                            <form>
                                                <input class="form-control" type="search" placeholder="Search" aria-label="Search">
                                            </form>
                                        </div> -->
                                    </div>
                                    
                                </div>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                        <div class="row">	
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>	
                                                    <p>Nepal Telecom Test</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3">
                                                <div class="bg-img"></div>								
                                                <div class="service-items mt-3 d-flex">
                                                    <div class="rounded-img"></div>
                                                    <p>Nepal Telecom</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>				
                                </div>						
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End of Main contents -->
        </ng-template>
    </div>
</section>
<!-- End of Services -->

<!-- Japanese manner -->
<section class="manner custom-section">
    <div class="container">
        <div id="slider-manner" class="carousel slide" data-ride="carousel">
            <!-- The slideshow -->
            <div class="container carousel-inner no-padding">	
                <ng-container *ngIf="jpMannerList.length > 0; else defaultJPManners">
                    <ng-container *ngFor="let jpManner of jpMannerList; let i=index; trackBy:trackById;">
                        <div class="carousel-item" [ngClass]="i ==0 ? 'active':''">
                            <div class="row">
                                <ng-container *ngIf="!jpManner.is_youtube; else youtubeJapaneseManner">
                                    <div class="col-md-6">
                                        <div class="outer-bgimg">
                                            <img [imagepreloader]="assetBaseUrl + jpManner.image" [targetSection]="'jpmanners'" [routerLink]="['/japanese-manners/' + jpManner.category.category_name + '/' + jpManner.category.id + '/manner/' + jpManner.id]" class="img-fluid">
                                        </div>
                                    </div>    
                                    <div class="col-md-6 col-lg-5 offset-lg-1 offset-md-0">  
                                        <div class="m-details">
                                            <div class="title-header">
                                                <p>{{jpManner.category.category_name}}</p>
                                                <h1>{{jpManner.title}}</h1>
                                            </div>
                                            <p [innerHTML]="jpManner.description"></p>
                
                                            <!-- <div class="tag-links mt-4">
                                                <a href="#">Education</a>
                                                <a href="#">Sport</a>
                                            </div> -->
                                        </div>
                                        <a [routerLink]="['/japanese-manners/' + jpManner.category.category_name + '/' + jpManner.category.id + '/manner/' + jpManner.id]" class="mt-4 mt-md-5 d-inline-block more">Learn More <i class="fas fa-arrow-right"></i></a>
                                    </div>
                                </ng-container>
                                <ng-template #youtubeJapaneseManner>
                                    <div class="col-md-6">
                                        <div class="outer-bgimg">
                                            <iframe width="100%" height="400"  [src]="jpManner.description | safeResourceUrl" title="{{jpManner.title}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            <!-- <img [imagepreloader]="assetBaseUrl + jpManner.image" [targetSection]="'jpmanners'" class="img-fluid"> -->
                                        </div>
                                    </div>    
                                    <div class="col-md-6 col-lg-5 offset-lg-1 offset-md-0">  
                                        <div class="m-details">
                                            <div class="title-header">
                                                <p>{{jpManner.category.category_name}}</p>
                                                <h1>{{jpManner.title}}</h1>
                                            </div>
                                            <!-- <p [innerHTML]="jpManner.description"></p> -->
                
                                            <!-- <div class="tag-links mt-4">
                                                <a href="#">Education</a>
                                                <a href="#">Sport</a>
                                            </div> -->
                                        </div>
                                        <a [routerLink]="['/japanese-manners/' + jpManner.id]" class="mt-4 mt-md-5 d-inline-block more">Learn More <i class="fas fa-arrow-right"></i></a>
                                    </div>
                                </ng-template>
                                
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #defaultJPManners>
                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="outer-bgimg">
                                    <img src="assets/img/default_banners/default-jp-manner.svg" class="img-fluid">
                                </div>
                            </div>    
                            <div class="col-md-6 col-lg-5 offset-lg-1 offset-md-0">  
                                <div class="title-header">
                                    <p>BNPJ-Buy Now Pay Japan</p>
                                    <!-- <h1>Learning Japanese Manners</h1> -->
                                </div>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> -->
    
                                <!-- <div class="tag-links mt-4">
                                    <a href="#">Education</a>
                                    <a href="#">Sport</a>
                                </div> -->
                                <!-- <a href="#" class="mt-4 mt-md-5 d-inline-block more">Learn More <i class="fas fa-arrow-right"></i></a> -->
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <a class="carousel-control-prev" href="#slider-manner" data-slide="prev">
                <i class="fas fa-chevron-circle-left"></i>
            </a>
            <a class="carousel-control-next" href="#slider-manner" data-slide="next">
                <i class="fas fa-chevron-circle-right"></i>
            </a>

        </div>
        
    </div>

</section>
<!-- End of Japanese manner -->

<section class="news custom-section">
<div class="container">
    <div class="col-md-12">
        <div class="title-header text-center">
            <p>Success Stories</p>
            <h1>Latest News</h1>
        </div>
    </div>

    <div class="text-center mb-4 mb-md-5">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" (click)="toogleActivePanel('foryou')">
                <a class="nav-link active" id="foryou-tab" data-toggle="tab" href="#for-you" role="tab" aria-controls="for-you" aria-selected="true">For You</a>
            </li>
            <li class="nav-item" (click)="toogleActivePanel('news')">
                <a class="nav-link" id="news-tab" data-toggle="tab" href="#news" role="tab" aria-controls="news" aria-selected="false">News</a>
            </li>
            <li class="nav-item" (click)="toogleActivePanel('alert')">
                <a class="nav-link" id="alerts-tab" data-toggle="tab" href="#alerts" role="tab" aria-controls="alerts" aria-selected="false">Alerts</a>
            </li>
        </ul>
    </div>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="for-you" role="tabpanel">
            <div class="row">
                <ng-container *ngIf="latestAlertList != undefined && latestAlertList.length > 0">
                    <div class="col-lg-12 col-md-12 mb-4 alert-carousal-container">
                        <div id="alertcarousel" class="carousel slide" data-ride="carousel">
                            <!-- <ol class="carousel-indicators">
                                <li class="alert-carousel-control active" data-target="#alertcarousel" data-slide-to="0"></li>
                                <li class="alert-carousel-control" data-target="#alertcarousel" data-slide-to="1"></li>
                                <li class="alert-carousel-control" data-target="#alertcarousel" data-slide-to="2"></li>
                            </ol> -->
                            <div class="container carousel-inner no-padding">
                                <ng-container *ngFor="let alert of latestAlertList; let i = index; trackBy:trackAlertByCode;">
                                    <ng-container *ngIf="i < 3">
                                        <div class="carousel-item" [ngClass]="i ==0 ? 'active':''">
                                            <!-- <div class="row"> -->
                                            <ng-container *ngIf="alert.label === 'Earthquake'">
                                                <div class="alerts-item d-flex justify-content-between news-alerts-item">
                                                    <div class="l-detail d-flex">
                                                      <img src="assets/img/icon-earthquake.svg">
                                                      <div class="alert-wrap">
                                                        <a [routerLink]="['/earthquake/en/' + alert.code]">{{ alert.epicenter_value }}</a>
                                                        <div class="mt-1">
                                                          <item>
                                                            <span>{{ alert.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                                            <span>
                                                              {{ alert.occurred_value | date : 'HH:mm' }}</span>
                                                          </item>
                                                          <span>{{alert.magnitude_title}} {{alert.magnitude_value}}</span>
                                                        </div>
                            
                                                      </div>
                                                    </div>
                                                    <div class="r-info text-center">
                                                      <strong class="d-block mb-2">{{alert.magnitude_value}}</strong>
                                                      <span>{{alert.occurred_value | dateAgo}}</span>
                                                    </div>
                                                    <!-- <div class="r-info text-center">
                                                        <strong class="d-block mb-2">{{alert.magnitude_value}}</strong>
                                                        <span>{{alert.occurred_value | dateAgo}}</span>
                                                        <a [routerLink]="['/earthquake/en/' + alert.code]">Read More</a>
                                                    </div> -->
                                                    <!-- <div class="r-info text-center">
                                                        <strong class="d-block mb-2 link" [routerLink]="['/earthquake/en/' + alert.code]">Read More</strong>
                                                    </div> -->
                                                  </div>
                                            </ng-container>
                                            <ng-container *ngIf="alert.label === 'Volcano'">
                                                <div class="alerts-item d-flex justify-content-between news-alerts-item">
                                                    <div class="l-detail d-flex">
                                                      <img src="assets/img/icon-volcano.svg">
                                                      <div class="alert-wrap">
                                                        <a [routerLink]="['/volcano/en/' + alert.volcano_code]">{{alert.volcano_name_value}}</a>
                                                        <div class="mt-1">
                                                          <item>
                                                            <span>{{ alert.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                                            <span> {{ alert.occurred_value | date : 'HH:mm' }}</span>
                                                          </item>
                                                          <span>{{alert.level_title}} {{alert.level_value}} {{alert.level_text ? (alert.level_text) : ''}}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="r-info text-center">
                                                        <strong class="d-block mb-2 link" [routerLink]="['/volcano/en/' + alert.volcano_code]">Read More</strong>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!-- </div> -->
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <a class="carousel-control-prev" href="#alertcarousel" data-slide="prev">
                                <i class="fas fa-chevron-circle-left"></i>
                            </a>
                            <a class="carousel-control-next" href="#alertcarousel" data-slide="next">
                                <i class="fas fa-chevron-circle-right"></i>
                            </a>
                        </div>
                        <!-- End Carousel slider -->
                    </div>
                </ng-container>
                <ng-container *ngIf="forYouNews.length > 0; else noNewsResponse">
                    <ng-container *ngFor="let news of forYouNews; let i=index; trackBy:trackNewsByLink;">
                        <ng-container *ngIf="news == -1; else hasForYouNewsData">
                            <h6 class="col-lg-12 text-center">
                                Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                            </h6>
                        </ng-container>
                        <ng-template #hasForYouNewsData>
                            <div class="col-lg-4 col-md-6">
                                <div class="news-box">
                                    <div class="img-box newsBox">
                                        <img [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link" (click)="detailNews(news)">
                                    </div>
                                    <div class="thumb-title">
                                        <span><i class="far fa-calendar-alt"></i>{{news.published_date | date:'MMM d, y'}}</span>
                                        <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                    </div>
                                    <div class="news-tag mt-3">
                                        <a href="#">{{news.category}}</a>
                                    </div>
                                    <h3 class="link" (click)="openNewsUrl(news.link)">{{news.title}}</h3>
                                    <div class="n-source d-flex">
                                        <div>
                                            <img class="link" [imagepreloader]="news.source_logo" [targetSection]="'newslogo'" (click)="detailNews(news)">
                                            <span>{{news.source  | uppercase }}</span>
                                        </div>
                                        <a href="{{news.link}}" target="_blank">Read More <i class="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
            <div class="row justify-content-center" *ngIf="showForYouNewsLoadMore">
                <button type="submit" class="btn btn-orange s-btn" (click)="getForYouNews()">
                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchNewsTriggered"></i>{{fetchNewsTriggered ? 'Loading' : 'Load More'}}
                </button>
            </div>
        </div>
        <div class="tab-pane fade" id="news" role="tabpanel">
            <div class="row">
                <ng-container *ngIf="newsList.length > 0; else noNewsResponse">
                    <ng-container *ngFor="let news of newsList; let i=index; trackBy:trackNewsByLink;">
                        <ng-container *ngIf="news == -1; else hasNewsData">
                            <h6 class="col-lg-12 text-center">
                                Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                            </h6>
                        </ng-container>
                        <ng-template #hasNewsData>
                            <div class="col-lg-4 col-md-6">
                                <div class="news-box">
                                    <div class="img-box newsBox">
                                        <img [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link" (click)="detailNews(news)">
                                    </div>
                                    <div class="thumb-title">
                                        <span><i class="far fa-calendar-alt"></i>{{news.published_date | date:'MMM d, y'}}</span>
                                        <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                    </div>
                                    <div class="news-tag mt-3">
                                        <a href="#">{{news.category}}</a>
                                    </div>
                                    <h3 class="link" (click)="openNewsUrl(news.link)">{{news.title}}</h3>
                                    <div class="n-source d-flex">
                                        <div>
                                            <img class="link" [imagepreloader]="news.source_logo" [targetSection]="'newslogo'" (click)="detailNews(news)">
                                            <span>{{news.source | uppercase }}</span>
                                        </div>
                                        <a href="{{news.link}}" target="_blank">Read More <i class="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
            <div class="row justify-content-center" *ngIf="showLatestNewsLoadMore">
                <button type="submit" class="btn btn-orange s-btn" (click)="getNews()">
                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchLatestNewsTriggered"></i>{{fetchLatestNewsTriggered ? 'Loading' : 'Load More'}}
                </button>
            </div>
        </div>
        <div class="tab-pane fade" id="alerts" role="tabpanel">
            <div class="row">
                <div class="col-md-12">
                    <ng-container *ngIf="latestAlertList != undefined && latestAlertList.length > 0; else noAlertResponse">
                        <ng-container *ngFor="let alert of latestAlertList; trackBy:trackAlertByCode;">
                            <ng-container *ngIf="alert == -1; else hasAlertDatas">
                                <h6 class="col-lg-12 text-center">
                                    Alerts data unavailable.
                                </h6>
                            </ng-container>
                            <ng-template #hasAlertDatas>
                                <ng-container *ngIf="alert.label === 'Earthquake'">
                                    <div class="alerts-item d-flex justify-content-between">
                                        <div class="l-detail d-flex">
                                          <img src="assets/img/icon-earthquake.svg">
                                          <div class="alert-wrap">
                                            <a [routerLink]="['/earthquake/en/' + alert.code]">{{ alert.epicenter_value }}</a>
                                            <div class="mt-1">
                                              <item>
                                                <span>{{ alert.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                                <span>
                                                  {{ alert.occurred_value | date : 'HH:mm' }}</span>
                                              </item>
                                              <span>{{alert.magnitude_title}} {{alert.magnitude_value}}</span>
                                            </div>
                
                                          </div>
                                        </div>
                                        <div class="r-info text-center">
                                          <strong class="d-block mb-2">{{alert.magnitude_value}}</strong>
                                          <span>{{alert.occurred_value | dateAgo}}</span>
                                        </div>
                                      </div>
                                </ng-container>
                                <ng-container *ngIf="alert.label === 'Volcano'">
                                    <div class="alerts-item d-flex justify-content-between">
                                        <div class="l-detail d-flex">
                                          <img src="assets/img/icon-volcano.svg">
                                          <div class="alert-wrap">
                                            <a [routerLink]="['/volcano/en/' + alert.volcano_code]">{{alert.volcano_name_value}}</a>
                                            <div class="mt-1">
                                              <item>
                                                <span>{{ alert.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                                <span> {{ alert.occurred_value | date : 'HH:mm' }}</span>
                                              </item>
                                              <span>{{alert.level_title}} {{alert.level_value}} {{alert.level_text ? (alert.level_text) : ''}}</span>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="showLatestAlertLoadMore">
                            <div class="row justify-content-center mt-5">
                                <button type="submit" class="btn btn-orange s-btn" (click)="fetchLatestAlerts()">
                                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchingLatestAlerts"></i>{{fetchingLatestAlerts ? 'Loading' : 'Load More'}}
                                </button>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>		
</div>
</section>
</ng-container>
<ng-template #noAlertResponse>
    <section class="content content-section loading-skeleton avoid-skeleton-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                <div class="head-tabs">
                  <div class="row">
                    <!-- <div class="col-md-12"> -->
                      <!-- <div class="tab-content" id="myTabContent"> -->
                        <!-- <div class="tab-pane fade show active" id="earthquake-info" role="tabpanel"> -->
                          <!-- <div class="row">	 -->
                            <div class="col-md-12">
                              <div class="alerts-item d-flex justify-content-between">
                                <div class="l-detail d-flex">
                                  <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                  <div class="alert-wrap">
                                    <a >Northern Wakayama Pref</a>
                                    <div class="mt-1">
                                      <item>
                                        <span>2021/03/24</span>
                                        <span> 06:46</span>
                                      </item>
                                      <span>Maximum seismic intensity 4.2</span>
                                    </div>

                                  </div>
                                </div>
                                <div class="r-info text-center">
                                  <strong class="d-block mb-2">4.2</strong>
                                  <span>10h ago</span>
                                </div>
                              </div>
                              <div class="alerts-item d-flex justify-content-between">
                                <div class="l-detail d-flex">
                                  <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                  <div class="alert-wrap">
                                    <a >Northern Wakayama Pref</a>
                                    <div class="mt-1">
                                      <item>
                                        <span>2021/03/24</span>
                                        <span> 06:46</span>
                                      </item>
                                      <span>Maximum seismic intensity 4.2</span>
                                    </div>

                                  </div>
                                </div>
                                <div class="r-info text-center">
                                  <strong class="d-block mb-2">4.2</strong>
                                  <span>10h ago</span>
                                </div>
                              </div>
                              <div class="alerts-item d-flex justify-content-between">
                                <div class="l-detail d-flex">
                                  <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                  <div class="alert-wrap">
                                    <a >Northern Wakayama Pref</a>
                                    <div class="mt-1">
                                      <item>
                                        <span>2021/03/24</span>
                                        <span> 06:46</span>
                                      </item>
                                      <span>Maximum seismic intensity 4.2</span>
                                    </div>

                                  </div>
                                </div>
                                <div class="r-info text-center">
                                  <strong class="d-block mb-2">4.2</strong>
                                  <span>10h ago</span>
                                </div>
                              </div>
                              <div class="alerts-item d-flex justify-content-between">
                                <div class="l-detail d-flex">
                                  <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                  <div class="alert-wrap">
                                    <a >Northern Wakayama Pref</a>
                                    <div class="mt-1">
                                      <item>
                                        <span>2021/03/24</span>
                                        <span> 06:46</span>
                                      </item>
                                      <span>Maximum seismic intensity 4.2</span>
                                    </div>

                                  </div>
                                </div>
                                <div class="r-info text-center">
                                  <strong class="d-block mb-2">4.2</strong>
                                  <span>10h ago</span>
                                </div>
                              </div>
                              <div class="alerts-item d-flex justify-content-between">
                                <div class="l-detail d-flex">
                                  <div class="s-default-img s-round mr-3" style="height: 70px; width: 120px"></div>
                                  <div class="alert-wrap">
                                    <a >Northern Wakayama Pref</a>
                                    <div class="mt-1">
                                      <item>
                                        <span>2021/03/24</span>
                                        <span> 06:46</span>
                                      </item>
                                      <span>Maximum seismic intensity 4.2</span>
                                    </div>

                                  </div>
                                </div>
                                <div class="r-info text-center">
                                  <strong class="d-block mb-2">4.2</strong>
                                  <span>10h ago</span>
                                </div>
                              </div>
                            </div>
                          <!-- </div> -->
                        <!-- </div> -->
                      <!-- </div> -->
                    <!-- </div> -->
                  </div>							
                </div>												
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <!-- End of Main contents -->
</ng-template>
<ng-template #noNewsResponse>
    <div class="container loading-skeleton">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="news-box">								
                    <div class="s-default-img" style="height: 210px; width: 397px"></div>	
                    <div class="news-tag mt-1 mb-1">
                        <a href="#">Politics</a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consetu elit eiusmod</p>
                    <div class="n-source d-flex">
                        <div class="d-flex">
                            <div class="rounded-img"></div>	
                            <span>News Source</span>
                        </div>
                        <a href="#">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="news-box">								
                    <div class="s-default-img" style="height: 210px; width: 397px"></div>	
                    <div class="news-tag mt-1 mb-1">
                        <a href="#">Politics</a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consetu elit eiusmod</p>
                    <div class="n-source d-flex">
                        <div class="d-flex">
                            <div class="rounded-img"></div>	
                            <span>News Source</span>
                        </div>
                        <a href="#">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="news-box">								
                    <div class="s-default-img" style="height: 210px; width: 397px"></div>	
                    <div class="news-tag mt-1 mb-1">
                        <a href="#">Politics</a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consetu elit eiusmod</p>
                    <div class="n-source d-flex">
                        <div class="d-flex">
                            <div class="rounded-img"></div>	
                            <span>News Source</span>
                        </div>
                        <a href="#">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</ng-template>
<ng-template #noResponse>
    <section>
		<div class="container-fluid loading-skeleton">
			<div class="row">
				<div class="col-md-12 no-padding">
					<div class="s-banner">
					</div>
				</div>
			</div>
		</div>
	</section>

	<div class="container loading-skeleton">
		<div class="row">
			<div class="col-12">
				<div class="mt-5">
					<div class="card-body text-center">
						<p class="d-inline-block">About About About</p><br>
						<h1 class="d-inline-block">BNPJ-Buy Now Pay</h1>
					</div>
					<div class="card-body text-center">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>

						<p>Labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

						<p>bore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

						<button class="btn mt-4">View More</button>
					</div>

				</div>
			</div>
		</div>
	</div> 
</ng-template>