import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AboutUsService } from '../utils/services/aboutus.service';
import { ContactUsService } from './service/contactus.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  private ngUnsubscribe: Subject<any> = new Subject();
  hasResponse: boolean = false;
  aboutUsDescription: string = '';
  contactUsForm!: FormGroup;
  contactUsFormSubmitted: boolean = false;
  showLoader: boolean = false;
  /**
   * variables declared for recaptcha
   */
   captchaResponse = '';
   captchaVerification : boolean= false;

  constructor(private aboutusService: AboutUsService, private formBuilder: FormBuilder,
    private service: ContactUsService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
    this.getAboutUsDetails();
  }

  getAboutUsDetails() { 
    this.aboutusService.fetchAboutUsDetails().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.aboutUsDescription = res['about_us'][0]['description'];
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Failed to fetch About details', 'ERROR');
      });
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    this.captchaVerification= true;
  }

  submitContactUsMessage() {
    this.contactUsFormSubmitted = true;
    if(this.contactUsForm.invalid || grecaptcha.getResponse.length <= 0){
      this.captchaVerification = false;
      return;
    }
    this.showLoader = true;
    const data = {
      name: this.contactUsForm.controls.name.value,
      email: this.contactUsForm.controls.email.value,
      message: this.contactUsForm.controls.message.value,
      g_recaptcha_response: grecaptcha.getResponse() 
    }
    this.service.submitContactMessage(data).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        grecaptcha.reset();
        this.contactUsForm.reset();
        this.captchaVerification = false;
        this.contactUsFormSubmitted = false;
        this.showLoader = false;
        this.toastr.success('Message sent successfully!!', 'SUCCESS');
      }, (err: any) => {
        grecaptcha.reset();
        this.captchaVerification = false;
        this.contactUsFormSubmitted = false;
        this.showLoader = false;
        this.toastr.error('Failed to send message. Please try again.', 'ERROR');
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
