import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { VerifyEmailComponent } from './verify-email.component';

const VERIFY_EMAIL_ROUTES: Routes = [
    { path: '', component : VerifyEmailComponent}
];
@NgModule({
    imports: [RouterModule.forChild(VERIFY_EMAIL_ROUTES)],
    exports: [RouterModule]
})
export class VerifyEmailRoutingModule {
}
