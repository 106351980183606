import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { TransactionComponent } from './transaction.component';

const TRANSACTION: Routes = [
    { path: '', component : TransactionComponent}
];
@NgModule({
    imports: [RouterModule.forChild(TRANSACTION)],
    exports: [RouterModule]
})
export class TransactionRoutingModule {
}
