import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyEmailRoutingModule } from './verify-email-routing.module';
import { VerifyEmailComponent } from './verify-email.component';
import { AuthenticationService } from '../login/services/authentication.service';

@NgModule({
    imports: [
        CommonModule,
        VerifyEmailRoutingModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        VerifyEmailComponent
    ],
    providers: [
        AuthenticationService
    ]
})

export class VerifyEmailModule {
}
