import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private baseUrl = environment.apiUrl;

  private searchHeader = new HttpHeaders({
    'cache': 'false',
  });

  constructor(private http: HttpClient) { }

  searchData(searchText = '') {
      return this.http.get(this.baseUrl + '/search/?search=' + searchText, {headers: this.searchHeader});
  }
}
