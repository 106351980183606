import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseUrl = environment.apiUrl;

//   private newsHeader = new HttpHeaders({
//     'cache': 'true',
//     'cache_group': 'profile'
//   });

  private authProfileHeader = new HttpHeaders({
    auth: 'true',
    'cache': 'true',
    'cache_group': 'profile'
  });

  constructor(private http: HttpClient) { }

  fetchUserProfileDetails() {
      return this.http.get(this.baseUrl + '/profile/get', {headers: this.authProfileHeader});
  }

  updateUserProfileDetails(profileDetails: any) {
    return this.http.post(this.baseUrl + '/profile_update/en', profileDetails, {headers: this.authProfileHeader});
  }

  fetchUserBalance() {
    const header = new HttpHeaders({
      auth: 'true',
      'cache': 'false'
    });
    return this.http.get(this.baseUrl + '/profile/get/balance', {headers: header});
  }
}
