<!-- Page header -->
<section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>Specified Commercial Transactions Law（Draft)</h2>
            </div>
        </div>
    </div>
</section>
<!-- End of Page header -->	

<!-- Main contents -->
<section class="content content-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="box-description">
                    <p>
                        3PLATZ Co., Ltd. displays necessary items in accordance with the guidelines based on the Japan Act of Specified Commercial Transactions and other related laws and regulations in order to contribute to the spread of sound electronic commerce and earn the trust of customers in mail-order sales using the Internet. 
                    </p>
                </div>
                <div class="box-description">
                    <h5>● Distributor</h5>
                    3PLATZ Co., Ltd.<br/>
                    Representative：Kazuhide Kamitani<br/>
                    Address：9th floor, NAX Building 1-3-2 Shinkawa, Chuo-ku, Tokyo 104-0033<br/>
                    Telephone：03-6222-8365（Reception time：am 9:30～pm5：30　※Closed on Saturdays, Sundays, and holidays)<br/>
                    メール：info@bnpj.jp
                </div>
                <div class="box-description">
                    <h5>● Selling price</h5>
                    The tax-included price is displayed for each product.
                </div>
                <div class="box-description">
                    <h5>● Necessary charges other than the product price</h5>
                    Shipping fee and consumption tax added to each.
                </div>
                <div class="box-description">
                    <h5>● Payment method</h5>
                    Payment by credit card.
                </div>
                <div class="box-description">
                    <h5>● Payment time</h5>
                    Withdrawal date of each card company.
                </div>
                <div class="box-description">
                    <h5>● Postage</h5>
                    Please see the product page for details on shipping costs.
                </div>
                <div class="box-description">
                    <h5>● Product delivery method</h5>
                    Ordered products are usually delivered by courier within 1 to 2 days (※) after receiving your order.
                </div>
                <div class="box-description">
                    <h5>● Return / exchange method</h5>
                    We accept returns of products within 14 days of receipt at our expense. We do not accept exchanges for other products.
                    Please note that we will only process your return if you return all of the items you ordered at the same time.
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Main contents -->