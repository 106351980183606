import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { AlertComponent } from './alert.component';
import { EarthquakeDetailComponent } from './earthquake-detail/earthquake-detail.component';
import { VolcanoDetailComponent } from './volcano-detail/volcano-detail.component';

const   ALERTS_ROUTES: Routes = [
    { path: '', component : AlertComponent},
    { path: 'earthquake/:language/:code', component : EarthquakeDetailComponent},
    { path: 'volcano/:language/:code', component : VolcanoDetailComponent}
];
@NgModule({
    imports: [RouterModule.forChild(ALERTS_ROUTES)],
    exports: [RouterModule]
})
export class AlertRoutingModule {
}
