<!-- Page header -->
<section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>BNPJ Service Terms of Use</h2>
            </div>
        </div>
    </div>
</section>
<!-- End of Page header -->	

<!-- Main contents -->
<section class="content content-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="box-description">
                    <h3>Terms of Use Part 1 General Guidelines</h3>
                    <p>The general guidelines apply to all users who utilize the services of 3PLATZ Corporation (hereinafter referred to as “Our Company”).
                    </p>
                </div>
                <div class="box-description">
                    <h4>Chapter 1 General Provisions</h4>
                    <ol>
                        <li>
                            <dl>
                                <dt><h5>Utilization of Our Company’s Services</h5></dt>
                                <dd>A person may use Our Company’s services by giving its consent to these Terms of Use.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Warranties, Changes and Subcontracting Relating to Services</h5></dt>
                                <dd>Our Company disclaims any warranty that the contents of services provided by Our Company is free of any defects and bugs.<br>
                                    Further, Our Company may, without giving any prior notice to the users, change contents or specifications of services, or suspend or discontinue the provision thereof.<br>
                                    Our Company may subcontract all or part of the services provided by Our Company to any third party (including those located in foreign countries); provided, however, that it will be subject to the conditions that such third party is bound by the same confidential obligations which Our Company has hereunder and implements appropriate measures to protect the Personal Information.
                                    </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Restriction of Utilization of Services</h5></dt>
                                <dd>Our Company may make a condition on utilization of services, such as limiting the range of persons who may utilize the services: to the persons who have registered the BNPJ account (meaning an account for the services which Our Company provides; hereinafter referred to as the “Account”), to the persons certain years of age and older, or to the users who meet a certain requirement by passing the procedure prescribed by Our Company such as an identity verification.<br>
                                    Further, Our Company prohibits members of anti-social force (including any person who was such member at any point in its past) and their affiliates, persons who are likely to launder money or otherwise abuse services, or users who bring troubles on third parties from utilizing services.
                                    </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Registered Information of the Account</h5></dt>
                                <dd>It is the user’s obligation in the case of registering or changing the Account that (1) registering true and accurate information, and (2) correcting the registered items by the user itself as necessary so as to be updated. If the registered items do not reflect the latest information, Our Company’s services may not be available to use.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Users’ Responsibility Relating to the Account, Password, etc.</h5></dt>
                                <dd>With respect to any login via any authentication method for specifying the users which are publicized on Our Company’s website or application (including, but not limited to, any authentication by confirmation of its correspondence between the registered information and, the combination of the Account and password or the code uniquely given to each mobile phone number sent by a mobile phone carrier), Our Company deems it the utilization by such user itself. If any fee or charge (regardless of its name such as charge, fee, expenses or others with respect to the utilization of Our Company’s services, and including user’s debt which any third party has outsourced its collection to Our Company; hereinafter referred to as the “Charge”) arises from utilization of services, purchase of products, etc., Our Company will claim it to such user.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Compliance Rules on Utilizing Services</h5></dt>
                                <dd>Users are prohibited from doing any of the following acts (including any act provoking it or preparing for it) with respect to utilization of Our Company’s services.</dd>
                                <div class="box-description">
                                    <ol>
                                        <li>
                                            <dl>
                                                <dt>Any act which violates the laws and regulations of Japan or any country or region where the user is located at the time of utilization.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of posting, publishing, disclosing, providing or transmitting (hereinafter collectively referred to as “posting etc.”) of something which: violates the laws and regulations, societal norms or public policy; infringes any rights of Our Company or a third party; brings any trouble on a third party; or is otherwise inappropriate, such as the following acts etc.</dt>
                                                <dd>
                                                    <ul>
                                                        <li>Act of posting etc. of false information intentionally.</li>
                                                        <li>Act of posting etc. of same comments intentionally at many times.</li>
                                                        <li>Act of posting etc. of enquiries or complaints relating to Our Company’s services, function of such services, and accuracy of information provided by such services.</li>
                                                        <li>Act of posting etc. of something containing any information by which an individual can be identified such as a photo of principal, name of a third party, email address, address, phone number or person under recognizable condition, etc. (including, but not limited to, the cases that it is used in a name of the poster or contents of the post).</li>
                                                        <li>Act of posting etc. of something containing any advertisement, commercial message or marketing, public offices election campaign, proselytizing of certain thought or religion, or similar contents.</li>
                                                        <li>Act of posting etc. of something containing contents that grossly provokes or foments unruliness, brutality or crime on children or youths, and interferes with their healthy development.</li>
                                                        <li>Act of posting etc. of indecent information, harmful information for youths, and information relating to association with a person of opposite sex.</li>
                                                        <li>Act of posting etc. of something in which any oppressiveness or threat against any third party is found.</li>
                                                        <li>Act of posting etc. of something containing any discriminatory expression.</li>
                                                        <li>Act of posting etc. of something containing any contents which is or may be against a subject or purpose of Our Company’s services.</li>
                                                        <li>Act of posting etc. of something containing any garbled or grotesque contents.</li>
                                                        <li>Act of posting etc. of something containing any contents which intentionally leads someone to misunderstand that products or services are superior such as an act of ‘decoy’ etc.</li>
                                                        <li>Act of posting etc. of something containing any contents which defames competing products or services for the purpose of destruction of its confidence.</li>
                                                        <li>of program etc. which destroys or obstructs functions of software, hardware, etc. under other user’s use.</li>
                                                    </ul>
                                                    <p>Otherwise, act of posting etc. of something which Our Company determines that is inappropriate contents or expression for Our Company’s services.</p>
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act which destroys or obstructs functions of Our Company’s server or network.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of unduly accessing to Our Company’s computer such as a server etc., or sending a spam email, chain letter, junk email, etc.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of collecting or storing other user’s Personal Information, Personal Data (as defined in the Privacy Policy prescribed by Our Company), etc. without such user’s permission.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of utilizing services for any purpose other than the intrinsic purpose of provision of services in the light of intention of provision such as money laundering, cashing purpose, etc.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of utilizing services using other user’s Account.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of, regardless of its means, acquiring password from any third party, or disclosing or providing password to any third party.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of, relating to Our Company’s services, directly or indirectly providing benefit to anti-social force.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of which purpose is fraud.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of utilizing Our Company’s services by any process other than the process which Our Company has approved.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of modifying Our Company’s services without permission.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act of: discriminating or defaming Our Company or any third party; impairing honor, privacy, etc.; or having a risk thereof.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act which obstructs Our Company’s services or advertisement distributed by Our Company, or any services or advertisement provided on Our Company’s website and Our Company’s application.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act which infringes or may infringe portrait right, copyright, trademark right or other intellectual property right of Our Company or any third party, or any act of impersonating Our Company or any third party.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act which relates to criminal act.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any act which is likely to impair reputation of Our Company, Our Company’s affiliated company or Our Company’s alliance partner.</dt>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Any other act which violates or may violate the laws and regulations, public policy or these Terms of Use.</dt>
                                            </dl>
                                        </li>
                                    </ol>
                                </div>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Prohibition of Reuse of Our Company’s Services Etc.</h5></dt>
                                <dd>In the event that a user utilizes Our Company’s services or any data which consists them beyond purpose of provision of services, Our Company shall have a right to preclude such acts and demand the amount equivalent to the benefit which such user has acquired.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Compensation to Our Company</h5></dt>
                                <dd>In the event that Our Company has borne any cost or paid any compensation in connection with the complaint caused by a user’s act, such user shall bear the cost, compensation, etc. which Our Company has paid (including attorney fees which Our Company has paid).</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Handling of User’s Data and Contents</h5></dt>
                                <dd>Our Company shall not have the obligation to make backups of the data which users have saved to servers managed by Our Company, and each user must make backups by itself.<br>

                                    In the case it is necessary for maintenance, improvement, etc. of Our Company’s services, Our Company may duplicate the data which users have saved in the servers managed by Our Company to the extent necessary for maintenance, improvement, etc. services.<br>
                                    With respect to any contents which a user has made posting etc. on any service to which unspecified or large number of users can access such as electric bulletin board systems, copyright thereof will belong to such user or copyright holders of such contents.<br>
                                    With respect to any contents which a user has made posting etc. on Our Company’s services, copyright thereof will belong to such user, and such user must warrant that such contents are such user’s own creation and they are not involved with any third party’s copyright or other rights; provided, that in the cases that it is exceptionally necessary so such contents contain a third party’s copyrighted material etc., it must be assumed and warranted that such user processes necessary rights-handling with its own responsibility and at its own expense.<br>
                                    
                                    A user is deemed to grant Our Company a non-compensatory and non-exclusive right of utilization (including duplicating, screening, publicly transmitting, exhibiting, distributing, transferring, lending, translating, adapting and publishing) of the contents which such user has made posting etc., inside or outside of Japan with no time limit (including right to sublicense).<br>
                                    Further, such users shall not exercise a moral right of author.
                                    </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Posting of Advertisement</h5></dt>
                                <dd>Our Company may post advertisements of Our Company or any third party who has requested Our Company to post it, on the services or software which Our Company provides.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Deletion of Posting Etc., Cease of Utilization of Services, Deletion of the Account</h5></dt>
                                <dd>Our Company may, in order to properly operate the services which it provides, in the event that it falls into any of the following items, implement measures such as deleting any data or contents, denying utilization of all or part of Our Company’s services, deleting any user’s Account, or any other measures which Our Company reasonably determines it is necessary and appropriate, without any prior notice.<br>
                                    Our Company may perform identity verification which Our Company determines it is necessary to confirm that a user does not fall into any of the following items, and implement measures such as denial of access to all or part of services, cease of utilization, etc. until completion of such identity verification.<br>
                                    Further, if any user’s Account is deleted, BNPJ Balance, usage history and any and all other rights and information of such user recorded in such Account will be erased.<br>
                                    <ol>
                                        <li><dl><dt>In the event that such user violates these Terms of Use or any other matters stipulated in any terms applied to such user, or Our Company determines that there is a suspicion thereof.</dt></dl></li>
                                        <li><dl><dt>In the event that delay payment has occurred with respect to any Charge which should be paid to Our Company.</dt></dl></li>
                                        <li><dl><dt>In the event that utilization of credit cards or bank accounts designated as settlement method for the Charge in BNPJ Wallet (meaning the service which Our Company provides and by which users can register and manage their payment method, the same applies hereinafter) is suspended.</dt></dl></li>
                                        <li><dl><dt>In the event that Our Company determines that the creditworthiness of such user has worsened, such as the cases that petition to bankruptcy or civil rehabilitation is made against such user, such user made any of them by itself, etc.</dt></dl></li>
                                        <li><dl><dt>In the event that the Account has been registered or used by any anti-social force, its members or affiliates, or in the event that Our Company determines that there is a suspicion thereof.</dt></dl></li>
                                        <li><dl><dt>In the case of the passage of ten (10) years commencing on the date on which the latest change has occurred on a user’s BNPJ Balance (meaning what stipulated in these Terms of Use, Part 2, Chapter 1, Article 1, item 13); provided, however, that if such user’s Account will be deleted for this cause, Our Company will give a notice to such user in advance.</dt></dl></li>
                                        <li><dl><dt>In the event that such user passed away.</dt></dl></li>
                                        <li><dl><dt>In the event that such user utilizes more than two Accounts in order to unduly acquire the benefit given by a campaign etc.</dt></dl></li>
                                        <li><dl><dt>In the event that Our Company’s service is utilized for any wrongdoing such as money laundering etc., or in the event that Our Company determines that there is a suspicion thereof.</dt></dl></li>
                                        <li><dl><dt>In the event that the registered information is not true and accurate, such as the case that the registered information of the Account does not reflect the latest information.</dt></dl></li>
                                        <li><dl><dt>In the event that Our Company determines that it is difficult to sustain the contract between Our Company and such user, such as the case that a trust relationship between such user has lost, etc.</dt></dl></li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Disclaimers</h5></dt>
                                <dd>
                                    <ol>
                                        <li>
                                            <dl>
                                                <dt>Our Company disclaims any warranty on the following items. A user must, when it utilizes Our Company’s services, evaluate by itself a usefulness etc. of the information or services provided via Our Company’s services, and utilize it on its own responsibility.</dt>
                                                <dd>
                                                    <ul>
                                                        <li>
                                                            <dl>
                                                                <dd>Usefulness, adequateness, completeness, accuracy, safety, lawfulness, newness, etc. of all information provided via Our Company’s services (including any and all information etc. existing in a linked website managed and operated by any third party which is displayed on Our Company’s services as Our Company’s services, the same applies hereinafter).</dd>
                                                            </dl>
                                                        </li>
                                                        <li>
                                                            <dl>
                                                                <dd>Any and all matters relating to negotiation or reservation, or offer or execution of contracts by users with any third parties, etc. using information provided via Our Company’s services.</dd>
                                                            </dl>
                                                        </li>
                                                        <li>
                                                            <dl>
                                                                <dd>Our Company’s services, and products, services, information, etc. which can be acquired via Our Company’s services are suitable for a user’s request or expectations.</dd>
                                                            </dl>
                                                        </li>
                                                        <li>
                                                            <dl>
                                                                <dd>Any failure, error or interference will not occur on provision of Our Company’s services.</dd>
                                                            </dl>
                                                        </li>
                                                        <li>
                                                            <dl>
                                                                <dd>Any of email, web contents, etc. which is sent relating to Our Company’s services does not contain any harmful materials such as computer virus, etc.</dd>
                                                            </dl>
                                                        </li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl>
                                                <dt>Our Company shall never assumes any and all liability for any and all damage caused from a user’s utilization of Our Company’s services (including, but not limited to, provision of information by Our Company or any third party, etc. relating to these) or unavailability of utilization (including, but not limited to, any damage stipulated in each item of this paragraph and any and all disadvantage including any damage, emotional distress or other monetary loss which users or third parties suffered in accordance with preceding paragraph), except for Our Company’s willful misconduct or gross negligence.</dt>
                                                <dd>
                                                    Our Company shall never assumes any and all liability for any and all damage caused from a user’s utilization of Our Company’s services (including, but not limited to, provision of information by Our Company or any third party, etc. relating to these) or unavailability of utilization (including, but not limited to, any damage stipulated in each item of this paragraph and any and all disadvantage including any damage, emotional distress or other monetary loss which users or third parties suffered in accordance with preceding paragraph), except for Our Company’s willful misconduct or gross negligence.
                                                    <br>
                                                    <ul>
                                                        <li><dt>Any damage which a user suffered due to any act conducted via Our Company’s services such as downloading of contents provided by any third party, and accessing to linked website which any third party manages and operates, etc.</dt></li>
                                                        <li><dt>Any damage which a user suffered due to any wrongdoings such as an unauthorized modification to Our Company’s services, unauthorized access to any data relating to Our Company’s services, mixture of computer virus, etc. although Our Company has implemented adequate security measures.</dt></li>
                                                        <li><dt>Any damage caused by suspension, delay, discontinue, loss of data, etc. of Our Company’s server or system or Our Company’s services due to: force majeure such as natural disaster, fire, power failure , etc.; system maintenance; or failure, etc. of communication line or computer.</dt></li>
                                                        <li><dt>Any damage caused by a trouble arose between a user and a third party on utilizing services.</dt></li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <dl><dt>In the event that the contracts for utilization of Our Company’s services subject to these Terms of Use with users fall into the consumer contract stipulated in the Consumer Contract Act, the disclaimer in above (2) does not apply; in such a case, Our Company assumes liability to compensate damage within the damages ordinarily incurred from it and up to the amount of the Charge in the case of paid services (or, in the case of continuing services, equivalent to the amount for one (1) month), except for the cases that it is caused by Our Company’s willful misconduct or gross negligence.</dt></dl>
                                        </li>
                                        <li><dl><dt>With respect to any contents which a user has made posting etc., Our Company does not have any responsibility to always monitor such contents or delete certain posted contents.</dt></dl></li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Mini-Application Provided by Merchant Stores</h5></dt>
                                <dd>Users may additionally utilize the applications (hereinafter referred to as the “Mini-Application”) developed by any third party developer (hereinafter referred to as the “Mini-Application Merchant Store”) on BNPJ application. The Mini-Application will be directly provided to users by the Mini-Application Merchant Store with its name and on its own responsibility, and users will utilize it under the contract directly executed with such Mini-Application Merchant Store. Our Company never makes any warranty and assumes any responsibility on quality, trustworthiness, adequateness, etc. of the Mini-Application. Users must check terms of use of the Mini-Application prescribed by the Mini-Application Merchant Store, and utilize it on users’ own responsibility. In the event that any problem relating to user’s utilization of the Mini-Application occurs, such user must resolve it between such user and the Mini-Application Merchant Store based on the terms of use of the Mini-Application prescribed by the Mini-Application Merchant Store.<br>
                                    Further, Our Company may review contents of the Mini-Application provided by the Mini-Application Merchant Store in order to check whether it complies with the policies prescribed by Our Company or applicable laws and regulations, as needed; provided, that Our Company does not have any obligation to do so.<br>
                                    <br>
                                    Our Company reserves a right to delete or discontinue the Mini-Application which is available on BNPJ application pursuant to these Terms of Use at Our Company’s discretion without giving any notice to users.
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Conditions of Use for Individual Services</h5></dt>
                                <dd>Some specific services of Our Company may have conditions of use specific to such service together with general guidelines.<br>
                                    In the event that general guidelines stipulated in these Terms of Use, Part 1 and the conditions of use specific to each service including conditions of use for individual services stipulated in these Terms of Use, Part 2 differ, the conditions of use specific to such service will prevail over the general guidelines with respect to such services as long as it has no special provision.
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Revision on Terms of Use</h5></dt>
                                <dd>Our Company may, if it determines that it is necessary, revise these Terms of Use at any time without giving any prior notice to users; provided, that if it materially affects the active users, Our Company will take a reasonable prior notice period in advance.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Notice or Contact</h5></dt>
                                <dd>If a user desires to contact Our Company, such user must contact via an enquiries page which Our Company prepared, or by calling to the phone number or sending email to the email address which Our Company specifies.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>No Assignment of Rights and Obligations, Etc.</h5></dt>
                                <dd>A user shall not, with respect to all contracts based on these Terms of Use, assign its contractual position and all or part of rights and obligations arose from these to any third party, without Our Company’s prior written approval.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Governing Law, Jurisdiction</h5></dt>
                                <dd>These Terms of Use are governed by the laws of Japan with respect to its execution, validity and construction.
                                    <br>The Tokyo District Court has exclusive jurisdiction for the first-instance over any dispute that arises between Our Company and users caused by or in connection with Our Company’s services (including posted contents and advertisements) or software.
                                    </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Limitation of Application of Terms of Use</h5></dt>
                                <dd>In the event that any provision in these Terms of Use goes against the related laws and regulations which apply to contracts with users based on these Terms of Use, such provision will not apply to such contract with users only to such extent; provided, in such a case, it will not affect the validity of other provisions in these Terms of Use.</dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Usage</h5></dt>
                                <dd>
                                    <ol>
                                        <li><dl><dt>The contract (hereinafter referred to as the “Order Contract”) with respect to the object Product etc. (hereinafter referred to as the “Object Product etc.”) of the Order becomes effective when the User of BNPJ has made such Order of such Object Products etc. to the Merchant through BNPJ and then a notice of the Acceptance of such Order by the Merchant arrives to the User of BNPJ via BNPJ application.</dt></dl></li>
                                        <li><dl>
                                            <dt>Our Company makes the Tentative Settlement for the amount equivalent to the Charge of the Object Product etc. when the Order Contract becomes effective, and finish the settlement for such amount at the earlier of the following (Ⅰ) or (Ⅱ). By the completion of this settlement, the settlement with the Merchant is completed.</dt>
                                            <dd>
                                                <p>Ⅰ.When the User of BNPJ gives a Notice of Completion of Receipt stipulated in paragraph　2 of the succeeding Article.</p>
                                                <p>Ⅱ.When the hours have passed from the time that the Notice of Readiness of Delivery    stipulated in paragraph 1 of the succeeding Article had arrived to the User of BNPJ.</p>
                                            </dd>
                                        </dl></li>
                                    </ol>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Receipt of the Products etc.</h5></dt>
                                <dd>
                                    <ul>
                                        <li><dl><dd>In the event that the Order Contract has been executed, the User of BNPJ may receive the Object Product etc. by visiting the specified Merchant’s shop and notifying its reference number to such shop by presenting the Order screen on the Terminal Device, within one (1) hour (hereinafter referred to as the “Receipt Available Time”) from the arrival of the notice (hereinafter referred to as a “Notice of Readiness of Delivery”) by the Merchant to the effect that the Object Product etc. has been ready to be delivered.</dd></dl></li>
                                        <li><dl><dd>The User of BNPJ hall, when it receives the Object Product etc., immediately give a notice to the effect that the receipt has completed (hereinafter referred to as a “Notice of Completion of Receipt”).</dd></dl></li>
                                        <li><dl><dd>The User of BNPJ shall, in advance, consent to disposal of the Object Product etc. if it does not receive the Object Product etc. within the Receipt Available Time.</dd></dl></li>
                                    </ul>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Rescission etc.</h5></dt>
                                <dd>
                                    <ul>
                                        <li><dl><dd>The User of BNPJ may rescind the Order only until the Merchant accepts such Order subject to paragraph 18 of Article 2.</dd></dl></li>
                                        <li><dl><dd>When once the Order Contract becomes effective, the User of BNPJ and the Merchant may cancel the Order Contract only if it obtains the other party’s consent, except for the case stipulated in the succeeding paragraph.</dd></dl></li>
                                        <li><dl><dd>The User of BNPJ may, if it does not receive a Notice of Readiness of Delivery from the Merchant within the period prescribed separately from the time of the Order, cancel the Order Contract by giving a notice to the Merchant by a proper manner such as telephone etc.; provided, that Our Company never assumes any responsibility for the cancellation of the Order Contract (including, but not limited to, any trouble such as it cannot reach to the Merchant etc.), except for the cases that there is any cause attributable to Our Company.</dd></dl></li>
                                        <li><dl><dd>In the case that the delivered Object Product etc. of the Order Contract has any defect and does not conform to the terms of the contract thereof, the User of BNPJ may receive a refund or replacement in exchange for such products after contacting the Merchant within three hundred and 365 days from such delivery.</dd></dl></li>
                                    </ul>
                                </dd>
                            </dl>
                        </li>
                        <li>
                            <dl>
                                <dt><h5>Rescission of the Settlement etc.</h5></dt>
                                <dd>
                                    <ul>
                                        <li>
                                            <dl>
                                                <dt>In the case that it falls into any of the following (Ⅰ) to (Ⅲ), Our Company will rescind the reservation of BNPJ Balance applied to the Tentative Settlement with respect to such Order, in a manner prescribed by Our Company, and take the process to enable the User of BNPJ to use such balance to other transactions.</dt>
                                                <dd>
                                                    <p>I. In the case that the Merchant does not give its Acceptance for the Order stipulated in paragraph 2 of Article 18.</p>
                                                    <p>Ⅱ. In the case that the User of BNPJ rescinded the Order subject to the preceding Article, paragraph 1.</p>
                                                    <p>Ⅲ.In the case that the Merchant does not give a Notice of Readiness of Delivery after the Order Contract becomes effective even though the period prescribed separately at the time of the Order elapses (regardless whether the User of BNPJ cancels the Order Contract subject to the preceding Article, paragraph 3 or not).</p>
                                                </dd>
                                            </dl>
                                        </li>
                                        <li><dl>
                                            <dt>In the case of the preceding Article, paragraph 2, the Merchant shall preform a rescission process within the period prescribed by Our Company on its own responsibility, and Our Company shall not assume any responsibility for such rescission process; provided, that this will not apply for the cases of Our Company’s willful misconduct or gross negligence.</dt>
                                        </dl></li>
                                    </ul>
                                </dd>
                            </dl>
                        </li>
                    </ol>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Main contents -->