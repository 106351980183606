<ng-container *ngIf="hasResponse; else noApiResponse">
<!-- Page header -->
<section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>Services</h2>
            </div>
        </div>
    </div>
</section>
<!-- End of Page header -->

<!-- Main contents -->

    <section class="content content-section content-listing">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    
                    <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                        <div class="head-tabs">
                            <div class="row">
								<div class="col-md-8 col-lg-9">
									<ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <ng-container *ngIf="categoryList.length > 0">
                                            <ng-container *ngFor="let category of categoryList;let i=index; trackBy:trackById;">
                                                <li class="nav-item" (click)="getJapaneseServicesByCategory(category, true, true)">
                                                    <a class="nav-link" [ngClass]="activeCategory == category.category_name ? 'active':''" id="tab-1" data-toggle="tab" href="#tab1" role="tab" attr.aria-controls="tab1" attr.aria-selected="true">{{category.category_name}}</a>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
								</div>
								<div class="col-md-4 col-lg-3">
									<form [formGroup]="filterForm">
                                        <!-- <input class="form-control" name="filterTerm" [(ngModel)]="filterTerm" type="search" placeholder="Search" aria-label="Search"> -->
                                        <input class="form-control" formControlName="searchText" type="text" placeholder="Search" aria-label="Search">
                                    </form>
								</div>
							</div>
                        </div>
                        <div class="tab-content" id="myTabContent">
                            <ng-container *ngIf="categoryList.length > 0">
                                <!-- <ng-container *ngFor="let category of categoryList;let i=index;"> -->
                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                        <ng-container *ngIf="filteredServicesList.length > 0; else noData">
                                            <div class="row">	
                                                    <!-- <ng-container *ngFor="let service of filteredServicesList | filterPartnerServices: filterTerm"> -->
                                                    <ng-container *ngFor="let service of filteredServicesList; trackBy:trackById;">
                                                        <ng-container *ngIf="service === -1; else hasFilteredData">
                                                            <h6 class="col-lg-12 text-center">
                                                                Sorry, but nothing matched your search terms. Please try again with some different keywords.
                                                            </h6>
                                                        </ng-container>
                                                        <ng-template #hasFilteredData>
                                                            <ng-container *ngIf="service == -2; else hasAPIResponseData">
                                                                <h6 class="col-lg-12 text-center">
                                                                    Data unavailable at the moment.
                                                                </h6>
                                                            </ng-container>
                                                            <ng-template #hasAPIResponseData>
                                                                <div class="col-md-6 col-lg-3">
                                                                    <img [imagepreloader]="assetBaseUrl + service.company_banner_image" [targetSection]="'services'" [routerLink]="['/partner-services/' +service.category +'/' + service.id]"  class="img-fluid">
                                                                    <!-- <img src="img/wp2504321.jpg" class="img-fluid">								 -->
                                                                    <div class="service-items mt-2 d-flex">
                                                                        <img [imagepreloader]="assetBaseUrl + service.company_logo" [targetSection]="'sliderslogo'" >
                                                                        <!-- <img src="img/comp-icons1.png">	 -->
                                                                        <a [routerLink]="['/partner-services/' + service.category + '/' + service.id]">{{service.service_product_name}}</a>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </ng-template>
                                                    </ng-container>
                                            </div>
                                        </ng-container>
                                        <ng-template #noData>
                                            <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                                                <div class="tab-content" id="myTabContent">
                                                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                                                        <div class="row">	
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>	
                                                                                    <p>Nepal Telecom Test</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>				
                                                                </div>						
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </ng-template>
                                    </div>
                                <!-- </ng-container> -->
                            </ng-container>					
                        </div>						
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</ng-container>
<!-- End of Main contents -->

<ng-template #noApiResponse>
    <!-- Page header -->
	<section>
		<div class="container-fluid loading-skeleton">
			<div class="row">
				<div class="col-md-12 no-padding">
					<div class="s-banner inner">
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Page header -->

	<!-- Main contents -->
	<section class="content content-section loading-skeleton">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
						<div class="head-tabs">
							<div class="row">
								<div class="col-md-8 col-lg-9">
									<ul class="nav nav-tabs" id="myTab" role="tablist">
										<li class="nav-item">
											<p>Featured</p>
										</li>
										<li class="nav-item">
											<p>Nep Telecom</p>
										</li>
										<li class="nav-item">
											<p>Featur</p>
										</li>
										<li class="nav-item">
											<p>Telecom</p>
										</li>
										<li class="nav-item">
											<p>Featur</p>
										</li>
										<li class="nav-item">
											<p>Nep Telecom</p>
										</li>
										<li class="nav-item">
											<p>Shop</p>
										</li>
									</ul>
								</div>
								<div class="col-md-4 col-lg-3">
									<form>
										<input class="form-control" type="search" placeholder="Search" aria-label="Search">
									</form>
								</div>
							</div>
							
						</div>
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="tab1" role="tabpanel">
								<div class="row">	
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>	
											<p>Nepal Telecom Test</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
                                    <div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
								</div>
							</div>				
						</div>						
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Main contents -->
</ng-template>