import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardMaskPipe } from './card-mask.pipe';


@NgModule({
  declarations: [CreditCardMaskPipe],
  imports: [
    CommonModule
  ],
    exports:[
        CreditCardMaskPipe
    ]
})
export class CreditCardMaskModule { }
