import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndexService {

  private baseUrl = environment.apiUrl;

  private httpHeaders =  new HttpHeaders({
    'cache': 'true',
    'cache_group': 'home_page'
  });

  constructor(private http: HttpClient) { }

  getHomePageContents() {
    return this.http.get(this.baseUrl + '/home_api/', {headers: this.httpHeaders});
  }
}
