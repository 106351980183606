import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadBalanceRoutingModule } from './load-balance-routing.module';
import { LoadBalanceComponent } from './load-balance.component';
import { CreditCardMaskModule } from '../utils/pipes/card-mask.module';
import { CardFormatterModule } from '../utils/directives/cardformatter.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        LoadBalanceRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CreditCardMaskModule,
        CardFormatterModule,
        NgxMaskModule
    ],
    declarations: [
        LoadBalanceComponent
    ],
    providers: [
        
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})

export class LoadBalanceModule {
}
