import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-law',
  templateUrl: './transaction-law.component.html',
  styleUrls: ['./transaction-law.component.css']
})
export class TransactionLawComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
