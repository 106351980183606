<ng-container *ngIf="hasResponse; else noApiResponse">
   <!-- Page header -->
    <section class="page-header bg-header-next">
        <div class="container">
            <div class="row">
                <div class="col-md-12 page-title text-center">
                    <h2>Japanese Manner</h2>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Page header -->

<!-- Main contents -->
    <section class="content content-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-manner inner-tabs nav-tabs-wrapper">
                        
                        <div class="head-tabs">
                            <div class="row">
                                <div class="col-md-8 col-lg-9">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <ng-container *ngIf="categoryList.length > 0">
                                            <ng-container *ngFor="let category of categoryList;let i=index; trackBy:trackById;">
                                                <li class="nav-item" (click)="getJapaneseMannersByCategory(category, true, true)">
                                                    <a class="nav-link" [ngClass]="activeCategory == category.category_name ? 'active':''" id="tab-1" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">{{category.category_name}}</a>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <form [formGroup]="filterForm">
                                        <!-- <input class="form-control" name="filterTerm" [(ngModel)]="filterTerm" type="search" placeholder="Search" aria-label="Search"> -->
                                        <input class="form-control" formControlName="searchText" type="search" placeholder="Search" aria-label="Search">
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content" id="myTabContent">
                            <ng-container *ngIf="categoryList.length > 0">
                                <!-- <ng-container *ngFor="let category of categoryList;let i=index;"> -->
                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                        <ng-container *ngIf="filteredMannerList.length > 0; else noData">
                                            <div class="row">
                                            
                                                <!-- <ng-container *ngFor="let manner of filteredMannerList | filterJapaneseManners: filterTerm"> -->
                                                <ng-container *ngFor="let manner of filteredMannerList; trackBy:trackById;">
                                                    <ng-container *ngIf="manner === -1; else hasFilteredData">
                                                        <h6 class="col-lg-12 text-center">
                                                            Sorry, but nothing matched your search terms. Please try again with some different keywords.
                                                        </h6>
                                                    </ng-container>
                                                    <ng-template #hasFilteredData>
                                                        <ng-container *ngIf="manner == -2; else hasAPIResponseData">
                                                            <h6 class="col-lg-12 text-center">
                                                                Data unavailable at the moment.
                                                            </h6>
                                                        </ng-container>
                                                        <ng-template #hasAPIResponseData>
                                                            <!-- <ng-container *ngIf="category.category_name == manner.category.category_name || category.category_name == 'All' || (category.category_name == 'Featured' && manner.is_featured == true)"> -->
                                                                <ng-container *ngIf="!manner.is_youtube; else youtubeJapaneseManner">
                                                                    <div class="col-lg-3 col-md-6">
                                                                        <img [imagepreloader]="assetBaseUrl + manner.image" [targetSection]="'jpmanners'" [routerLink]="['/japanese-manners/'  + manner.category.category_name + '/' + manner.category.id + '/manner/' + manner.id]" class="img-fluid jpmanner-list-img">								
                                                                        <div class="items mt-3">
                                                                            <p class="link" [routerLink]="['/japanese-manners/' + manner.category.category_name + '/' + manner.category.id + '/manner/' + manner.id]">{{manner.title}}</p>
                                                                            <a class="btn-gray-outline link" (click)="getJapaneseMannersByCategory(manner.category, true, true)">{{manner.category.category_name}}</a>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #youtubeJapaneseManner>
                                                                    <div class="col-lg-3 col-md-6">
                                                                        <iframe width="auto" height="170" [src]="manner.description | safeResourceUrl" title="{{manner.title}}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                                        <p class="link" [routerLink]="['/japanese-manners/' + manner.category.category_name + '/' + manner.category.id + '/manner/' + manner.id]">{{manner.title}}</p>
                                                                        <a class="btn-gray-outline link" (click)="getJapaneseMannersByCategory(manner.category, true, true)">{{manner.category.category_name}}</a>
                                                                    </div>
                                                                </ng-template>
                                                            <!-- </ng-container> -->
                                                        </ng-template>
                                                    </ng-template>
                                                    
                                                </ng-container>
                                            
                                            </div>
                                        </ng-container>
                                        <ng-template #noData>
                                            <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                                                <div class="tab-content" id="myTabContent">
                                                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                                                        <div class="row">	
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>	
                                                                                    <p>Nepal Telecom Test</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6 col-lg-3">
                                                                                <div class="bg-img"></div>								
                                                                                <div class="service-items mt-3 d-flex">
                                                                                    <div class="rounded-img"></div>
                                                                                    <p>Nepal Telecom</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>				
                                                                </div>						
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </ng-template>
                                    </div>
                                <!-- </ng-container> -->
                            </ng-container>          
                        </div>	
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

<!-- End of Main contents -->
</ng-container>

<ng-template #noApiResponse>
    <!-- Page header -->
	<section>
		<div class="container-fluid loading-skeleton">
			<div class="row">
				<div class="col-md-12 no-padding">
					<div class="s-banner inner">
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Page header -->

	<!-- Main contents -->
	<section class="content content-section loading-skeleton">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
						<div class="head-tabs">
							<div class="row">
								<div class="col-md-8 col-lg-9">
									<ul class="nav nav-tabs" id="myTab" role="tablist">
										<li class="nav-item">
											<p>Featured</p>
										</li>
										<li class="nav-item">
											<p>Nep Telecom</p>
										</li>
										<li class="nav-item">
											<p>Featur</p>
										</li>
										<li class="nav-item">
											<p>Telecom</p>
										</li>
										<li class="nav-item">
											<p>Featur</p>
										</li>
										<li class="nav-item">
											<p>Nep Telecom</p>
										</li>
										<li class="nav-item">
											<p>Shop</p>
										</li>
									</ul>
								</div>
								<div class="col-md-4 col-lg-3">
									<form>
										<input class="form-control" type="search" placeholder="Search" aria-label="Search">
									</form>
								</div>
							</div>
							
						</div>
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="tab1" role="tabpanel">
								<div class="row">	
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>	
											<p>Nepal Telecom Test</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
									<div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
                                    <div class="col-md-6 col-lg-3">
										<div class="bg-img"></div>								
										<div class="service-items mt-3 d-flex">
											<div class="rounded-img"></div>
											<p>Nepal Telecom</p>
										</div>
									</div>
								</div>
							</div>				
						</div>						
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Main contents -->
</ng-template>