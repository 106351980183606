import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[sliderimagepreloader]',
  host: {
    '[attr.style]': 'finalImage'    // the attribute of the host element we want to update. in this case, <img 'src' />
  }
})
export class SliderImagepreloaderDirective implements OnInit{

  @Input('sliderimagepreloader') targetSource!: string;

  downloadingImage: any; // In class holder of remote image
  finalImage: any; // property bound to our host attribute.
  defaultImage = 'background-image: url(assets/img/default_banners/default-banner.svg)';

  constructor() { }

  // ngOnInit is needed to access the @inputs() variables. these aren't available on constructor()
  ngOnInit() {
    // First set the final image to some default image while we prepare our preloader:
    this.finalImage = this.defaultImage;

    this.downloadingImage = new Image();  // create image object
    this.downloadingImage.onload = () => { // Once image is completed, console.log confirmation and switch our host attribute
      this.finalImage = 'background-image: url(' + this.targetSource + ')';  // do the switch 😀
    }
    this.downloadingImage.onerror = () => {
      this.finalImage = this.defaultImage; 
    }
    // Assign the src to that of some_remote_image_url. Since its an Image Object the
    // on assignment from this.targetSource download would start immediately in the background
    // and trigger the onload()
    this.downloadingImage.src = this.targetSource;
  }

}
