import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../login/services/authentication.service';

@Component({
  selector: 'app-verify-email-status',
  templateUrl: './verify-email-status.component.html',
  styleUrls: ['./verify-email-status.component.css']
})
export class VerifyEmailStatusComponent implements OnInit, OnDestroy {

  private email: string = '';
  private activation_code = '';
  verifiedStatus = 'pending';
  showResendCodeLoader: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private authenticationDataService: AuthenticationService, private toastr: ToastrService) {
    this.email = atob(this.activatedRoute.snapshot.paramMap.get('base64EncodedEmail') + '');
    this.activation_code = this.activatedRoute.snapshot.paramMap.get('activation_code') + '';
  }

  ngOnInit(): void {
    this.verifyEmail();
  }

  resentVerificationCode() {
    this.showResendCodeLoader = true;
    let resendCodeData = {
      email: this.email
    }
    this.authenticationDataService.requestEmailActivationcode(resendCodeData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.showResendCodeLoader = false;
          this.toastr.clear();
          this.toastr.success(res.success, 'Success');
          this.router.navigate(['/verify-email/' + btoa(this.email)]);
        }, (err: any) => {
          this.showResendCodeLoader = false;
          this.toastr.clear();
          const errors = err.error.error;
          Object.keys(errors).forEach(key => {
            this.toastr.error(errors[key][0], key)
          });
          // this.toastr.error(err.error.error[Object.keys(err.error.error)[0]][0], 'Error');
        });
  }

  verifyEmail() {
    let emailVerificationData = {
      email: this.email,
      code: this.activation_code
    }
    this.authenticationDataService.requestEmailVerification(emailVerificationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.verifiedStatus = 'success';
        console.log("Response ", res);
      }, (err: any) => {
        this.verifiedStatus = 'error';
        console.log("Error ", err);
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
    ngOnDestroy(): any {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

}
