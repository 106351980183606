import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

    private loginStatus = new BehaviorSubject(false);
    currentLoginStatus = this.loginStatus.asObservable();

    private news = new BehaviorSubject(false);
    newsDetails = this.loginStatus.asObservable();

    private fetchResumeStatus = new BehaviorSubject(false);
    currentFetchResumeStatus = this.fetchResumeStatus.asObservable();

    private japaneseMannerCategory = new BehaviorSubject(false);
    activeJapaneseMannerCategory = this.japaneseMannerCategory.asObservable();

    private partnerServiceCategory = new BehaviorSubject(false);
    activePartnerServiceCategory = this.partnerServiceCategory.asObservable();

    private alertTab = new BehaviorSubject(false);
    activeAlertTab = this.alertTab.asObservable();

    constructor() { }

    changeLoginStatus(message: boolean) {
        this.loginStatus.next(message)
    }

    changeNewsDetails(clickedNews: any) {
      sessionStorage.setItem('news', JSON.stringify(clickedNews));
      this.news.next(clickedNews)
    }

    changeFetchResumeStatus(resumeStatus: any) {
      this.fetchResumeStatus.next(resumeStatus)
    }

    changeJapaneseMannerActiveCategory(category_name: any) {
      this.japaneseMannerCategory.next(category_name)
    }

    changePartnerServiceActiveCategory(category_name: any) {
      this.partnerServiceCategory.next(category_name)
    }

    changeAlertActiveTab(tab: any) {
      this.alertTab.next(tab)
    }
}
