<!-- Page header -->
<!-- <section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>{{earthquakeDetail?.epicenter_value}}</h2>
            </div>
            <ul class="breadcrumb">
                <li><a [routerLink]="['/alert']">Alerts</a></li>
                <li>Earthquake info</li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End of Page header -->

<ng-container *ngIf="hasEarthquakeResponse; else fetchingEarthquakeDetail">
    <!-- Main contents -->
    <section class="bg-breadcrumb">
		<div class="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><a [routerLink]="['/alert']">Alerts</a></li>
                    <li class="breadcrumb-item breadcrumb-link" (click)="redirectUsersToListingPage('earthquake-tab')"><a class="link">Earthquakes</a></li>
					<li class="breadcrumb-item active" aria-current="page">{{earthquakeDetail?.epicenter_value}}</li>
				</ol>
			</nav>
		</div>
	</section>
    <!-- <section class="content content-section no-breadcrump-header"> -->
    <section class="content content-with-breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="alert-details">
                        <agm-map [latitude]="earthquakeDetail?.lat" [longitude]="earthquakeDetail?.lon" [zoom]="8">
                            <agm-marker [latitude]="earthquakeDetail?.lat" [longitude]="earthquakeDetail?.lon"></agm-marker>
                        </agm-map>
                        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d831438.4633291857!2d139.2093851493426!3d35.50906267231795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x605d1b87f02e57e7%3A0x2e01618b22571b89!2sTokyo%2C%20Japan!5e0!3m2!1sen!2snp!4v1618467650824!5m2!1sen!2snp" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                        <div class="mt-3 inner d-flex">
                            <strong>{{earthquakeDetail?.magnitude_value}}</strong>
                            <div class="mt-2 ml-3">
                                <p>{{earthquakeDetail?.epicenter_value}}</p>
                                <span>{{earthquakeDetail?.tsunami_message}}</span>
                            </div>
                        </div>
                        <hr class="my-2 pb-3">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
                                <img src="assets/img/icon-clock.svg">
                                <div class="ml-2">
                                    <strong class="d-block">Date & Time</strong>
                                    <span>{{earthquakeDetail?.occurred_value | dateAgo}}</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
                                <img src="assets/img/icon-intensity.svg">
                                <div class="ml-2">
                                    <strong class="d-block">Max Intensity</strong>
                                    <span>{{earthquakeDetail?.max_value}}</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
                                <img src="assets/img/icon-magnitude.svg">
                                <div class="ml-2">
                                    <strong class="d-block">Maginitude</strong>
                                    <span>{{earthquakeDetail?.magnitude_value}}</span>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
                                <img src="assets/img/icon-depth.svg">
                                <div class="ml-2">
                                    <strong class="d-block">Epicenter Depth</strong>
                                    <span>{{earthquakeDetail?.depth_value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 right-card">
                    <div class="recent">
                        <h4 class="d-block mb-2">Recent Earthquake</h4>
                        <ng-container *ngIf="earthquakeList != undefined && earthquakeList.length > 0; else loader">
                            <ng-container *ngFor="let earthquake of earthquakeList;  trackBy:trackAlertByCode;">
                                <ng-container *ngIf="earthquake == -1; else hasEarthquakeData">
                                    <h6 class="col-lg-12">
                                      Data Unavailable.
                                    </h6>
                                </ng-container>
                                <ng-template #hasEarthquakeData>
                                    <div class="alerts-item d-flex justify-content-between">
                                        <div class="l-detail d-flex">
                                            <img src="assets/img/icon-earthquake.svg">
                                            <div class="alert-wrap">
                                                <a class="link" (click)="changeEarthquakeDetails(earthquake)" [routerLink]="['/earthquake/en/' + earthquake.code]">{{ earthquake.epicenter_value }}</a>
                                                <div class="mt-1">
                                                    <item>
                                                        <span>{{ earthquake.occurred_value | date : 'yyyy-MM-dd' }}</span>
                                                        <span> {{ earthquake.occurred_value | date : 'HH:mm' }}</span>
                                                    </item>
                                                    <span>{{earthquake.magnitude_title}} {{earthquake.magnitude_value}}</span>
                                                </div>
                
                                            </div>
                                        </div>
                                        <div class="r-info text-center">
                                            <strong class="d-block mb-2">{{earthquake.magnitude_value}}</strong>
                                            <span>{{earthquake.occurred_value | dateAgo}}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ng-container>
                        <ng-template #loader>
                            <section class="content content-section detail-section loading-skeleton avoid-skeleton-padding">
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
                                        <div class="alert-wrap">
                                            <a href="#">Northern Wakayama Pref</a>
                                            <div class="mt-1">
                                                <item>
                                                    <span>2021/03/24</span>
                                                    <span> 06:46</span>
                                                </item>
                                                <span>Maximum seismic intensity 4.2</span>
                                            </div>
        
                                        </div>
                                    </div>
                                    <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                    </div>
                                </div>
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
                                        <div class="alert-wrap">
                                            <a href="#">Northern Wakayama Pref</a>
                                            <div class="mt-1">
                                                <item>
                                                    <span>2021/03/24</span>
                                                    <span> 06:46</span>
                                                </item>
                                                <span>Maximum seismic intensity 4.2</span>
                                            </div>
        
                                        </div>
                                    </div>
                                    <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                    </div>
                                </div>
                                <div class="alerts-item d-flex justify-content-between">
                                    <div class="l-detail d-flex">
                                        <div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
                                        <div class="alert-wrap">
                                            <a href="#">Northern Wakayama Pref</a>
                                            <div class="mt-1">
                                                <item>
                                                    <span>2021/03/24</span>
                                                    <span> 06:46</span>
                                                </item>
                                                <span>Maximum seismic intensity 4.2</span>
                                            </div>
        
                                        </div>
                                    </div>
                                    <div class="r-info text-center">
                                        <strong class="d-block mb-2">4.2</strong>
                                        <span>10h ago</span>
                                    </div>
                                </div>
                            </section>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Main contents -->
</ng-container>
<ng-template #fetchingEarthquakeDetail>
    <!-- Main contents -->
	<section class="content content-section detail-section loading-skeleton no-breadcrump-header">
		<div class="container">
			<div class="row">
				<div class="col-md-8">
					<div class="inner s-content" style="height: 400px;"></div>
					<div class="alert-details">
						<div class="mt-3 inner d-flex">
							<strong>4.2</strong>
							<div class="mt-2 ml-3">
								<p>Richer Scale</p>
								<span>No tsunami warnings announced from this earthquake</span>
							</div>
						</div>
						<hr class="my-2 pb-3">
						<div class="row">
							<div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
								<img src="assets/img/icon-clock.svg">
								<div class="ml-2">
									<strong class="d-block">Date & Time</strong>
									<span>10h ago</span>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
								<img src="assets/img/icon-intensity.svg">
								<div class="ml-2">
									<strong class="d-block">Max Intensity</strong>
									<span>1</span>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
								<img src="assets/img/icon-magnitude.svg">
								<div class="ml-2">
									<strong class="d-block">Maginitude</strong>
									<span>4.2</span>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 d-flex item-alerts align-items-start">
								<img src="assets/img/icon-depth.svg">
								<div class="ml-2">
									<strong class="d-block">Epicenter Depth</strong>
									<span>50Km</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 right-card">
					<div class="recent">
						<h4 class="d-block mb-2">Recent Earthquake</h4>
						<div class="alerts-item d-flex justify-content-between">
							<div class="l-detail d-flex">
								<div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
								<div class="alert-wrap">
									<a href="#">Northern Wakayama Pref</a>
									<div class="mt-1">
										<item>
											<span>2021/03/24</span>
											<span> 06:46</span>
										</item>
										<span>Maximum seismic intensity 4.2</span>
									</div>

								</div>
							</div>
							<div class="r-info text-center">
								<strong class="d-block mb-2">4.2</strong>
								<span>10h ago</span>
							</div>
						</div>
						<div class="alerts-item d-flex justify-content-between">
							<div class="l-detail d-flex">
								<div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
								<div class="alert-wrap">
									<a href="#">Northern Wakayama Pref</a>
									<div class="mt-1">
										<item>
											<span>2021/03/24</span>
											<span> 06:46</span>
										</item>
										<span>Maximum seismic intensity 4.2</span>
									</div>

								</div>
							</div>
							<div class="r-info text-center">
								<strong class="d-block mb-2">4.2</strong>
								<span>10h ago</span>
							</div>
						</div>
						<div class="alerts-item d-flex justify-content-between">
							<div class="l-detail d-flex">
								<div class="s-default-img s-round mr-3" style="height: 70px; width: 110px"></div>
								<div class="alert-wrap">
									<a href="#">Northern Wakayama Pref</a>
									<div class="mt-1">
										<item>
											<span>2021/03/24</span>
											<span> 06:46</span>
										</item>
										<span>Maximum seismic intensity 4.2</span>
									</div>

								</div>
							</div>
							<div class="r-info text-center">
								<strong class="d-block mb-2">4.2</strong>
								<span>10h ago</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- End of Main contents -->
</ng-template>
