import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import { AlertRoutingModule } from './alert-routing.module';
import { AlertComponent } from './alert.component';
import { EarthquakeDetailComponent } from './earthquake-detail/earthquake-detail.component';
import { VolcanoDetailComponent } from './volcano-detail/volcano-detail.component';
import { AlertService } from './service/alert.service';
import { DateAgoModule } from '../utils/pipes/date-ago.module';
import { ImagepreloadermoduleModule } from '../utils/directives/imagepreloader.module';
import { AgmCoreModule } from '@agm/core';

@NgModule({
    imports: [
        CommonModule,
        AlertRoutingModule,
        DateAgoModule,
        ImagepreloadermoduleModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD_bOw8sRO6LD-voTYPn2BFrjch4pYdYcA',
            libraries: ['places']
          }),
    ],
    declarations: [
        AlertComponent,
        EarthquakeDetailComponent,
        VolcanoDetailComponent
    ],
    providers: [
        AlertService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})

export class AlertModule {
}
