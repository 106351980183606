<!-- Page header -->
<section class="page-header bg-header-next">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title text-center">
                <h2>Privacy Policy</h2>
            </div>
        </div>
    </div>
</section>
<!-- End of Page header -->	

<!-- Main contents -->
<section class="content content-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="box-description">
                    <h4>【Consent clause regarding the handling of personal information】</h4>
                </div>
                <div class="box-description">
                    <h5>Article 1（Consent to collect and use personal information</h5>
                    <p>(1）BNPJ users (hereinafter referred to as "users") agree that the Company will collect and use the user's personal information (including acquisition from the Internet, etc., the same shall apply hereinafter) for the following purposes.</p>
                    <ol>
                        <li>To provide our services and improve their quality</li>
                        <li>For security of our service and prevention of unauthorized use</li>
                        <li>To announce information about our services and services of partner companies</li>
                    </ol>
                    <p>(2) Users agree that we will collect and use the following information:</p>
                    <ol>
                        <li>Name, gender, date of birth, nationality, place of origin, residence card number, status of residence, period of stay, postal code, address, telephone number, mobile phone number, e-mail address, occupation, driver's license, passport, etc. , Information about the device and browser used.</li>
                        <li>Regarding the BNPJ app, application date, application route (information on application channel, application destination company, etc.), usage start date, planned travel country, scheduled departure date, user ID, etc., entry information for receiving services such as point exchange, etc. "Contract information".</li>
                        <li>"Transaction information" regarding the start date of use of the BNPJ app and whether or not it will continue to be used.</li>
                        <li>Usage information of this service and related services such as cookies, access logs, information on service usage status, payment information, location information, device information, information on services operated by third parties, etc.</li>
                    </ol>
                    <p>(3）The user should agree to check the contents of the certificate such as driver's license, passport, residence card, etc. in order to confirm that the person who applies for the use of the BNPJ application or uses the BNPJ application is the user himself / herself. Users agree to request a copy of the residence card, etc. (including the case of confirming the address after starting to use the BNPJ app).</p>
                    <p>(4）The user agrees that we will collect the usage of the BNPJ App and the negotiations at a later date for the trail after the fact.</p>
                    <p>(5）The Company shall retain personal information for 5 years after the end of use of the BNPJ App. However, if other laws and regulations have a retention period, the relevant laws and regulations will apply.</p>
                </div>
                <div class="box-description">
                    <h5>Article 2（Consent to use / provide personal information for purposes other than application</h5>
                    <p>（1）The user agrees that the Company will use the personal information of Article 1 (2) ① to ④ to the extent necessary to achieve the following purposes of use in the business described in the Articles of Incorporation of the Company.</p>
                    <ol>
                        <li>When using for notification of new product information and related after-sales service.</li>
                        <li>When used for market research and product development.</li>
                        <li>When used for advertising in writing or other media (including telephone), sales promotion activities, business information, solicitation regarding loan contracts. The specific business content of our company is posted on our website.</li>
                    </ol>
                    <p>（2）Users, The company can use Article 1 (2) ① to ④ for the purpose of carrying out advertising, sales promotion activities, etc. of the company on behalf of the company's parent company, subsidiaries, group companies, and partner companies. Users agree to use your personal information.</p>
                </div>
                <div class="box-description">
                    <h5>Article 3 (Agreement to provide personal information to partner companies)</h5>
                    <p>The users should agree to propose and guide various products and services in the affiliated companies, we will provide the personal information of Article 1 (2) ① to ④ on the condition that the partner company is clearly indicated and consent is obtained.</p>
                </div>
                <div class="box-description">
                    <h5>Article 4 (Agreement on deposit of personal information, etc.)</h5>
                    <p>Users agree to deposit the personal information collected in accordance with Article 1 (2) with the trustee when the Company outsources paperwork (computer work, payment settlement work, transfer remittance work and related work, etc.) to a third party, the user shall take measures to protect personal information before the Company takes measures to protect personal information.</p>
                </div>
                <div class="box-description">
                    <h5>Article 5 (Disclosure / correction / deletion of personal information)</h5>
                    <p>(1）The user may request the disclosure of personal information about himself / herself collected by the Company as provided in the Act on the Protection of Personal Information. If you request disclosure from us, please contact the window described in Article 10 or each center. We will answer the details of the disclosure request procedure (reception desk, reception method, required documents, fees, etc.). The disclosure request procedure is posted on our website.</p>
                    <p>(2）If the disclosure request to the Company based on the preceding paragraph reveals that the content of the personal information is not true, the Company shall promptly correct or delete it.</p>
                </div>
                <div class="box-description">
                    <h5>Article 6 (Measures in case of disagreement with this agreement)</h5>
                    <p>Users, etc. do not wish to enter the necessary items for using the BNPJ app (matters to be entered when applying to use the BNPJ app), or all the contents of this clause except Articles 2 and 3. Or, if you cannot approve a part, you agree that we may refuse to use the BNPJ app.</p>
                </div>
                <div class="box-description">
                    <h5>Article 7 (Request for suspension of use)</h5>
                    <p>Even if we use and provide personal information within the scope of consent under Articles 2 and 3, the user requests to suspend the use for the purposes of Articles 2 and 3. In that case, the Company shall take measures to suspend the use for the relevant purpose thereafter.</p>
                </div>
                <div class="box-description">
                    <h5>Article 8 (Agreement when use of BNPJ app is disapproved)</h5>
                    <p>Even if the use of the BNPJ app is not approved, the user will keep the information of the fact that he / she applied under this clause regardless of the reason why the Company declined the application for the use of the BNPJ app based on Article 1. I agree to use it for a period of time. In such a case, the personal information collected by the Company at the time of application shall be destroyed after being stored by the Company for a certain period of time.</p>
                </div>
                <div class="box-description">
                    <h5>Article 9 (Change of clause)</h5>
                    <p>This clause may be changed to the extent necessary by the procedure stipulated by law.</p>
                </div>
                <div class="box-description">
                    <h5>Article 10 (Contact for inquiries regarding personal information)</h5>
                    <p>For personal information, the Personal Information Management Office will be the responsible department. The contact point for requesting disclosure, deletion, and correction of personal information and the contact information for inquiries regarding personal information are as follows.</p>
                    <p>Address: 1-3-2 Shinkawa, Chuo-ku, Tokyo NAX Building 9th floor</p>
                    <p>Department in charge: 3PLATZ Co., Ltd. Customer Service Office</p>
                    <p>phone number：03-6222-8045</p>
                    <p>Email:info@bnpj.jp</p>
                    <p>URL：https://bnpj.jp</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End of Main contents -->