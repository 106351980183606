<div class="right-card-box mt-3 mt-md-0">
    <h4 class="d-block mb-3">Download Resume</h4>
    <div id="accordion">
        <div class="card">
            <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><i class="fa" aria-hidden="true"></i>English</button>
                </h5>
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <!-- <a href="#"><i class="fas fa-envelope mr-1"></i>Email Resume</a> -->
                    <a class="link resume-download-link" (click)="downloadResume('en')">
                        <i class="fas fa-download mr-1 p-0" *ngIf="resumeDownloadRequested != 'en'"></i>
                        <i class="fas fa-spinner fa-spin mr-1 p-0" *ngIf="resumeDownloadRequested == 'en'"></i>
                           Download pdf
                    </a>
                    <!-- <a  class="link" (click)="shareResume('en')"><i class="fas fa-share mr-1" *ngIf="resumeLinkShareRequested != 'en'"></i><i class="fas fa-spinner fa-spin" *ngIf="resumeLinkShareRequested == 'en'"></i>Link Share</a> -->
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <i class="fa" aria-hidden="true"></i>Japanese</button>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                        <div class="card-body">
                            <!-- <a href="#"><i class="fas fa-envelope mr-1"></i>Email Resume</a> -->
                            <a class="link resume-download-link" (click)="downloadResume('jp')">
                                <i class="fas fa-download mr-1 p-0" *ngIf="resumeDownloadRequested != 'jp'"></i>
                                <i class="fas fa-spinner fa-spin mr-1 p-0" *ngIf="resumeDownloadRequested == 'jp'"></i>   
                                Download pdf
                            </a>
                            <!-- <a class="link" (click)="shareResume('jp')"><i class="fas fa-share mr-1" *ngIf="resumeLinkShareRequested != 'jp'"></i><i class="fas fa-spinner fa-spin" *ngIf="resumeLinkShareRequested == 'jp'"></i>Link Share</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>