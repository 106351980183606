import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/utils/data-share.service';


// import * as countries from '../../../assets/data/countries.json';
// import * as nepalDistricts from '../../../assets/data/districts_nepal.json';
import { ResumeService } from '../service/resume.service';

@Component({
  selector: 'app-jp-resume',
  templateUrl: './jp-resume.component.html',
  styleUrls: ['./jp-resume.component.css']
})
export class JpResumeComponent implements OnInit, OnDestroy {

  @Input() resumeData: any;
  @Input() jpJpPrefectures : any;
  @Input() npProvinces: any;
  @Input() uuid: string = '';
  @Input() userDetails: any;

  private ngUnsubscribe: Subject<any> = new Subject();
  // uuid: string = '';
  resumeSectionUpdateRequest: string = '';
  resumeUpdateRequested: boolean = false;
  jpResumeBasicInfoForm!: FormGroup;
  jpResumeCurrentAddressForm!: FormGroup;
  jpResumePermanentAddressForm!: FormGroup;
  jpResumeAcademicForm!: FormGroup;
  jpResumeExperienceForm!: FormGroup;
  jpResumeQualificationForm!: FormGroup;
  jpResumeOthersForm!: FormGroup;
  genderList = ['男性', '女性'];
  jpLanguageLevelList = ['N1', 'N2', 'N3', 'N4', 'N5', 'N/A'];
  booleanValuesList = ['はい', 'いいえ'];
  hoursList: string[] = Array.from(Array(9), (v, i) => '' + i);
  minutesList: string[] = ['00', '30'];
  yearsList: any[] = [];
  academicEndYearList: any[] = [];
  workEndYearList: any[] = [];
  monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  minimumDate = {year: (new Date().getFullYear() - 120) , month: 1, day: 1};
  /**
   * variables defined to check if edit of any section is requested or not
   */
   basicInfoEdit: boolean = false;
   currAddressEdit: boolean = false;
   contAddressEdit: boolean = false;
   academicEdit: boolean = false;
   workEdit: boolean = false;
   qualificationEdit: boolean = false;
   othersEdit: boolean = false;
   /**
   * variables for jp options
   */
  jpCompanyTypes: any[] = [];
  jpHobbies: any[] = [];
  jpKnownLanguages: any[] = [];
  jpMajorSubjects: any[] = [];
  jpNationalities: any[] = [];
  jpPreferences: any[] = [];
  jpSkills: any[] = [];
  jpProfessions: [] = [];
  /**
   * variable declared for resume completition rates
   */
  jp_academics_completition_rate = 0;
  jp_personal_info_completition_rate = 0;
  jp_qualification_info_completition_rate = 0;
  jp_work_completition_rate = 0;
  /**
   * variabled declared to hold values from json files
   */
  // countryList = (countries as any).default;
  // nepalDistrictList = (nepalDistricts as any).default;
  currVisibleCityList: any[] = [];
  contVisibleCityList: any[] = [];
   hasDistrict: boolean = false;
   hasPostalCodeSearch: boolean = false;
   hasPrefecture: boolean= false;
   hasCity: boolean = false;
   hasProvince: boolean = false;
   hasConstDistrict: boolean = false;
   hasConstPostalCodeSearch: boolean = false;
   hasConstPrefecture: boolean= false;
   hasConstCity: boolean = false;
   hasConstProvince: boolean = false;
   hasSpouse: boolean = false;
   /**
    * variabled declared to hold english resume details
    */
  enUSerDetails: any = {
    first_name : '',
    last_name : '',
    furigana : '',
    profession : '',
    dob : '',
    age : '',
    gender : '',
    nationality: '',
    email: '',
    contact_number: ''
  }
  enCurrentAddress: any = {
    curr_country: '',
    curr_postal_code : '',
    curr_prefecture : '',
    curr_city: '',
    curr_address: '',
    curr_phone: ''
  }
  enPermanentAddress: any = {
    cont_country: '',
    cont_postal_code : '',
    cont_prefecture : '',
    cont_city: '',
    cont_address: '',
    cont_phone: ''
  }
  enAcademicList: any[] = [];
  enExperienceList: any[] = [];
  enQualificationList: any[] = [];
  enOthers: any = {
    known_languages : '',
    hobbies: '',
    motivation: '',
    jlpt: '',
    self_pr: '',
    skills: '',
    extra_point: '',
    available_working_hours: '',
    available_working_minutes: '',
    dependents_except_spouse: '',
    spouse: '',
    spouse_support_obligation: '',
    language_school_prefecture_id: '',
    language_school_city_id: '',
    language_school_postal_code: '',
    language_school_address: '',
    language_school_phone: '',
    university: '',
    university_postal_code: '',
    university_prefecture_id: '',
    university_city_id: '',
    special_conditions: ''
  }
  /**
   * 
   */
  currAddressLoader: boolean = false;
  constAddressLoader: boolean =  false;
  showWorkingMinutes: boolean = true;
  /**
   * variables declared to show/hode month selection based on year selection value
   */
   showAcademicEndMonthSelection: boolean = true;
   showExperienceEndMonthSelection: boolean = true;
  /**
   * variables declared for removing particular data from academic/work-experience/qualification
   */
   data_type_to_remove = '';
   data_index_to_be_removed: any; 

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private resumeService: ResumeService,
    private dataShareService: DataShareService, private el: ElementRef) {
    this.hoursList.splice(0, 0, 'Fulltime');
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    // const currentMonth = new Date().getMonth();
    for (let i = -50; i <= 0; i++ ) {
      this.yearsList.push(currentYear + i);
      this.academicEndYearList.push(currentYear + i);
      this.workEndYearList.push(currentYear + i);
    }
    this.yearsList.sort((a, b) => b - a);
    this.academicEndYearList.sort((a, b) => b - a);
    this.workEndYearList.sort((a, b) => b - a);
    this.academicEndYearList.unshift('在学中');
    this.workEndYearList.unshift('在職中');
    this.jpResumeBasicInfoForm = this.formBuilder.group({
      image: [''],
      first_name: ['', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z0-9]*')]],
      last_name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9]*')]],
      furigana: [''],
      profession: [null],
      dob: [null],
      age: [{value:'', disabled: true}],
      gender: [null],
      nationality: [null],
      email: ['', [Validators.required, Validators.email]],
      contact_number: ['']
    });
    /**
     * detect change in date value and calculate age
     */
     this.jpResumeBasicInfoForm.controls.dob.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(date => {
       if(date) {
         let age = this.calculateAge(new Date(date));
         this.jpResumeBasicInfoForm.controls.age.setValue(age);
       } else {
         this.jpResumeBasicInfoForm.controls.age.setValue('');
       }
     });
    this.jpResumeCurrentAddressForm = this.formBuilder.group({
      curr_country: [null],
      curr_postal_code: [''],
      curr_prefecture: [null],
      curr_city: [null],
      curr_address: [''],
      curr_phone: ['']
    });
    this.jpResumePermanentAddressForm = this.formBuilder.group({
      cont_country: [null],
      cont_postal_code: [''],
      cont_prefecture: [null],
      cont_city: [null],
      cont_address: [''],
      cont_phone: ['']
    });
    this.jpResumeAcademicForm = this.formBuilder.group({
      education: this.formBuilder.array([])
    });
    this.jpResumeExperienceForm = this.formBuilder.group({
      experience: this.formBuilder.array([])
    });
    this.jpResumeQualificationForm = this.formBuilder.group({
      qualification: this.formBuilder.array([]),
    });
    this.jpResumeOthersForm = this.formBuilder.group({
      known_languages: [null],
      hobbies: [null],
      motivation: [''],
      jlpt: [null],
      self_pr: [''],
      skills: [''],
      extra_point: [''],
      available_working_hours: [null],
      available_working_minutes: [null],
      dependents_except_spouse: [''],
      spouse: [null],
      spouse_support_obligation: [null],
      language_school_prefecture_id: [''],
      language_school_city_id: [''],
      language_school_postal_code: [''],
      language_school_address: [''],
      language_school_phone: [''],
      university: [''],
      university_postal_code: [''],
      university_prefecture_id: [''],
      university_city_id: [''],
      special_conditions: ['貴社の規定に従います']
    });
    /**
     * populate user resume details
     */
     if(this.resumeData) {
       this.jpCompanyTypes = this.resumeData.options?.company_types;
       this.jpHobbies = this.resumeData.options?.hobbies;
       this.jpKnownLanguages = this.resumeData.options?.known_languages;
       this.jpMajorSubjects = this.resumeData.options?.major_subjects;
       this.jpNationalities = this.resumeData.options?.nationalities;
       this.jpPreferences = this.resumeData.options?.preferences;
       this.jpSkills = this.resumeData.options?.skills;
       this.jpProfessions = this.resumeData.options?.professions;
       this.jp_academics_completition_rate = this.resumeData.academics_completion_rate;
       this.jp_personal_info_completition_rate = this.resumeData.personal_info_completion_rate;
       this.jp_qualification_info_completition_rate = this.resumeData.qualification_completion_rate;
       this.jp_work_completition_rate = this.resumeData.works_completion_rate;
       const resumePersonalDetails = this.resumeData.personal_info;
       if(resumePersonalDetails) {
        this.enUSerDetails.first_name =  resumePersonalDetails.first_name || this.userDetails.first_name;
        this.enUSerDetails.last_name =  resumePersonalDetails.last_name || this.userDetails.last_name;
        this.enUSerDetails.furigana =  resumePersonalDetails.furigana;
        this.enUSerDetails.profession =  resumePersonalDetails.profession !='null' ? resumePersonalDetails.profession : '';
        this.enUSerDetails.dob =  resumePersonalDetails.dob;
        this.enUSerDetails.age =  resumePersonalDetails.dob ? this.calculateAge(new Date(resumePersonalDetails.dob)) : '';
        this.enUSerDetails.gender = this.translateGender(resumePersonalDetails.gender); 
        this.enUSerDetails.nationality =  resumePersonalDetails.nationality; 
        this.enUSerDetails.email =  resumePersonalDetails.email || this.userDetails.email; 
        this.enUSerDetails.contact_number =  resumePersonalDetails.contact_number || this.userDetails.mobile; 
        Object.keys(this.enUSerDetails).forEach(key => {
          if (this.enUSerDetails[key] === null || this.enUSerDetails[key] === "null" || this.enUSerDetails[key] === '') {
            delete this.enUSerDetails[key];
          }
        });
        this.jpResumeBasicInfoForm .patchValue(this.enUSerDetails);
        /**
         * populate current address details
         */
         this.enCurrentAddress.curr_country = resumePersonalDetails.curr_country;
         if(this.enCurrentAddress.curr_country === '日本') {
          this.hasPostalCodeSearch = true;
         }
         this.enCurrentAddress.curr_postal_code = resumePersonalDetails.curr_postal_code;
         this.enCurrentAddress.curr_prefecture = resumePersonalDetails.curr_prefecture;
         this.enCurrentAddress.curr_city = resumePersonalDetails.curr_city;
         this.enCurrentAddress.curr_address = resumePersonalDetails.curr_address;
         this.enCurrentAddress.curr_phone = resumePersonalDetails.curr_phone;
         Object.keys(this.enCurrentAddress).forEach(key => {
          if (this.enCurrentAddress[key] === null || this.enCurrentAddress[key] === "null" || this.enCurrentAddress[key] === '') {
            delete this.enCurrentAddress[key];
          }
        });
         this.jpResumeCurrentAddressForm.patchValue(this.enCurrentAddress);
          /**
           * populate permanent address details
           */
          this.enPermanentAddress.cont_country = resumePersonalDetails.cont_country;
          if(this.enPermanentAddress.cont_country === '日本') {
            this.hasConstPostalCodeSearch = true;
          }
          this.enPermanentAddress.cont_postal_code = resumePersonalDetails.cont_postal_code;
          this.enPermanentAddress.cont_prefecture = resumePersonalDetails.cont_prefecture;
          this.enPermanentAddress.cont_city = resumePersonalDetails.cont_city;
          this.enPermanentAddress.cont_address = resumePersonalDetails.cont_address;
          this.enPermanentAddress.cont_phone = resumePersonalDetails.cont_phone;
          Object.keys(this.enPermanentAddress).forEach(key => {
            if (this.enPermanentAddress[key] === null || this.enPermanentAddress[key] === "null" || this.enPermanentAddress[key] === '') {
              delete this.enPermanentAddress[key];
            }
          });
          this.jpResumePermanentAddressForm.patchValue(this.enPermanentAddress);
          /**
          * populate otherd resume info
          */
           this.enOthers.known_languages = resumePersonalDetails.known_languages;
           this.enOthers.hobbies = resumePersonalDetails.hobbies;
           this.enOthers.motivation = resumePersonalDetails.motivation;
           this.enOthers.jlpt = resumePersonalDetails.jlpt;
           this.enOthers.self_pr = resumePersonalDetails.self_pr;
           this.enOthers.skills = resumePersonalDetails.skills;
           this.enOthers.extra_point = resumePersonalDetails.extra_point;
           const workingHours = resumePersonalDetails.working_hours?.split(":")
           if(workingHours) {
            this.enOthers.available_working_hours = workingHours[0];
            this.enOthers.available_working_minutes = workingHours.length > 1 ? workingHours[1]: '';
           }
           this.enOthers.dependents_except_spouse = resumePersonalDetails.dependents_except_spouse;
           this.enOthers.spouse = resumePersonalDetails.spouse;
           this.enOthers.spouse_support_obligation = resumePersonalDetails.spouse_support_obligation;
           this.enOthers.special_conditions = resumePersonalDetails.special_conditions || '貴社の規定に従います';
           Object.keys(this.enOthers).forEach(key => {
            if (this.enOthers[key] === null || this.enOthers[key] === "null" || this.enOthers[key] === '') {
              delete this.enOthers[key];
            }
          });
          this.jpResumeOthersForm.patchValue(this.enOthers);
       }
       
         /**
          * populate academic details
          */
         this.educationGroupArray.clear();
         this.enAcademicList = this.resumeData.academic_history;
         if(this.enAcademicList && this.enAcademicList.length > 0) {
          this.enAcademicList.forEach(obj => {
            this.addEducationRepeatition();
            Object.keys(obj).forEach(key => {
              if (obj[key] === null || obj[key] === "null" || obj[key] === '') {
                delete obj[key];
              } else {
                if(key == 'completion_year') {
                  obj[key] = this.translateAcademicStatus(obj[key]);
                }
              }
            });
          });
          this.jpResumeAcademicForm.controls.education.patchValue(this.enAcademicList);
         }
         /**
          * populate work experience details
          */
          this.experienceGroupArray.clear();
          this.enExperienceList = this.resumeData.work_history;
          if(this.enExperienceList && this.enExperienceList.length > 0) {
           this.enExperienceList.forEach(obj => {
             this.addExperienceRepeatition();
             Object.keys(obj).forEach(key => {
              if (obj[key] === null || obj[key] === "null" || obj[key] === '') {
                delete obj[key];
              } else {
                if(key == 'end_year') {
                  obj[key] = this.translateWorkStatus(obj[key]);
                }
              }
            });
            });
           this.jpResumeExperienceForm.controls.experience.patchValue(this.enExperienceList);
          }
        /**
          * populate qualification details
          */
         this.qualificationGroupArray.clear();
         this.enQualificationList =this.resumeData.qualification_history;
         if(this.enQualificationList && this.enQualificationList.length > 0) {
          this.enQualificationList.forEach(obj => {
             this.addQualificationRepeatition()});
          this.jpResumeQualificationForm.controls.qualification.patchValue(this.enQualificationList);
         }
     }
    /**
     * detect change of value in spouse section
     */
    this.jpResumeOthersForm.get('spouse')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if(value === 'Yes') {
          this.hasSpouse = true;
        } else {
          this.hasSpouse = false;
        }
      });


      this.jpResumeOthersForm.get('available_working_hours')?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if(value == 'Fulltime') {
          this.showWorkingMinutes = false;
        } else {
          this.showWorkingMinutes = true;
        }
      });

    /**
     * 
     */
     this.jpResumeCurrentAddressForm.get('curr_country')?.valueChanges
     .pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(country => {
       if(this.currAddressEdit) {
        this.jpResumeCurrentAddressForm.controls.curr_postal_code.reset();
        this.jpResumeCurrentAddressForm.controls.curr_prefecture.reset();
        this.jpResumeCurrentAddressForm.controls.curr_city.reset();
        this.jpResumeCurrentAddressForm.controls.curr_address.reset();
       }
       if(country === '日本' && this.currAddressEdit) {
         this.hasPrefecture = true;
         this.hasCity = true;
         this.hasProvince = false;
         this.hasPostalCodeSearch = true;
         this.jpResumeCurrentAddressForm.controls.curr_city.disable();
       } else if(country === 'ネパール' && this.currAddressEdit) {
         this.hasProvince = true;
         this.hasCity = true;
         this.hasPrefecture = false;
         this.hasPostalCodeSearch = false;
         this.jpResumeCurrentAddressForm.controls.curr_city.disable();
       } else {
         this.hasCity = false;
         this.hasProvince = false;
         this.hasPrefecture = false;
         this.hasPostalCodeSearch = false;
         if(country && this.currAddressEdit) {
          this.jpResumeCurrentAddressForm.controls.curr_city.enable();
         }
       }
      //  let filteredCountry = this.countryList.find((obj: any) => obj.countryName == country);
      //  this.jpResumeCurrentAddressForm.controls.curr_phone.setValue(filteredCountry?.phoneCode);
     });

   this.jpResumeCurrentAddressForm.get('curr_prefecture')?.valueChanges
     .pipe(takeUntil(this.ngUnsubscribe))
     .subscribe(value => {
       const selectedCountry = this.jpResumeCurrentAddressForm.controls.curr_country.value;
       if(value != null && (selectedCountry == '日本' || selectedCountry == 'ネパール')) {
         this.jpResumeCurrentAddressForm.controls.curr_city.disable();
         this.resumeService.getCityOrDistrict(selectedCountry, value, 'jp').pipe(takeUntil(this.ngUnsubscribe))
           .subscribe((res: any) => {
             if(selectedCountry == 'ネパール') {
               this.currVisibleCityList = [...new Set(res.map((data: any) => data.district))];
               this.jpResumeCurrentAddressForm.controls.curr_city.enable();
             }
             if(selectedCountry == '日本') {
               this.currVisibleCityList = [...new Set(res.map((data: any) => data.city))];
               this.jpResumeCurrentAddressForm.controls.curr_city.enable();
             }
           });
       } else {
         this.currVisibleCityList = [];
         this.jpResumeCurrentAddressForm.controls.curr_city.enable();
       }
     });
   

   /**
    * 
    */
    this.jpResumePermanentAddressForm.get('cont_country')?.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(country => {
      if(this.contAddressEdit) {
        this.jpResumePermanentAddressForm.controls.cont_postal_code.reset();
        this.jpResumePermanentAddressForm.controls.cont_prefecture.reset();
        this.jpResumePermanentAddressForm.controls.cont_city.reset();
        this.jpResumePermanentAddressForm.controls.cont_address.reset();
      }
      if(country === '日本' && this.contAddressEdit) {
       this.hasConstPrefecture = true;
       this.hasConstCity = true;
       this.hasConstProvince = false;
       this.hasConstPostalCodeSearch = true;
       this.jpResumePermanentAddressForm.controls.cont_city.disable();
      } else if(country === 'ネパール' && this.contAddressEdit) {
       this.hasConstProvince = true;
       this.hasConstCity = true;
       this.hasConstPrefecture = false;
       this.hasConstPostalCodeSearch = false;
       this.jpResumePermanentAddressForm.controls.cont_city.disable();
      } else {
       this.hasConstCity = false;
       this.hasConstProvince = false;
       this.hasConstPrefecture = false;
       this.hasConstPostalCodeSearch = false;
       if(this.contAddressEdit) {
        this.jpResumePermanentAddressForm.controls.cont_city.enable();
       }
      }
    });

  this.jpResumePermanentAddressForm.get('cont_prefecture')?.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value => {
     const selectedCountry = this.jpResumePermanentAddressForm.controls.cont_country.value;
     if(value != null && (selectedCountry == '日本' || selectedCountry == 'ネパール')) {
       this.jpResumePermanentAddressForm.controls.cont_city.disable();
       this.resumeService.getCityOrDistrict(selectedCountry, value, 'jp').pipe(takeUntil(this.ngUnsubscribe))
         .subscribe((res: any) => {
           if(selectedCountry == 'ネパール') {
             this.contVisibleCityList = [...new Set(res.map((data: any) => data.district))];
             this.jpResumePermanentAddressForm.controls.cont_city.enable();
           }
           if(selectedCountry == '日本') {
             this.contVisibleCityList = [...new Set(res.map((data: any) => data.city))];
             this.jpResumePermanentAddressForm.controls.cont_city.enable();
           }
         });
     } else {
       this.contVisibleCityList = [];
       this.jpResumePermanentAddressForm.controls.cont_city.enable();
     }
    });
  }

  private translateGender(gender: any) {
    if(gender == 'Male') {
      return '男性'
    }
    if(gender == 'Female') {
        return '女性';
    }
    return gender;
  }

  private translateAcademicStatus(academicStatus: any) {
    if(academicStatus == 'Running') {
      return '在学中';
    }
    return academicStatus;
  }

  private translateWorkStatus(workStatus: any) {
    if(workStatus == 'Running') {
      return '在職中';
    } 
    return workStatus;
  }

  private calculateAge(dob: any): any {
    var timeDiff = Math.abs(Date.now() - dob);
    return Math.floor((timeDiff / (1000 * 3600 * 24))/365);
  }

  checkAcademicDate(AC: AbstractControl) {
    let startyear = AC.get('start_year')?.value;
    let startmonth = AC.get('start_month')?.value;
    let endyear = AC.get('completion_year')?.value;
    let endmonth = AC.get('completion_month')?.value;
    if(endyear > startyear) {
      return;
    }
    if(startyear == endyear && startmonth >= endmonth) {
      return;
    }
    AC.get('completion_year')?.setErrors({checkAcademicDate: true});
  }

  /**
   * form array initialization funtion for education
   */
   arrayOfEducation(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      institute: [''],
      start_year: [null],
      start_month: [null],
      completion_year: [null],
      completion_month: [null],
      major_subject: [null]
    }, {validator: this.checkAcademicDate});
  }

  /**
   * returning the form control of education as FormArray
   */
  get educationGroupArray() {
    return this.jpResumeAcademicForm.get('education') as FormArray;
  }

  /**
   * function for adding form fields into education FormArray
   */
  addEducationRepeatition() {
    this.educationGroupArray.push(this.arrayOfEducation());
    if(this.educationGroupArray.length > 1) {
      setTimeout(() => {
        const elem = document.getElementById("educationSection" + (this.educationGroupArray.length -1));
        if(elem) {
          elem.scrollIntoView({behavior: 'smooth', block: "center"});
        }
      }, 500);
    }
  }

  /**
   * function for removing form fields into education FormArray
   */
   removeEducationRepeatition(index: number) {
    // if(confirm('Are you sure you want to remove?')){
      this.educationGroupArray.removeAt(index);
      // if(this.jpResumeAcademicForm.touched || this.jpResumeAcademicForm.dirty) {
        // this.saveAcademicsEn();
      // }
    // }
    // if(this.educationGroupArray.length > 0) {
    //   setTimeout(() => {
    //     const elem = document.getElementById("educationSection" + (index -1));
    //     if(elem) {
    //       elem.scrollIntoView({behavior: 'smooth', block: "center"});
    //     }
    //   }, 500);
    // }
  }

  /**
   * form array initialization funtion for experience
   */
   arrayOfExperience(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      company_name: [''],
      company_type: [null],
      start_year: [null],
      start_month: [null],
      end_year: [null],
      end_month: [null],
      purpose_of_resign: ['']
    });
  }

  /**
   * returning the form control of experience as FormArray
   */
  get experienceGroupArray() {
    return this.jpResumeExperienceForm.get('experience') as FormArray;
  }

  /**
   * function for adding form fields into experience FormArray
   */
  addExperienceRepeatition() {
    this.experienceGroupArray.push(this.arrayOfExperience());
    if(this.experienceGroupArray.length > 1) {
      setTimeout(() => {
        const elem = document.getElementById("experienceSection" + (this.experienceGroupArray.length -1));
        if(elem) {
          elem.scrollIntoView({behavior: 'smooth', block: "center"});
        }
      }, 500);
    }
  }

  /**
   * function for removing form fields into education FormArray
   */
   removeExperienceRepeatition(index: number) {
    // if(confirm('Are you sure you want to remove?')){
      this.experienceGroupArray.removeAt(index);
      // if(this.jpResumeExperienceForm.touched || this.jpResumeExperienceForm.dirty) {
        // this.saveWorkExperienceEn();
      // }
    // }
    // if(this.experienceGroupArray.length > 0) {
    //   setTimeout(() => {
    //     const elem = document.getElementById("experienceSection" + (this.experienceGroupArray.length -1));
    //     if(elem) {
    //       elem.scrollIntoView({behavior: 'smooth', block: "center"});
    //     }
    //   }, 500);
    // }
  }


  /**
   * form array initialization funtion for qualification
   */
   arrayOfQualification(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      qualification_name: [''],
      certified_year: [null],
      certified_month: [null]
    });
  }

  /**
   * returning the form control of qualification as FormArray
   */
  get qualificationGroupArray() {
    return this.jpResumeQualificationForm.get('qualification') as FormArray;
  }

  /**
   * function for adding form fields into qualification FormArray
   */
  addQualificationRepeatition() {
    this.qualificationGroupArray.push(this.arrayOfQualification());
    if(this.qualificationGroupArray.length > 1) {
      setTimeout(() => {
        const elem = document.getElementById("qualificationSection" + (this.qualificationGroupArray.length -1));
        if(elem) {
          elem.scrollIntoView({behavior: 'smooth', block: "center"});
        }
      }, 500);
    }
  }

  /**
   * function for removing form fields into education FormArray
   */
   removeQualificationRepeatition(index: number) {
    // if(confirm('Are you sure you want to remove?')){
      this.qualificationGroupArray.removeAt(index);
      // if(this.jpResumeQualificationForm.touched || this.jpResumeQualificationForm.dirty) {
        // this.saveQualificationEn();
      // }
    // }
    // if(this.qualificationGroupArray.length > 0) {
    //   setTimeout(() => {
    //     const elem = document.getElementById("qualificationSection" + (this.qualificationGroupArray.length -1));
    //     if(elem) {
    //       elem.scrollIntoView({behavior: 'smooth', block: "center"});
    //     }
    //   }, 500);
    // }
  }

  // workingHoursValue() {
  //   return this.jpResumeOthersForm.controls.available_working_hours.value;
  // }

  /**
   * function to copy current address to contact address
   * @param event 
   */
   sameAsCurrentAddress(event: any) {
    if(event.target.checked) {
      this.jpResumePermanentAddressForm.controls.cont_country.setValue(this.jpResumeCurrentAddressForm.controls.curr_country.value);
      this.jpResumePermanentAddressForm.controls.cont_country.updateValueAndValidity();
      this.jpResumePermanentAddressForm.controls.cont_postal_code.setValue(this.jpResumeCurrentAddressForm.controls.curr_postal_code.value);
      this.jpResumePermanentAddressForm.controls.cont_postal_code.updateValueAndValidity();
      this.jpResumePermanentAddressForm.controls.cont_prefecture.setValue(this.jpResumeCurrentAddressForm.controls.curr_prefecture.value);
      this.jpResumePermanentAddressForm.controls.cont_prefecture.updateValueAndValidity();
      this.jpResumePermanentAddressForm.controls.cont_city.setValue(this.jpResumeCurrentAddressForm.controls.curr_city.value);
      this.jpResumePermanentAddressForm.controls.cont_city.updateValueAndValidity();
      this.jpResumePermanentAddressForm.controls.cont_address.setValue(this.jpResumeCurrentAddressForm.controls.curr_address.value);
      this.jpResumePermanentAddressForm.controls.cont_address.updateValueAndValidity();
      this.jpResumePermanentAddressForm.controls.cont_phone.setValue(this.jpResumeCurrentAddressForm.controls.curr_phone.value);
      this.jpResumePermanentAddressForm.controls.cont_phone.updateValueAndValidity();
    } else {
      this.jpResumePermanentAddressForm.patchValue(this.enPermanentAddress);
    }
  }

  getCurrAddressByPostalCode() {
    const postal_code = this.jpResumeCurrentAddressForm.controls.curr_postal_code.value;
    if(postal_code == '' || postal_code == null) {
      this.toastr.info("Please provide postal code first");
      const postalCodeControl = this.el.nativeElement.querySelector('[formControlName="curr_postal_code"]');
      postalCodeControl.focus();
      return;
    }
    this.currAddressLoader = true;
    this.resumeService.getAddressByPostalCode(postal_code.replace(/-/g, ""))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res:any) => {
        const addressDetails = res.postal_code[0];
        this.jpResumeCurrentAddressForm.controls.curr_prefecture.setValue(addressDetails.prefecture);
        this.jpResumeCurrentAddressForm.controls.curr_prefecture.updateValueAndValidity();
        this.jpResumeCurrentAddressForm.controls.curr_city.setValue(addressDetails.city);
        this.jpResumeCurrentAddressForm.controls.curr_city.updateValueAndValidity();
        this.jpResumeCurrentAddressForm.controls.curr_address.setValue(addressDetails.street);
        this.jpResumeCurrentAddressForm.controls.curr_address.updateValueAndValidity();
        this.currAddressLoader = false;
      }, (err: any) => {
        this.toastr.error(err.error.error, 'Error');
        this.currAddressLoader = false;
      });
  }

  getPermanentAddressByPostalCode() {
    const postal_code = this.jpResumePermanentAddressForm.controls.cont_postal_code.value;
    if(postal_code == '' || postal_code == null) {
      this.toastr.info("Please provide postal code first");
      const postalCodeControl = this.el.nativeElement.querySelector('[formControlName="cont_postal_code"]');
      postalCodeControl.focus();
      return;
    }
    this.constAddressLoader = true;
    this.resumeService.getAddressByPostalCode(postal_code.replace(/-/g, ""))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res:any) => {
        const addressDetails = res.postal_code[0];
        this.jpResumePermanentAddressForm.controls.cont_prefecture.setValue(addressDetails.prefecture);
        this.jpResumePermanentAddressForm.controls.cont_prefecture.updateValueAndValidity();
        this.jpResumePermanentAddressForm.controls.cont_city.setValue(addressDetails.city);
        this.jpResumePermanentAddressForm.controls.cont_city.updateValueAndValidity();
        this.jpResumePermanentAddressForm.controls.cont_address.setValue(addressDetails.street);
        this.jpResumePermanentAddressForm.controls.cont_address.updateValueAndValidity();
        this.constAddressLoader = false;
      }, (err: any) => {
        this.toastr.error(err.error.error, 'Error');
        this.constAddressLoader = false;
      });
  }

  toogleBasicInfoEdit() {
    this.basicInfoEdit = !this.basicInfoEdit;
    if(!this.basicInfoEdit) {
      this.jpResumeBasicInfoForm.markAsPristine();
      this.jpResumeBasicInfoForm.markAsUntouched();
    }
  }

  saveBasicInfoEn() {
    if(this.jpResumeBasicInfoForm.invalid) {
      return ;
    }
    this.resumeSectionUpdateRequest = 'basic_info';
    this.resumeUpdateRequested = true;
    const resumeDetails = new FormData();
    const formValues = this.jpResumeBasicInfoForm.value;
    let basicInfoDetails = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      furigana: formValues.furigana,
      profession: formValues.profession ? formValues.profession : '',
      age: formValues.age,
      gender: formValues.gender ? formValues.gender : '',
      nationality: formValues.nationality ? formValues.nationality: '',
      resume_email: formValues.email,
      contact_number: formValues.contact_number
    }
    if(formValues.dob) {
      Object.assign({ dob: formValues.dob }, basicInfoDetails);
    }
    this.resumeService.updateEnglishResume(basicInfoDetails, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumeBasicInfoForm.markAsPristine();
        this.jpResumeBasicInfoForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });

  }

  toogleCurrentAddressEdit() {
    this.currAddressEdit = !this.currAddressEdit;
    if(!this.currAddressEdit) {
      this.jpResumeCurrentAddressForm.markAsPristine();
      this.jpResumeCurrentAddressForm.markAsUntouched();
    } else {
      this.checkCurrentCountryValue();
    }
  }

  checkCurrentCountryValue() {
    let currentCountry = this.jpResumeCurrentAddressForm.get('curr_country')?.value;
    if(currentCountry === '日本' && this.currAddressEdit) {
      this.hasPrefecture = true;
      this.hasCity = true;
      this.hasProvince = false;
      this.hasPostalCodeSearch = true;
      this.jpResumeCurrentAddressForm.controls.curr_city.disable();
    } else if(currentCountry === 'ネパール' && this.currAddressEdit) {
      this.hasProvince = true;
      this.hasCity = true;
      this.hasPrefecture = false;
      this.hasPostalCodeSearch = false;
      this.jpResumeCurrentAddressForm.controls.curr_city.disable();
    } else {
      this.hasCity = false;
      this.hasProvince = false;
      this.hasPrefecture = false;
      this.hasPostalCodeSearch = false;
      if(currentCountry && this.currAddressEdit) {
        this.jpResumeCurrentAddressForm.controls.curr_city.enable();
      }
    }
  }

  saveCurrentAddressEn() { 
    this.resumeSectionUpdateRequest = 'en_current_address';
    this.resumeUpdateRequested = true;
    this.resumeService.updateEnglishResume(this.jpResumeCurrentAddressForm.value, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumeCurrentAddressForm.markAsPristine();
        this.jpResumeCurrentAddressForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  tooglePermanentAddressEdit() {
    this.contAddressEdit = !this.contAddressEdit;
    if(!this.contAddressEdit) {
      this.jpResumePermanentAddressForm.markAsPristine();
      this.jpResumePermanentAddressForm.markAsUntouched();
    } else {
      this.checkPermanentCountry();
    }
  }

  checkPermanentCountry() {
    let contCountry = this.jpResumePermanentAddressForm.get('cont_country')?.value;
    if(contCountry === '日本' && this.contAddressEdit) {
      this.hasConstPrefecture = true;
      this.hasConstCity = true;
      this.hasConstProvince = false;
      this.hasConstPostalCodeSearch = true;
      this.jpResumePermanentAddressForm.controls.cont_city.disable();
     } else if(contCountry === 'ネパール' && this.contAddressEdit) {
      this.hasConstProvince = true;
      this.hasConstCity = true;
      this.hasConstPrefecture = false;
      this.hasConstPostalCodeSearch = false;
      this.jpResumePermanentAddressForm.controls.cont_city.disable();
     } else {
      this.hasConstCity = false;
      this.hasConstProvince = false;
      this.hasConstPrefecture = false;
      this.hasConstPostalCodeSearch = false;
      if(contCountry && this.contAddressEdit) {
        this.jpResumePermanentAddressForm.controls.cont_city.enable();
      }
     }
  }

  savePermanentAddressEn() { 
    this.resumeSectionUpdateRequest = 'en_permanent_address';
    this.resumeUpdateRequested = true;
    this.resumeService.updateEnglishResume(this.jpResumePermanentAddressForm.value, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumePermanentAddressForm.markAsPristine();
        this.jpResumePermanentAddressForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleAcademicEdit() {
    this.academicEdit = !this.academicEdit;
    if(!this.academicEdit) {
      this.jpResumeAcademicForm.markAsPristine();
      this.jpResumeAcademicForm.markAsUntouched();
    }
  }

  saveAcademicsEn() {
    this.resumeSectionUpdateRequest = 'en_academics';
    this.resumeUpdateRequested = true;
    if(this.jpResumeAcademicForm.invalid) {
      return;
    }
    const formValues = this.jpResumeAcademicForm.value;
    /**
     * replace null values by '' in education array
     */
     formValues.education.forEach((edu: any) => {
      Object.keys(edu).forEach(key => {
        if(edu[key] === null) {
          edu[key] = '';
        }
        if(edu['id'] === ''){
          delete edu['id'];
        }
      });
    });
    // const academicDetails = {
    //   education: formValues.education
    // }
    this.resumeService.updateEnglishResume(formValues, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumeAcademicForm.markAsPristine();
        this.jpResumeAcademicForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleWorkExperienceEdit() {
    this.workEdit = !this.workEdit;
    if(!this.workEdit) {
      this.jpResumeExperienceForm.markAsPristine();
      this.jpResumeExperienceForm.markAsUntouched();
    }
  }

  saveWorkExperienceEn() {
    this.resumeSectionUpdateRequest = 'en_work_experience';
    this.resumeUpdateRequested = true;
    const formValues = this.jpResumeExperienceForm.value;
    /**
     * replace null values by '' in experience array
     */
     formValues.experience.forEach((edu: any) => {
      Object.keys(edu).forEach(key => {
        if(edu[key] === null) {
          edu[key] = '';
        }
        if(edu['id'] === ''){
          delete edu['id'];
        }
      });
    });
    // const experienceDetails = {
    //   'experience': JSON.stringify(formValues.experience)
    // }
    this.resumeService.updateEnglishResume(formValues, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumeExperienceForm.markAsPristine();
        this.jpResumeExperienceForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleQualificationEdit() {
    this.qualificationEdit = !this.qualificationEdit;
    if(!this.qualificationEdit) {
      this.jpResumeQualificationForm.markAsPristine();
      this.jpResumeQualificationForm.markAsUntouched();
    }
  }

  saveQualificationEn() {
    this.resumeSectionUpdateRequest = 'en_qualification';
    this.resumeUpdateRequested = true;
    const formValues = this.jpResumeQualificationForm.value;
    /**
     * replace null values by '' in qualification array
     */
     formValues.qualification.forEach((edu: any) => {
      Object.keys(edu).forEach(key => {
        if(edu[key] === null) {
          edu[key] = '';
        }
        if(edu['id'] === ''){
          delete edu['id'];
        }
      });
    });
    this.resumeService.updateEnglishResume(formValues, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumeQualificationForm.markAsPristine();
        this.jpResumeQualificationForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  toogleOthersEdit() {
    this.othersEdit = !this.othersEdit;
    if(!this.othersEdit) {
      this.jpResumeOthersForm.markAsPristine();
      this.jpResumeOthersForm.markAsUntouched();
    }
  }

  saveOthersInfoEn() {
    
    this.resumeSectionUpdateRequest = 'en_others_info';
    this.resumeUpdateRequested = true;
    const formValues = this.jpResumeOthersForm.value;
    let availableWorkingTime = '';
    if(formValues.available_working_hours) {
      availableWorkingTime = formValues.available_working_hours + ':' + (formValues.available_working_minutes ? formValues.available_working_minutes : '00');
    }
    const othersInfoDetails = {
      known_languages: formValues.known_languages? formValues.known_languages : [],
      hobbies: formValues.hobbies ? formValues.hobbies : [],
      motivation: formValues.motivation,
      jlpt: formValues.jlpt? formValues.jlpt : '',
      self_pr: formValues.self_pr,
      skills: formValues.skills ? formValues.skills : [],
      extra_point: formValues.extra_point,
      working_hours: availableWorkingTime,
      dependents_except_spouse: formValues.dependents_except_spouse,
      spouse: formValues.spouse ? formValues.spouse : '',
      spouse_support_obligation :formValues.spouse_support_obligation ? formValues.spouse_support_obligation : '',
      special_conditions :formValues.special_conditions ? formValues.special_conditions : ''
    }
    this.resumeService.updateEnglishResume(othersInfoDetails, this.uuid, 'jp')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        this.toastr.success(res.message, 'Success');
        this.jpResumeOthersForm.markAsPristine();
        this.jpResumeOthersForm.markAsUntouched();
        this.dataShareService.changeFetchResumeStatus(true);
      }, (err: any) => {
        this.resumeSectionUpdateRequest = '';
        this.resumeUpdateRequested = false;
        const errors = err.error.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error('Failed to update resume.', 'Error');
      });
  }

  /**
   * function to remove data from education/work experience/qualification
   * @param index index of a data in an array of education/work experience/qualification data
   * @param data_id id of a data to be removed
   * @param data_type data type ie. education/work experience/qualification
   */
   removeData(index: number, data_id: number, data_type: string) {
    if(confirm('Are you sure you want to remove?')){
      this.data_type_to_remove = data_type;
      this.data_index_to_be_removed = index;
      if(data_id) {
        this.resumeService.removeResumeData(data_id, data_type).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if(this.data_type_to_remove == 'education') {
            this.toogleAcademicEdit();
          }
          if(this.data_type_to_remove == 'work_experience') {
            this.toogleWorkExperienceEdit();
          }
          if(this.data_type_to_remove == 'qualification') {
            this.toogleQualificationEdit();
          }
          this.data_type_to_remove = '';
          this.data_index_to_be_removed = ''; 
          this.dataShareService.changeFetchResumeStatus(true);
          this.toastr.success('Data removed from the list', 'SUCCESS');
        }, (err: any) =>{
          this.data_type_to_remove = '';
          this.data_index_to_be_removed = '';
          const errors = err.error.error;
          Object.keys(errors).forEach(key => {
            this.toastr.error(errors[key][0], key)
          });
          // this.toastr.error('Unable to remove data. Please try agaian', 'ERROR');
        });
      } else {
        if(this.data_type_to_remove == 'education') {
          this.removeEducationRepeatition(index);
        }
        if(this.data_type_to_remove == 'experience') {
          this.removeExperienceRepeatition(index);
        }
        if(this.data_type_to_remove == 'qualification') {
          this.removeQualificationRepeatition(index);
        }
        this.data_type_to_remove = '';
        this.data_index_to_be_removed = '';
      }
    }
  }

  /**
   * check if the user has made any changes to the form or not
   * if the form state is changed
   * ask user for confirmation before deactivating component
   */
   @HostListener('window:beforeunload')
   canDeactivate() {
      if (this.jpResumeBasicInfoForm.dirty && !this.jpResumeBasicInfoForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in basic details will be discarded.");
      } else if (this.jpResumeCurrentAddressForm.dirty && !this.jpResumeCurrentAddressForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in current address will be discarded.");
      } else if (this.jpResumePermanentAddressForm.dirty && !this.jpResumePermanentAddressForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in permanent address will be discarded.");
      } else if (this.jpResumeAcademicForm.dirty && !this.jpResumeAcademicForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in education will be discarded.");
      } else if (this.jpResumeExperienceForm.dirty && !this.jpResumeExperienceForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in work will be discarded.");
      } else if (this.jpResumeQualificationForm.dirty && !this.jpResumeQualificationForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in qualification will be discarded.");
      } else if (this.jpResumeOthersForm.dirty && !this.jpResumeOthersForm.pristine) {
        return confirm("Are you sure you want to leave? The changes you made in others will be discarded.");
      }
     return true;
   }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
