import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { TermsConditionsRoutingModule } from './terms-conditions-routing.module';
import { TermsConditionsComponent } from './terms-conditions.component';

@NgModule({
    imports: [
        CommonModule,
        TermsConditionsRoutingModule
    ],
    declarations: [
        TermsConditionsComponent
    ],
    providers: [
        
    ]
})

export class TermsConditionsModule {
}
