import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private portableNewsBaseUrl = 'https://news.nettvnepal.com.np/api/v1';
  private clientName = 'PortableNews';
  private clientToken = '8r3f7rsucI';
  private httpHeaders =  new HttpHeaders({
    'cache': 'true',
    'cache_group': 'alerts'
  });

  constructor(private http: HttpClient) { }

  getLatestAlerts(limit: number) {
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/earthquakes-eruptions?lang=en&limit='+ limit +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }

  getLatestEarthquakeList(limit: number) {
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/earthquakes?lang=en&limit='+ limit +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }

  getEarthquakeDetailByCode(lang: string, code: string) {
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/earthquake/details?lang='+ lang +'&code='+ code  +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }

  getEarthquakeMap(code: any) {
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/earthquake/map?code='+ code  +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }

  getLatestVolcanoList(limit: number) {
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/eruptions?lang=en&limit='+ limit +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }

  getVolcanoDetailByCode(lang: string, code: string) {
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/eruption/details?lang='+ lang +'&code='+ code  +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }

  /**
   * function to fetch warning list
   * @param latitude 
   * @param longitude 
   * @returns 
   */
  getWarningList(latitude: number, longitude: number){
    let apiUrl = this.portableNewsBaseUrl + '/portable-news/warnings?lat='+ latitude +'&lon='+ longitude  +'&client-name=' 
                  + this.clientName +'&client-token=' + this.clientToken;
    return this.http.get(apiUrl, {headers: this.httpHeaders});
  }
}
