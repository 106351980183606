import { AbstractControl, FormGroup } from '@angular/forms';

export function urlValidator(control: AbstractControl) {
  if (!control.value.startsWith('https') || !control.value.includes('.me')) {
    return { urlValid: true };
  }
  return null;
}


export function CheckPasswordPattern(control: AbstractControl) {
    const passNumericOnly = /^[0-9]+$/.test(control.value);
    const passCharacterOnly = /^[a-zA-Z]+$/.test(control.value);
    if(!passNumericOnly && !passCharacterOnly) {
      return null;
    }
    return {passwordPatternValid: true};
}

export function CheckPasswordMatch(password: string, conform_password: string){
    return (formGroup: FormGroup) => {
        const passwordValue = formGroup.controls[password];
        const conformPasswordValue = formGroup.controls[conform_password];
        if (conformPasswordValue.errors && !conformPasswordValue.errors.passwordMatch) {
            return;
        }
        if (passwordValue.value !== conformPasswordValue.value) {
            conformPasswordValue.setErrors({ passwordMatch: true });
        } else {
            conformPasswordValue.setErrors(null);
        }
    }
}