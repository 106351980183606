
    <div class="card-resume">
        <div class="card-item">
            <div class="d-flex justify-content-between">
                <div class="title d-flex">
                    <strong>Basic Info</strong>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="jp_personal_info_completition_rate + '%'" [attr.aria-valuenow]="jp_personal_info_completition_rate" aria-valuemin="0" aria-valuemax="100">{{jp_personal_info_completition_rate}}%</div>
                    </div>
                </div>
                <ng-container *ngIf="!basicInfoEdit">
                    <a class="card-edit link" (click)="toogleBasicInfoEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-container>
                <!-- <ng-container *ngIf="basicInfoEdit; else nonEditableBasicInfo">
                    <a class="btn-save" [ngClass]="jpResumeBasicInfoForm.valid ? 'link':'disabled'" (click)="jpResumeBasicInfoForm.valid ? saveBasicInfoEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'basic_info'"></i>Save</a>
                    <a (click)="toogleBasicInfoEdit()"><i class="fas fa-times mr-1"></i></a>
                </ng-container>
                <ng-template #nonEditableBasicInfo>
                    <a class="card-edit link" (click)="toogleBasicInfoEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-template> -->
            </div>
            <div class="card-inner mt-3">
                <ng-container *ngIf="basicInfoEdit; else nonEditableBasicInfoForm">
                    <form [formGroup]="jpResumeBasicInfoForm">
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Name</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="first_name" placeholder="Name">
                                    <ng-container *ngIf="jpResumeBasicInfoForm.controls.first_name.errors && (jpResumeBasicInfoForm.controls.first_name.dirty || jpResumeBasicInfoForm.controls.first_name.touched)">
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.first_name.errors?.required">
                                            <div class="help-block text-danger mt-1">Please enter your first name</div>
                                        </ng-container>
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.first_name.errors?.minlength">
                                            <div class="help-block text-danger mt-1">At least 3 characters required.</div>
                                        </ng-container>
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.first_name.errors?.pattern">
                                            <div class="help-block text-danger mt-1">Special Characters are not allowed.</div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Family Name</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="last_name" placeholder="Family Name">
                                    <ng-container *ngIf="jpResumeBasicInfoForm.controls.last_name.errors && (jpResumeBasicInfoForm.controls.last_name.dirty || jpResumeBasicInfoForm.controls.last_name.touched)">
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.last_name.errors?.required">
                                            <div class="help-block text-danger mt-1">Please enter your family name</div>
                                        </ng-container>
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.last_name.errors?.minlength">
                                            <div class="help-block text-danger mt-1">At least 2 characters required.</div>
                                        </ng-container>
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.last_name.errors?.pattern">
                                            <div class="help-block text-danger mt-1">Special Characters are not allowed.</div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Furigana</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="furigana" placeholder="Furigana">
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Profession</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Profession" [clearable]="true" [items]="jpProfessions"
                                    formControlName="profession"></ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Date of Birth</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input id="datepicker" class="form-control"  formControlName="dob" placeholder="Select Date"  ngbDatepicker #d="ngbDatepicker" [minDate]="minimumDate" (click)="d.toggle()">
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Age</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="number" formControlName="age" placeholder="Age">
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Gender</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Gender" [clearable]="true" [items]="genderList"
                                    formControlName="gender">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Nationality</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Nationality" [clearable]="true" [items]="jpNationalities"
                                    formControlName="nationality"></ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Email</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="email" placeholder="Email">
                                    <ng-container *ngIf="jpResumeBasicInfoForm.controls.email.errors && (jpResumeBasicInfoForm.controls.email.dirty || jpResumeBasicInfoForm.controls.email.touched)">
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.email.errors?.required">
                                            <div class="help-block text-danger mt-1">Please enter your email address</div>
                                        </ng-container>
                                        <ng-container *ngIf="jpResumeBasicInfoForm.controls.email.errors?.email">
                                            <div class="help-block text-danger mt-1">Please enter a valid email</div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Phone</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="number" formControlName="contact_number" placeholder="Phone">
                                </div>
                            </div>
                        </div> -->
                    </form>
                </ng-container>
                <ng-template #nonEditableBasicInfoForm>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Name</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.first_name}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Family Name</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.last_name}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Furigana</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.furigana}}</span>
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Profession</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.profession}} </span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Date of Birth</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.dob}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Age</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.age}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Gender</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.gender}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Nationality</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.nationality}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Email</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.email}}</span>
                            
                        </div>
                    </div>
                    <!-- <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Phone</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enUSerDetails.contact_number}}</span>
                            
                        </div>
                    </div> -->
                </ng-template>
                
            </div>
            <div class="d-flex justify-content-between" *ngIf="basicInfoEdit">
                <a class="btn-save" [ngClass]="jpResumeBasicInfoForm.valid ? 'link':'disabled'" (click)="jpResumeBasicInfoForm.valid ? saveBasicInfoEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'basic_info'"></i>Save</a>
                <a (click)="toogleBasicInfoEdit()"><i class="fas fa-times mr-1"></i></a>
            </div>
        </div>
    </div>
    <div class="card-resume">
        <div class="card-item">
            <div class="d-flex justify-content-between">
                <div class="title d-flex">
                    <strong>Address</strong>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="jp_personal_info_completition_rate + '%'" [attr.aria-valuenow]="jp_personal_info_completition_rate" aria-valuemin="0" aria-valuemax="100">{{jp_personal_info_completition_rate}}%</div>
                    </div>
                </div>
            </div>
            <div class="card-inner mt-3">
                <div class="inner-title d-flex justify-content-between">
                    <strong>Current Address</strong>
                    <ng-container *ngIf="!currAddressEdit">
                        <a class="edit-innerCard" (click)="toogleCurrentAddressEdit()"><i class="fas fa-pen mr-1"></i></a>
                    </ng-container>
                    <!-- <ng-container *ngIf="currAddressEdit; else nonEditableCurrentAddress">
                        <div class="lt-btn">
                            <a class="btn-save" [ngClass]="jpResumeCurrentAddressForm.valid ? 'link':'disabled'" (click)="jpResumeCurrentAddressForm.valid ? saveCurrentAddressEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_current_address'"></i>Save</a>
                        </div>
                        <i class="fas fa-times mr-1 link" (click)="toogleCurrentAddressEdit()"></i>
                    </ng-container>
                    <ng-template #nonEditableCurrentAddress>
                        <a class="edit-innerCard" (click)="toogleCurrentAddressEdit()"><i class="fas fa-pen mr-1"></i></a>
                    </ng-template> -->
                </div>
                <ng-container *ngIf="currAddressEdit; else nonEditableCurrentAddressForm">
                    <form [formGroup]="jpResumeCurrentAddressForm">
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Country</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Country" [clearable]="true" [items]="jpNationalities"
                                    formControlName="curr_country"></ng-select>
                                </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Postal Code</strong>
                            </div>
                            <ng-container *ngIf="hasPostalCodeSearch; else noPostalCodeSearch">
                                <div class="col-md-8 col-lg-5 et">
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="curr_postal_code" placeholder="XXX-XXXX" mask="000-0000" [showMaskTyped]="true" placeHolderCharacter="X">
                                    </div>
                                </div>
                                <div class="col-md-8 col-lg-3 et">
                                    <div class="form-group">
                                        <button class="btn btn-orange" (click)="getCurrAddressByPostalCode()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="currAddressLoader"></i>Search</button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noPostalCodeSearch>
                                <div class="col-md-8 col-lg-5 et">
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="curr_postal_code" placeholder="Postal Code">
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Prefecture/Province</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-container *ngIf="hasProvince; else checkPrefectureAvailability">
                                    <ng-select class="customngselect" placeholder="Select Province" [clearable]="true" [items]="npProvinces" bindLabel="province_name" bindValue="province_name"
                                        formControlName="curr_prefecture"></ng-select>
                                </ng-container>
                                <ng-template #checkPrefectureAvailability>
                                    <ng-container *ngIf="hasPrefecture; else districtOrPrefectureUnavailable">
                                        <ng-select class="customngselect" placeholder="Select Prefecture" [clearable]="true" [items]="jpJpPrefectures" bindLabel="name" bindValue="name"
                                            formControlName="curr_prefecture"></ng-select>
                                    </ng-container>
                                    <ng-template #districtOrPrefectureUnavailable>
                                        <div class="form-group">
                                            <input class="form-control" type="text" formControlName="curr_prefecture" placeholder="Prefecture">
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>City/District</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-container *ngIf="hasCity; else cityUnavailable">
                                    <ng-select class="customngselect" placeholder="Select City" [clearable]="true" [items]="currVisibleCityList" bindLabel="name" bindValue="name"
                                            formControlName="curr_city"></ng-select>
                                </ng-container>
                                <ng-template #cityUnavailable>
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="curr_city" placeholder="City">
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Address</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="curr_address" placeholder="Address">
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Phone</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="number" formControlName="curr_phone" placeholder="Phone">
                                </div>

                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-template #nonEditableCurrentAddressForm>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Country</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enCurrentAddress.curr_country}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Postal Code</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enCurrentAddress.curr_postal_code}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Prefecture/Province</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enCurrentAddress.curr_prefecture}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>City/District</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enCurrentAddress.curr_city}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Address</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enCurrentAddress.curr_address}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Phone</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enCurrentAddress.curr_phone}}</span>
                            <a href="#"><i class="fas fa-pen ml-2"></i></a>
                        </div>
                    </div>
                </ng-template>
                <div class="inner-title d-flex justify-content-between" *ngIf="currAddressEdit">
                    <div class="lt-btn">
                        <a class="btn-save" [ngClass]="jpResumeCurrentAddressForm.valid ? 'link':'disabled'" (click)="jpResumeCurrentAddressForm.valid ? saveCurrentAddressEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_current_address'"></i>Save</a>
                    </div>
                    <i class="fas fa-times mr-1 link" (click)="toogleCurrentAddressEdit()"></i>
                </div>
            </div>
            <div class="card-inner mt-3">
                <div class="inner-title d-flex justify-content-between">
                    <strong>Permanent Address</strong>
                    <ng-container *ngIf="!contAddressEdit">
                        <a class="edit-innerCard" (click)="tooglePermanentAddressEdit()"><i class="fas fa-pen mr-1"></i></a>
                    </ng-container>
                    <!-- <ng-container *ngIf="contAddressEdit; else nonEditablePermanentAddress">
                        <div class="lt-btn">
                            <a class="btn-save" [ngClass]="jpResumePermanentAddressForm.valid ? 'link':'disabled'" (click)="jpResumePermanentAddressForm.valid ? savePermanentAddressEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_permanent_address'"></i>Save</a>
                        </div>
                        <i class="fas fa-times mr-1 link" (click)="tooglePermanentAddressEdit()"></i>
                    </ng-container>
                    <ng-template #nonEditablePermanentAddress>
                        <a class="edit-innerCard" (click)="tooglePermanentAddressEdit()"><i class="fas fa-pen mr-1"></i></a>
                    </ng-template> -->
                </div>
                <ng-container *ngIf="contAddressEdit; else nonEditablePermanentAddressForm">
                    <form [formGroup]="jpResumePermanentAddressForm">
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Same as Current Address</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <input type="checkbox" (change)="sameAsCurrentAddress($event)">
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Country</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Country" [clearable]="true" [items]="jpNationalities"
                                    formControlName="cont_country"></ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Postal Code</strong>
                            </div>
                            <ng-container *ngIf="hasConstPostalCodeSearch; else noConstPostalCodeSearch">
                                <div class="col-md-8 col-lg-5 et">
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="cont_postal_code" placeholder="XXX-XXXX" mask="000-0000" [showMaskTyped]="true" placeHolderCharacter="X">
                                    </div>
                                </div>
                                <div class="col-md-8 col-lg-3 et">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-orange" (click)="getPermanentAddressByPostalCode()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="constAddressLoader"></i>Search</button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noConstPostalCodeSearch>
                                <div class="col-md-8 col-lg-5 et">
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="cont_postal_code" placeholder="Postal Code">
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Prefecture/Province</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-container *ngIf="hasConstProvince; else checkConstPrefectureAvailability">
                                    <ng-select class="customngselect" placeholder="Select Province" [clearable]="true" [items]="npProvinces" bindLabel="province_name" bindValue="province_name"
                                        formControlName="cont_prefecture"></ng-select>
                                </ng-container>
                                <ng-template #checkConstPrefectureAvailability>
                                    <ng-container *ngIf="hasConstPrefecture; else constDistrictOrPrefectureUnavailable">
                                        <ng-select class="customngselect" placeholder="Select Prefecture" [clearable]="true" [items]="jpJpPrefectures" bindLabel="name" bindValue="name"
                                            formControlName="cont_prefecture"></ng-select>
                                    </ng-container>
                                    <ng-template #constDistrictOrPrefectureUnavailable>
                                        <div class="form-group">
                                            <input class="form-control" type="text" formControlName="cont_prefecture" placeholder="Prefecture">
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>City/District</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-container *ngIf="hasConstCity; else constCityUnavailable">
                                    <ng-select class="customngselect" placeholder="Select City" [clearable]="true" [items]="contVisibleCityList" bindLabel="name" bindValue="name"
                                            formControlName="cont_city"></ng-select>
                                </ng-container>
                                <ng-template #constCityUnavailable>
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="cont_city" placeholder="City">
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Address</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="cont_address" placeholder="Address">
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Phone</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="number" formControlName="cont_phone" placeholder="Phone">
                                </div>

                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-template #nonEditablePermanentAddressForm>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Country</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enPermanentAddress.cont_country}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Postal Code</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enPermanentAddress.cont_postal_code}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Prefecture/Province</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enPermanentAddress.cont_prefecture}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>City/District</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enPermanentAddress.cont_city}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Address</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enPermanentAddress.cont_address}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Phone</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enPermanentAddress.cont_phone}}</span>
                            
                        </div>
                    </div>
                </ng-template>
                <div class="inner-title d-flex justify-content-between" *ngIf="contAddressEdit">
                    <div class="lt-btn">
                        <a class="btn-save" [ngClass]="jpResumePermanentAddressForm.valid ? 'link':'disabled'" (click)="jpResumePermanentAddressForm.valid ? savePermanentAddressEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_permanent_address'"></i>Save</a>
                    </div>
                    <i class="fas fa-times mr-1 link" (click)="tooglePermanentAddressEdit()"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="card-resume">
        <div class="card-item">
            <div class="d-flex justify-content-between">
                <div class="title d-flex">
                    <strong>Academics</strong>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="jp_academics_completition_rate + '%'" [attr.aria-valuenow]="jp_academics_completition_rate" aria-valuemin="0" aria-valuemax="100">{{jp_academics_completition_rate}}%</div>
                    </div>
                </div>
                <ng-container *ngIf="!academicEdit">
                    <a class="card-edit link" (click)="toogleAcademicEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-container>
                <!-- <ng-container *ngIf="academicEdit; else nonEditableAcademic">
                    <a class="btn-save" [ngClass]="jpResumeAcademicForm.valid ? 'link':'disabled'" (click)="jpResumeAcademicForm.valid ? saveAcademicsEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_academics'"></i>Save</a>
                    <a class="btn-add link" (click)="addEducationRepeatition()"><i class="fas fa-plus mr-1"></i>Add</a>
                    <a (click)="toogleAcademicEdit()"><i class="fas fa-times mr-1"></i></a>
                </ng-container>
                <ng-template #nonEditableAcademic>
                    <a class="card-edit link" (click)="toogleAcademicEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-template> -->
            </div>
            <ng-container *ngIf="academicEdit; else nonEditableAcademicForm">
                <form [formGroup]="jpResumeAcademicForm">
                    <ng-container *ngIf="educationGroupArray !== null && educationGroupArray !== undefined && educationGroupArray.length > 0">
                        <ng-container formArrayName="education" *ngFor="let group of educationGroupArray.controls; let i = index;">
                            <ng-container [formGroupName]="i">
                                <div class="card-inner mt-3" id="educationSection{{i}}">
                                    <div class="inner-title d-flex justify-content-between">
                                        <strong>Academic {{i+1}}</strong>
                                        <a class="btn-remove" [ngClass]="data_type_to_remove == 'education' ? 'disabled' : 'link'" (click)="data_type_to_remove == 'education' ? '' : removeData(i, group.get('id')?.value, 'education')"><i class="fa fa-spinner fa-spin mr-2" style="padding: 0px;" *ngIf="data_type_to_remove == 'education' && data_index_to_be_removed == i"></i>Remove</a>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <label>Name of Institute</label>
                                        </div>
                                        <div class="col-md-8 col-lg-5 et">
                                            <div class="form-group">
                                                <input class="form-control" type="text" formControlName="institute" placeholder="Name of Institute">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <strong>Major Subject</strong>
                                        </div>
                                        <div class="col-md-8 col-lg-5 et">
                                            <ng-select class="customngselect" placeholder="Select Major Subject" [clearable]="true" [items]="jpMajorSubjects"
                                            formControlName="major_subject"></ng-select>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <strong>Year of Enroll</strong>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Year" [clearable]="true" [items]="yearsList"
                                                    formControlName="start_year"></ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et mt-2 mt-md-0">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Month" [clearable]="true" [items]="monthsList"
                                                    formControlName="start_month"></ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <strong>Year of Completion</strong>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Year" [clearable]="true" [items]="academicEndYearList"
                                                    formControlName="completion_year"></ng-select>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="group.get('completion_year')?.value != '在学中'">
                                            <div class="col-md-8 col-lg-3 et mt-2 mt-md-0">
                                                <div class="form-group">
                                                    <ng-select class="customngselect" placeholder="Select Month" [clearable]="true" [items]="monthsList"
                                                        formControlName="completion_month"></ng-select>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="group.get('completion_year')?.hasError('checkAcademicDate') && (group.get('completion_year')?.dirty || group.get('completion_year')?.touched || (resumeUpdateRequested && resumeSectionUpdateRequest == 'en_academics'))">
                                            <div class="help-block text-danger mt-1">End year must be greater than start year</div>
                                        </ng-container>	
                                    </div>
                                    
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </form>
            </ng-container>
            <ng-template #nonEditableAcademicForm>
                <ng-container *ngFor="let academic of enAcademicList; let i= index">
                    <div class="card-inner mt-3">
                        <div class="inner-title d-flex justify-content-between">
                            <strong>Academic {{i+1}}</strong>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Name of Institute</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{academic.institute}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Major Subject</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{academic.major_subject}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Year of Enroll</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{academic.start_year}}{{academic.start_month ? '/' + academic.start_month : ''}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Year of Completion</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{academic.completion_year}}
                                    <ng-container *ngIf="academic.completion_year != '在学中'">
                                        {{academic.completion_month ? '/' + academic.completion_month : ''}}
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
            <div class="d-flex justify-content-between" *ngIf="academicEdit">
                <a class="btn-save" [ngClass]="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_academics' ? 'disabled':'link'" (click)="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_academics' ? '' : saveAcademicsEn()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_academics'"></i>Save</a>
                <a class="btn-add link" (click)="addEducationRepeatition()"><i class="fas fa-plus mr-1"></i>Add</a>
                <a (click)="toogleAcademicEdit()"><i class="fas fa-times mr-1"></i></a>
            </div>
        </div>
    </div>

    <div class="card-resume">
        <div class="card-item">
            <div class="d-flex justify-content-between">
                <div class="title d-flex">
                    <strong>Work Experience</strong>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="jp_work_completition_rate + '%'" [attr.aria-valuenow]="jp_work_completition_rate" aria-valuemin="0" aria-valuemax="100">{{jp_work_completition_rate}}%</div>
                    </div>
                </div>
                <ng-container *ngIf="!workEdit">
                    <a class="card-edit link" (click)="toogleWorkExperienceEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-container>
                <!-- <ng-container *ngIf="workEdit; else nonEditableWorkExperience">
                    <a class="btn-save" [ngClass]="jpResumeExperienceForm.valid ? 'link':'disabled'" (click)="jpResumeExperienceForm.valid ? saveWorkExperienceEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_work_experience'"></i>Save</a>
                    <a class="btn-add link" (click)="addExperienceRepeatition()"><i class="fas fa-plus mr-1"></i>Add</a>
                    <a (click)="toogleWorkExperienceEdit()"><i class="fas fa-times mr-1"></i></a>
                </ng-container>
                <ng-template #nonEditableWorkExperience>
                    <a class="card-edit link" (click)="toogleWorkExperienceEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-template> -->
            </div>
            <ng-container *ngIf="workEdit; else nonEditableWorkExperienceForm">
                <form [formGroup]="jpResumeExperienceForm">
                    <ng-container *ngIf="experienceGroupArray !== null && experienceGroupArray !== undefined && experienceGroupArray.length > 0">
                        <ng-container formArrayName="experience" *ngFor="let group of experienceGroupArray.controls; let i = index;">
                            <ng-container [formGroupName]="i">
                                <div class="card-inner mt-3" id="experienceSection{{i}}">
                                    <div class="inner-title d-flex justify-content-between">
                                        <strong>Work {{i+1}}</strong>
                                        <a class="btn-remove" [ngClass]="data_type_to_remove == 'experience' ? 'disabled' : 'link'" (click)="data_type_to_remove == 'experience' ? '' : removeData(i, group.get('id')?.value, 'experience')"><i class="fa fa-spinner fa-spin mr-2" style="padding: 0px;" *ngIf="data_type_to_remove == 'experience' && data_index_to_be_removed == i"></i>Remove</a>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <label>Name of the company</label>
                                        </div>
                                        <div class="col-md-8 col-lg-5 et">
                                            <div class="form-group">
                                                <input class="form-control" type="" formControlName="company_name" placeholder="Name of the company">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <label>Type of company</label>
                                        </div>
                                        <div class="col-md-8 col-lg-5 et">
                                            <ng-select class="customngselect" placeholder="Select Type of company" [clearable]="true" [items]="jpCompanyTypes"
                                                formControlName="company_type"></ng-select>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <strong>Started Year</strong>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Year" [clearable]="true" [items]="yearsList"
                                                    formControlName="start_year"></ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et mt-2 mt-md-0">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Month" [clearable]="true" [items]="monthsList"
                                                    formControlName="start_month"></ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <strong>End Year</strong>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Year" [clearable]="true" [items]="workEndYearList"
                                                    formControlName="end_year"></ng-select>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="group.get('end_year')?.value != '在職中'">
                                            <div class="col-md-8 col-lg-3 et mt-2 mt-md-0">
                                                <div class="form-group">
                                                    <ng-select class="customngselect" placeholder="Select Month" [clearable]="true" [items]="monthsList"
                                                        formControlName="end_month"></ng-select>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- <ng-container *ngIf="group.get('end_year')?.hasError('checkAcademicDate') && (group.get('end_year')?.dirty || group.get('end_year')?.touched || (resumeUpdateRequested && resumeSectionUpdateRequest == 'en_work_experience'))">
                                            <div class="help-block text-danger mt-1">You must accept Terms of Service and Privacy Policy</div>
                                        </ng-container>	 -->
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <label>Purpose of Resign</label>
                                        </div>
                                        <div class="col-md-8 col-lg-5 et">
                                            <div class="form-group">
                                                <input class="form-control" type="" formControlName="purpose_of_resign" placeholder="Purpose of Resign">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </form>
            </ng-container>
            <ng-template #nonEditableWorkExperienceForm>
                <ng-container *ngFor="let experience of enExperienceList; let i=index">
                    <div class="card-inner mt-3">
                        <div class="inner-title d-flex justify-content-between">
                            <strong>Work Experience {{i+1}}</strong>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Name of the company</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{experience.company_name}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Type of company</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{experience.company_type}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Started Year</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{experience.start_year}}{{experience.start_month ? '/' + experience.start_month : ''}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>End Year</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>
                                    {{experience.end_year}}
                                    <ng-container *ngIf="experience.end_year != '在職中'">
                                        {{experience.end_month ? '/' + experience.end_month : ''}}
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Purpose of resign</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{experience.purpose_of_resign}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
            <div class="d-flex justify-content-between" *ngIf="workEdit">
                <a class="btn-save" [ngClass]="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_work_experience' ? 'disabled':'link'" (click)="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_work_experience' ? '' : saveWorkExperienceEn()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_work_experience'"></i>Save</a>
                <a class="btn-add link" (click)="addExperienceRepeatition()"><i class="fas fa-plus mr-1"></i>Add</a>
                <a (click)="toogleWorkExperienceEdit()"><i class="fas fa-times mr-1"></i></a>
            </div>
        </div>
    </div>

    <div class="card-resume">
        <div class="card-item">
            <div class="d-flex justify-content-between">
                <div class="title d-flex">
                    <strong>Qualification</strong>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="jp_qualification_info_completition_rate + '%'" [attr.aria-valuenow]="jp_qualification_info_completition_rate" aria-valuemin="0" aria-valuemax="100">{{jp_qualification_info_completition_rate}}%</div>
                    </div>
                </div>
                <ng-container *ngIf="!qualificationEdit">
                    <a class="card-edit link" (click)="toogleQualificationEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-container>
                <!-- <ng-container *ngIf="qualificationEdit; else nonEditableQualification">
                    <a class="btn-save" [ngClass]="jpResumeQualificationForm.valid ? 'link':'disabled'" (click)="jpResumeQualificationForm.valid ? saveQualificationEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_qualification'"></i>Save</a>
                    <a class="btn-add link" (click)="addQualificationRepeatition()"><i class="fas fa-plus mr-1"></i>Add</a>
                    <a (click)="toogleQualificationEdit()"><i class="fas fa-times mr-1"></i></a>
                </ng-container>
                <ng-template #nonEditableQualification>
                    <a class="card-edit link" (click)="toogleQualificationEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-template> -->
            </div>
            <ng-container *ngIf="qualificationEdit; else nonEditableQualificationForm">
                <form [formGroup]="jpResumeQualificationForm">
                    <ng-container *ngIf="qualificationGroupArray !== null && qualificationGroupArray !== undefined && qualificationGroupArray.length > 0">
                        <ng-container formArrayName="qualification" *ngFor="let group of qualificationGroupArray.controls; let i = index;">
                            <ng-container [formGroupName]="i">
                                <div class="card-inner mt-3" id="qualificationSection{{i}}">
                                    <div class="inner-title d-flex justify-content-between">
                                        <strong>Qualification {{i+1}}</strong>
                                        <a class="btn-remove" [ngClass]="data_type_to_remove == 'qualification' ? 'disabled' : 'link'" (click)="data_type_to_remove == 'qualification' ? '' : removeData(i, group.get('id')?.value, 'qualification')"><i class="fa fa-spinner fa-spin mr-2" style="padding: 0px;" *ngIf="data_type_to_remove == 'qualification' && data_index_to_be_removed == i"></i>Remove</a>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <label>Name of qualification</label>
                                        </div>
                                        <div class="col-md-8 col-lg-5 et">
                                            <div class="form-group">
                                                <input class="form-control" type="" formControlName="qualification_name" placeholder="Qualification Name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row list">
                                        <div class="col-md-4 col-lg-3">
                                            <strong>Certified Year</strong>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Year" [clearable]="true" [items]="yearsList"
                                                    formControlName="certified_year"></ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-lg-3 et mt-2 mt-md-0">
                                            <div class="form-group">
                                                <ng-select class="customngselect" placeholder="Select Month" [clearable]="true" [items]="monthsList"
                                                    formControlName="certified_month"></ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </form>
            </ng-container>
            <ng-template #nonEditableQualificationForm>
                <ng-container *ngFor="let qualification of enQualificationList; let i = index">
                    <div class="card-inner mt-3">
                        <div class="inner-title d-flex justify-content-between">
                            <strong>Qualification {{i+1}}</strong>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Name of qualification</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{qualification.qualification_name}}</span>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Certified Year</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{qualification.certified_year}}{{qualification.certified_month ? '/' + qualification.certified_month : ''}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
            <div class="d-flex justify-content-between" *ngIf="qualificationEdit">
                <a class="btn-save" [ngClass]="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_qualification' ? 'disabled':'link'" (click)="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_qualification' ? '' : saveQualificationEn()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_qualification'"></i>Save</a>
                <a class="btn-add link" (click)="addQualificationRepeatition()"><i class="fas fa-plus mr-1"></i>Add</a>
                <a (click)="toogleQualificationEdit()"><i class="fas fa-times mr-1"></i></a>
            </div>
        </div>
    </div>

    <div class="card-resume">
        <div class="card-item">
            <div class="d-flex justify-content-between">
                <div class="title d-flex">
                    <strong>Others</strong>
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="jp_personal_info_completition_rate + '%'" [attr.aria-valuenow]="jp_personal_info_completition_rate" aria-valuemin="0" aria-valuemax="100">{{jp_personal_info_completition_rate}}%</div>
                    </div>
                </div>
                <ng-container *ngIf="!othersEdit">
                    <a class="card-edit link" (click)="toogleOthersEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-container>
                <!-- <ng-container *ngIf="othersEdit; else nonEditableOthers">
                    <a class="btn-save" [ngClass]="jpResumeOthersForm.valid ? 'link':'disabled'" (click)="jpResumeOthersForm.valid ? saveOthersInfoEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_others_info'"></i>Save</a>
                    <a (click)="toogleOthersEdit()"><i class="fas fa-times mr-1"></i></a>
                </ng-container>
                <ng-template #nonEditableOthers>
                    <a class="card-edit link" (click)="toogleOthersEdit()"><i class="fas fa-pen mr-1"></i></a>
                </ng-template> -->
            </div>
            <div class="card-inner mt-3">
                <ng-container *ngIf="othersEdit; else nonEditableOthersForm">
                    <form [formGroup]="jpResumeOthersForm">
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Languages</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Languages" [multiple]="true" [clearable]="true" [items]="jpKnownLanguages" bindLabel="label" bindValue="value"
                                    formControlName="known_languages"></ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Japanese Language Completence (JLPT Level)</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <ng-select class="customngselect" placeholder="Select JLPT Level" [clearable]="true" [items]="jpLanguageLevelList"
                                        formControlName="jlpt"></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Self PR</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <textarea class="form-control" formControlName="self_pr" placeholder="Self PR"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Hobbies</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Hobbies" [multiple]="true" [clearable]="true" [items]="jpHobbies"
                                    formControlName="hobbies"></ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Skills</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Select Skills" [multiple]="true" [clearable]="true" [items]="jpSkills"
                                    formControlName="skills"></ng-select>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Motivation and Appeal points</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="extra_point" placeholder="Motivation and Appeal points">
                                </div>
                            </div>
                        </div>
                        
                        <!-- <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Extra Points</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="extra_point" placeholder="Extra Points">
                                </div>
                            </div>
                        </div> -->
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Available Working Hours</label>
                            </div>
                            <div class="col-md-8 col-lg-3 et">
                                <div class="form-group">
                                    <ng-select class="customngselect" placeholder="Select Hour" [clearable]="true" [items]="hoursList"
                                        formControlName="available_working_hours"></ng-select>
                                </div>
                            </div>
                            <ng-container *ngIf="showWorkingMinutes">
                                <div class="col-md-8 col-lg-3 et mt-2 mt-md-0">
                                    <div class="form-group">
                                        <ng-select class="customngselect" placeholder="Select Minutes" [clearable]="true" [items]="minutesList"
                                            formControlName="available_working_minutes"></ng-select>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Number of Dependent (Excluding Spouse)</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <input class="form-control" type="number" formControlName="dependents_except_spouse" placeholder="Number of Dependent (Excluding Spouse)">
                                </div>
                            </div>
                        </div>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Spouse</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <ng-select class="customngselect" placeholder="Spouse" [clearable]="true" [items]="booleanValuesList"
                                    formControlName="spouse"></ng-select>
                            </div>
                        </div>
                        <ng-container *ngIf="hasSpouse">
                            <div class="row list">
                                <div class="col-md-4 col-lg-3">
                                    <label>Spouse Support Obligation</label>
                                </div>
                                <div class="col-md-8 col-lg-5 et">
                                    <ng-select class="customngselect" placeholder="Spouse Support Obligation" [clearable]="true" [items]="booleanValuesList"
                                        formControlName="spouse_support_obligation"></ng-select>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <label>Special Conditions or Request if any</label>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <div class="form-group">
                                    <textarea class="form-control" formControlName="special_conditions" placeholder="貴社の規定に従います"></textarea>
                                    <!-- <input class="form-control" type="text" formControlName="special_conditions" placeholder="Special Conditions or Request if any"> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-template #nonEditableOthersForm> 
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Languages</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.known_languages}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Japanese Language Completence (JLPT Level)</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.jlpt}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Self PR</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.self_pr}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Hobbies</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.hobbies}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Skills</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.skills}} </span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Motivation and Appeal points</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.extra_point}}</span>
                            
                        </div>
                    </div>
                    
                    <!-- <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Extra Points</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.extra_point}}</span>
                            
                        </div>
                    </div> -->
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Available Working Hours</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>
                                <ng-container *ngIf="enOthers.available_working_hours">
                                    <ng-container *ngIf="enOthers.available_working_hours == 'Fulltime'; else workingInHoursNMinutes">
                                        {{enOthers.available_working_hours}}
                                    </ng-container>
                                    <ng-template #workingInHoursNMinutes>
                                        {{enOthers.available_working_hours}} Hours {{enOthers.available_working_minutes}} Minutes
                                    </ng-template>
                                </ng-container>
                            </span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Number of Dependent (Excluding Spouse)</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.dependents_except_spouse}}</span>
                            
                        </div>
                    </div>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Spouse</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.spouse}}</span>
                            
                        </div>
                    </div>
                    <ng-container *ngIf="enOthers.spouse && enOthers.spouse == 'true'">
                        <div class="row list">
                            <div class="col-md-4 col-lg-3">
                                <strong>Spouse Support Obligation</strong>
                            </div>
                            <div class="col-md-8 col-lg-5 et">
                                <span>{{enOthers.spouse_support_obligation}}</span>
                                
                            </div>
                        </div>
                    </ng-container>
                    <div class="row list">
                        <div class="col-md-4 col-lg-3">
                            <strong>Special Conditions or Request if any</strong>
                        </div>
                        <div class="col-md-8 col-lg-5 et">
                            <span>{{enOthers.special_conditions}}</span>
                            
                        </div>
                    </div>
                </ng-template>
                
            </div>
            <div class="d-flex justify-content-between" *ngIf="othersEdit">
                <a class="btn-save" [ngClass]="jpResumeOthersForm.valid ? 'link':'disabled'" (click)="jpResumeOthersForm.valid ? saveOthersInfoEn() : ''"><i class="fa fa-spinner fa-spin mr-2" *ngIf="resumeUpdateRequested && resumeSectionUpdateRequest == 'en_others_info'"></i>Save</a>
                <a (click)="toogleOthersEdit()"><i class="fas fa-times mr-1"></i></a>
            </div>
        </div>
    </div>