<!-- Page header -->
<!-- <section class="page-header bg-news-list">
    <div class="container">
        <div class="row">
            <div class="col-md-12 page-title">
                <h2>News</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </div>
    </div>
</section> -->
<!-- End of Page header -->

<!-- Main contents -->
<section class="content content-section pt-85">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="newsList inner-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" (click)="toogleActivePanel('foryou')">
                            <a class="nav-link active" id="foryou-tab" data-toggle="tab" href="#for-you" role="tab" aria-controls="for-you" aria-selected="true">For You</a>
                        </li>
                        <li class="nav-item" (click)="toogleActivePanel('news')">
                            <a class="nav-link" id="news-tab" data-toggle="tab" href="#news" role="tab" aria-controls="news" aria-selected="false">News</a>
                        </li>
                        <!-- <li class="nav-item" (click)="toogleActivePanel('alert')">
                            <a class="nav-link" id="alerts-tab" data-toggle="tab" href="#alerts" role="tab" aria-controls="alerts" aria-selected="false">Alerts</a>
                        </li> -->
                        <li class="nav-item" (click)="toogleActivePanel('preference')">
                            <a class="nav-link" id="preference-tab" data-toggle="tab" href="#preference" role="tab" aria-controls="preference" aria-selected="false">News Preference</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="for-you" role="tabpanel">
                            <div class="row">	
                                <div class="col-md-12">
                                    <ng-container *ngIf="forYouNewsList.length > 0; else noNewsDataAvailableYet">
                                        <ng-container *ngFor="let news of forYouNewsList; let i=index; trackBy:trackNewsByLink;">
                                            <ng-container *ngIf="news == -1; else hasForYouNewsData">
                                                <h6 class="col-lg-12 text-center">
                                                    Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                                                </h6>
                                            </ng-container>
                                            <ng-template #hasForYouNewsData>
                                                <div class="news-item" id="foryou{{i}}">
                                                    <!-- <div class="img-box newsBox"> -->
                                                        <img [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link" (click)="openNewsUrl(news.link)">
                                                    <!-- </div> -->
                                                    <div class="card-item">
                                                        <div class="top-card d-flex">
                                                            <div class="date-time">
                                                                <span><i class="far fa-calendar-alt"></i>{{news.published_date | date:'MMM d, y'}}</span>
                                                                <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                                            </div>
                                                            <div class="news-tag">
                                                                <a href="#">{{news.category}}</a>
                                                            </div>
                                                        </div>
                                                        <div class="text-left mt-3">
                                                            <h3 class="link" (click)="openNewsUrl(news.link)">{{news.title}}</h3>
                                                            <p [innerHTML]="news.description" class="news-desc-from-api"></p>
                                                        </div>
                                                        <div class="news-source">
                                                            <div class="mt-2">
                                                                <img class="link img-fluid" [imagepreloader]="news.source_logo" [targetSection]="'newslogo'" (click)="openNewsUrl(news.link)">
                                                                <span class="ml-2">{{news.source | uppercase }}</span>
                                                            </div>
                                                            <button class="btn btn-orange" (click)="openNewsUrl(news.link)">Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="showForYouNewsLoadMore">
                                        <div class="row justify-content-center mt-5">
                                            <button type="submit" class="btn btn-orange s-btn" (click)="getForYouNews()">
                                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchNewsTriggered && forYouNewsList.length > 0"></i>{{fetchNewsTriggered && forYouNewsList.length > 0 ? 'Loading' : 'Load More'}}
                                            </button>
                                        </div>
                                    </ng-container>
                                    <ng-template #noNewsDataAvailableYet>
                                        <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
                                                            <div class="head-tabs">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="inner s-content" style="height:450px">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12 mt-4">
                                                                        <div class="inner s-content" style="height:450px">
                                                                        </div>
                                                                    </div>
                                                                </div>							
                                                            </div>												
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="news" role="tabpanel">
                            <div class="row">	
                                <div class="col-md-12">
                                    <ng-container *ngIf="newsList.length > 0; else noNewsDataAvailableYet">
                                        <ng-container *ngFor="let news of newsList; trackBy:trackNewsByLink;">
                                            <ng-container *ngIf="news == -1; else hasNewsData">
                                                <h6 class="col-lg-12 text-center">
                                                    Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                                                </h6>
                                            </ng-container>
                                            <ng-template #hasNewsData>
                                                <div class="news-item">
                                                    <!-- <div class="img-box newsBox"> -->
                                                        <img [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link" (click)="openNewsUrl(news.link)">
                                                    <!-- </div> -->
                                                    <div class="card-item">
                                                        <div class="top-card d-flex">
                                                            <div class="date-time">
                                                                <span><i class="far fa-calendar-alt"></i>{{news.published_date | date:'MMM d, y'}}</span>
                                                                <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                                            </div>
                                                            <div class="news-tag">
                                                                <a href="#">{{news.category}}</a>
                                                            </div>
                                                        </div>
                                                        <div class="text-left mt-3">
                                                            <h3 class="link" (click)="openNewsUrl(news.link)">{{news.title}}</h3>
                                                            <p [innerHTML]="news.description" class="news-desc-from-api"></p>
                                                        </div>
                                                        <div class="news-source">
                                                            <div class="mt-2">
                                                                <img class="link img-fluid" (click)="openNewsUrl(news.link)" [imagepreloader]="news.source_logo" [targetSection]="'newslogo'">
                                                                <span class="ml-2">{{news.source | uppercase }}</span>
                                                            </div>
                                                            <button class="btn btn-orange" (click)="openNewsUrl(news.link)">Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="showNewsLoadMore">
                                        <div class="row justify-content-center mt-5">
                                            <button type="submit" class="btn btn-orange s-btn" (click)="getNews()">
                                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchLatestNewsTriggered && newsList.length > 0"></i>{{fetchLatestNewsTriggered && newsList.length > 0 ? 'Loading' : 'Load More'}}
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="preference" role="tabpanel">
                            <div class="row">	
                                <div class="col-md-12">
                                    <ng-container *ngIf="newsList.length > 0; else noNewsDataAvailableYet">
                                        <ng-container *ngFor="let news of newsList; trackBy:trackNewsByLink;">
                                            <ng-container *ngIf="news == -1; else hasPreferenceNewsData">
                                                <h6 class="col-lg-12 text-center">
                                                    Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                                                </h6>
                                            </ng-container>
                                            <ng-template #hasPreferenceNewsData>
                                                <div class="news-item">
                                                    <!-- <div class="img-box newsBox"> -->
                                                        <img [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link" (click)="openNewsUrl(news.link)">
                                                    <!-- </div> -->
                                                    <div class="card-item">
                                                        <div class="top-card d-flex">
                                                            <div class="date-time">
                                                                <span><i class="far fa-calendar-alt"></i>{{news.published_date | date:'MMM d, y'}}</span>
                                                                <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                                            </div>
                                                            <div class="news-tag">
                                                                <a href="#">{{news.category}}</a>
                                                            </div>
                                                        </div>
                                                        <div class="text-left mt-3">
                                                            <h3 class="link" (click)="openNewsUrl(news.link)">{{news.title}}</h3>
                                                            <p [innerHTML]="news.description" class="news-desc-from-api"></p>
                                                        </div>
                                                        <div class="news-source">
                                                            <div class="mt-2">
                                                                <img class="img-fluid link" (click)="openNewsUrl(news.link)" [imagepreloader]="news.source_logo" [targetSection]="'newslogo'">
                                                                <span class="ml-2">{{news.source | uppercase }}</span>
                                                            </div>
                                                            <button class="btn btn-orange" (click)="openNewsUrl(news.link)">Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="showNewsLoadMore">
                                        <div class="row justify-content-center mt-5">
                                            <button type="submit" class="btn btn-orange s-btn" (click)="getNews()">
                                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="fetchLatestNewsTriggered && newsList.length > 0"></i>{{fetchLatestNewsTriggered && newsList.length > 0 ? 'Loading' : 'Load More'}}
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>						
                </div>
            </div>
            <ng-container *ngIf="activeTabPanel == 'foryou' && isLoggedIn; else newsPreferencePanel">
                <div class="col-md-4 right-card">
					<div class="p-tags gener">
						<h4 class="d-block">Genre</h4>
						<span class="d-block mb-3">Subscribe to topics of interest to surface the stories you want to read</span>
						<ng-container *ngIf="newsGenreList.length > 0; else genreUnavailable">
                            <div class="tag-lists mt-4">
                                <ng-container *ngFor="let genre of newsGenreList; trackBy:trackGenreByGenrename;">
                                    <a (click)="addSelectedGenres(genre)" [ngClass]="checkIfGenreIsSelectedOrNot(genre) ? 'link active' : 'link'">{{genre}}</a>
                                </ng-container>
                            </div>
                            <hr>
                            <button [ngClass]="allGenreSelected ? 'btn-orange': 'btn-select-all-genre'" (click)="selectAllGenre()" style="margin-right: 10px;outline: none;">Select All</button>
                            <button class="btn btn-orange" (click)="storeUserNewsPreferences('genre')"><i class="fa fa-spinner fa-spin mr-2" *ngIf="requestedPreferenceToSave == 'genre'"></i>Save</button>
                        </ng-container>
                        <ng-template #genreUnavailable>
                            <!-- Main contents -->
                            <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12 right-card">
                                            <div class="p-tags">
                                                <div class="tag-lists">
                                                    <a href="#" class="btn">International</a>
                                                    <a href="#" class="btn">Polictics</a>
                                                    <a href="#" class="btn">Business</a>
                                                    <a href="#" class="btn">Sports</a>
                                                    <a href="#" class="btn">Education</a>
                                                    <a href="#" class="btn">Digital</a>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- End of Main contents -->
                        </ng-template>
					</div>
				</div>
            </ng-container>
            <ng-template #newsPreferencePanel>
                <ng-container *ngIf="activeTabPanel == 'preference' && isLoggedIn; else newsPanel">
                    <div class="col-md-4 right-card">
                        <div class="s-preference">
                            <h4 class="d-block">Preference</h4>
                            <span class="d-block mb-3">Select your favourite Language</span>
                            <div id="accordion">
                                    <ng-container *ngIf="newsPreferencesList.length > 0; else fetchingLanguageAndSources">
                                        <ng-container *ngFor="let preference of newsPreferencesList; let i=index; trackBy:trackNewsPreferences;">
                                            <ng-container *ngIf="preference == -1; else hasLanguageAndSourceForPreference">
                                                <h6 class="col-lg-12">
                                                    Data Unavailable.
                                                </h6>
                                            </ng-container>
                                            <ng-template #hasLanguageAndSourceForPreference>
                                                <ng-container *ngIf="i == 0">
                                                    <div class="card">
                                                        <div class="card-header" id="headingOne">
                                                            <div class="form-check d-inline">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="selectAllLanguageAndSources($event)" [checked]="allLanguageAndSourceSelected">
                                                            </div>
                                                            <h5 class="mb-0 d-inline-block">
                                                                <button class="btn btn-link btn-block text-left collapsed">
                                                                    <span>Select All</span>
                                                                </button>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="card">
                                                    <div class="card-header" id="headingOne">
                                                        <div class="form-check d-inline">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="addSelectedLanguage(preference)" [checked]="preference.checked">
                                                        </div>
                                                        <h5 class="mb-0 d-inline-block">
                                                            <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" [attr.data-target]="'#collapse' + i" aria-expanded="false" aria-controls="collapseOne">
                                                                <i class="fa" aria-hidden="true"></i>
                                                                <span>{{preference.name}} ({{preference.sources.length}})</span>
                                                            </button>
                                                        </h5>
                                                    </div>
                    
                                                    <div id="collapse{{i}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                        <div class="card-body">
                                                            <ng-container *ngIf="preference.sources.length > 0 ;else newsSourceUnavailable">
                                                                <ul>
                                                                    <ng-container *ngFor="let source of preference.sources; trackBy:trackNewsPreferences;">
                                                                        <li (click)="addSelectedSources(preference.code, source.slug)">
                                                                            <a [ngClass]="source.checked ? 'link active' : 'link'">
                                                                                <img class="img-fluid" [imagepreloader]="source.image" [targetSection]="'newslogo'">
                                                                                <span>{{source.name | uppercase}}</span>
                                                                            </a>
                                                                        </li>
                                                                    </ng-container>
                                                                </ul>
                                                            </ng-container>
                                                            <ng-template #newsSourceUnavailable>
                                                                News sources unavailable.
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="i == newsPreferencesList.length-1">
                                                    <hr>
                                                    <button class="btn btn-orange" (click)="storeUserNewsPreferences('languagesource')"><i class="fa fa-spinner fa-spin mr-2" *ngIf="requestedPreferenceToSave == 'languagesource'"></i>Save</button>
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #fetchingLanguageAndSources>
                                        <!-- Main contents -->
                                        <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                            <div class="container">
                                                                <div class="card">
                                                                    <div class="card-header d-flex" id="headingOne">
                                                                        <div class="mt-1 mr-2 " style="border:1px solid #ddd; width: 15px; height: 15px; border-radius: 3px;"></div>								
                                                                        <h5 class="mb-0 d-inline-block">
                                                                            <!-- <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><i class="fa" aria-hidden="true"></i> -->
                                                                                <!-- <i class="fa" aria-hidden="true" class="float-right"></i> -->
                                                                                <span>English (10)</span>
                                                                            <!-- </button> -->
                                                                        </h5>
                                                                    </div>

                                                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                                        <div class="card-body">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="#" class="active"> <span>CNN</span></a>												
                                                                                </li>
                                                                                
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-header d-flex" id="headingTwo">
                                                                        <div class="mt-1 mr-2 " style="border:1px solid #ddd; width: 15px; height: 15px; border-radius: 3px;"></div>
                                                                        <h5 class="mb-0 d-inline-block">
                                                                            <!-- <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> -->
                                                                                <!-- <i class="fa" aria-hidden="true"></i> -->
                                                                                
                                                                                <span>English (USA) (4)</span>
                                                                            <!-- </button> -->
                                                                        </h5>
                                                                    </div>
                                                                    <!-- <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                        <div class="card-body">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="#"><span>CNN</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-header d-flex" id="headingTwo">
                                                                        <div class="mt-1 mr-2 " style="border:1px solid #ddd; width: 15px; height: 15px; border-radius: 3px;"></div>
                                                                        <h5 class="mb-0 d-inline-block">
                                                                            <!-- <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> -->
                                                                                <!-- <i class="fa" aria-hidden="true"></i> -->
                                                                                
                                                                                <span>English (USA) (4)</span>
                                                                            <!-- </button> -->
                                                                        </h5>
                                                                    </div>
                                                                    <!-- <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                        <div class="card-body">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="#"><span>CNN</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-header d-flex" id="headingTwo">
                                                                        <div class="mt-1 mr-2 " style="border:1px solid #ddd; width: 15px; height: 15px; border-radius: 3px;"></div>
                                                                        <h5 class="mb-0 d-inline-block">
                                                                            <!-- <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> -->
                                                                                <!-- <i class="fa" aria-hidden="true"></i> -->
                                                                                
                                                                                <span>English (USA) (4)</span>
                                                                            <!-- </button> -->
                                                                        </h5>
                                                                    </div>
                                                                    <!-- <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                        <div class="card-body">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="#"><span>CNN</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-header d-flex" id="headingTwo">
                                                                        <div class="mt-1 mr-2" style="border:1px solid #ddd; width: 15px; height: 15px; border-radius: 3px;"></div>
                                                                        <h5 class="mb-0 d-inline-block">
                                                                            <!-- <button class="btn btn-link btn-block text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> -->
                                                                                <!-- <i class="fa" aria-hidden="true"></i> -->
                                                                                
                                                                                <span>English (USA) (4)</span>
                                                                            <!-- </button> -->
                                                                        </h5>
                                                                    </div>
                                                                    <!-- <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                                        <div class="card-body">
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="#"><span>CNN</span></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                                <hr>
                                                                <button class="btn btn-orange">Save</button>
                                            </div>
                                        </section>
                                        <!-- End of Main contents -->
                                    </ng-template>
                                </div>
                                <!-- <ng-container *ngIf="newsPreferencesList.length > 0">
                                    <hr>
                                    <button class="btn btn-orange" (click)="storeUserNewsPreferences('languagesource')"><i class="fa fa-spinner fa-spin mr-2" *ngIf="requestedPreferenceToSave == 'languagesource'"></i>Save</button>
                                </ng-container> -->
                            </div>
                        </div>
                </ng-container>
                <ng-template #newsPanel>
                    <div class="col-md-4 right-card">
                        <div class="recent-post">
                            <h4 class="d-block mb-4">Recent Post</h4>
                            <ng-container *ngIf="latestNewsList.length > 0; else noRecentNews">
                                <ng-container *ngFor="let news of latestNewsList; let i=index; trackBy:trackNewsByLink;">
                                    <ng-container *ngIf="i < 3">
                                        <ng-container *ngIf="news == -1; else hasLatestNewsData">
                                            <h6 class="col-lg-12">
                                                Sorry, but nothing matched your news preferences. Please try again with some different news preferences.
                                            </h6>
                                        </ng-container>
                                        <ng-template #hasLatestNewsData>
                                            <div class="post-list">
                                                <!-- <div class="img-box newsBox"> -->
                                                    <img (click)="openNewsUrl(news.link)" [imagepreloader]="news.image" [targetSection]="'news'" class="img-fluid link">
                                                <!-- </div> -->
                                                <div class="post-info ml-3">
                                                    <a (click)="openNewsUrl(news.link)">{{news.title}}</a>
                                                    <span>{{news.published_date | date:'MMM d, y'}}</span>
                                                    <span class="ml-4"><i class="fas fa-clock"></i>{{news.published_date | dateAgo}}</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="p-tags mt-4 mt-md-5">
                            <h4 class="d-block mb-4">Popular Tags</h4>
                            <div class="tag-lists">
                                <ng-container *ngIf="popularTags.length > 0; else popularTagsUnavailable">
                                    <ng-container *ngFor="let tags of popularTags; trackBy:trackGenreByGenrename;">
                                        <ng-container *ngIf="tags == '-1'; else hasPopularTags">
                                            <h6 class="col-lg-12">
                                                Popular tags unavailable.
                                            </h6>
                                        </ng-container>
                                        <ng-template #hasPopularTags>
                                            <a href="#">{{tags}}</a>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                                <ng-template #popularTagsUnavailable>
                                    <!-- Main contents -->
                                    <section class="content content-section loading-skeleton avoid-skeleton-padding">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12 right-card">
                                                    <div class="p-tags">
                                                        <div class="tag-lists">
                                                            <a href="#" class="btn">International</a>
                                                            <a href="#" class="btn">Polictics</a>
                                                            <a href="#" class="btn">Business</a>
                                                            <a href="#" class="btn">Sports</a>
                                                            <a href="#" class="btn">Education</a>
                                                            <a href="#" class="btn">Digital</a>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <!-- End of Main contents -->
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <ng-template #noRecentNews>
                <!-- Main contents -->
                <section class="content content-section loading-skeleton avoid-skeleton-padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 right-card">
                                <div class="recent-post">
                                    <div class="post-list">
                                        <div class="s-default-img" style="height: 90px; width: 100px"></div>
                                        <div class="post-info ml-3">
                                            <p>Lorem ipsum dolor sit amet idjed sde</p>
                                            <span>4 February, 2021</span>
                                        </div>
                                    </div>
                                    <div class="post-list">
                                        <div class="s-default-img" style="height: 90px; width: 100px"></div>
                                        <div class="post-info ml-3">
                                            <p>Lorem ipsum dolor sit ame sdsed</p>
                                            <span>4 February, 2021</span>
                                        </div>
                                    </div>
                                    <div class="post-list">
                                        <div class="s-default-img" style="height: 90px; width: 100px"></div>
                                        <div class="post-info ml-3">
                                            <p>Lorem ipsum dolor sdfasdf de sdfes</p>
                                            <span>4 February, 2021</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- End of Main contents -->
            </ng-template>
        </div>
    </div>
</section>
<!-- End of Main contents -->