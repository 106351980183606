import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  private baseUrl = environment.apiUrl;
  private resumeUrl = environment.resumeUrl;
  /**
   * configuring required headers
   */
   private authHeader = new HttpHeaders({
    auth: 'true',
    'Content-Type': 'application/json',
    'cache': 'true',
    'cache_group': 'resume'
   });

  constructor(private http: HttpClient) { }

  getResumeDetails() {
    return this.http.get(this.baseUrl + '/resume/', {headers: this.authHeader});
  }

  downloadResume(language: string, userUuid: string) {
    const resumeHeader = new HttpHeaders({
      Authorization: 'Bearer ' + userUuid,
      'cache': 'false'
     });
    return this.http.get(this.resumeUrl + '/api/jobseeker/resume-pdf/' + language, {headers: resumeHeader.set('cache', 'false')});
  }

  getAddressByPostalCode(postal_code: any) {
    let resumeHeader = new HttpHeaders({
      'cache': 'true',
      'cache_group': 'resume'
     });
    return this.http.get(this.baseUrl + '/postal_codes/'+ postal_code+'/details/', {headers: resumeHeader});
  }
  
  getCityOrDistrict(country: String, prefecture: String, language: String) {
    let resumeHeader = new HttpHeaders({
      'cache': 'true',
      'cache_group': 'resume'
     });
    return this.http.get(this.baseUrl + '/resume/get_city_district/'+ country +'/' + prefecture + '/' + language, {headers: resumeHeader});
  }

  updateEnglishResume(resumeDetails: any, userUuid: string, language: string) {
    let resumeHeader = new HttpHeaders({
      auth: 'true',
      'cache': 'false',
      'cache_group': 'resume'
     });
     return this.http.post(this.baseUrl + '/profile_update/' + language, resumeDetails, {headers: resumeHeader});
    // const resumeHeader = new HttpHeaders({
    //   Authorization: 'Bearer ' + userUuid,
    //   'Content-Type': 'application/json',
    //   'cache_group': 'resume'
    //  });
    // return this.http.post('https://nspresume.truestreamz.com/api/jobseeker/update_profile/' + language, enResumeDetails, {headers: resumeHeader});
  }

  removeResumeData(data_id: number, data_type: string) {
    let resumeHeader = new HttpHeaders({
      auth: 'true',
      'cache': 'false',
      'cache_group': 'resume'
     });
     return this.http.delete(this.baseUrl + '/resume/delete/' + data_id + '/' + data_type, {headers: resumeHeader});
  }
}
