import { ImagepreloaderDirective } from './imagepreloader.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ImagepreloaderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImagepreloaderDirective
  ]
})
export class ImagepreloadermoduleModule { }
