<ng-container *ngIf="hasResponse; else noResponse">
<!-- Page header -->
<section class="page-header bg-header-next">
  <div class="container">
    <div class="row">
      <div class="col-md-12 page-title text-center">
        <h2>Profile</h2>
      </div>
    </div>
  </div>
</section>
<!-- End of Page header -->
  <!-- Main contents -->
  <section class="content content-section">
    <div class="container">
      <div class="row">
        <ng-template #userProfileUploadSection>
          <div class="inner-title d-flex justify-content-between">
            <strong>User Photo</strong>
          </div>
          <div class="inner-detail mt-3 d-flex">
            <img class="profile-img userPhoto" [src]="selectedProfileImageUrl != '' ? selectedProfileImageUrl : 'assets/img/avatar-img.jpg'" onerror="this.src='assets/img/avatar-img.jpg'">
            <div class="upload-btn-wrapper btn-changeUser">
              <button href="#" class="change-Userpic" (click)="profileImageSelectionSection.click()"><i class="fas fa-pen mr-1"></i></button>
              <!-- <input hidden type="file" accept="image/*" #profileImage (change)="onProfileImageSelect(profileImage.files)"> -->
              <input hidden type="file" accept="image/*" #profileImageSelectionSection (change)="onProfileImageSelect($event)">
            </div>
          </div>
          <ng-container *ngIf="profileImageSelected">
            <button class="btn btn-orange mt-1" (click)="saveProfileImage()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="profileImageUpdateRequested"></i>Save</button>
          </ng-container>
        </ng-template>
        <ng-container *ngIf="!isKYCVerified; else verifiedProfile">
        <div class="col-lg-8">
          <div class="inner-manner inner-tabs">
            <div class="head-tabs">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="tab-1" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1"
                    aria-selected="true">Personal Details</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="tab-2" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2"
                    aria-selected="false">Documents Details</a>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                <div class="row">
                  <div class="col-md-12">
                    <form class="frm-content">
                      <div class="inner-section">
                        <div class="inner-title d-flex justify-content-between">
                          <strong>Personal Info</strong>
                          <ng-container *ngIf="!personalInfoEdit">
                            <a (click)="tooglePersonalInfoEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-container>
                          <!-- <ng-container *ngIf="personalInfoEdit; else nonEditablePresonalInfoForm">
                            <button type="button" class="btn btn-on-edit" (click)="savePersonalInfo()">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="showPersonalInfoLoader"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="tooglePersonalInfoEdit()"></i>
                          </ng-container>
                          <ng-template #nonEditablePresonalInfoForm>
                            <a (click)="tooglePersonalInfoEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-template> -->
                          
                        </div>
                        <div class="inner-detail mt-3 d-flex">
                          <img class="profile-img" [src]="selectedProfileImageUrl != '' ? selectedProfileImageUrl : 'assets/img/avatar-img.jpg'" onerror="this.src='assets/img/avatar-img.jpg'">
                          <div class="ml-4 mt-1">
                            <div class="p-head">
                              <strong>{{ userProfile?.first_name }} {{ userProfile?.last_name }}</strong>
                              <span class="active-info ml-3"><i class="fas fa-times-circle mr-1"></i>Unverifed</span>
                            </div>
                            <div class="p-detail mt-4">
                              <span>{{ userProfile?.nationality }}</span><br>
                              <span>{{ userProfile?.email }}</span><br>
                              <span>{{ userProfile?.mobile_phone }}</span><br>
                            </div>
                          </div>
                        </div>
                        <form [formGroup]="personalInfoForm">
                          <div class="row mt-4">
                            <div class="form-group col-md-6">
                              <label>First Name</label>
                              <input class="form-control" formControlName="first_name" placeholder="First Name">
                              <ng-container *ngIf="personalInfoForm.controls.first_name.errors && (personalInfoForm.controls.first_name.dirty || personalInfoForm.controls.first_name.touched || personalInfoUpdateRequested)">
                                <ng-container *ngIf="personalInfoForm.controls.first_name.errors?.required">
                                    <div class="help-block text-danger mt-1">Please enter your first name</div>
                                </ng-container>
                            </ng-container>
                            </div>
                            <div class="form-group col-md-6">
                              <label>Last Name</label>
                              <input class="form-control" formControlName="last_name" placeholder="Last Name">
                              <ng-container *ngIf="personalInfoForm.controls.last_name.errors && (personalInfoForm.controls.last_name.dirty || personalInfoForm.controls.last_name.touched || personalInfoUpdateRequested)">
                                <ng-container *ngIf="personalInfoForm.controls.last_name.errors?.required">
                                    <div class="help-block text-danger mt-1">Please enter your Last name</div>
                                </ng-container>
                            </ng-container>
                            </div>
                            <div class="form-group col-md-12">
                              <label>Furigana</label>
                              <input class="form-control" formControlName="furigana" placeholder="Furigana">
                            </div>
                            <!-- <div class="form-group col-md-6">
                              <label>Father Name</label>
                              <input class="form-control" formControlName="father_name" placeholder="Father Name">
                            </div>
                            <div class="form-group col-md-6">
                              <label>Mother Name</label>
                              <input class="form-control" formControlName="mother_name" placeholder="Mother Name">
                            </div>
                            <div class="form-group col-md-6">
                              <label>Grandfather Name</label>
                              <input class="form-control" formControlName="grandfather_name"
                                placeholder="Grandfather Name">
                            </div> -->
                            <div class="form-group col-md-6">
                              <label>Company</label>
                              <input class="form-control" formControlName="company" placeholder="Company">
                            </div>
                            <div class="form-group col-md-6">
                              <label>Nationality</label>
                              <ng-select class="customngselect" placeholder="Select Nationality" [clearable]="true"
                                [items]="nationalityList" formControlName="nationality"></ng-select>
                            </div>
                            <div class="form-group col-md-6">
                              <label>Profession</label>
                              <ng-select class="customngselect" placeholder="Select Profession" [clearable]="true"
                                [items]="professionList" formControlName="profession"></ng-select>
                            </div>
                            <div class="form-group col-md-6">
                              <label>Gender</label>
                              <ng-select class="customngselect" placeholder="Select Gender" [clearable]="true"
                                [items]="genderList" formControlName="gender">
                              </ng-select>
                            </div>
                            <div class="form-group col-md-6">
                              <label>Marital Status</label>
                              <ng-select class="customngselect" placeholder="Select Marital Status" [clearable]="true"
                                [items]="marriedStatusList" formControlName="marital_status">
                              </ng-select>
                            </div>
                            <div class="form-group col-md-6">
                              <label>Date Of Birth</label>
                              <input id="datepicker" class="form-control" formControlName="dob"
                                placeholder="Select Date Of Birth" ngbDatepicker #d="ngbDatepicker" [minDate]="minimumDate"
                                (click)="d.toggle()">
                            </div>
                            <div class="form-group col-md-6">
                              <label>Mobile Phone</label>
                              <input class="form-control" formControlName="mobile_phone" placeholder="Mobile Phone">
                            </div>
                            <div class="form-group col-md-6">
                              <label>Other Phone</label>
                              <input class="form-control" formControlName="other_phone" placeholder="Other Phone">
                            </div>
                            <div class="form-group col-md-6">
                              <label>Email</label>
                              <input class="form-control" formControlName="email" placeholder="Email">
                              <ng-container *ngIf="personalInfoForm.controls.email.errors && (personalInfoForm.controls.email.dirty || personalInfoForm.controls.email.touched || personalInfoUpdateRequested)">
                                <ng-container *ngIf="personalInfoForm.controls.email.errors?.required">
                                    <div class="help-block text-danger mt-1">Please enter your email address</div>
                                </ng-container>
                                <ng-container *ngIf="personalInfoForm.controls.email.errors?.email">
                                    <div class="help-block text-danger mt-1">Please enter a valid email</div>
                                </ng-container>
                            </ng-container>
                            </div>
                          </div>
                        </form>
                        <div class="inner-title d-flex justify-content-between">
                          <ng-container *ngIf="personalInfoEdit">
                            <button type="button" class="btn btn-on-edit" (click)="savePersonalInfo()">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="showPersonalInfoLoader"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="tooglePersonalInfoEdit()"></i>
                          </ng-container>
                        </div>

                      </div>
                      <div class="inner-section">
                        <div class="inner-title d-flex justify-content-between">
                          <strong>Origin/Permanent Address</strong>
                          <ng-container *ngIf="!originAddressEdit">
                            <a (click)="toogleOriginAddressEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-container>
                          <!-- <ng-container *ngIf="originAddressEdit; else nonEditableOriginAddressForm">
                            <button type="button" class="btn btn-on-edit" [ngClass]="originAddressForm.valid ? 'link':'disabled'"
                              (click)="originAddressForm.valid ? saveOriginAddressInfo() : ''">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="originAddressUpdateRequested"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="toogleOriginAddressEdit()"></i>
                          </ng-container>
                          <ng-template #nonEditableOriginAddressForm>
                            <a (click)="toogleOriginAddressEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-template> -->
                        </div>
                        <div class="inner-detail d-flex">
                          <form [formGroup]="originAddressForm">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <label>Country</label>
                                <ng-select class="customngselect" placeholder="Select Country" [clearable]="true"
                                  [items]="nationalityList" formControlName="country_of_origin"></ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label>Postal Code</label>
                                <ng-container *ngIf="originPostalCodeSearch; else noOriginPostalCodeSearch">
                                  <input type="text" class="form-control" formControlName="origin_postal_code" placeholder="XXX-XXXX" mask="000-0000" [showMaskTyped]="true" placeHolderCharacter="X">
                                  <div class="row justify-content-center">
                                    <button class="btn btn-on-edit link mt-2" (click)="searchOriginAddressByPostalCode()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="originAddressSearchLoader"></i>Search</button>
                                  </div>  
                                </ng-container>
                                <ng-template #noOriginPostalCodeSearch>
                                  <input class="form-control" formControlName="origin_postal_code" placeholder="Postal Code">
                                </ng-template>
                              </div>
                              <div class="form-group col-md-6">
                                <label>Prefecture/Province</label>
                                <ng-container *ngIf="originHasProvince; else checkOriginPrefectureAvailability">
                                  <ng-select class="customngselect" placeholder="Select Prefecture/Province"
                                    [clearable]="true" [items]="provinceList" bindLabel="province_name"
                                    bindValue="province_name" formControlName="origin_province"></ng-select>
                                </ng-container>
                                <ng-template #checkOriginPrefectureAvailability>
                                  <ng-container *ngIf="originHasPrefecture; else originDistrictOrPrefectureUnavailable">
                                    <ng-select class="customngselect" placeholder="Select Prefecture"
                                      [clearable]="true" [items]="prefectureList" bindLabel="name"
                                      bindValue="name" formControlName="origin_province"></ng-select>
                                  </ng-container>
                                  <ng-template #originDistrictOrPrefectureUnavailable>
                                    <input class="form-control" type="text" formControlName="origin_province"
                                      placeholder="Prefecture">
                                  </ng-template>
                                </ng-template>
                              </div>
                              <div class="form-group col-md-6">
                                <label>City/District</label>
                                <ng-container *ngIf="originHasCity; else originCityUnavailable">
                                  <ng-select class="customngselect" placeholder="Select City" [clearable]="true"
                                    [items]="originCityList" bindLabel="name" bindValue="name"
                                    formControlName="origin_city_district"></ng-select>
                                </ng-container>
                                <ng-template #originCityUnavailable>
                                  <div class="form-group">
                                    <input class="form-control" type="text" formControlName="origin_city_district"
                                      placeholder="City">
                                  </div>
                                </ng-template>
                              </div>
                              <div class="form-group col-md-6">
                                <label>Address</label>
                                <input class="form-control" formControlName="origin_street_address"
                                  placeholder="Street Address">
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="inner-title d-flex justify-content-between">
                          <ng-container *ngIf="originAddressEdit">
                            <button type="button" class="btn btn-on-edit" [ngClass]="originAddressForm.valid ? 'link':'disabled'"
                              (click)="originAddressForm.valid ? saveOriginAddressInfo() : ''">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="originAddressUpdateRequested"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="toogleOriginAddressEdit()"></i>
                          </ng-container>
                        </div>
                      </div>
                      <div class="inner-section">
                        <div class="inner-title d-flex justify-content-between">
                          <strong>Residence/Current Address</strong>
                          <ng-container *ngIf="!residenceAddressEdit">
                            <a (click)="toogleResidenceAddressEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-container>
                          <!-- <ng-container *ngIf="residenceAddressEdit; else nonEditableResidenceAddressForm">
                            <button type="button" class="btn btn-on-edit" [ngClass]="residenceAddressForm.valid ? 'link':'disabled'"
                              (click)="residenceAddressForm.valid ? saveResidenceAddressInfo() : ''">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="residenceAddressUpdateRequested"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="toogleResidenceAddressEdit()"></i>
                          </ng-container>
                          <ng-template #nonEditableResidenceAddressForm>
                            <a (click)="toogleResidenceAddressEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-template> -->
                        </div>
                        <div class="inner-detail d-flex">
                          <form [formGroup]="residenceAddressForm">
                            <div class="row mt-2">
                              <ng-container *ngIf="residenceAddressEdit">
                                <div class="form-group col-md-12">
                                  <input type="checkbox" (change)="sameAsOriginAddress($event)">&nbsp;&nbsp;
                                  <label>Same as Origin/Permanent Address</label>
                                </div>
                              </ng-container>
                              <div class="form-group col-md-6">
                                <label>Country</label>
                                <ng-select class="customngselect" placeholder="Select Country" [clearable]="true"
                                  [items]="nationalityList" formControlName="country_of_residence"></ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label>Postal Code</label>
                                <ng-container *ngIf="residencePostalCodeSearch; else noResidencePostalCodeSearch">
                                  <input type="text" class="form-control" formControlName="postal_code" placeholder="XXX-XXXX" mask="000-0000" [showMaskTyped]="true" placeHolderCharacter="X">
                                  <div class="row justify-content-center">
                                    <button class="btn btn-on-edit link mt-2" (click)="searchResidenceAddressByPostalCode()"><i class="fa fa-spinner fa-spin mr-2" *ngIf="residenceAddressSearchLoader"></i>Search</button>
                                  </div> 
                                </ng-container>
                                <ng-template #noResidencePostalCodeSearch>
                                  <input class="form-control" formControlName="postal_code" placeholder="Postal Code">
                                </ng-template>
                              </div>
                              <div class="form-group col-md-6">
                                <label>Prefecture/Province</label>
                                <ng-container *ngIf="residenceHasProvince; else checkResidencePrefectureAvailability">
                                  <ng-select class="customngselect" placeholder="Select Prefecture/Province"
                                    [clearable]="true" [items]="provinceList" bindLabel="province_name"
                                    bindValue="province_name" formControlName="province"></ng-select>
                                </ng-container>
                                <ng-template #checkResidencePrefectureAvailability>
                                  <ng-container
                                    *ngIf="residenceHasPrefecture; else residenceDistrictOrPrefectureUnavailable">
                                    <ng-select class="customngselect" placeholder="Select Prefecture"
                                      [clearable]="true" [items]="prefectureList" bindLabel="name"
                                      bindValue="name" formControlName="province"></ng-select>
                                  </ng-container>
                                  <ng-template #residenceDistrictOrPrefectureUnavailable>
                                    <input class="form-control" type="text" formControlName="province"
                                      placeholder="Prefecture">
                                  </ng-template>
                                </ng-template>
                              </div>
                              <div class="form-group col-md-6">
                                <label>City/District</label>
                                <ng-container *ngIf="residenceHasCity; else residenceCityUnavailable">
                                  <ng-select class="customngselect" placeholder="Select City" [clearable]="true"
                                    [items]="residenceCityList" bindLabel="name" bindValue="name"
                                    formControlName="city"></ng-select>
                                </ng-container>
                                <ng-template #residenceCityUnavailable>
                                  <div class="form-group">
                                    <input class="form-control" type="text" formControlName="city" placeholder="City">
                                  </div>
                                </ng-template>
                              </div>
                              <!-- <div class="form-group col-md-6">
                                <label>Building Name</label>
                                <input class="form-control" formControlName="building_name" placeholder="Building Name">
                              </div> -->
                              <div class="form-group col-md-6">
                                <label>Address</label>
                                <input class="form-control" formControlName="street_address"
                                  placeholder="Address">
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="inner-title d-flex justify-content-between">
                          <ng-container *ngIf="residenceAddressEdit">
                            <button type="button" class="btn btn-on-edit" [ngClass]="residenceAddressForm.valid ? 'link':'disabled'"
                              (click)="residenceAddressForm.valid ? saveResidenceAddressInfo() : ''">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="residenceAddressUpdateRequested"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="toogleResidenceAddressEdit()"></i>
                          </ng-container>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="tab2" role="tabpanel">
                <div class="row">
                  <div class="col-md-12">
                    <form class="frm-content">
                      <div class="inner-section">
                        
                        <ng-container [ngTemplateOutlet]="userProfileUploadSection"></ng-container>
                        
                      </div>
                      <div class="inner-section">
                        <div class="inner-title d-flex justify-content-between">
                          <strong>KYC for Country of Origin</strong>
                          <ng-container *ngIf="!originDocEdit">
                            <a (click)="toogleOriginDocEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-container>
                          <!-- <ng-container *ngIf="originDocEdit; else nonEditableOriginDocumentForm">
                            <button type="button" class="btn btn-on-edit" [ngClass]="originDocumentForm.valid ? 'link':'disabled'"
                              (click)="originDocumentForm.valid ? saveOriginDocuments() : ''">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="originDocUpdateRequested"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="toogleOriginDocEdit()"></i>
                          </ng-container>
                          <ng-template #nonEditableOriginDocumentForm>
                            <a (click)="toogleOriginDocEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-template> -->
                          
                        </div>
                        <div class="inner-detail">
                          <form [formGroup]="originDocumentForm">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <label>Document Type</label>
                                <ng-select class="customngselect" placeholder="Select Document Type" [clearable]="true"
                                  [items]="documentTypes" formControlName="origin_kyc_doc_type"></ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label>{{selectedDocumentTypeForOriginCountry}}</label>
                                <input type="text" class="form-control" formControlName="origin_kyc_doc_no" placeholder="{{selectedDocumentTypeForOriginCountry}}"/>
                              </div>
                              <div class="form-group upload-doc col-md-6">
                                <label>Document</label>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="upload-btn-wrapper next">
                                      <div class="btn-upload-doc "><img [src]="originKYCDocFrontUrl != '' ? originKYCDocFrontUrl : 'assets/img/icon-uploadDoc.svg'" (click)="originDocEdit ? originKYCDocFront.click() : ''" style="height: 70px;"></div>
                                      <input hidden type="file" accept="image/*" #originKYCDocFront (change)="onOriginKYCFrontSelect(originKYCDocFront.files)">
                                    </div>
                                    <span class="d-block text-center mt-3">Front</span>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="upload-btn-wrapper next">
                                      <div class="btn-upload-doc "><img [src]="originKYCDocBackUrl != '' ? originKYCDocBackUrl : 'assets/img/icon-uploadDoc.svg'" (click)="originDocEdit ? originKYCDocBack.click() : ''" style="height: 70px;"></div>
                                      <input hidden type="file" accept="image/*" #originKYCDocBack (change)="onOriginKYCBackSelect(originKYCDocBack.files)">
                                    </div>
                                    <span class="d-block text-center mt-3">Back</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div class="inner-title d-flex justify-content-between">
                            <ng-container *ngIf="originDocEdit">
                              <button type="button" class="btn btn-on-edit" [ngClass]="originDocumentForm.valid ? 'link':'disabled'"
                                (click)="originDocumentForm.valid ? saveOriginDocuments() : ''">
                                  <i class="fa fa-spinner fa-spin mr-2" *ngIf="originDocUpdateRequested"></i>Save
                              </button>
                              <i class="fas fa-times mr-1 link" (click)="toogleOriginDocEdit()"></i>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="inner-section">
                        <div class="inner-title d-flex justify-content-between">
                          <strong>KYC for Japan</strong>
                          <ng-container *ngIf="!residenceDocEdit">
                            <a (click)="toogleResidenceDocEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-container>
                          <!-- <ng-container *ngIf="residenceDocEdit; else nonEditableResidenceDocumentForm">
                            <button type="button" class="btn btn-on-edit" [ngClass]="residenceDocumentForm.valid ? 'link':'disabled'"
                              (click)="residenceDocumentForm.valid ? saveResidenceDocuments() : ''">
                                <i class="fa fa-spinner fa-spin mr-2" *ngIf="residenceDocUpdateRequested"></i>Save
                            </button>
                            <i class="fas fa-times mr-1 link" (click)="toogleResidenceDocEdit()"></i>
                          </ng-container>
                          <ng-template #nonEditableResidenceDocumentForm>
                            <a (click)="toogleResidenceDocEdit()" class="edit-innerCard"><i class="fas fa-pen mr-1"></i></a>
                          </ng-template> -->
                        </div>
                        <div class="inner-detail">
                          <form [formGroup]="residenceDocumentForm">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <label>Document Type</label>
                                <ng-select class="customngselect" placeholder="Select Document Type" [clearable]="true"
                                  [items]="jpDocumentTypes" formControlName="kyc_doc_type"></ng-select>
                              </div>
                              <div class="form-group col-md-6">
                                <label>{{selectedDocumentTypeForJapan}}</label>
                                <input type="text" class="form-control" formControlName="kyc_doc_no" placeholder="{{selectedDocumentTypeForJapan}}"/>
                              </div>
                              <div class="form-group upload-doc col-md-6">
                                <label>Document</label>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="upload-btn-wrapper next">
                                      <div class="btn-upload-doc "><img [src]="residenceKYCDocFrontUrl != '' ? residenceKYCDocFrontUrl : 'assets/img/icon-uploadDoc.svg'" (click)="residenceDocEdit ? residenceKYCDocFront.click() : ''" style="height: 70px;"></div>
                                      <input hidden type="file" accept="image/*" #residenceKYCDocFront (change)="onResidenceKYCFrontSelect(residenceKYCDocFront.files)">
                                    </div>
                                    <span class="d-block text-center mt-3">Front</span>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="upload-btn-wrapper next">
                                      <div class="btn-upload-doc "><img [src]="residenceKYCDocBackUrl != '' ? residenceKYCDocBackUrl : 'assets/img/icon-uploadDoc.svg'" (click)="residenceDocEdit ? residenceKYCDocBack.click() : ''" style="height: 70px;"></div>
                                      <input hidden type="file" accept="image/*" #residenceKYCDocBack (change)="onResidenceKYCBackSelect(residenceKYCDocBack.files)">
                                    </div>
                                    <span class="d-block text-center mt-3">Back</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div class="inner-title d-flex justify-content-between">
                            <ng-container *ngIf="residenceDocEdit">
                              <button type="button" class="btn btn-on-edit" [ngClass]="residenceDocumentForm.valid ? 'link':'disabled'"
                                (click)="residenceDocumentForm.valid ? saveResidenceDocuments() : ''">
                                  <i class="fa fa-spinner fa-spin mr-2" *ngIf="residenceDocUpdateRequested"></i>Save
                              </button>
                              <i class="fas fa-times mr-1 link" (click)="toogleResidenceDocEdit()"></i>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
        <ng-template #verifiedProfile>
          <div class="col-lg-8">
            <div class="inner-manner inner-tabs">
              <div class="head-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="tab-1" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Personal Details</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="tab-2" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Document Details</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                  <div class="row">
                    <div class="col-md-12">	
                      <form class="frm-content">	
                        <div class="inner-section">										
                          <div class="inner-title d-flex justify-content-between">
                            <strong>Personal Info</strong>
                          </div>
                          <div class="inner-detail mt-3 d-flex">
                            <img class="profile-img" [src]="userProfile?.avatar ? userProfile?.avatar : 'assets/img/avatar-img.jpg'" onerror="this.src='assets/img/avatar-img.jpg'">
                            <div class="ml-4 mt-1">
                              <div class="p-head">
                                <strong>{{ userProfile?.first_name }} {{ userProfile?.last_name }}</strong>
                                <span class="active-info verified ml-3"><i class="fas fa-check-circle mr-1"></i>Verifed</span>
                              </div>
                              <div class="p-detail mt-4">
                                <span>{{ userProfile?.nationality }}</span>
                                                              <span>{{ userProfile?.email }}</span>
                                                              <span>{{ userProfile?.mobile_phone }}</span>
                              </div>
                            </div>													
                          </div>
                          <div class="inner-detail mt-3">
                            <div class="row mt-4">
                              <div class="form-group col-md-6">
                                <strong>First Name</strong>
                                <span>{{ userProfile?.first_name }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Last Name</strong>
                                <span>{{ userProfile?.last_name }} </span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Furigana</strong>
                                <span>{{ userProfile?.furigana }}</span>
                              </div>

                              <div class="form-group col-md-6">
                                <strong>Company</strong>
                                <span>{{ userProfile?.company }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Profession</strong>
                                <span>{{ userProfile?.profession }} </span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Nationality</strong>
                                <span>{{ userProfile?.nationality }}</span>
                              </div>

                              <div class="form-group col-md-6">
                                <strong>Gender</strong>
                                <span>{{ userProfile?.gender }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Marital Status</strong>
                                <span>{{ userProfile?.marital_status }} </span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Date of Birth</strong>
                                <span>{{ userProfile?.dob }}</span>
                              </div>

                              <div class="form-group col-md-6">
                                <strong>Mobile Phone</strong>
                                <span>{{ userProfile?.mobile_phone }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Other Phone</strong>
                                <span>{{ userProfile?.other_phone }} </span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Email</strong>
                                <span>{{ userProfile?.email }}</span>
                              </div>
                              <!-- <div class="form-group col-md-6">
                                <strong>Father Name</strong>
                                <span>{{ userProfile?.father_name }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Mother Name</strong>
                                <span>{{ userProfile?.mother_name }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Grandfather Name</strong>
                                <span>{{ userProfile?.grandfather_name }}</span>
                              </div>	 -->
                              <!-- <div class="form-group col-md-6">
                                <strong>Passport/Citizenship No.</strong>
                                <span>{{ userProfile?.passport_citizenship_number }}</span>
                              </div>												 -->
                            </div>
                          </div>
                        </div>
                        <div class="inner-section">										
                          <div class="inner-title d-flex justify-content-between">
                            <strong>Origin/Permanent Address</strong>
                          </div>
                          <div class="inner-detail d-flex">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <strong>Country</strong>
                                <span>{{ userProfile?.country_of_origin }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Postal Code</strong>
                                <span>{{ userProfile?.origin_postal_code }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Prefecture/Province</strong>
                                <span>{{ userProfile?.origin_province }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>City/District</strong>
                                <span>{{ userProfile?.origin_city_district }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Address</strong>
                                <span>{{ userProfile?.origin_street_address }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="inner-section">										
                          <div class="inner-title d-flex justify-content-between">
                            <strong>Residence/Current Address</strong>
                          </div>
                          <div class="inner-detail d-flex">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <strong>Country</strong>
                                <span>{{ userProfile?.country_of_residence }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Postal Code</strong>
                                <span>{{ userProfile?.postal_code }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Preffecture/Province</strong>
                                <span>{{ userProfile?.province }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>City/District</strong>
                                <span>{{ userProfile?.city }}</span>
                              </div>
                              <!-- <div class="form-group col-md-6">
                                <strong>Building Name</strong>
                                <span>{{ userProfile?.building_name }}</span>
                              </div> -->
                              <div class="form-group col-md-6">
                                <strong>Address</strong>
                                <span>{{ userProfile?.street_address }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab2" role="tabpanel">
                  <div class="row">	
                    <div class="col-md-12">
                      <form class="frm-content">	
                        <div class="inner-section">
                          <!-- <div class="inner-title d-flex justify-content-between">
                            <strong>User Photo</strong>
                          </div>
                          <div class="inner-detail mt-3 d-flex">
                            <img class="profile-img userPhoto" [src]="userProfile?.avatar != '' ? userProfile?.avatar : 'assets/img/avatar-img.jpg'">
                          </div> -->
                          <ng-container [ngTemplateOutlet]="userProfileUploadSection"></ng-container>
                        </div>
                        <div class="inner-section">
                          <div class="inner-title d-flex justify-content-between">
                            <strong>KYC for Country of Origin</strong>
                          </div>
                          <div class="inner-detail">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <strong>Document Type</strong>
                                <span>{{userProfile?.origin_kyc_doc_type}}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Document Number</strong>
                                <span>{{userProfile?.origin_kyc_doc_no}}</span>
                              </div>
                              <div class="form-group upload-doc col-md-12">
                                <div class="row">
                                  <div class="col-md-6">
                                    <strong>Document Front</strong>
                                    <div class="inner-border">
                                      <img src="{{userProfile.origin_kyc_doc_front? assetBaseUrl + userProfile.origin_kyc_doc_front : 'assets/img/icon-uploadDoc.svg'}}" onerror="this.src='assets/img/icon-uploadDoc.svg'" style="height: 185px;">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <strong>Document Back</strong>
                                    <div class="inner-border">
                                      <img src="{{userProfile.origin_kyc_doc_back? assetBaseUrl + userProfile.origin_kyc_doc_back : 'assets/img/icon-uploadDoc.svg'}}" onerror="this.src='assets/img/icon-uploadDoc.svg'" style="height: 185px;">
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="inner-section">
                          <div class="inner-title d-flex justify-content-between">
                            <strong>KYC for Japan</strong>
                          </div>
                          <div class="inner-detail">
                            <div class="row mt-2">
                              <div class="form-group col-md-6">
                                <strong>Document Type</strong>
                                <span>{{userProfile?.kyc_doc_type}}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <strong>Document Number</strong>
                                <span>{{userProfile?.kyc_doc_no}}</span>
                              </div>
                              <div class="form-group upload-doc col-md-12">
                                <div class="row">
                                  <div class="col-md-6">
                                    <strong>Document Front</strong>
                                    <div class="inner-border">
                                      <img src="{{userProfile.kyc_doc_front ? assetBaseUrl + userProfile.kyc_doc_front : 'assets/img/icon-uploadDoc.svg'}}" onerror="this.src='assets/img/icon-uploadDoc.svg'" style="height: 185px;">
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <strong>Document Back</strong>
                                    <div class="inner-border">
                                      <img src="{{ userProfile.kyc_doc_back ? assetBaseUrl + userProfile.kyc_doc_back : 'assets/img/icon-uploadDoc.svg'}}" onerror="this.src='assets/img/icon-uploadDoc.svg'" style="height: 185px;">
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </form>
                    </div>
                  </div>
                </div>
              </div>				
            </div>						
          </div>
        </ng-template>
        <div class="col-lg-4">
          <div class="right-card-box mt-3 mt-md-0">
            <h4 class="d-block mb-3">Status</h4>
            <hr class="mt-0">
            <div class="d-flex justify-content-between">
              <div class="balance">
                <span>Available Balance</span>
                <h5>JPY {{userProfile?.balance}}</h5>
                <!-- <h5>JPY {{userProfile?.balance  | number: '1.0-0'}}</h5> -->
                <a [routerLink]="['/topup']" class="btn btn-orange mt-2"><i class="fas fa-plus pr-1"></i>Add Balance</a>
              </div>
              <div class="points">
                <span class="d-block">Points</span>
                <strong>{{userProfile?.point}}</strong>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- End of Main contents -->

<!-- Modal for displaying selected image for cropping-->
<div class="modal fade" id="cropImageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="3/4"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <div class="modal-footer align-content-center justify-content-center">
        <button type="button" class="btn-select-all-genre" (click)="cancelCroppedImageUpload()">CANCEL</button>
        <button type="button" class="btn btn-orange" (click)="readyToUploadCroppedImage()">OK</button>
      </div>
    </div>
  </div>
</div>
</ng-container>
<ng-template #noResponse>
  <!-- Page header -->
  <section>
    <div class="container-fluid loading-skeleton">
      <div class="row">
        <div class="col-md-12 no-padding">
          <div class="s-banner inner">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Page header -->

  <!-- Main contents -->
  <section class="content content-section loading-skeleton">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="servicesList inner-tabs inner-service nav-tabs-wrapper">
            <div class="head-tabs">
              <div class="row">
                <div class="col-md-12">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <p>Personal Details</p>
                    </li>
                    <li class="nav-item">
                      <p>Documents Details</p>
                    </li>
                  </ul>
                </div>
                <div class="col-md-12">
                  <div class="inner s-content" style="height:700px">
                  </div>
                </div>
              </div>							
            </div>												
          </div>
        </div>
        <div class="col-md-4">
          <div class="inner s-content" style="height: 250px">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Main contents -->
</ng-template>

