import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/utils/data-share.service';
import { environment } from 'src/environments/environment';
import { PartnerServicesService } from '../service/partner-services.service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  assetBaseUrl = environment.assetBaseUrl;
  hasResponse: boolean = false;
  // hasCategorywiseData: boolean = false;
  partner_service_id: number = 0;
  partner_service_category_name = '';
  partnerServiceDetails: any;
  categoryPartnerServiceList: any[] = [];

  constructor(private service: PartnerServicesService, private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private dataShareService: DataShareService, private router: Router) {
      this.partner_service_category_name = this.activatedRoute.snapshot.paramMap.get('categoryName') || '';
      this.partner_service_id = Number(this.activatedRoute.snapshot.paramMap.get('serviceId')) || 0;
     }

  ngOnInit(): void {
    this.getJapaneseMannerDetails();
    this.getPartnerServiceByCategoryName();
    // this.getFeaturedPartnerServices();
  }

  // getFeaturedPartnerServices() {
  //   this.service.getAllPartnerServices(1).pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.featuredPartnerServiceList = res.partner_services;
  //     }, (err: any) => {
  //       this.toastr.error('Unable to fetch featured partner services', 'ERROR');
  //     });
  // }

  getJapaneseMannerDetails() {
    this.service.getPartnerServiceDetails(this.partner_service_id).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.partnerServiceDetails = res.partner_services[0];
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse = false;
        // this.toastr.error('Unable to fetch japanese manner details', 'ERROR');
      });
  }

  /**
   * function to fetch partner services by category
   */
  getPartnerServiceByCategoryName() {
    this.service.getPartnerServicesByServiceCategoryName(this.partner_service_category_name, 1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.categoryPartnerServiceList = res.partner_services;
        if(this.categoryPartnerServiceList.length <= 0) {
          this.categoryPartnerServiceList = [-1];
        }
        // this.hasCategorywiseData = true;
      }, (err: any) => {
        // this.hasCategorywiseData =  false;
        // this.toastr.error('Failed to fetch partner services by category', 'ERROR');
      });
  }

  partnerServiceDetail(partnerService: any) {
    this.partnerServiceDetails = partnerService;
  }

  /**
   * function to redirect user to partner services listing page with the clicked category as active category
   */
   redirectUsersToListingPage(category: any) {
    this.router.navigate(['/partner-services'])
    .then(() => {
      this.dataShareService.changePartnerServiceActiveCategory(category);
    });
  }

  /**
   * function to redirect user to external link of partner service
   * @param link partner service external link
   */
   openPartnerServiceUrl(link: string) {
    window.open(link, '_blank');
  }

  trackById(index: number, item: any): any {
    if(item != -1 && item != '-1') {
      return item.id;
    }
    return item;
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
