import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CheckPasswordMatch, CheckPasswordPattern } from '../utils/custom.validators';
import { DataShareService } from '../utils/data-share.service';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  private returnUrl = '';
  loggedIn: boolean= false;
  private ngUnsubscribe: Subject<any> = new Subject();
  registerForm!: FormGroup;
  registerRequested: boolean = false;
  registerLoader: boolean = false;
  loginForm!: FormGroup;
  loginRequested: boolean = false;
  loginLoader: boolean = false;
  facebookLoginInProgress: boolean = false;
  googleLoginInProgress: boolean = false;
  /**
   * variables declared for show/hode password
   */
  passwordInputType: string = 'password';
  signUpPasswordInputType: string = 'password';
  signUpConfirmPasswordInputType: string = 'password';
  // namePattern: string = '[a-zA-Z0-9]*';

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private authService: SocialAuthService, private toastr: ToastrService, private router: Router,
    private dataShareService: DataShareService, private activatedRoute: ActivatedRoute) {
      if (localStorage.getItem('a_token') || sessionStorage.getItem('a_token')) {
        this.router.navigate(['/']);
      }
      const returnRoute = (this.activatedRoute.snapshot.queryParamMap.get('returnUrl')) || ''; 
      this.returnUrl = returnRoute == '' ? '/' : '/'+returnRoute;
    }

  ngOnInit(): void {
    // initializing the register form
    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), CheckPasswordPattern]],
      password_confirmation: ['', [Validators.required]],
      tc: [false, Validators.requiredTrue],
    }, {validator: CheckPasswordMatch('password', 'password_confirmation')});
    // detecting change in mobile field and if the value's length is greater than 0
    // set validation of min length 10 for mobile field
    // this.registerForm.get('mobile')?.valueChanges
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .pipe(debounceTime(500))
    //   .subscribe(value => {
    //     let mobileField = this.registerForm.get('mobile');
    //     if(value.length > 0) {
    //       mobileField?.setValidators([Validators.minLength(10), Validators.maxLength(10)]);
    //     } else { 
    //       mobileField?.setValidators(null);
    //     }
    //     mobileField?.updateValueAndValidity();
    //   });
    
    // initializing the login form
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: [false],
    });
  }

/**
 * function for registering a user
 * @returns 
 */
register() {
  this.registerRequested = true;
  if (this.registerForm.invalid) {
    return;
  }
  this.registerLoader = true;
  let registerData = {
    first_name: this.registerForm.controls.first_name.value,
    last_name: this.registerForm.controls.last_name.value,
    email: this.registerForm.controls.email.value,
    password: this.registerForm.controls.password.value,
    password_confirmation: this.registerForm.controls.password_confirmation.value,
    firebaseToken: localStorage.getItem('fb-token') ? localStorage.getItem('fb-token') : 'n/a',
    created_gps : localStorage.getItem('created_gps') ? localStorage.getItem('created_gps') : 'n/a',
  }
  const bae64EncodedEmail = btoa(this.registerForm.controls.email.value);
  this.authenticationService.requestRegistration(registerData)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      console.log(res)
      this.registerLoader = false;
      this.registerRequested = false;
      
      this.toastr.clear();
      this.toastr.success(res.success);
      this.router.navigate(['/verify-email/' + bae64EncodedEmail]);
      this.registerForm.reset();
    }, (err: any) => {
      console.log(err)
      this.registerLoader = false;
      this.registerRequested = false;
      this.toastr.clear();
      const errorValue = err.error.error[Object.keys(err.error.error)[0]][0];
      if(err.status == 422 && errorValue == 'Please activate your email using code') {
        this.router.navigate(['/verify-email/' + bae64EncodedEmail]);
      }
      const errors = err.error.error;
      Object.keys(errors).forEach(key => {
        this.toastr.error(errors[key][0], key)
      });
    });
}

/**
 * function for user login using email and password
 */
login() {
  this.loginRequested = true;
  if (this.loginForm.invalid) {
    return;
  }
  this.loginLoader = true;
  let loginData = {
    email: this.loginForm.controls.email.value,
    password: this.loginForm.controls.password.value,
    firebaseToken: localStorage.getItem('fb-token') ? localStorage.getItem('fb-token') : 'n/a',
    last_login_gps : localStorage.getItem('created_gps') ? localStorage.getItem('created_gps') : 'n/a',
  }
  let remember = this.loginForm.controls.rememberMe.value;
  this.authenticationService.requestLogin(loginData)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      // console.log("Login response ", res);
      this.loginLoader= false;
      this.loginRequested = false;
      this.toastr.success('Logged in', 'Success');
      if(remember) {
        sessionStorage.removeItem('a-token');
        localStorage.setItem('a_token', res.token.access);
        sessionStorage.removeItem('r-token');
        localStorage.setItem('r_token', res.token.refresh);
        sessionStorage.removeItem('avatar');
        localStorage.removeItem('avatar');
        if(res.token.avatar) {
          localStorage.setItem('avatar', res.token.avatar);
        }
      } else {
        localStorage.removeItem('a-token');
        sessionStorage.setItem('a_token', res.token.access);
        localStorage.removeItem('r-token');
        sessionStorage.setItem('r_token', res.token.refresh);
        localStorage.removeItem('avatar');
        sessionStorage.removeItem('avatar');
        if(res.token.avatar) {
          sessionStorage.setItem('avatar', res.token.avatar);
        }
      }
      localStorage.setItem('social', '0');
      this.dataShareService.changeLoginStatus(true);
      this.router.navigate([this.returnUrl]);
      this.loginForm.reset();
    }, (err: any) => {
      this.loginRequested = false;
      this.loginLoader= false;
      this.toastr.error(err.error.error);
      if(err.error.error == 'Please verify your email!') {
        const bae64EncodedEmail = btoa(this.loginForm.controls.email.value);
        this.router.navigate(['/verify-email/' + bae64EncodedEmail]);
        this.loginForm.reset();
      }
    });
}

signInWithGoogle(): void {
  this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userData => {
    // console.log("Google login data ", userData);
    let googleUserRegistration = {
      first_name : userData.firstName,
      last_name : userData.lastName,
      // mobile : '' ,
      email: userData.email,
      gmail_id : userData.id,
      avatar : userData.photoUrl,
      firebaseToken: localStorage.getItem('fb-token') ? localStorage.getItem('fb-token') : 'n/a',
      created_gps : localStorage.getItem('created_gps') ? localStorage.getItem('created_gps') : 'n/a',
      last_login_gps: localStorage.getItem('last_login_gps') ? localStorage.getItem('last_login_gps') : 'n/a',
    }
    this.googleLoginInProgress = true;
    this.authenticationService.requestGoogleLogin(googleUserRegistration)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      this.googleLoginInProgress = false;
      this.toastr.success('Logged in', 'Success');
      sessionStorage.removeItem('a-token');
      localStorage.setItem('a_token', res.token.access);
      sessionStorage.removeItem('r-token');
      localStorage.setItem('r_token', res.token.refresh);
      sessionStorage.removeItem('avatar');
      if(res.token.avatar) {
        localStorage.setItem('avatar', res.token.avatar);
      }
      localStorage.setItem('social', '1');
      this.dataShareService.changeLoginStatus(true);
      this.router.navigate([this.returnUrl]);
      // this.loginForm.reset();
    }, (err: any) => {
      this.googleLoginInProgress = false;
      this.toastr.error('Failed to login', 'Error');
    });
  });
}

signInWithFB(): void {
  this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userData => {
    const fullName = userData.name.split(" ");
    const fname = fullName[0];
    let lname = "";
    if(fullName.length > 1) {
      lname = fullName[fullName.length -1];
    }
    let facebookUserRegistration = {
      first_name : fname,
      last_name : lname,
      // mobile: '',
      email: userData.email,
      facebook_id : userData.id,
      avatar : userData.photoUrl,
      firebaseToken: localStorage.getItem('fb-token') ? localStorage.getItem('fb-token') : 'n/a',
      created_gps : localStorage.getItem('created_gps') ? localStorage.getItem('created_gps') : 'n/a',
      last_login_gps: localStorage.getItem('last_login_gps') ? localStorage.getItem('last_login_gps') : 'n/a',
    }
    this.facebookLoginInProgress = true;
    this.authenticationService.requestFacebookLogin(facebookUserRegistration)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      this.facebookLoginInProgress = false;
      this.toastr.success('Logged in', 'Success');
      sessionStorage.removeItem('a-token');
      localStorage.setItem('a_token', res.token.access);
      sessionStorage.removeItem('r-token');
      localStorage.setItem('r_token', res.token.refresh);
      sessionStorage.removeItem('avatar');
      if(res.token.avatar) {
        localStorage.setItem('avatar', res.token.avatar);
      }
      localStorage.setItem('social', '1');
      this.dataShareService.changeLoginStatus(true);
      this.router.navigate([this.returnUrl]);
    }, (err: any) => {
      this.facebookLoginInProgress = false;
      this.toastr.error('Failed to login', 'Error');
    });
  });
}

/**
 * function to show password
 */
showPassword() {
  this.passwordInputType = 'text';
}

/**
 * function to hide password
 */
hidePassword() {
  this.passwordInputType = 'password';
}

/**
 * function to show signup password
 */
 showSignupPassword() {
  this.signUpPasswordInputType= 'text';
}

/**
 * function to hide signup password
 */
hideSignupPassword() {
  this.signUpPasswordInputType = 'password';
}

/**
 * function to show signup confirm password
 */
 showSignupConfirmPassword() {
  this.signUpConfirmPasswordInputType = 'text';
}

/**
 * function to hide signup confirm password
 */
hideSignupConfirmPassword() {
  this.signUpConfirmPasswordInputType = 'password';
}

/**
 * unsubcribing active subscription to avaoid memory leak
 */
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
