import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadBalanceService } from './service/load-balance.service';
import * as KhaltiCheckout from "khalti-checkout-web";
import { TransactionService } from '../transaction/service/transaction.service';

declare var $: any;

@Component({
  selector: 'app-load-balance',
  templateUrl: './load-balance.component.html',
  styleUrls: ['./load-balance.component.css']
})
export class LoadBalanceComponent implements OnInit, OnDestroy {

  @ViewChild('esewaPaymentInitiationForm') esewaPaymentInitiationForm!: ElementRef;
  private ngUnsubscribe: Subject<any> = new Subject();
  hasResponse: boolean = false;
  hasUserBalanceResponse = false;
  numberRegex = '^[0-9]*$';
  assetBaseUrl = environment.assetBaseUrl;
  userBalance: number = 0;
  userId: number = 0;
  userReedemPoints: any = 0;
  isUserKYCVefified: boolean = false;
  currency_conversion_rate = 0;

  
  creditCardList: any[] = [];
  paymentList: any[] = [];
  /**
   * variabled declared for esewa
   */
  // esewaDetails: any = '';
  esewaForm!: FormGroup;
  esewaFormSubmitted: boolean = false;
  esewaBalanceLimit: number = 1000;
  esewaLowerBalanceLimit: number = 100;
  esewaMerchant = '';
  esewaBaseUrl = '';
  esewaTopupAmount = 0;
  esewaSuccessUrl = environment.baseUrl + '/topup/?status=success&gateway=esewa';
  esewaErrorUrl = environment.baseUrl + '/topup/?status=error&gateway=esewa';
  randomAlphaNumeric = '';
  esewaAmount: number = 0
  esewaConvertedAmount: number = 0;
  /**
   * payment verification status for topup
   */
  paymentVerificationStatus: string = 'PENDING';
  paymentErrorMessage: string = 'Topup failed. Please try again.';
  paymentGateway: string = '';
  paymentStatus: string = '';
  paymentReferenceID: string = '';
  /**
   * variables declared to hold esewa payment redirect responses
   */
  // esewaPaymentStatus = 'error';
  esewaOid = '';
  esewaRefId = '';
  /**
   * variabled declared for stripe/credit card payment
   */
  creditCardForm!: FormGroup;
  creditCardFormSubmitted: boolean = false;
  displayCreditCardLoader: boolean = false;
  cardBalanceLimit: number = 1000;
  cardLowerBalanceLimit: number = 100;
  /**
   * variables declared for payment from saved cards
   */
  savedCardSelected: boolean = false;
  savedCardForm!: FormGroup;
  savedCardFormSubmitted: boolean = false;
  displaySavedCardLoader: boolean = false;
  cardDetailsToRemove: any;
  removeCardLoader: boolean = false;

  /**
    * variables declared for khalti payment
    */
  khaltiForm!: FormGroup;
  khaltiToken: any;
  checkout: any;
  khaltiFormSubmitted: boolean = false;
  khaltiBalanceLimit: number = 1000;
  khaltiLowerBalanceLimit: number = 100;
  khaltiAmount: number = 0;
  khaltiConvertedAmount: number = 0;
  /**
   * variables declared for ime payment
   */
  imePayForm!: FormGroup;
  imePayFormSubmitted: boolean = false;
  imePayLoader: boolean = false;
  imePayBalanceLimit: number = 1000;
  imePayLowerBalanceLimit: number = 100;
  imePayAmount: number = 0;
  imePayConvertedAmount: number = 0;
  /**
   * variables declared for prabhu pay
   */
  prabhuPayForm!: FormGroup;
  prabhuPayFormSubmitted: boolean = false;
  prabhuPayLoader: boolean = false;
  prabhuPayBalanceLimit: number = 1000;
  prabhuPayLowerBalanceLimit: number = 100;
  prabhuPayAmount: number = 0;
  prabhuPayConvertedAmount: number = 0;
  prabhuPayCallBackURL: string = '';
  /**
   * variables declared to show/hide payment details
   */
  displayStripe: boolean = false;
  displayEsewa: boolean = false;
  displayKhalti: boolean = false;
  displayImePay: boolean = false;
  displayPrabhuPay: boolean = false;
  /**
   * configurations defined for input field masking
   */
  // dateInputMask = createMask<Date>({
  //   alias: 'datetime',
  //   inputFormat: 'yyyy'
  // });
  // monthInputMask = createMask<Date>({
  //   alias: 'datetime',
  //   inputFormat: 'mm'
  // });
  // cardInputMask = createMask('9999 9999 9999 9999');
  /**
   * variables declared for transaction statement
   */
  hasTransactionResponse: boolean = false;
  transactionList: any[] = [];


  constructor(private service: LoadBalanceService, private toastr: ToastrService, private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, private transactionService: TransactionService) { 
      /**
       * gettign values from query params for payments
       */
      this.paymentGateway = this.activatedRoute.snapshot.queryParamMap.get('gateway') || '';
      this.paymentStatus = this.activatedRoute.snapshot.queryParamMap.get('status') || '';
      this.paymentReferenceID = this.activatedRoute.snapshot.queryParamMap.get('reference_id') || '';
      /**
       * getting values from query params for esewa payment
       */
      this.esewaOid = this.activatedRoute.snapshot.queryParamMap.get('oid') || '';
      this.esewaRefId = this.activatedRoute.snapshot.queryParamMap.get('refId') || '';
      this.esewaTopupAmount = Number(this.activatedRoute.snapshot.queryParamMap.get('amt')) || 0;
  }

  ngOnInit(): void {
    this.esewaForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(this.esewaLowerBalanceLimit), Validators.pattern(this.numberRegex)]]
    });
    /**
     * detect change in esewa topup amount and display a conversion rate
     */
    this.esewaForm.controls.amount.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .pipe(debounceTime(200))
      .subscribe(res => {
        if(res && Number(res) > 0 && this.currency_conversion_rate > 0) {
          this.esewaAmount = res;
          this.esewaConvertedAmount = Number(res) * Number(this.currency_conversion_rate);
        } else {
          this.esewaConvertedAmount = 0;
          this.esewaAmount = 0;
        }
      });
    this.khaltiForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(this.khaltiLowerBalanceLimit), Validators.pattern(this.numberRegex)]]
    });
    /**
     * detect change in khalti topup amount and display a conversion rate
     */
     this.khaltiForm.controls.amount.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
     .pipe(debounceTime(200))
     .subscribe(res => {
      if(res && Number(res) > 0 && this.currency_conversion_rate > 0) {
        this.khaltiAmount = res;
        this.khaltiConvertedAmount = Number(res) * Number(this.currency_conversion_rate);
      } else {
        this.khaltiAmount = 0;
        this.khaltiConvertedAmount = 0;
      }
     });
    this.imePayForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(this.imePayLowerBalanceLimit), Validators.pattern(this.numberRegex)]]
    });
    /**
     * detect change in imepay topup amount and display a conversion rate
     */
     this.imePayForm.controls.amount.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
     .pipe(debounceTime(200))
     .subscribe(res => {
      if(res && Number(res) > 0 && this.currency_conversion_rate > 0) {
        this.imePayAmount = res;
        this.imePayConvertedAmount = Number(res) * Number(this.currency_conversion_rate);
      } else {
        this.imePayAmount = 0
        this.imePayConvertedAmount = 0;
      }
     });
     this.prabhuPayForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(this.prabhuPayLowerBalanceLimit), Validators.pattern(this.numberRegex)]]
    });
    /**
     * detect change in imepay topup amount and display a conversion rate
     */
     this.prabhuPayForm.controls.amount.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
     .pipe(debounceTime(200))
     .subscribe(res => {
      if(res && Number(res) > 0 && this.currency_conversion_rate > 0) {
        this.prabhuPayAmount = res;
        this.prabhuPayConvertedAmount = Number(res) * Number(this.currency_conversion_rate);
      } else {
        this.prabhuPayAmount = 0
        this.prabhuPayConvertedAmount = 0;
      }
     });
    this.creditCardFormInIt();
    this.savedCreditCardFormInit();
    this.randomAlphaNumeric = this.generateAlphaNumericValue(10);
    this.updateUserBalance();
    this.getPaymentMethods();
    this.getTransactionStatement();
    /**
     * handle esewa payment status and payment verification
     */
    if ( this.paymentStatus === 'success' && this.paymentGateway === 'esewa') {
      $('#paymentVerificationStatusModal').modal('show');
      this.verifyEsewaPayment();
    } 
    if ( this.paymentStatus === 'error' && this.paymentGateway === 'esewa') {
      this.paymentVerificationStatus = 'ERROR'
      // OPEN ERROR DIALOG MODEL
      $('#paymentVerificationStatusModal').modal('show');
    }
    /**
     * handle imepay payment status
     */
    if(this.paymentStatus === 'success' && this.paymentGateway === 'imepay') {
      this.updateUserBalance();
      this.paymentVerificationStatus = 'SUCCESS';
      $('#paymentVerificationStatusModal').modal('show');
    }
    if(this.paymentStatus === 'error' && this.paymentGateway === 'imepay') {
      this.paymentVerificationStatus = 'ERROR';
      this.paymentErrorMessage = 'Topup failed. Please try again.';
      $('#paymentVerificationStatusModal').modal('show');
    }
    /**
     * handle prabhu payment status
     */
     if ( this.paymentStatus === 'success' && this.paymentGateway === 'prabhupay') {
      // setTimeout(()=> {
        // console.log("Show success modal");
        this.paymentVerificationStatus = 'SUCCESS';
        $('#paymentVerificationStatusModal').modal('show');
      // }, 500);
    } 
    if ( this.paymentStatus === 'error' && this.paymentGateway === 'prabhupay') {
      // setTimeout(()=> {
        this.paymentVerificationStatus = 'ERROR'
        this.paymentErrorMessage = 'Something went wrong. Please Note your reference Id '+ this.paymentReferenceID +' and contact our customer support.';
        $('#paymentVerificationStatusModal').modal('show');
      // }, 500);
    }
  }

  creditCardFormInIt() {
    this.creditCardForm = this.formBuilder.group({
      name: ['', Validators.required],
      card_number: ['', Validators.required],
      exp_month: ['mm', [Validators.required, Validators.min(1), Validators.max(12),  Validators.pattern(this.numberRegex)]],
      exp_year: ['yyyy', [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      cvc: ['', [Validators.required, Validators.pattern(this.numberRegex)]],
      amount: ['', [Validators.required, Validators.min(this.cardLowerBalanceLimit), Validators.pattern(this.numberRegex)]],
      save_card: [true],
      is_saved_card: [false]
    }, {validators: [this.ValidateCardInput, this.ValidateMonthInput, this.ValidateYearInput]});
  }

  // ValidateExpiryYear() {

  // }

  // ValidateExpiryYearValue(AC: AbstractControl) {
  //   let expiry_year = AC.get('expiry_year')?.value;
  //   if(expiry_year) {
  //     if(isNaN(expiry_year)) {
  //       AC.get('expiry_year')?.setErrors({ValidateExpiryYearValue: true});
  //     } else {
  //       return;
  //     }
  //   }
  // }

  ValidateCardInput(AC: AbstractControl) {
    let card_number = AC.get('card_number')?.value;
    if(card_number) {
      let card_number_void_space = card_number.replace(/\s+/g, '');
      if(isNaN(card_number_void_space) || card_number_void_space.length <16) {
        AC.get('card_number')?.setErrors({ValidateCardInput: true});
      } else {
        return;
      }
    }
  }

  ValidateMonthInput(AC: AbstractControl) {
    let exp_month = AC.get('exp_month')?.value;
    if(exp_month) {
      if(isNaN(exp_month)) {
        AC.get('exp_month')?.setErrors({ValidateMonthInput: true});
      } else {
        return;
      }
    }
  }

  ValidateYearInput(AC: AbstractControl) {
    let exp_year = AC.get('exp_year')?.value;
    if(exp_year) {
      if(isNaN(exp_year)) {
        AC.get('exp_year')?.setErrors({ValidateYearInput: true});
      } else {
        return;
      }
    }
  }

  savedCreditCardFormInit() {
    this.savedCardForm = this.formBuilder.group({
      name: [{value: '', disabled: true}, Validators.required],
      card_number: [{value: '', disabled: true}, [Validators.required, Validators.pattern(this.numberRegex)]],
      amount: ['', [Validators.required, Validators.min(this.cardLowerBalanceLimit), Validators.pattern(this.numberRegex)]],
      save_card: [false],
      is_saved_card: [true]
    });
  }

  /**
   * generating random alpha numeric values
   */
   generateAlphaNumericValue(length: any): string {
    let result = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  getPaymentMethods() {
    this.service.fetchPaymentTypes().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        // console.log("Payment list ", res);
        this.paymentList = res.payment_methods;
        // filter the active payment types
        this.paymentList = this.paymentList.filter(obj => obj.is_active === true);
        /**
         * find the index of stripe payment and push the stripe payment to the last of the array
         */
        let stripePaymentIndex = this.paymentList.findIndex(obj => obj.type === 'stripe');
        if(stripePaymentIndex > -1) {
          const stripePaymentDetails = this.paymentList[stripePaymentIndex];
          // remove stripe payment first
          this.paymentList.splice(stripePaymentIndex, 1);
          // push stripe payment to the last of the paymentList
          this.paymentList.push(stripePaymentDetails);
        }
        /**
         * end of find the index of stripe payment and push the stripe payment to the last of the array
         */
        this.userBalance = Number(res.balance);
        this.userId = +res.user_id;
        this.userReedemPoints = res.point;
        this.creditCardList = res.credit_cards;
        this.currency_conversion_rate = res.topup_conversion_rate;
        /**
         * set esewa credentials
         */
        let esewaPaymentDetails = res.payment_methods.filter((obj: any) => obj.type === 'esewa')[0];
        if(esewaPaymentDetails) {
          this.esewaMerchant = esewaPaymentDetails?.merchant_code;
          this.esewaBaseUrl = esewaPaymentDetails?.bse_url;
          this.esewaBalanceLimit = esewaPaymentDetails?.txn_upper_limit;
          this.esewaLowerBalanceLimit = esewaPaymentDetails?.txn_lower_limit;
          this.esewaForm.controls.amount.setValidators([Validators.required, Validators.min(this.esewaLowerBalanceLimit), Validators.pattern(this.numberRegex)]);
          this.esewaForm.controls.amount.updateValueAndValidity();
        }
        
        /**
         * set stripe/credit card credentials
         */
        let cardPaymentDetails = res.payment_methods.filter((obj: any) => obj.type === 'stripe')[0];
        if(cardPaymentDetails) {
          this.cardBalanceLimit = cardPaymentDetails?.txn_upper_limit;
          this.cardLowerBalanceLimit = cardPaymentDetails?.txn_lower_limit;
          this.creditCardForm.controls.amount.setValidators([Validators.required, Validators.min(this.cardLowerBalanceLimit), Validators.pattern(this.numberRegex)]);
          this.creditCardForm.controls.amount.updateValueAndValidity();
          this.savedCardForm.controls.amount.setValidators([Validators.required, Validators.min(this.cardLowerBalanceLimit), Validators.pattern(this.numberRegex)]);
          this.savedCardForm.controls.amount.updateValueAndValidity();
        }
        
        /**
         * set khalti credentials
         */
        let khaltiPaymentDetails = res.payment_methods.filter((obj: any) => obj.type === 'khalti')[0];
        if(khaltiPaymentDetails) {
          this.khaltiBalanceLimit = khaltiPaymentDetails?.txn_upper_limit;
          this.khaltiLowerBalanceLimit = khaltiPaymentDetails?.txn_lower_limit;
          this.khaltiForm.controls.amount.setValidators([Validators.required, Validators.min(this.khaltiLowerBalanceLimit), Validators.pattern(this.numberRegex)]);
          this.khaltiForm.controls.amount.updateValueAndValidity();
          this.configureKhaltiPayment(khaltiPaymentDetails?.public_key);
        }
        
        /**
         * set ime pay credentials
         */
        let imePaymentDetails = res.payment_methods.filter((obj: any) => obj.type === 'ime_pay')[0];
        if(imePaymentDetails) {
          this.imePayBalanceLimit = imePaymentDetails?.txn_upper_limit;
          this.imePayLowerBalanceLimit = imePaymentDetails?.txn_lower_limit;
          this.imePayForm.controls.amount.setValidators([Validators.required, Validators.min(this.imePayLowerBalanceLimit), Validators.pattern(this.numberRegex)]);
          this.imePayForm.controls.amount.updateValueAndValidity();
        }
        
        /**
         * set prabhupay credentials
         */
        let prabhuPaymentDetails = res.payment_methods.filter((obj: any) => obj.type === 'prabhupay')[0];
        if(prabhuPaymentDetails) {
          this.prabhuPayBalanceLimit = prabhuPaymentDetails?.txn_upper_limit;
          this.prabhuPayLowerBalanceLimit = prabhuPaymentDetails?.txn_lower_limit;
          this.prabhuPayCallBackURL =  this.assetBaseUrl + prabhuPaymentDetails?.callback_url;
          this.prabhuPayForm.controls.amount.setValidators([Validators.required, Validators.min(this.prabhuPayLowerBalanceLimit), Validators.pattern(this.numberRegex)]);
          this.prabhuPayForm.controls.amount.updateValueAndValidity();
        }
        
        this.hasResponse = true;
      }, (err: any) => {
        this.hasResponse =false;
        // this.toastr.error("Unable to fetch payment types", "ERROR");
      });
  }

  getTransactionStatement() {
    this.transactionService.getTransactionStatement(1).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.hasTransactionResponse = true;
        this.transactionList = res.statements;
        if(this.transactionList.length <= 0) {
          this.transactionList = ['-1'];
        }
      });
  }

  initiateEsewaPayment() {
    this.esewaFormSubmitted = true;
    if(this.esewaForm.invalid) {
      return;
    }
    this.esewaTopupAmount = Number(this.esewaForm.controls.amount.value);
    if((this.esewaBalanceLimit < this.esewaTopupAmount + this.userBalance) && !this.isUserKYCVefified) {
      this.toastr.error('Your balance limit is ' + this.esewaBalanceLimit + '. Please verify your kyc to remove balance limit.', 'ERROR');
      return;
    }
    // submit form for esewa payment initiation
    setTimeout(() => {
      this.esewaForm.markAsPristine();
      this.esewaForm.markAsUntouched();
      this.esewaPaymentInitiationForm.nativeElement.submit();
    }, 500);
  }

  verifyEsewaPayment() {
    this.esewaFormSubmitted = false;
    const data = {
      reference_id: this.esewaRefId,
      amount: this.esewaTopupAmount,
      product_id : this.esewaOid,
      gps : localStorage.getItem('created_gps') ? localStorage.getItem('created_gps') : 'n/a',
    };
    this.service.verifyEsewaPayment(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.updateUserBalance();
        this.paymentVerificationStatus = 'SUCCESS';
        $('#paymentVerificationStatusModal').modal('show');
      }, (error: any) => {
        this.paymentErrorMessage = 'Something went wrong. Please Note your reference_id '+ this.esewaRefId +'. Contact Our Customer Support';
        this.paymentVerificationStatus = 'ERROR';
        $('#paymentVerificationStatusModal').modal('show');
      });
  }

  /**
   * function for making the fragment scroll into the view
   * @param viewId id of the fragment to bring into view
   */
   scrollToFragment(viewId: string): void {
    this.savedCardSelected = false;
    const elem = document.getElementById(viewId);
    elem?.scrollIntoView({behavior: 'smooth', block: "center"});
  }

  initiateCreditCardPayment() {
    this.creditCardFormSubmitted = true;
    if(this.creditCardForm.invalid) {
      return;
    }
    let cardTopupAmount = Number(this.creditCardForm.controls.amount.value);
    if((this.cardBalanceLimit < cardTopupAmount + this.userBalance) && !this.isUserKYCVefified) {
      this.toastr.error('Your balance limit is ' + this.cardBalanceLimit + '. Please verify your kyc to remove balance limit.', 'ERROR');
      return;
    }
    const data = {
      name: this.creditCardForm.controls.name.value,
      card_number: (this.creditCardForm.controls.card_number.value).replace(/\s+/g, '').substr(0, 16),
      exp_month: this.creditCardForm.controls.exp_month.value,
      exp_year: this.creditCardForm.controls.exp_year.value,
      cvc: this.creditCardForm.controls.cvc.value,
      amount: this.creditCardForm.controls.amount.value,
      save_card: this.creditCardForm.controls.save_card.value,
      is_saved_card: this.creditCardForm.controls.is_saved_card.value
    }
    this.displayCreditCardLoader = true;
    this.service.cardPaymentDetails(data).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.displayCreditCardLoader = false;
        this.creditCardFormSubmitted = false;
        this.creditCardFormInIt();
        this.creditCardForm.markAsPristine();
        this.creditCardForm.markAsUntouched();
        this.toastr.success('Topup successful', 'SUCCESS');
        // this.updateUserBalance();
        this.getPaymentMethods();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, (err: any) => {
        this.creditCardFormSubmitted = false;
        this.displayCreditCardLoader = false;
        this.toastr.error(err.error.error, 'ERROR');
      });
  }

  paymentFromSavedCards(cardDetails: any) {
    this.savedCardForm.controls.name.setValue(cardDetails.name);
    this.savedCardForm.controls.name.updateValueAndValidity();
    this.savedCardForm.controls.card_number.setValue(cardDetails.card_number);
    this.savedCardForm.controls.card_number.updateValueAndValidity();
    this.savedCardSelected = true;
    const elem = document.getElementById('creditCardPaymentForm');
    elem?.scrollIntoView({behavior: 'smooth', block: "center"});
  }

  initiatePaymentFromSavedCard() {
    this.savedCardFormSubmitted = true;
    if(this.savedCardForm.invalid) {
      return;
    }
    let cardTopupAmount = Number(this.savedCardForm.controls.amount.value);
    if((this.cardBalanceLimit < cardTopupAmount + this.userBalance) &&!this.isUserKYCVefified) {
      this.toastr.error('Your balance limit is ' + this.cardBalanceLimit + '. Please verify your kyc to remove balance limit.', 'ERROR');
      return;
    }
    this.displaySavedCardLoader = true;
    this.service.cardPaymentDetails(this.savedCardForm.getRawValue()).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.savedCardFormSubmitted = false;
        // this.savedCardForm.reset();
        this.savedCreditCardFormInit();
        this.savedCardForm.markAsPristine();
        this.savedCardForm.markAsUntouched();
        // this.updateUserBalance();
        this.getPaymentMethods();
        this.displaySavedCardLoader = false;
        this.toastr.success('Topup successful', 'SUCCESS');
      }, (err: any) => {
        this.savedCardFormSubmitted = false;
        this.displaySavedCardLoader = false;
        const errors = err.error;
        Object.keys(errors).forEach(key => {
          this.toastr.error(errors[key][0], key)
        });
        // this.toastr.error(err.error[Object.keys(err.error)[0]][0], 'ERROR');
      });
  }

  initiateRemoveSaveddCard(cardDetails: any) {
    this.cardDetailsToRemove = cardDetails;
    $('#cardRemovalConfirmationModal').modal('show');
  }

  removeSaveCard() {
    if(this.cardDetailsToRemove != undefined && this.cardDetailsToRemove != '') {
      this.removeCardLoader = true;
      this.service.removeSavedCard(this.cardDetailsToRemove.id).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          let cardIndex = this.creditCardList.findIndex(obj => obj.id == this.cardDetailsToRemove.id);
          if(cardIndex > -1) {
            this.creditCardList.splice(cardIndex, 1);
          } else {
            this.getPaymentMethods();
          }
          $('#cardRemovalConfirmationModal').modal('hide');
          this.removeCardLoader = false;
          this.toastr.success('Card removed successfully!!', 'SUCCESS');
          this.cardDetailsToRemove = undefined;
        }, (err: any) => {
          this.removeCardLoader = false;
          this.cardDetailsToRemove = undefined;
          this.toastr.error('Failed to remove card. Please try again later.', 'ERROR');
        });
    }
  }


  updateUserBalance() {
    this.service.fetchUserBalance().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.userBalance = Number(res.balance);
        this.isUserKYCVefified = res.is_kyc_verified;
        this.hasUserBalanceResponse = true;
      }, (err: any) => {
        this.hasUserBalanceResponse = false;
        // this.toastr.error("Unable to fetch balance", "ERROR");
      });
  }

  configureKhaltiPayment(publicKey: any) {
    /**
     * khalti configuration
     */
    let self = this;
    let config = {
      // replace this key with yours
      publicKey: publicKey,
      productIdentity: this.generateAlphaNumericValue(10),
      productName: 'Buy Now Pay in Japan (BNPJ)',
      productUrl: environment.baseUrl,
      eventHandler: {
        onSuccess(payload: any): void {
          self.khaltiPayment(payload);
        },
        // onError handler is optional
        onError(error: any): void {
          self.paymentErrorMessage = 'Topup failed. Please try again.';
          self.paymentVerificationStatus = 'ERROR';
          $('#paymentVerificationStatusModal').modal('show');
        },
        onClose(): void {
          // console.log('widget is closing');
        }
      }
    };
    this.checkout = new KhaltiCheckout(config);
    // end of khalti configuration
}

  khaltiCheckout(): void {
    this.khaltiFormSubmitted = true;
    if(this.khaltiForm.invalid) {
      return;
    }
    let khaltiTopupAmount = Number(this.khaltiForm.controls.amount.value);
    if((this.khaltiBalanceLimit < khaltiTopupAmount + this.userBalance) && !this.isUserKYCVefified) {
      this.toastr.error('Your balance limit is ' + this.khaltiBalanceLimit + '. Please verify your kyc to remove balance limit.', 'ERROR');
      return;
    }
    this.checkout.show({ amount: khaltiTopupAmount * 100 });
    this.khaltiForm.reset();
    this.khaltiForm.markAsPristine();
    this.khaltiForm.markAsUntouched();
  }

  khaltiPayment(khaltiToken: any): void {
    this.khaltiFormSubmitted = false;
    $('#paymentVerificationStatusModal').modal('show');
    const data = {
      token: khaltiToken.token,
      amount : khaltiToken.amount/100,
      verify_amount: khaltiToken.amount
    }
    this.service.verifyKhaltiPayment(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.updateUserBalance();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.paymentVerificationStatus = 'SUCCESS';
      }, (err: any) => {
        this.paymentVerificationStatus = 'ERROR';
        this.paymentErrorMessage = err.error.error;
      });
  }

  imePayCheckout(): void {
    this.imePayFormSubmitted = true;
    if(this.imePayForm.invalid) {
      return;
    }
    let imeTopupAmount = Number(this.imePayForm.controls.amount.value);
    if((this.imePayBalanceLimit < imeTopupAmount + this.userBalance) && !this.isUserKYCVefified) {
      this.toastr.error('Your balance limit is ' + this.imePayBalanceLimit + '. Please verify your kyc to remove balance limit.', 'ERROR');
      return;
    }
    const randomnum = this.generateAlphaNumericValue(10);
    const referenceId = imeTopupAmount + '_' + randomnum;
    const data = {
      reference_id: referenceId,
      amount : imeTopupAmount,
      gps : localStorage.getItem('created_gps') ? localStorage.getItem('created_gps') : 'n/a'
    };
    this.imePayLoader = true;
    this.service.initiateIMEPay(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.imePayLoader = false;
        this.imePayForm.reset();
        this.imePayForm.markAsPristine();
        this.imePayForm.markAsUntouched();
        window.open(response.payment_link, '_self');
        // Detect pop blocker
        // setTimeout(function() {
        //   if(!pop || pop.closed || pop.closed == undefined || pop == undefined || pop.innerWidth == 0 || pop.document.documentElement.clientWidth != 150 || pop.document.documentElement.clientHeight != 150){
        //     alert("Popup Blocker is enabled! Please add this site to your exception list.");
        //   }
        // }, 1000);
      }, (error: any) => {
        this.imePayLoader = false;
        this.toastr.error('Failed to make topup payment.', 'ERROR');
      });
  }

  initiatePrabhuPayCheckout(): void {
    this.prabhuPayFormSubmitted = true;
    if(this.prabhuPayForm.invalid) {
      return;
    }
    let prabhuTopupAmount = Number(this.prabhuPayForm.controls.amount.value);
    if((this.prabhuPayBalanceLimit < prabhuTopupAmount + this.userBalance) && !this.isUserKYCVefified) {
      this.toastr.error('Your balance limit is ' + this.prabhuPayBalanceLimit + '. Please verify your kyc to remove balance limit.', 'ERROR');
      return;
    }
    const randomnum = this.generateAlphaNumericValue(10);
    const referenceId = prabhuTopupAmount + '_' + randomnum;
    const data = {
      reference_id: referenceId,
      amount : prabhuTopupAmount,
      remarks: 'Topup Rs.' + prabhuTopupAmount,
      product_name: 'Topup_A' + prabhuTopupAmount + 'U' + this.userId,
      return_url: this.prabhuPayCallBackURL
    };
    this.prabhuPayLoader = true;
    this.service.initiatePrabhuPay(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.prabhuPayLoader = false;
        this.prabhuPayForm.reset();
        this.prabhuPayForm.markAsPristine();
        this.prabhuPayForm.markAsUntouched();
        window.open(response.payment_link.data.redirectionUrl, '_self');
        // Detect pop blocker
        // setTimeout(function() {
        //   if(!pop || pop.closed || pop.closed == undefined || pop == undefined || pop.innerWidth == 0 || pop.document.documentElement.clientWidth != 150 || pop.document.documentElement.clientHeight != 150){
        //     alert("Popup Blocker is enabled! Please add this site to your exception list.");
        //   }
        // }, 1000);
      }, (error: any) => {
        this.prabhuPayLoader = false;
        this.toastr.error('Failed to make topup payment.', 'ERROR');
      });
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
