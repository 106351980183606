import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { ProfileComponent } from './profile.component';

const PROFILE: Routes = [
    { path: '', component : ProfileComponent}
];
@NgModule({
    imports: [RouterModule.forChild(PROFILE)],
    exports: [RouterModule]
})
export class ProfileRoutingModule {
}
