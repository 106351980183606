import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import { NewsComponent } from './news.component';
import { NewsRoutingModule } from './news-routing.module';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsService } from './services/news.service';
import { DateAgoModule } from '../utils/pipes/date-ago.module';
import { ImagepreloadermoduleModule } from '../utils/directives/imagepreloader.module';

@NgModule({
    imports: [
        CommonModule,
        NewsRoutingModule,
        DateAgoModule,
        ImagepreloadermoduleModule
    ],
    declarations: [
        NewsComponent,
        NewsDetailComponent
    ],
    providers: [
        NewsService
    ]
})

export class NewsModule {
}
