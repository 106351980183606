import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IndexService } from './service/index.service';
import { NewsService } from './../news/services/news.service'
import { DataShareService } from '../utils/data-share.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert/service/alert.service';
import { ToastrService } from 'ngx-toastr';
import { PartnerServicesService } from '../services/service/partner-services.service';

declare function alignTabs(): any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  isLoggedIn: boolean = false;
  hasResponse: boolean = false;
  sliderList: any[] = [];
  aboutUs!: any;
  serviceCategoryList: any[] = [];
  serviceList: any[] = [];
  jpMannerList: any[] = [];
  newsList: any[] = [];
  forYouNews: any[] = [];
  // jpManner!: any;
  assetBaseUrl = environment.assetBaseUrl;
  /**
   * variables for news
   */
  fetchNewsTriggered: boolean = false;
  fetchLatestNewsTriggered: boolean = false;
  activeTabPanel: string = 'foryou';
  forYouPageNum: number = 0;
  showForYouNewsLoadMore: boolean = false;
  latestNewsPageNum: number = 0;
  showLatestNewsLoadMore: boolean = false;
  /**
   * variabled declared to send as a parameter to filter news
   */
  userGenrePreference: string[] = [];
  userLanguagePreference: string[] = [];
  userSourcePreference: string[] = [];
  /**
   * variables declared for alerts
   */
  fetchingLatestAlerts: boolean = false;
  showLatestAlertLoadMore: boolean = false;
  latestAlertLimit: number = 0;
  latestAlertList: any[] = [];
  /**
   * variables declared for partner services section
   */
  hasPartnerServiceCategories: boolean = false;
  parnerServiceCategoryList: any[] = [
    {id: 0,category_name: "Featured",page: 0},
  ];
  activePartnerServiceCategory: string  = 'Featured';
  partnerServiceList: any[] = [];
  filteredPartnnerServicesList: any[] = [];

  constructor(private indexService: IndexService, private newsService: NewsService, private toastr: ToastrService,
    private dataShareService: DataShareService, private router: Router, private alertService: AlertService,
    private partnerService: PartnerServicesService) {
      if (localStorage.getItem('a-token') != null || sessionStorage.getItem('a-token') != null) {
        this.isLoggedIn = true;
      }
    }

  ngOnInit(): void {
    this.dataShareService.currentLoginStatus.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(status => {
          if (localStorage.getItem('a_token') != null || sessionStorage.getItem('a_token') != null) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = status;
          }
        });
    this.getIndexContents();
    this.getPartnerServiceCategories();
    this.fetchLatestAlerts();
    if(this.isLoggedIn) {
      this.fetchUserNewsPreference();
    } else {
      this.getNews();
      this.getForYouNews();
    }
  }

  /**
   * function to fetch data for index/home
   */
  getIndexContents() {
    this.indexService.getHomePageContents().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.hasResponse = true;
        const sliderObj = res[1]['home_data'].find((obj: any) => obj.type == 'slider');
        this.sliderList = sliderObj.data;
        const aboutObj = res[1]['home_data'].find((obj: any) => obj.type == 'about_us');
        this.aboutUs = aboutObj.data[0];
        const serviceObj = res[1]['home_data'].find((obj: any) => obj.type == 'services');
        this.serviceList = serviceObj.data;
        this.serviceCategoryList = [...new Set(this.serviceList.map(service => service.category))];
        this.serviceCategoryList.splice(0, 0, "All");
        const jpMannerObj = res[1]['home_data'].find((obj: any) => obj.type == 'jp_manners');
        this.jpMannerList = jpMannerObj.data;
      }, (error: any) => {
        // this.toastr.error('Failed to fetch contents. Please try again.', 'ERROR');
      });
  }

  /**
   * function to fetch user saved genre, language and source preference
   */
   fetchUserNewsPreference() {
    this.newsService.getUserNewsPreference().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if(res != null && res.length >0){
          this.userGenrePreference = res[0].genre;
          this.userLanguagePreference = res[0].language;
          this.userSourcePreference = res[0].source;
        }
        /**
         * fetch news with preferences
         */
        this.getNews();
        this.getForYouNews();
      });
  }

  /**
   * function to fetch latest news
   */
  getNews() {
    this.fetchLatestNewsTriggered = true;
    this.newsService.fetchLatestNwws(this.latestNewsPageNum + 1, this.userGenrePreference, this.userSourcePreference, this.userLanguagePreference).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchLatestNewsTriggered = false;
        this.newsList = this.newsList.concat(res.data);
        this.latestNewsPageNum = Number(res.page);
        /**
         * if the for you news length is 0 push -1 to the news list so that the proper information can be displayed to the users
         */
        if(this.newsList.length <= 0) {
          this.newsList = [-1];
        }
        /**
         * toogle load more button based on response length
         * 12 is used because for every request limit=12 is sent
         */
         if(res.data.length < 12) {
          this.showLatestNewsLoadMore = false;
        } else {
          this.showLatestNewsLoadMore = true;
        }
      }, (err: any) => {
        this.fetchLatestNewsTriggered = false;
        // this.toastr.error('Failed to fetch latest news. Please try again.', 'ERROR');
      });
  }

  /**
   * function to fetch for you news
   */
  getForYouNews() {
    this.fetchNewsTriggered = true;
    this.newsService.getForYouNews(this.forYouPageNum + 1, this.userGenrePreference, this.userSourcePreference, this.userLanguagePreference)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchNewsTriggered = false;
        this.forYouNews = this.forYouNews.concat(res.data);
        this.forYouPageNum = Number(res.page);
        /**
         * if the for you news length is 0 push -1 to the news list so that the proper information can be displayed to the users
         */
        if(this.forYouNews.length <= 0) {
          this.forYouNews = [-1];
        }
        /**
         * toogle load more button based on response length
         * 12 is used because for every request limit=12 is sent
         */
        if(res.data.length < 12) {
          this.showForYouNewsLoadMore = false;
        } else {
          this.showForYouNewsLoadMore = true;
        }
      }, (err: any) => {
        this.fetchNewsTriggered = false;
        // this.toastr.error('Failed to fetch for you news. Please try again.', 'ERROR');
      });
  }

  /**
   * function to fetch latest alert list
   */
   fetchLatestAlerts() {
    this.fetchingLatestAlerts = true;
    this.alertService.getLatestAlerts(this.latestAlertLimit + 10)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchingLatestAlerts = false;
        this.latestAlertList = this.latestAlertList.concat(res);
        /**
         * filter out dublicate objects
         */
        this.latestAlertList = this.latestAlertList.reduce((acc, x) =>
            acc.concat(acc.find((y: any) => y.code ? y.code === x.code : y.volcano_code === x.volcano_code) ? [] : [x])
          , []);
        this.latestAlertLimit = this.latestAlertLimit + 10;
        /**
         * if latest alert length is 0 push -1 to the latestAlertList so that the proper information can be displayed to the users
         */
        if(this.latestAlertList.length <= 0) {
          this.latestAlertList = [-1];
        }
        /**
         * toogle load more button based on response length
         * 12 is used because for every request limit=10 is sent
         */
         if(res.length < 10) {
          this.showLatestAlertLoadMore = false;
        } else {
          this.showLatestAlertLoadMore = true;
        }
      }, (err: any) => {
        this.fetchingLatestAlerts = false;
        // this.toastr.error('Failed to fetch latest alerts', 'Error');
      });
  }

  /**
   * @depricated
   * function to redirect user to news details section
   * @param news clicked news object
   */
  detailNews(news: any) {
    this.dataShareService.changeNewsDetails(news);
    this.router.navigate(['/news/details']);
  }

  /**
   * function to toggle active news tab panel 
   * @param paneltitle news tab panel title
   */
  toogleActivePanel(paneltitle: string) {
    this.activeTabPanel = paneltitle;
  }

  /**
   * function to open a news in a separate tab
   * @param link news link
   */
  openNewsUrl(link: string) {
    window.open(link, '_blank');
  }

  /**
   * function to fetch partner service categories
   */
  getPartnerServiceCategories() {
    this.partnerService.getPartnerServicesCategory().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any)=> {
        this.parnerServiceCategoryList = this.parnerServiceCategoryList.concat(res.categories);
        let timeout = 500;
        this.parnerServiceCategoryList.forEach(obj => {
          obj.page = 0;
          timeout = timeout + 500;
          setTimeout(()=>{
            this.getJapaneseServicesByCategory(obj);
          },timeout);
        });
        this.hasPartnerServiceCategories = true;
        setTimeout(function(){
          alignTabs();
        },1500);
      }, (err: any) => {
        // this.toastr.error('Failed to fetch partner service categories', 'ERROR');
      });
  }

  /**
   * function to fetch partner services list by category name
   * @param category partner service category object
   * @param changeCategory boolean value whether to toogle activePartnerServiceCategory value
   * @returns void
   */
  getJapaneseServicesByCategory(category: any, changeCategory = false) {
    if(this.activePartnerServiceCategory == category.category_name && category.page > 0) {
      return;
    }
    if(changeCategory) {
      this.activePartnerServiceCategory = category.category_name;
    }
    if(category.page <= 0 && category.category_name != 'Featured') {
      const indexOfCategory = this.parnerServiceCategoryList.findIndex(obj => obj.id === category.id);
      this.partnerService.getPartnerServicesByServiceCategoryName(category.category_name, category.page + 1)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if(res.partner_services.length > 0) {
            this.parnerServiceCategoryList[indexOfCategory].page = this.parnerServiceCategoryList[indexOfCategory].page + 1;
            // push partner services to the list of they do not exists
            res.partner_services.forEach((obj: any) => {
              let partnerServiceIndex = this.partnerServiceList.findIndex((service: any) => service.id === obj.id);
              if(partnerServiceIndex < 0) {
                this.partnerServiceList.push(obj);
              }
              if(this.activePartnerServiceCategory == 'Featured') { 
                this.filteredPartnnerServicesList = [];
                this.filteredPartnnerServicesList = this.partnerServiceList.filter(obj => obj.is_featured == true);
              } else {
                /**
                 * filter data from all japanese manner list
                 */
                this.filteredPartnnerServicesList = [];
                this.filteredPartnnerServicesList = this.partnerServiceList.filter(obj => obj.category.category_name == this.activePartnerServiceCategory);
              }
            });
          }
      }, (err: any) => {
        // this.toastr.error('Failed to fetch partner services by category', 'ERROR');
      });
    } else {
      if(category.category_name == 'Featured') { 
        this.filteredPartnnerServicesList = [];
        this.filteredPartnnerServicesList = this.partnerServiceList.filter(obj => obj.is_featured == true);
        return;
      } else {
        /**
         * filter data from all japanese manner list
         */
        this.filteredPartnnerServicesList = [];
        this.filteredPartnnerServicesList = this.partnerServiceList.filter(obj => obj.category  == category.category_name);
      }
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll($event: any) {
  //   if ((document.body.clientHeight + window.scrollY + 2200 ) >= document.body.scrollHeight) {
  //     if(!this.fetchNewsTriggered) {
  //       this.fetchNewsTriggered = true;
  //       if(this.activeTabPanel === 'foryou') {
  //         this.getForYouNews();
  //       }
  //       if(this.activeTabPanel === 'news') {
  //         this.getNews();
  //       }
  //     }
  //   }
  // }

  /**
   * function to redirect user to partner services listing page with All as active category
   */
   redirectUsersToPartnerServiceListingPage() {
    this.router.navigate(['/partner-services'])
    .then(() => {
      this.dataShareService.changePartnerServiceActiveCategory('All');
    });
  }

  trackById(index: number, item: any): any {
    if(item != -1 && item != '-1') {
      return item.id;
    }
    return item;
  }

  trackAlertByCode(index: number, item: any) {
    if(item != -1 && item != '-1') {
      if(item.label == 'Earthquake') {
        return item.code;
      }
      return item.volcano_code;
    }
    return item;
  }

  trackNewsByLink(index: number, item: any) {
    if(item != -1 && item != '-1') {
      return item.link;
    }
    return item;
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    setTimeout(() => {
      alignTabs();
    }, 500);
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
