import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../utils/data-share.service';
import { SearchService } from '../utils/services/search.service';
import { JapaneseMannerService } from './service/japanese-manner.service';

declare function alignTabs(): any;

@Component({
  selector: 'app-japanese-manner',
  templateUrl: './japanese-manner.component.html',
  styleUrls: ['./japanese-manner.component.css']
})
export class JapaneseMannerComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  assetBaseUrl = environment.assetBaseUrl;
  // filterTerm: string = '';
  filterForm!: FormGroup;
  activeCategory: string = 'Featured';
  /**
   * variables declared for categories
   */
  hasResponse: boolean = false;
  categoryList: any[] = [
    {id: 0,category_name: "Featured",page: 0},
    {id: 0,category_name: "All",page: 0}
  ];
  japaneseMannerList: any[] = [];
  filteredMannerList: any[] = [];

  constructor(private service: JapaneseMannerService, private toastr: ToastrService, private dataShareService: DataShareService,
    private formBuilder: FormBuilder, private searchService: SearchService) { }

  ngOnInit(): void {
    /**
     * initiate filter form
     */
    this.filterForm = this.formBuilder.group({
      searchText: ['']
    });
    /**
     * call a function to fetch all japanese manners category
     */
    this.getJapaneseMannerCategories();
    /**
     * subscribe to event for japanese manner category click event
     */
    this.dataShareService.activeJapaneseMannerCategory.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((category: any) => {
          if(category) {
            this.activeCategory = category.category_name;
            category.page = 0;
          }
        });
    /**
     * subscribe to the change in value of a search text field
     */
    this.filterForm.controls.searchText.valueChanges
        .pipe(debounceTime(1000))
        .pipe(distinctUntilChanged())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(value => {
          if(value) {
            // let filteredData = this.japaneseMannerList.filter(obj => obj.title.toLocaleLowerCase().includes(value));
            // if(filteredData.length > 0) {
            //   this.filteredMannerList = filteredData;
            // } else {
            //   this.filteredMannerList = [-1];
            // }
            this.searchService.searchData(value).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              this.filteredMannerList = [];
              let filteredData = res.filter((obj: any) => obj.type === 'jp_manners');
              if(filteredData[0]?.data?.length > 0) {
                this.filteredMannerList = filteredData[0].data;
              } else {
                this.filteredMannerList = [ -1];
              }
            }, (err: any) => {
              this.filteredMannerList = [ -1];
            });
          } else {
            if(this.activeCategory == 'Featured') { 
              this.filteredMannerList = [];
              this.filteredMannerList = this.japaneseMannerList.filter(obj => obj.is_featured == true);
            } else if(this.activeCategory == 'All') {
              this.filteredMannerList = [];
              this.filteredMannerList = this.japaneseMannerList;
            } else {
              /**
               * filter data from all japanese manner list
               */
              this.filteredMannerList = [];
              this.filteredMannerList = this.japaneseMannerList.filter(obj => obj.category.category_name == this.activeCategory);
            }
            /**
             * check if the length of filteredMannerList is 0 or not
             * if 0 then push -2 to the filteredMannerList 
             * so that proper information is displayed to the user
             */
            if(this.filteredMannerList.length <= 0) {
              this.filteredMannerList = [-2];
            }
          }
        });
  }

  getJapaneseMannerCategories() {
    this.service.getJapaneseMannersCategory().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any)=> {
        this.categoryList = this.categoryList.concat(res.categories);
        let timeout = 500;
        this.categoryList.forEach((obj, key, array) => {
          let isLastItem = key === array.length - 1;
          obj.page = 0;
          timeout = timeout + 500;
          setTimeout(()=>{
            this.getJapaneseMannersByCategory(obj, false, isLastItem);
          },timeout);
        });
        this.hasResponse = true;
        setTimeout(function(){
          alignTabs();
        },100);
      }, (err: any) => {
        // this.toastr.error('Failed to fetch Japanese Manner Categories. Please try again.', 'ERROR');
      });
  }

  getJapaneseMannersByCategory(category: any, changeCategory = false, isLastItem=false) {
    if(this.activeCategory == category.category_name && category.page > 0) {
      return;
    }
    if(changeCategory) {
      this.activeCategory = category.category_name;
    }
    if(category.page <= 0 && category.category_name != 'Featured' && category.category_name != 'All') {
      /**
       * fetch from api
       */
       const indexOfCategory = this.categoryList.findIndex(obj => obj.id === category.id);
       this.service.getJapaneseMannersByCategory(category.id, category.page + 1).pipe(takeUntil(this.ngUnsubscribe))
         .subscribe((res: any) => {
           if(res.jp_manners.length > 0) {
             this.categoryList[indexOfCategory].page = this.categoryList[indexOfCategory].page + 1;
             // push japanese manners to the list if they do not exists
             res.jp_manners.forEach((obj: any) => {
               let jpMannerIndex = this.japaneseMannerList.findIndex(jpManner => jpManner.id === obj.id);
               if(jpMannerIndex < 0) {
                this.japaneseMannerList.push(obj);
               }
                if(this.activeCategory == 'Featured') { 
                  this.filteredMannerList = [];
                  this.filteredMannerList = this.japaneseMannerList.filter(obj => obj.is_featured == true);
                } else if(this.activeCategory == 'All') {
                  this.filteredMannerList = [];
                  this.filteredMannerList = this.japaneseMannerList;
                } else {
                  /**
                   * filter data from all japanese manner list
                   */
                  this.filteredMannerList = [];
                  this.filteredMannerList = this.japaneseMannerList.filter(obj => obj.category.category_name == this.activeCategory);
                }
                // console.log("japanese manner list ", this.japaneseMannerList);
             });
           } 
           /**
            * check if the length of filteredMannerList is 0 or not
            * if 0 then push -2 to the filteredMannerList 
            * so that proper information is displayed to the user
            */
            if(isLastItem && this.filteredMannerList.length <= 0) {
              this.filteredMannerList = [-2];
            }
         }, (err: any) => {
          //  this.toastr.error('Failed to fetch Japanese Manner by Categories. Please try again.', 'ERROR');
         });
    } else {
      if(category.category_name == 'Featured') { 
        this.filteredMannerList = [];
        this.filteredMannerList = this.japaneseMannerList.filter(obj => obj.is_featured == true);
        // return;
      } else if(category.category_name == 'All') {
        this.filteredMannerList = [];
        this.filteredMannerList = this.japaneseMannerList;
        // return;
      } else {
        /**
         * filter data from all japanese manner list
         */
        this.filteredMannerList = [];
        this.filteredMannerList = this.japaneseMannerList.filter(obj => obj.category.category_name == category.category_name);
      }
      /**
       * check if the length of filteredMannerList is 0 or not
       * if 0 then push -2 to the filteredMannerList 
       * so that proper information is displayed to the user
       */
      if(isLastItem && this.filteredMannerList.length <= 0) {
        this.filteredMannerList = [-2];
      }
    }
  }

  trackById(index: number, item: any): any {
    if(item != -1 && item != '-1') {
      return item.id;
    }
    return item;
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event: any) {
    setTimeout(() => {
      alignTabs();
    }, 100);
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
   ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
