import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  private baseUrl = environment.apiUrl;

  private aboutusHeader = new HttpHeaders({
    'cache': 'true',
    'cache_group': 'aboutus'
  });

  constructor(private http: HttpClient) { }

  fetchAboutUsDetails() {
      return this.http.get(this.baseUrl + '/pages/about_us', {headers: this.aboutusHeader});
  }
}
