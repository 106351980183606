import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, TimeoutError } from 'rxjs';
import { catchError, delay, filter, mergeMap, retryWhen, switchMap, take, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CacheService } from '../services/cache.service';
import { DataShareService } from '../data-share.service';
import { AuthenticationService } from 'src/app/login/services/authentication.service';

@Injectable()
export class AuthInterceptors  {
    retryLimit = 3;
    attempt = 0;
    token: string = '';

    /**
     * variables declared for getting new access token from refresh token
     */
    isTokenRefreshing: boolean = false;
    tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    refreshToken = localStorage.getItem('r_token') || localStorage.getItem('r_token');

    constructor(private router: Router, private location: Location, private toastr: ToastrService,
        private cacheService: CacheService, private dataShareService: DataShareService,
        private authenticationService: AuthenticationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            /**
             * check if the request is made for data manipulation
             * if the request if for create, update or delete or any kind of data manipulation
             * delete the cache by cache_group value
             */
            if (req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE' ||
                req.headers.get('cache') === 'delete') {
                    this.cacheService.deleteCacheByGroup(req.headers.get('cache_group'));
                    /**
                     * check dependent cache
                     */
                    // if (req.headers.has('dependentCache')) {
                    //     const dependentCache: string[] = req.headers.get('dependentCache').split(',');
                    //     dependentCache.forEach(cacheGroup => this.cacheService.deleteCacheByGroup(cacheGroup));
                    // }
            }
            /**
             * check if the cache persists or not
             * if cache exists for the requested api return response from cache
             */
            if (req.headers.get('cache') === 'true' && req.method === 'GET') {
                const cachedResponse = this.cacheService.get(req);
                if (cachedResponse !== null) {
                    // console.log('Response from cache => ' + cachedResponse);
                    return of(cachedResponse);
                }
            }
        if (req.headers.has('auth') && req.headers.get('auth') === 'true') {
            const accessToken = sessionStorage.getItem('a_token') != null ? sessionStorage.getItem('a_token') : localStorage.getItem('a_token');
            if (accessToken) {
                this.token = accessToken;
            }
            let authReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + this.token}});
            /**
             * delete auth from header as it is user to determine whether to include token in request or not
             * also delete cache from header as its sole purpose is to determine what to do with caching
             */
            authReq = authReq.clone({headers: authReq.headers.delete('auth')});
            authReq = authReq.clone({headers: authReq.headers.delete('cache')});
            authReq = authReq.clone({headers: authReq.headers.delete('cache_group')});
            return next.handle(authReq)
                // .pipe(retryWhen(errors => 
                //     errors.pipe(
                //         delay(2000),
                //         // inside the retryWhen, use a tap operator to throw an error 
                //         // if you don't want to retry
                //         tap(error => {
                //             if (++this.attempt >= this.retryLimit || (error.status !== 429)) {
                //                 throw error;
                //             }
                //         })
                //     )
                // ))
                .pipe(
                    catchError(error => {
                        if (error instanceof TimeoutError) {
                            this.toastr.error('Timeout error. Please try again.', 'Error!');
                            return of(error);
                        }
                        if (error.status === 401) {
                            console.log("401 error occurrred");
                            // return this.handle401Error(req, next);
                            this.toastr.error('Unauthenticaed', 'ERROR');
                            sessionStorage.removeItem('a_token');
                            sessionStorage.removeItem('r_token');
                            sessionStorage.removeItem('avatar');
                            localStorage.removeItem('a_token');
                            localStorage.removeItem('r_token');
                            localStorage.removeItem('avatar');
                            localStorage.removeItem('social');
                            this.dataShareService.changeLoginStatus(false);
                            this.router.navigateByUrl('/login?returnUrl=' + this.router.url);
                            return of(error);
                        }
                        if (error.status === 403) {
                            this.toastr.error('Unauthorised', 'ERROR');
                            // this.location.back();
                            this.logout();
                        }
                        throw error;
                    })
                );
        } else {
            req = req.clone({headers: req.headers.delete('auth')});
            req = req.clone({headers: req.headers.delete('cache')});
            req = req.clone({headers: req.headers.delete('cache_group')});
            // to bypass the urls that do not need authorization headers like /login
            return next.handle(req.clone());
        }
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }})
    }

    //generating new token from refresh_token
    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        
        if (!this.isTokenRefreshing) {
            this.isTokenRefreshing = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);
            return this.authenticationService.requestNewAccessToken()
                .subscribe((res: any) => {
                    if(sessionStorage.getItem('a_token')) {
                        sessionStorage.setItem('a_token', res.access);
                    } else {
                        localStorage.setItem('a_token', res.access);
                    }
                    location.reload();
                    // this.tokenSubject.next(res);
                    // return next.handle(this.addToken(req, res));
                }, (err: any) => {
                    this.isTokenRefreshing=false;
                    return this.logout();
                });
        } else {
            return this.tokenSubject
                .pipe(
                    filter(token => token !=null)
                ),
                take(1),
                switchMap((token: string )=> {
                    return next.handle(this.addToken(req,token));
                });
        }
    }

    private logout() {
        sessionStorage.removeItem('a_token');
        sessionStorage.removeItem('r_token');
        sessionStorage.removeItem('avatar');
        localStorage.removeItem('a_token');
        localStorage.removeItem('r_token');
        localStorage.removeItem('avatar');
        localStorage.removeItem('social');
        this.dataShareService.changeLoginStatus(false);
        this.router.navigateByUrl('/login?returnUrl=' + this.router.url);
    }

}
