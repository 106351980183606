
<app-header></app-header>
<!-- <section #highleveldiv> -->
  <router-outlet (activate)="onActivate($event)"></router-outlet>
<!-- </section>   -->
<app-footer></app-footer>

<!-- Modal for displaying app information if user is using web from mobile-->
<div class="modal fade" id="mobileUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header" style="justify-content: center;">
          <h5 class="text-center">Important Message</h5>
        </div>
        <div class="modal-body">
          <!-- <h5 class="modal-title text-center">Important Message</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
            <div class="card text-center">
              <div class="card-body">
                <p class="card-title">Seems like you are viewing this website from your mobile device. We request you to download our mobile
                  app for better experience, visibility and performance. We provide this app for both android and ios platform.
                </p>
                <div class="container-fluid d-flex">
                  <!-- <div class="row d-flex justify-space-between"> -->
                    <div class="app-option">
                      <a href="https://play.google.com/store/apps/details?id=nsp.platz.nitv.com" target="_blank"><img src="assets/img/android.png" class="img-fluid" alt="Play store"></a>
                    </div>
                    <div class="app-option">
                      <a href="https://apps.apple.com/jm/app/bnpj-buy-now-pay-japan/id1558970505" target="_blank"><img src="assets/img/ios.png" class="img-fluid" alt="App store"></a>
                    </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer align-content-center justify-content-center">
          <button type="button" class="btn btn-orange" data-dismiss="modal" (click)="userAgreement()">I understand and would ike to continue</button>
        </div>
      </div>
    </div>
  </div>