import { Component, OnInit, OnDestroy, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataShareService } from '../utils/data-share.service';
import { NewsService } from './services/news.service';

declare let $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  isLoggedIn: boolean = false;
  newsGenreList: any[] = [];
  newsPreferencesList: any[] = [];
  popularTags: any[] = [];
  fetchNewsTriggered: boolean = false;
  fetchLatestNewsTriggered: boolean = false;
  activeTabPanel: string = 'foryou';
  newsList: any[] = [];
  newsPageNum: number = 0;
  showNewsLoadMore: boolean = false;
  forYouNewsList: any[] = [];
  forYouPageNum: number = 0;
  showForYouNewsLoadMore: boolean = false;
  latestNewsList: any[] = [];
  latestNewsPageNum: number = 0;
  showLatestNewsLoadMore: boolean = false;
  /**
   * variables declared to save user preferences
   */
  allGenreSelected: boolean = false;
  allLanguageAndSourceSelected: boolean = false;
  selectedGenreList: string[] = [];
  requestedPreferenceToSave: string = '';
  selectedLanguageList: string [] = [];
  selectedSourceList: string[] = [];
  /**
   * variabled declared to send as a parameter to filter news
   */
  userGenrePreference: string[] = [];
  userLanguagePreference: string[] = [];
  userSourcePreference: string[] = [];

  constructor(private newsService: NewsService, private dataShareService: DataShareService,
    private router: Router, private toastr: ToastrService) {
      if (localStorage.getItem('a_token') || sessionStorage.getItem('a_token')) {
        this.isLoggedIn = true;
      }
    }

  ngOnInit(): void {
    /**
     * listen or check user login status
     */
    this.dataShareService.currentLoginStatus.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(status => {
          if (localStorage.getItem('a_token') != null || sessionStorage.getItem('a_token') != null) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = status;
          }
        });
    /**
     * function to be called only when the user is logged in
     */
    if(this.isLoggedIn) {
      this.getGenres();
      this.getNewsPreferences();
      this.fetchUserNewsPreference();
    } else {
      this.getForYouNews();
      this.getLatestNews();
      this.getNews();
    }
  }

  /**
   * function to fetch news genre list for genre subscription
   */
  getGenres() {
    this.newsService.getGenreList().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.newsGenreList = res;
        this.checkIfAllGenresAreSelectedOrNot();
      });
  }

  /**
   * function to fetch user saved genre, language and source preference
   */
  fetchUserNewsPreference() {
    this.newsService.getUserNewsPreference().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if(res != null && res.length > 0) {
          this.selectedGenreList = res[0].genre;
          this.userGenrePreference = res[0].genre;
          this.selectedLanguageList = res[0].language;
          this.userLanguagePreference = res[0].language;
          this.selectedSourceList = res[0].source;
          this.userSourcePreference = res[0].source;
        }        
        /**
         * fetch news with preferences
         */
        this.getForYouNews();
        this.getLatestNews();
        this.getNews();
        /**
         * check if all genres are selected or not
         */
        this.checkIfAllGenresAreSelectedOrNot();
        /**
         * make language and news sources selected from user preferences
         */
        if(this.newsPreferencesList != undefined && this.newsPreferencesList.length > 0) {
          this.makeLanguageSelectedFromUserPreference();
          this.makeNewsSourcesSelectedFromUserPreference();
        }
      });
  }

  /**
   * function to fetch news language and source list for user subscription
   */
  getNewsPreferences() {
    this.newsService.getPreferencesList().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.newsPreferencesList = res;
        if(this.newsPreferencesList.length > 0) {
          this.newsPreferencesList.forEach(obj => obj.checked = false);
        } else {
          this.newsPreferencesList = [-1];
        }
        if(this.selectedLanguageList != undefined && this.selectedLanguageList.length > 0) {
          this.makeLanguageSelectedFromUserPreference();
        }
        if(this.selectedSourceList != undefined && this.selectedSourceList.length > 0) {
          this.makeNewsSourcesSelectedFromUserPreference();
        }
      });
  }

  /**
   * making the news language pre-selected based on user language preferences stored in db
   */
  makeLanguageSelectedFromUserPreference() {
      this.newsPreferencesList.forEach(obj => {
        if(this.selectedLanguageList.includes(obj.code)) {
          obj.checked = true;
        }
      });
      this.checkIfEveryLanguageAndSourcesAreSelected();
  }

  /**
   * making the news source pre-selected based on user news source preferences stored in db
   */
  makeNewsSourcesSelectedFromUserPreference() {
    this.newsPreferencesList.forEach(obj => {
      obj.sources.forEach((sourceobj: any) => {
        if(this.selectedSourceList.includes(sourceobj.slug)) {
          sourceobj.checked = true;
        }
      });
    });
    this.checkIfEveryLanguageAndSourcesAreSelected();
  }
  /**
   * function to fetch news
   */
  getNews() {
    this.fetchLatestNewsTriggered = true;
    this.newsService.getNews(this.newsPageNum + 1, this.userGenrePreference, this.userSourcePreference, this.userLanguagePreference)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchLatestNewsTriggered = false;
        this.newsList = this.newsList.concat(res.data);
        this.newsPageNum = Number(res.page);
        this.popularTags = [...new Set(this.popularTags.concat([...new Set(this.newsList.map(news => news.category))]))];
        /**
         * if popular tags length is 0
         * push -1 to the list
         * so that the proper information can be displayed to the users
         */
        if(this.popularTags.length <= 0) {
          this.popularTags = [-1];
        }
        /**
         * if the news length is 0 push -1 to the news list so that the proper information can be displayed to the users
         */
         if(this.newsList.length <= 0) {
          this.newsList = [-1];
        }
        /**
         * toogle load more button based on response length
         * 12 is used because for every request limit=12 is sent
         */
         if(res.data.length < 12) {
          this.showNewsLoadMore = false;
        } else {
          this.showNewsLoadMore = true;
        }
      }, (error: any) => {
        this.fetchLatestNewsTriggered = false;
        // this.toastr.error('Failed to fetch news. Please try again.', 'ERROR');
      });
  }

  /**
   * function to fetch latest news
   */
  getLatestNews() {
    this.newsService.fetchLatestNwws(this.latestNewsPageNum + 1, this.userGenrePreference, this.userSourcePreference, this.userLanguagePreference)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.latestNewsList = this.latestNewsList.concat(res.data);
        this.latestNewsPageNum = Number(res.page);
        this.popularTags = [...new Set(this.popularTags.concat([...new Set(this.latestNewsList.map(news => news.category))]))];
        /**
         * if popular tags length is 0
         * push -1 to the list
         * so that the proper information can be displayed to the users
         */
         if(this.popularTags.length <= 0) {
          this.popularTags = [-1];
        }
        /**
         * if the latest news length is 0 push -1 to the latest news list so that the proper information can be displayed to the users
         */
         if(this.latestNewsList.length <= 0) {
          this.latestNewsList = [-1];
        }
        /**
         * toogle load more button based on response length
         * 12 is used because for every request limit=12 is sent
         */
         if(res.data.length < 12) {
          this.showLatestNewsLoadMore = false;
        } else {
          this.showLatestNewsLoadMore = true;
        }
      }, (error: any) => {
        // this.toastr.error('Failed to latest news. Please try again.', 'ERROR');
      });
  }

  /**
   * function to fetch for you news
   */
  getForYouNews() {
    this.fetchNewsTriggered = true;
    this.newsService.getForYouNews(this.forYouPageNum + 1, this.userGenrePreference, this.userSourcePreference, this.userLanguagePreference)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchNewsTriggered=false;
        this.forYouNewsList = this.forYouNewsList.concat(res.data);
        this.forYouPageNum = Number(res.page);
        this.popularTags = [...new Set(this.popularTags.concat([...new Set(this.forYouNewsList.map(news => news.category))]))];
        /**
         * if popular tags length is 0
         * push -1 to the list
         * so that the proper information can be displayed to the users
         */
         if(this.popularTags.length <= 0) {
          this.popularTags = [-1];
        }
        /**
         * if the for you news length is 0 push -1 to the news list so that the proper information can be displayed to the users
         */
         if(this.forYouNewsList.length <= 0) {
          this.forYouNewsList = [-1];
        }
        /**
         * toogle load more button based on response length
         * 12 is used because for every request limit=12 is sent
         */
        if(res.data.length < 12) {
          this.showForYouNewsLoadMore = false;
        } else {
          this.showForYouNewsLoadMore = true;
        }
      }, (error: any) => {
        this.fetchNewsTriggered=false;
        // this.toastr.error('Failed to for you news. Please try again.', 'ERROR');
      });
  }

  /**
   * function to redirect user to news detail page
   * @param news 
   */
  // detailNews(news: any) {
  //   this.dataShareService.changeNewsDetails(news);
  //   this.router.navigate(['/news/details']);
  // }

  /**
   * function to determine the current active panel
   * @param paneltitle panel title
   */
  toogleActivePanel(paneltitle: string) {
    this.activeTabPanel = paneltitle;
  }

  /**
   * function to redirect user to external link of news
   * @param link news external link
   */
  openNewsUrl(link: string) {
    window.open(link, '_blank');
  }

  /**
   * function to select all genre
   */
  selectAllGenre() {
    if(!this.allGenreSelected) {
      this.newsGenreList.forEach(genre => {
        let index = this.selectedGenreList.indexOf(genre);
        if(index < 0) {
          this.selectedGenreList.push(genre);
        }
      });
      this.allGenreSelected = true;
    } else {
      this.selectedGenreList = [];
      this.allGenreSelected = false;
    }
  }

  checkIfGenreIsSelectedOrNot(genre: string) {
    return this.selectedGenreList.includes(genre);
  }

  /**
   * check if all the genres are selected or not
   */
  checkIfAllGenresAreSelectedOrNot() {
    this.allGenreSelected = this.newsGenreList.length == this.selectedGenreList.length;
  }

  /**
   * function to list the selected genres preferences to store in db
   * @param genreSlug 
   */
  addSelectedGenres(genreSlug: string) {
    let index = this.selectedGenreList.indexOf(genreSlug);
    if(index > -1) {
      this.selectedGenreList.splice(index, 1);
    } else {
      this.selectedGenreList.push(genreSlug);
    }
    this.checkIfAllGenresAreSelectedOrNot();
  }

  /**
   * function to make every language and sources selected
   */
   selectAllLanguageAndSources(event: any) {
    if(event.target.checked) {
      this.newsPreferencesList.forEach(obj => {
        let index = this.selectedLanguageList.indexOf(obj.code);
        // if language is not selected make it selected
        if(index < 0) {
          this.selectedLanguageList.push(obj.code);
          this.updateCheckedStatusOfLanguage(obj.code);
        }
        // make all the sources checked
        obj.sources.forEach((source: any) => {
          let sourceIndex = this.selectedSourceList.indexOf(source.slug);
          if(sourceIndex < 0) {
            this.selectedSourceList.push(source.slug);
            this.updateCheckedStatusOfSource(obj.code, source.slug);
          }
        });
      });
      this.allLanguageAndSourceSelected = true;
    } else {
      this.selectedLanguageList = [];
      this.selectedSourceList = [];
      this.newsPreferencesList.forEach(obj => {
        obj.checked = false;
        obj.sources.forEach((source: any) => {
          source.checked = false;
        });
      });
      this.allLanguageAndSourceSelected = false;
    }
   }

  /**
   * function to list the selected language preferences to store in db
   * @param languageCode 
   */
  addSelectedLanguage(language: any) {
    let index = this.selectedLanguageList.indexOf(language.code);
    if(index > -1) {
      this.selectedLanguageList.splice(index, 1);
      /**
       * remove language sources from selectedSourceList as the language is unchecked
       */
      let languageObj = this.newsPreferencesList.find(obj => obj.code === language.code);
      languageObj.sources.forEach((source: any) => {
        let sourceIndexInSelectedSourceList = this.selectedSourceList.indexOf(source.slug);
        if(sourceIndexInSelectedSourceList > -1) {
          this.selectedSourceList.splice(sourceIndexInSelectedSourceList, 1);
          /**
           * toggle checked status of source
           */
           this.updateCheckedStatusOfSource(language.code, source.slug);
        }
      });
    } else {
      this.selectedLanguageList.push(language.code);
      /**
       * make all sources under the selected language selected
       */
      language.sources.forEach((source: any) => {
        this.addSelectedSources(language.code, source.slug);
      });
    }
    this.updateCheckedStatusOfLanguage(language.code);
  }

  /**
   * function to update checked status of language when selected/deselected
   * @param languageCode 
   */
  updateCheckedStatusOfLanguage(languageCode: string) {
    let languageIndex = this.newsPreferencesList.findIndex(obj => obj.code == languageCode);
    if(languageIndex > -1) {
      this.newsPreferencesList[languageIndex].checked = !this.newsPreferencesList[languageIndex].checked;
    }
    // this.checkIfEveryLanguageAndSourcesAreSelected();
  }

  /**
   * function to make a list of selected news sources to store in db
   * @param languageName 
   * @param sourceSlug 
   */
  addSelectedSources(languageCode: string, sourceSlug: string) {
    let index = this.selectedSourceList.indexOf(sourceSlug);
    if(index > -1) {
      this.selectedSourceList.splice(index, 1);
    } else {
      this.selectedSourceList.push(sourceSlug);
      /**
       * check if language is selected or not
       * if not make the language selected
       */
       let languageIndex = this.selectedLanguageList.indexOf(languageCode);
       if(languageIndex < 0) {
        this.selectedLanguageList.push(languageCode);
        this.updateCheckedStatusOfLanguage(languageCode);
       }
    }
    /**
     * toogle checked status of a source
     */
    this.updateCheckedStatusOfSource(languageCode, sourceSlug);
  }

  /**
   * function to update checked status of news source when selected/deselected
   * @param languageName 
   * @param sourceName 
   */
  updateCheckedStatusOfSource(languageCode: string, sourceSlug: string) {
    let languageIndex = this.newsPreferencesList.findIndex(obj => obj.code === languageCode);
    if(languageIndex > -1) {
      let sourceIndex = this.newsPreferencesList[languageIndex].sources.findIndex((obj: any) => obj.slug === sourceSlug);
      if(sourceIndex > -1) {
        this.newsPreferencesList[languageIndex].sources[sourceIndex].checked = !this.newsPreferencesList[languageIndex].sources[sourceIndex].checked; 
        /**
         * check if any of the sources under a language is selected or not
         * if none of the sources under a particular language is selected
         * make language unselected
         */
        // if(!this.newsPreferencesList[languageIndex].sources.some((obj: any)=> obj.checked === true)){
        //   // make language unselected
        //   this.updateCheckedStatusOfLanguage(languageCode);
        // }
      }
    }
    this.checkIfEveryLanguageAndSourcesAreSelected();
  }

  /**
   * function to check if every language and sources are selected or not
   */
  checkIfEveryLanguageAndSourcesAreSelected() {
    let languagesChecked = this.newsPreferencesList.every(obj => obj.checked === true);
    let allSourcesChecked = true;
    this.newsPreferencesList.forEach(language => {
      allSourcesChecked = allSourcesChecked && language.sources.every((obj:any) => obj.checked === true);
    });
    if(languagesChecked && allSourcesChecked) {
      this.allLanguageAndSourceSelected = true;
    } else {
      this.allLanguageAndSourceSelected = false;
    }
  }

  /**
   * function to store user selected genre list ie. selectedGenreList
   * @returns 
   */
  storeUserNewsPreferences(section: string) {
    if(this.selectedGenreList.length <= 0 && section == 'genre') {
      this.toastr.info("Please atleast one genre.");
      return;
    }
    if(this.selectedLanguageList.length <= 0 && this.selectedSourceList.length <=0 && section == 'languagesource') {
      this.toastr.info("Please atleast one source.");
      return;
    }
    let userPreferenceData = {
      genre: this.selectedGenreList,
      language: this.selectedLanguageList,
      source: this.selectedSourceList
      // language : [...new Set(this.selectedPreferencesList.map(obj => obj.language))],
      // source: [...new Set(this.selectedPreferencesList.map(obj => obj.source))]
    }
    this.requestedPreferenceToSave = section;
    this.newsService.saveUserNewsPreference(userPreferenceData).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.requestedPreferenceToSave = '';
        this.toastr.success(res.success, 'Success');
        this.newsPageNum = 0;
        this.forYouPageNum = 0;
        this.latestNewsPageNum = 0;
        this.forYouNewsList = [];
        this.forYouNewsList = [];
        this.newsList = []
        this.fetchUserNewsPreference();
      }, (err: any) => {
        this.toastr.error('Failed to save preferences', 'Error');
        this.requestedPreferenceToSave = '';
      });
  }

  // addSelectedPreferences(languageName: string, sourceName: string) {
  //   let index = this.selectedPreferencesList.find(obj => obj.language == languageName && obj.source == sourceName);
  //   if(index > 0) {
  //     this.selectedPreferencesList.splice(index, 1);
  //   } else {
  //     this.selectedPreferencesList.push({language: languageName, source: sourceName});
  //   }
  //   this.updateCheckedStatusofSource(languageName, sourceName);
  // }

  // isLanguageSelected(languageName: string) {
  //   let index = this.selectedPreferencesList.indexOf((obj: any) => obj.language == languageName);
  //   if(index > 0) {
  //     return true;
  //   }
  //   return false;
  // }

  // isSourceSelected(languageName: string, sourceName: string) {
  //   let index = this.selectedPreferencesList.indexOf((obj: any) => obj.language == languageName && obj.source == sourceName);
  //   if(index > 0) {
  //     return true;
  //   }
  //   return false;
  // }

  // storeUserPreference() {
  //   if(this.selectedLanguageList.length <= 0 && this.selectedSourceList.length <=0) {
  //     this.toastr.info("Please atleast one source.");
  //     return;
  //   }
  //   let userPreferenceData = {
  //     genre: this.selectedGenreList,
  //     language: this.selectedLanguageList,
  //     source: this.selectedGenreList
  //     // language : [...new Set(this.selectedPreferencesList.map(obj => obj.language))],
  //     // source: [...new Set(this.selectedPreferencesList.map(obj => obj.source))]
  //   }
  //   this.newsPreferenceSaveRequested = true;
  //   this.newsService.saveUserNewsPreference(userPreferenceData).pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.newsPreferenceSaveRequested = false;
  //       this.toastr.success(res.success, 'Success');
  //     }, (err: any) => {
  //       this.newsPreferenceSaveRequested = false;
  //       this.toastr.error('Failed to save preferences', 'Error');
  //     });
  // }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll($event: any) {
  //   if ((document.body.clientHeight + window.scrollY + 2200 ) >= document.body.scrollHeight) {
  //     if(!this.fetchNewsTriggered) {
  //       this.fetchNewsTriggered = true;
  //       if(this.activeTabPanel === 'foryou') {
  //         this.getForYouNews();
  //       }
  //       if(this.activeTabPanel === 'news') {
  //         this.getNews();
  //       }
  //     }
  //   }
  // }

  trackNewsByLink(index: number, item: any) {
    if(item != -1 && item != '-1') {
      return item.link;
    }
    return item;
  }

  trackGenreByGenrename(index: number, item: any) {
    return item;
  }

  trackNewsPreferences(index: number, item: any) {
    if(item != -1 && item != '-1') {
      return item.code ? item.code : item.slug; 
    }
    return item;
  }

  /**
   * unsubcribing active subscription to avaoid memory leak
   */
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
